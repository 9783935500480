<div *ngIf="screenWidth != undefined && ticket">
    <div class="mb-4" *ngIf="screenWidth > 768">
        <div class="opcion text-center mb-2">
            <h5>DATOS DE LA REPARACIÓN</h5>
        </div>
        <p>Nº CASO FABRICANTE DISPOSITIVO</p>
        <p class="text-muted">{{ticket.weblordcase?ticket.weblordcase:'-'}}</p>
        <p>FABRICANTE</p>
        <p class="text-muted">{{ticket.manufacturer?ticket.manufacturer.name:'-'}}</p>
        <p>MODELO</p>
        <p class="text-muted">{{ticket.modelo?ticket.modelo:'-'}}</p>
        <p>NÚMERO DE SERIE</p>
        <p class="text-muted">{{ticket.numserie?ticket.numserie:'-'}}</p>
        <p>DESCRIPCIÓN DE AVERÍA</p>
        <div style="max-height: 200px; overflow-x: auto;">
            <p class="text-muted" [innerHtml]="ticket.contents"></p>
        </div>

        <div class="opcion text-center mt-4">
            <h5 *ngIf="ticket.cliente && ticket.cliente.tipo_cliente != 2">CLIENTE</h5>
            <h5 *ngIf="ticket.cliente && ticket.cliente.tipo_cliente == 2">EMPRESA</h5>
        </div>
        <div *ngIf="ticket.cliente">
            <p>NOMBRE</p>
            <p class="text-muted">{{ticket.cliente.nombre?ticket.cliente.nombre+' '+ticket.cliente.apellido:'-'}}</p>
            <p>E-MAIL</p>
            <p class="text-muted">{{ticket.cliente.email_contacto?ticket.cliente.email_contacto:'-'}}</p>
            <p>TELÉFONO</p>
            <p class="text-muted">{{ticket.cliente.tlf_contacto?ticket.cliente.tlf_contacto:'-'}}</p>
        </div>

        <div *ngIf="ticket.adjuntos">
            <div class="opcion text-center mt-4">
                <h5>ADJUNTOS</h5>
            </div>
            <ngb-toast [autohide]="false" *ngFor="let adjunto of ticket.adjuntos" (click)="descargarAdjunto(adjunto.ID, ticket.ID)" title="Descargar">
                <div class="flex-row between">
                    <div class="flex-row ellipsis">
                        <div class="mr5">
                            <i class="far fa-file-image" *ngIf="adjunto.mime == 'JPG' || adjunto.mime == 'PNG' || adjunto.mime == 'GIF' || adjunto.mime == 'JPEG'"></i>
                            <i class="far fa-file-pdf" *ngIf="adjunto.mime == 'PDF'"></i>
                            <i class="far fa-file-word" *ngIf="adjunto.mime == 'DOC'"></i>
                            <i class="far fa-file-excel" *ngIf="adjunto.mime == 'XLS'"></i>
                            <i class="far fa-file" *ngIf="adjunto.mime != 'JPG' && adjunto.mime != 'PNG' && adjunto.mime != 'GIF' && adjunto.mime != 'JPEG' && adjunto.mime != 'PDF'
                            && adjunto.mime != 'DOC' && adjunto.mime != 'XLS'"></i>
                        </div>
                        <div  class="mr5 ellipsis">
                            {{adjunto.name?adjunto.name:adjunto.filename}}
                        </div>
                    </div>

                    <i class="fas fa-download"></i>
                </div>
            </ngb-toast>
            <p class="text-muted" *ngIf="!ticket.adjuntos || ticket.adjuntos.length == 0">No hay archivos adjuntos</p>
        </div>

        <div *ngIf="dir_recogida">
            <div class="opcion text-center mt-4">
                <h5>DIRECCIÓN DE RECOGIDA</h5>
            </div>
            <p>NOMBRE</p>
            <p class="text-muted">{{dir_recogida.nombre?dir_recogida.nombre+' '+(dir_recogida.apellido?dir_recogida.apellido:''): '-'}}</p>
            <p>DIRECCIÓN</p>
            <p class="text-muted">{{dir_recogida.direccion?dir_recogida.direccion: '-'}}</p>
            <p>LOCALIDAD</p>
            <p class="text-muted">{{dir_recogida.localidad?dir_recogida.localidad.poblacion: '-'}}</p>
            <p>PROVINCIA</p>
            <p class="text-muted">{{dir_recogida.provincia?dir_recogida.provincia.provincia: '-'}}</p>
            <p>TELÉFONO</p>
            <p class="text-muted">{{dir_recogida.num_telefono?dir_recogida.num_telefono: '-'}}</p>
        </div>

        <div *ngIf="dir_envio">
            <div class="opcion text-center mt-4">
                <h5>DIRECCIÓN DE ENVIO</h5>
            </div>
            <p>NOMBRE</p>
            <p class="text-muted">{{dir_envio.nombre?dir_envio.nombre+' '+(dir_envio.apellido?dir_envio.apellido:''): '-'}}</p>
            <p>DIRECCIÓN</p>
            <p class="text-muted">{{dir_envio.direccion?dir_envio.direccion: '-'}}</p>
            <p>LOCALIDAD</p>
            <p class="text-muted">{{dir_envio.localidad?dir_envio.localidad.poblacion: '-'}}</p>
            <p>PROVINCIA</p>
            <p class="text-muted">{{dir_envio.provincia?dir_envio.provincia.provincia: '-'}}</p>
            <p>TELÉFONO</p>
            <p class="text-muted">{{dir_envio.num_telefono?dir_envio.num_telefono: '-'}}</p>
        </div>
    </div>
    <div *ngIf="screenWidth <= 768">
        <div ngbDropdown class="d-inline-block">
            <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle><i class="fa fa-list" aria-hidden="true"></i>
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <div class="opcion text-center mb-2">
                    <h5>DATOS DE LA REPARACIÓN</h5>
                </div>
                <p>Nº CASO FABRICANTE DISPOSITIVO</p>
                <p class="text-muted">{{ticket.weblordcase?ticket.weblordcase:'-'}}</p>
                <p>FABRICANTE</p>
                <p class="text-muted">{{ticket.manufacturer?ticket.manufacturer.name:'-'}}</p>
                <p>MODELO</p>
                <p class="text-muted">{{ticket.modelo?ticket.modelo:'-'}}</p>
                <p>NÚMERO DE SERIE</p>
                <p class="text-muted">{{ticket.numserie?ticket.numserie:'-'}}</p>
                <p>DESCRIPCIÓN DE AVERÍA</p>
                <div style="max-height: 200px; overflow-x: auto;">
                    <p class="text-muted" [innerHtml]="ticket.contents"></p>
                </div>

                <div class="opcion text-center mt-4">
                    <h5 *ngIf="ticket.cliente && ticket.cliente.tipo_cliente != 2">CLIENTE</h5>
                    <h5 *ngIf="ticket.cliente && ticket.cliente.tipo_cliente == 2">EMPRESA</h5>
                </div>
                <div *ngIf="ticket.cliente">
                    <p>NOMBRE</p>
                    <p class="text-muted">{{ticket.cliente.nombre?ticket.cliente.nombre+' '+ticket.cliente.apellido:'-'}}</p>
                    <p>E-MAIL</p>
                    <p class="text-muted">{{ticket.cliente.email_contacto?ticket.cliente.email_contacto:'-'}}</p>
                    <p>TELÉFONO</p>
                    <p class="text-muted">{{ticket.cliente.tlf_contacto?ticket.cliente.tlf_contacto:'-'}}</p>
                </div>

                <div *ngIf="ticket.adjuntos">
                    <div class="opcion text-center mt-4">
                        <h5>ADJUNTOS</h5>
                    </div>
                    <ngb-toast [autohide]="false" *ngFor="let adjunto of ticket.adjuntos"  (click)="descargarAdjunto(adjunto.ID, ticket.ID)" title="Descargar">
                        <div class="flex-row between">
                            <div class="flex-row ellipsis">
                                <div class="mr5">
                                    <i class="far fa-file-image" *ngIf="adjunto.mime == 'JPG' || adjunto.mime == 'PNG' || adjunto.mime == 'GIF' || adjunto.mime == 'JPEG'"></i>
                                    <i class="far fa-file-pdf" *ngIf="adjunto.mime == 'PDF'"></i>
                                    <i class="far fa-file-word" *ngIf="adjunto.mime == 'DOC'"></i>
                                    <i class="far fa-file-excel" *ngIf="adjunto.mime == 'XLS'"></i>
                                    <i class="far fa-file" *ngIf="adjunto.mime != 'JPG' && adjunto.mime != 'PNG' && adjunto.mime != 'GIF' && adjunto.mime != 'JPEG' && adjunto.mime != 'PDF'
                                    && adjunto.mime != 'DOC' && adjunto.mime != 'XLS'"></i>
                                </div>
                                <div  class="mr5 ellipsis">
                                    {{adjunto.name?adjunto.name:adjunto.filename}}
                                </div>
                            </div>

                            <i class="fas fa-download"></i>
                        </div>
                    </ngb-toast>
                    <p class="text-muted" *ngIf="!ticket.adjuntos || ticket.adjuntos.length == 0">No hay archivos adjuntos</p>
                </div>

                <div *ngIf="dir_recogida">
                    <div class="opcion text-center mt-4">
                        <h5>DIRECCIÓN DE RECOGIDA</h5>
                    </div>
                    <p>NOMBRE</p>
                    <p class="text-muted">{{dir_recogida.nombre?dir_recogida.nombre+' '+dir_recogida.apellido: '-'}}</p>
                    <p>DIRECCIÓN</p>
                    <p class="text-muted">{{dir_recogida.direccion?dir_recogida.direccion: '-'}}</p>
                    <p>LOCALIDAD</p>
                    <p class="text-muted">{{dir_recogida.localidad?dir_recogida.localidad.poblacion: '-'}}</p>
                    <p>PROVINCIA</p>
                    <p class="text-muted">{{dir_recogida.provincia?dir_recogida.provincia.provincia: '-'}}</p>
                    <p>TELÉFONO</p>
                    <p class="text-muted">{{dir_recogida.num_telefono?dir_recogida.num_telefono: '-'}}</p>
                </div>

                <div *ngIf="dir_envio">
                    <div class="opcion text-center mt-4">
                        <h5>DIRECCIÓN DE ENVIO</h5>
                    </div>
                    <p>NOMBRE</p>
                    <p class="text-muted">{{dir_envio.nombre?dir_envio.nombre+' '+dir_envio.apellido: '-'}}</p>
                    <p>DIRECCIÓN</p>
                    <p class="text-muted">{{dir_envio.direccion?dir_envio.direccion: '-'}}</p>
                    <p>LOCALIDAD</p>
                    <p class="text-muted">{{dir_envio.localidad?dir_envio.localidad.poblacion: '-'}}</p>
                    <p>PROVINCIA</p>
                    <p class="text-muted">{{dir_envio.provincia?dir_envio.provincia.provincia: '-'}}</p>
                    <p>TELÉFONO</p>
                    <p class="text-muted">{{dir_envio.num_telefono?dir_envio.num_telefono: '-'}}</p>
                </div>
            </div>
        </div>
    </div>
</div>