import { Product } from "./product";

export class CartItem {
    productId: number;
    productName: string;
    productPrice: number;
    quantity: number;
    imgUrl: string; 
    month: number;

    constructor(product: any) {
        this.productId = product.id;
        this.productName = product.name;
        this.productPrice = product.price;
        this.quantity = 1;
        this.imgUrl = product.imgUrl[0];
        this.month = product.monthsPossible[0];

    }
}
