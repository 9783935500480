import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ReparaService {

	constructor(
		private http: HttpClient,
		private authSvc: AuthService,
	) { }

	solicitarReparacion(datosForm: any): Observable<any> {
		return new Observable<any>(observer => {

			let data = Object.assign({}, datosForm);
			data.aceptance_direccion_entrega = datosForm.aceptance_direccion_entrega ? 'on' : 'off';
			data.aceptance_direccion_facturacion = datosForm.aceptance_direccion_facturacion ? 'on' : 'off';
			data.checkbox_envio_comunicaciones = datosForm.checkbox_envio_comunicaciones ? 'on' : 'off';

			let options = {
				headers: new HttpHeaders({
					// 'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			//Estamos logueados
			const accessToken = this.authSvc.getAccessToken();
			let url = environment.API_URL + '/tickets/solicitud-reparacion-flex';
			if(accessToken && this.authSvc.isUserLoggedIn()) {
				options = {
					headers: new HttpHeaders({
						'Authentication': 'Bearer ' + accessToken,
						'Content-Type': 'application/json; charset=utf-8'
					})
				};
				url = environment.API_URL + '/tickets/add-reparacion-flex';
			}

			this.http.post(url, data, options).subscribe(
				(response: any) => {
					console.log("ws solicitarReparacion", response);
					if (response.error) {
						observer.error(response.error);
					}
					else {
						observer.next(response);
					}
				},
				(err) => {
					console.log("error ws solicitarReparacion", err);
					console.log(data)
					let msgError = "Se ha producido un error guardando los datos";

					if (err.error.error === 'invalid_garantia10') {
						msgError = err.error.error_description;
					} else if(err.error.error === 'email_contacto') {
						msgError = "El email ya está registrado. Por favor, inicia sesión desde el apartado mi cuenta.";
					}

					observer.error(msgError);
				}
			);

			return { unsubscribe() { } };
		});
	}

	enviarDatosSmartphone(datosForm: any): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					// 'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			let data = Object.assign({}, datosForm);
			data.checkbox_envio_comunicaciones = datosForm.checkbox_envio_comunicaciones ? 'on' : 'off';

			const url = environment.API_URL + '/util/solicitud-repara-smartphone';
			this.http.post(url, data, options).subscribe(
				(response: any) => {
					console.log("ws envioDatosSmartphone", response);
					if (response.error) {
						observer.error(response.error);
					}
					else {
						observer.next(response);
					}
				},
				(err) => {
					console.log("error ws envioDatosSmartphone", err);
					console.log(data)
					let msgError = "Se ha producido un error guardando los datos";

					observer.error(msgError);
				}
			);

			return { unsubscribe() { } };
		});
	}

	getDireccionTicket(ticketId: number, tipoDireccion: number) {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/tickets/' + ticketId + "/direccionCliente/" + tipoDireccion;
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getDirección", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getDireccion", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return { unsubscribe() { } };
		});		
	}
	
	solcitarSintomaAutoDiagnosis(tipoElemento: string, marca: string, modelo: string, sintomas: any, lang: string) {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const data = {
				tipoElemento: tipoElemento,
				marca: marca,
				modelo: modelo,
				sintomas: sintomas,
				language: lang
			}

			const url = environment.API_URL + '/diagnosis/autodiagnosis-bot-flex';
			this.http.post(url, data, options).subscribe(
				(response: any) => {
					console.log("ws solicitarSintomaAutoDiagnosis", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws solicitarSintomaAutoDiagnosis", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return { unsubscribe() { } };
		});		
	}
}
