<footer class="border-top">
    <div class="container" *ngIf="screenWidth != undefined && screenWidth > 990">
        <div class="flex-row between vcenter">
            <div>
                <img class="img-fluid mb-4 mt-2" src="../../../assets/img/flex-logo-white.png">
                <div class="flex-column" style="text-align: left; white-space: nowrap; font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;">
                    <b>Avenida Umbrete 35. Pol. Industrial PIBO</b>
                    <b>Bollullos de la Mitación (Sevilla)</b>
                    <b style="color: var(--primary-color);"> <a style="text-decoration: none; color: var(--primary-color);" href="tel:+955776900">+34 955 776 900</a> / <a style="text-decoration: none; color: var(--primary-color);" href="mailto:flex@exxita.com">flex@exxita.com</a></b>
                </div>
            </div>
            <div class="flex-row end vcenter">
                <a href="../../../assets/terms-files/Certificado9001_ES_2021-10-18.pdf" download="Certificado9001.pdf" style="width: 12%; min-width: 40px; margin-right: 20px;">
                    <img class="logo-sello" src="../../../assets/img/SelloAENORISO9001_NEG-trans.png" style="width: 100%;">
                </a>
                <a href="../../../assets/terms-files/Certificado14001_ES_2021-10-18.pdf" download="Certificado14001.pdf" style="width: 12%; min-width: 40px; margin-right: 20px;">
                    <img class="logo-sello" src="../../../assets/img/SelloAENORISO14001-2015_NEG-trans.png" style="width: 100%;">
                </a>
                <a href="../../../assets/terms-files/Certificado27001_ES_2021-06-03.pdf" download="Certificado27001.pdf" style="width: 12%; min-width: 40px; margin-right: 20px;">
                    <img class="logo-sello" src="../../../assets/img/SelloAENORISO-IEC 27001.png" style="width: 100%;">
                </a>

                <img src="../../../assets/img/logo-fcs.png" style="width: 9%; min-width: 40px; margin-right: 20px;">
                <a href="https://exxita.com//" style="width: 30%; min-width: 40px;">
                    <img src="../../../assets/img/powered-cyg-logo.png" style="width: 100%;">
                </a>
            </div>
        </div>

        <hr>

        <div class="flex-row vcenter">
            <div class="grow2" style="font-size: 14px;">
                <label>© flex 2022 - <a class="noStyleLink" href="/privacy">Politica de privacidad</a> - <a class="noStyleLink" href="/conditions">Términos y condiciones</a> - <a class="noStyleLink" href="/legaladvice">Aviso legal</a> - <a class="noStyleLink" href="/cookies">Uso de Cookies</a> - <a class="noStyleLink" href="/security">Politica de seguridad de la información</a></label>
            </div>
            <div class="grow1" style="white-space: nowrap; text-align: right;">
                <a href="https://twitter.com/ExxitaFlex" target="_blank"><i class="fab fa-twitter fa-1x social"></i></a>
                <a href="https://www.facebook.com/flexbyexxita" target="_blank"><i class="fab fa-facebook fa-1x social"></i></a>
                <a href="https://www.linkedin.com/company/exxitaflex" target="_blank"><i class="fab fa-linkedin-in fa-1x social"></i></a>
                <a href="https://www.instagram.com/exxitaflex/?hl=es" target="_blank"><i class="fab fa-instagram fa-1x social"></i></a>
            </div>
        </div>
    </div>

    <div *ngIf="screenWidth != undefined && screenWidth > 320 && screenWidth <= 990" style="padding: 20px;">
        <div class="flex-column">
            <div class="flex-row between vcenter">
                <img class="img-fluid mb-4 mt-2" src="../../../assets/img/flex-logo-white.png" style="max-width: 100px;">
                <div class="flex-column" style="text-align: left; white-space: nowrap; font-size: 10px; font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;">
                    <b>Avenida Umbrete 35. Pol. Industrial PIBO</b>
                    <b>Bollullos de la Mitación (Sevilla)</b>
                    <b style="color: var(--primary-color);"><a style="text-decoration: none; color: var(--primary-color);" href="tel:+955776900">+34 955 776 900</a> / <a style="text-decoration: none; color: var(--primary-color);" href="mailto:flex@exxita.com">flex@exxita.com</a></b>
                </div>
            </div>
            <div class="flex-row end vcenter">
                <a href="../../../assets/terms-files/Certificado9001_ES_2021-10-18.pdf" download="Certificado9001.pdf" style="width: 12%; min-width: 40px; margin-right: 20px;">
                    <img class="logo-sello" src="../../../assets/img/SelloAENORISO9001_NEG-trans.png" style="width: 100%;">
                </a>
                <a href="../../../assets/terms-files/Certificado14001_ES_2021-10-18.pdf" download="Certificado14001.pdf" style="width: 12%; min-width: 40px; margin-right: 20px;">
                    <img class="logo-sello" src="../../../assets/img/SelloAENORISO14001-2015_NEG-trans.png" style="width: 100%;">
                </a>
                <a href="../../../assets/terms-files/Certificado27001_ES_2021-06-03.pdf" download="Certificado27001.pdf" style="width: 12%; min-width: 40px; margin-right: 20px;">
                    <img class="logo-sello" src="../../../assets/img/SelloAENORISO-IEC 27001.png" style="width: 100%;">
                </a>

                <img src="../../../assets/img/logo-fcs.png" style="width: 9%; min-width: 40px; margin-right: 20px;">

                <a href="https://exxita.com//" style="width: 30%; min-width: 40px;">
                    <img src="../../../assets/img/powered-cyg-logo.png" style="width: 100%;">
                </a>
            </div>
        </div>

        <hr>

        <div class="flex-column">
            <div style="font-size: 10px;">
                <label>© flex 2022 - <a class="noStyleLink" href="/privacy">Politica de privacidad</a> - <a class="noStyleLink" href="/conditions">Términos y condiciones</a> - <a class="noStyleLink" href="/legaladvice">Aviso legal</a> - <a class="noStyleLink" href="/cookies">Uso de Cookies</a> - <a class="noStyleLink" href="/security">Politica de seguridad de la información</a></label>
            </div>
            <div style="white-space: nowrap; text-align: right;">
                <a href="https://twitter.com/ExxitaFlex" target="_blank"><i class="fab fa-twitter fa-1x social"></i></a>
                <a href="https://www.facebook.com/flexbyexxita" target="_blank"><i class="fab fa-facebook fa-1x social"></i></a>
                <a href="https://www.linkedin.com/company/exxitaflex" target="_blank"><i class="fab fa-linkedin-in fa-1x social"></i></a>
                <a href="https://www.instagram.com/exxitaflex/?hl=es" target="_blank"><i class="fab fa-instagram fa-1x social"></i></a>
            </div>
        </div>
    </div>

    <div *ngIf="screenWidth != undefined && screenWidth <= 320" style="padding: 20px;">
        <div class="flex-column">
            <div class="flex-row between vcenter">
                <img class="img-fluid mb-4 mt-2" src="../../../assets/img/flex-logo-white.png" style="max-width: 100px;">
            </div>
            <div class="flex-row between vcenter">
                <div class="flex-column" style="text-align: left; white-space: nowrap; font-size: 10px; font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;">
                    <b>Avenida Umbrete 35. Pol. Industrial PIBO</b>
                    <b>Bollullos de la Mitación (Sevilla)</b>
                    <b style="color: var(--primary-color);"><a style="text-decoration: none; color: var(--primary-color);" href="tel:+955776900">+34 955 776 900</a> / <a style="text-decoration: none; color: var(--primary-color);" href="mailto:flex@exxita.com">flex@exxita.com</a></b>
                </div>
            </div>
            <div class="flex-row end vcenter mt-4">
                <a href="../../../assets/terms-files/Certificado9001_ES_2021-10-18.pdf" download="Certificado9001.pdf" style="width: 12%; min-width: 40px; margin-right: 20px;">
                    <img class="logo-sello" src="../../../assets/img/SelloAENORISO9001_NEG-trans.png" style="width: 100%;">
                </a>
                <a href="../../../assets/terms-files/Certificado14001_ES_2021-10-18.pdf" download="Certificado14001.pdf" style="width: 12%; min-width: 40px; margin-right: 20px;">
                    <img class="logo-sello" src="../../../assets/img/SelloAENORISO14001-2015_NEG-trans.png" style="width: 100%;">
                </a>
                <a href="../../../assets/terms-files/Certificado27001_ES_2021-06-03.pdf" download="Certificado27001.pdf" style="width: 12%; min-width: 40px; margin-right: 20px;">
                    <img class="logo-sello" src="../../../assets/img/SelloAENORISO-IEC 27001.png" style="width: 100%;">
                </a>
            </div>
            <div class="flex-row center vcenter mt-2">
                <img src="../../../assets/img/logo-fcs.png" style="width: 9%; min-width: 40px; margin-right: 20px;">

                <a href="https://exxita.com//" style="width: 30%; min-width: 40px;">
                    <img src="../../../assets/img/powered-cyg-logo.png" style="width: 100%;">
                </a>
            </div>
        </div>
        
        <hr>

        <div class="flex-column">
            <div style="font-size: 10px;">
                <label>© flex 2022 - <a class="noStyleLink" href="/privacy">Politica de privacidad</a> - <a class="noStyleLink" href="/conditions">Términos y condiciones</a> - <a class="noStyleLink" href="/legaladvice">Aviso legal</a> - <a class="noStyleLink" href="/cookies">Uso de Cookies</a> - <a class="noStyleLink" href="/security">Politica de seguridad de la información</a></label>
            </div>
            <div style="white-space: nowrap; text-align: right;">
                <a href="https://twitter.com/ExxitaFlex" target="_blank"><i class="fab fa-twitter fa-1x social"></i></a>
                <a href="https://www.facebook.com/flexbyexxita" target="_blank"><i class="fab fa-facebook fa-1x social"></i></a>
                <a href="https://www.linkedin.com/company/exxitaflex" target="_blank"><i class="fab fa-linkedin-in fa-1x social"></i></a>
                <a href="https://www.instagram.com/exxitaflex/?hl=es" target="_blank"><i class="fab fa-instagram fa-1x social"></i></a>
            </div>
        </div>
    </div>
</footer>