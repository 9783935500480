import { Component, HostListener, OnInit } from '@angular/core';
import { UpdateForm } from 'src/app/models/updateForm';
import { AuthService } from 'src/app/services/auth/auth.service';
import * as bcrypt from 'bcryptjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss']
})
export class AccountDetailsComponent implements OnInit {

  id = Number(localStorage.getItem('idCliente'))
  nombre = ''
  apellidos = ''
  nombreVisible = ''
  email_contacto = ''
  UpdateModel = new UpdateForm();
  Clientepass = ''

  msgSuccessChange = ''
  msgErrorChange = ''

  screenHeight: number | undefined;
  screenWidth: number | undefined;

  constructor(
    private authSvc: AuthService,
    private router: Router,
  ) { 
    this.onResize();
  }

  ngOnInit(): void {
    this.authSvc.getMe().subscribe(
      (data: any) => {
        this.nombre = data.nombre
        this.apellidos = data.apellido
        this.nombreVisible = data.nombreVisible
        this.email_contacto = data.email_contacto.replaceAll("'", "")
        this.Clientepass = data.password

      },
      (err) => {
        console.log('Error -> ' + err)
      }
    );
  }

  actualizaCliente(id: number, data: any) {
    this.msgErrorChange = ''
    this.authSvc.modificarCliente(id, data).subscribe(
      (data: any) => {
        console.log(data)
        this.msgSuccessChange = 'Cambios realizados correctamente'
      },
      (err) => {
        this.msgErrorChange = err;
      }
    );
  }

  async send() {
    if (this.UpdateModel.oldpassword != undefined) {
      const salt = bcrypt.genSaltSync(10);
      let pass = bcrypt.hashSync(this.UpdateModel.password, salt);

      await this.comparaContraseña(this.UpdateModel.oldpassword, this.Clientepass).then(centinela => {
        console.log(centinela);

        if (centinela) {
          if (this.UpdateModel.password == this.UpdateModel.password2) {
            let data = {
              'nombre': this.UpdateModel.name,
              'apellido': this.UpdateModel.surname,
              'nombreVisible': this.UpdateModel.visibleName,
              'email': this.UpdateModel.email,
              'password': pass
            }
            this.actualizaCliente(this.id, data)

            this.cerrarSesion()

          } else {
            this.msgErrorChange = 'Las contraseñas no coindicen'
          }
        } else {
          this.msgErrorChange = 'La contraseña actual es errónea';
        }
      })

    } else {
      this.actualizaCliente(this.id, this.UpdateModel)
      setTimeout(function () {
        window.location.reload();
      }, 3000);
    }

  }

  async comparaContraseña(pass: string, passEncr: string) {

    let validPassword = await bcrypt.compare(pass, passEncr);

    return validPassword
  }

  cerrarSesion(){
    this.authSvc.logout();
      
  }


  @HostListener('window:resize', ['$event'])
  onResize(event?: undefined) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }


}
