<div class="container mt-4 mb-4">
    <p>La expansión de las nuevas tecnologías y la apertura a redes
        públicas, ofrecen a Éxxita Be Circular, S.A. nuevos
        canales para llegar a los clientes y establecer relaciones con otras
        entidades, incrementando así sus procesos de negocio. No
        obstante, estas nuevas tecnologías y relaciones elevan el nivel de
        riesgo asociado a la exposición de la información y
        comunicaciones de Éxxita Be Circular, S.A.
    </p>
    <br>
    <br>
    <p>La </p>
    <p class="font-weight-bold">información</p>
    <p> se considera </p>
    <p class="font-weight-bold">un activo estratégico</p>
    <p> de Éxxita Be Circular, S.A.. En este contexto, se ha establecido un
        marco de referencia el cual define las directrices de actuación,
        mediante un enfoque 
    </p>
    <p class="font-weight-bold">preventivo, informativo, reactivo y de
        aprendizaje, para garantizar que la integridad, la disponibilidad,
        confidencialidad, autenticidad y trazabilidad de la información de
        Éxxita Be Circular, S.A.
    </p>
    <p> y de sus clientes no puedan
        verse comprometidas.
    </p>
    <br>
    <br>
    <p>Se establecen los siguientes principios y criterios de Seguridad de
        la Información:
    </p>
    <br>
    <p class="text-muted">● </p>
    <p>El compromiso de la Dirección en la Mejora Continua de sus
        actividades, productos y servicios y del propio Sistema de
        Seguridad de la Información mediante el análisis de datos.
    </p>
    <br>
    <p class="text-muted">● </p>
    <p>El compromiso del cumplimiento con la legislación y
        normativa vigente que sean de aplicación, así como otros
        requisitos en materia de seguridad de la información suscritos
        con nuestros clientes, manteniendo una conducta de
        permanente adecuación a los mismos.
    </p>
    <br>
    <p class="text-muted">● </p>
    <p>Éxxita Be Circular, S.A. asume como premisa de
        su Política de Seguridad de la Información la adaptación tanto
        de los sistemas de información como de los dispositivos de
        almacenamiento físico a las normativas y/o reglamentaciones
        municipales, autonómicas, ministeriales y órganos
        regulatorios.
    </p>
    <br>
    <p class="text-muted">● </p>
    <p>El objetivo es proporcionar a los empleados, clientes y
        visitantes las adecuadas medidas de seguridad dentro de las
        instalaciones y de los sistemas de información de Éxxita Be Circular, S.A.        
    </p>
    <br>
    <p class="text-muted">● </p>
    <p>La seguridad de la información que Éxxita Be Circular, 
        S.A. recoge, procesa, almacena y transmite, es
        esencial para garantizar su patrimonio y el de los accionistas.        
    </p>
    <br>
    <p class="text-muted">● </p>
    <p>Éxxita Be Circular, S.A. puede limitar el acceso a
        su información, tanto de personas como de objetos físicos o
        lógicos, para lo que se ha establecido un sistema de control
        de accesos.        
    </p>
    <br>
    <p class="text-muted">● </p>
    <p>La seguridad es una actividad que compete a todos los
        empleados y colaboradores de Éxxita Be Circular, 
        S.A., quienes deberán desarrollar sus actividades
        procurando una adecuada protección de los activos de CORE
        & GLOBAL IT SOLUTIONS, S.A., conociendo, asumiendo y
        aplicando las normas y procedimientos de seguridad.        
    </p>
    <br>
    <p class="text-muted">● </p>
    <p>La seguridad de la información, de los sistemas y de los
        dispositivos que la recogen, procesan, almacenan y trasmiten,
        es primordial para garantizar la continuidad operativa del
        negocio. Para garantizar la Confidencialidad, Integridad,
        Disponibilidad, Autenticidad y Trazabilidad de dicha
        información se han establecido las Políticas, Normas,
        Procedimientos y Mecanismos de Seguridad necesarios.
        Atenderá pues a lo descrito en el Cuerpo Normativo Interno de
        Éxxita Be Circular, S.A.
    </p>
    <br>
    <p class="text-muted">● </p>
    <p>La seguridad de la información deberá considerarse como
        parte de la operativa habitual, estando presente y aplicándose
        desde el diseño inicial de los procesos y sistemas de
        información.        
    </p>
    <br>
    <p class="text-muted">● </p>
    <p>Es imprescindible disponer de inventarios actualizados de los
        servicios y de los activos de información que los sustentan,
        sus responsables o titulares y los riesgos asociados; ello
        posibilita el continuo análisis de estos y permite el diseño y
        aplicación de nuevas medidas y mecanismos de seguridad
        con la adecuada gestión de cambios.        
    </p>
    <br>
    <p class="text-muted">● </p>
    <p>Se conocerá el valor de la información, para lo cual se
        especificarán métodos de clasificación de la misma según el
        nivel de importancia para la organización, desarrollando los
        procesos asociados para su tratamiento, almacenamiento,
        transmisión, desclasificación, acceso, reproducción y
        destrucción según su nivel de clasificación.        
    </p>
    <br>
    <br>

    <p>Fdo. D. José Ángel Costa</p>
    <br>
    <p>Responsable Seguridad de la Información</p>
    

</div>