<header>
    <div class="header-div flex-row between vcenter" *ngIf="screenWidth != undefined && screenWidth > 768">
        <a href="/" class="mr10"><img class="logo grow3"
            [src]="getUrlLogo()" 
            (error)="errorLogoHandler($event)"></a>

        <form name="searchForm" [formGroup]="searchForm" class="grow4 mr10" novalidate (keyup.enter)="goSearching()">
            <div class="search-bar">
                <input type="text" formControlName="busqueda" class="input-btn" #busqueda
                    placeholder="Estoy buscando..." name="search-object">
                <div class="search-button">
                    <a [routerLink]="['products/search']" [queryParams]="{filter: busqueda.value}"><i
                            class="fas fa-search icon"></i></a>
                </div>
            </div>
        </form>

        <div class="vl mr10"></div>

        <div class="info-tel grow1 mr10 flex-column " >
            <span class="d-flex info secondary-text">LLÁMANOS AHORA</span>
            <span class="d-flex"><a style="text-decoration: none; color: black" href="tel:+955776900">+34 955 776 900</a></span>
            <!--<a class="d-flex chat-link primary-color pointer">o Chat Online</a>-->
        </div>

        <div class="vl mr10"></div>

        <div class="flex-row between vcenter grow3">
            <div class="flex-row vcenter">
                <img style="max-height: 5vh;" class="mr10" src="../../../assets/img/logout-user.png">

                <div>
                    <span *ngIf="!logueado" class="info secondary-text">¡Hola!</span>
                    <span *ngIf="logueado" class="info secondary-text"> ¡Hola {{cliente?.nombre}}!</span>

                    <a href="/profile" *ngIf="logueado" class="menu-legend flex-column">
                        Mi cuenta
                    </a>

                    <a href="/profile" *ngIf="!logueado" class="menu-legend flex-column">
                        Inicia sesión
                    </a>
                </div>
            </div>
                
            <div class="flex-row around vcenter">
                <!--
                    <div class="mr10">
                        <img style="max-height: 3vh;" src="../../../assets/img/like-heart.png">
                    </div>
                -->
                <div class="dropdown"> <!-- dropdown-toggle -->
                    <button class="btn btn-default" routerLink="/shoppingCart"
                        type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                        aria-expanded="false">
                        <div class="badge-parent">
                        <span class="notify-badge" *ngIf="totalCarrito && (totalCarrito | async) != 0">{{totalCarrito | async }}</span>
                        <img style="max-height: 3vh;" src="../../../assets/img/shopping-bag.png">
                    </div>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="flex-row between vcenter phoneheader" *ngIf="screenWidth != undefined && screenWidth <= 768">
        <div class="grow2">
            <a href="/" class="mr10" style="margin: 10px;"><img class="logo grow3" style="width: 30px;"
                [src]="getUrlLogo()" 
                (error)="errorLogoHandler($event)"></a>
        </div>

        <ng-template #popContent class="d-none">
            <form name="searchForm" [formGroup]="searchForm" class="grow4 mr10" novalidate (keyup.enter)="goSearching(); p.close()">
                <div class="search-bar">
                    <input type="text" formControlName="busqueda" class="input-btn" #busqueda
                        placeholder="Estoy buscando..." name="search-object">
                    <div class="search-button">
                        <a (click)="goSearching(); p.close()"><i class="fas fa-search icon"></i></a>
                    </div>
                </div>
            </form>
        </ng-template>

          
        <div class="flex-row vcenter grow2" style="height: 100% !important;">
            <div class="lb grow1" style="padding: 10px; text-align: center; height: 100% !important;" [ngbPopover]="popContent" #p="ngbPopover" [autoClose]="'outside'" popoverTitle="Estoy buscando...">
                <i style="cursor: pointer;" class="fas fa-search icon"></i>
            </div>
            <div class="lb grow1" style="padding: 10px; text-align: center;" (click)="navigate('profile')">
                <img style="max-height: 4vh; cursor: pointer;" src="../../../assets/img/logout-user.png">
            </div>
            <div class="lb grow1" style="padding: 10px; text-align: center;">
                <div class="dropdown">
                    <button class="btn btn-default " routerLink="/shoppingCart"
                        type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                        aria-expanded="false">
                        <div class="badge-parent">
                        <span class="notify-badge" *ngIf="totalCarrito && (totalCarrito | async) != 0">{{totalCarrito | async }}</span>
                        <img style="max-height: 3vh;" src="../../../assets/img/shopping-bag.png">
                    </div>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="flex-row between vcenter submenu" *ngIf="screenWidth != undefined && screenWidth > 992">
        <div class="primary-color grow1">
            <label class="decides">tú decides:</label>
        </div>
        <div class="flex-row around center grow2">
            <a href="/repara" style="text-decoration:none">
                <div class="flex-row pointer" [ngClass]="background == 'repara' ? 'fondoverdeRepara' : 'fondoRepara'">
                    <label class="pointer" style="margin-left: 50px;">repara_</label>
                </div>
            </a>
            <a href="https://exxitashop.com/" style="text-decoration:none">
                <div class="flex-row pointer" [ngClass]="background == 'products' ? 'fondoverdeAlquiler' : 'fondoAlquiler'" >
                    <label class="pointer" style="margin-left: 50px;">alquila_</label>
                </div>
            </a>
            <a href="https://exxitashop.com/" style="text-decoration:none">
                <div class="flex-row pointer"[ngClass]="background == 'products/buy' ? 'fondoverdeCompra' : 'fondoCompra'">
                    <label class="pointer" style="margin-left: 50px;">compra_</label>
                </div>
            </a> 
        </div>
        <div class="grow1" class="powered">
            <a href="https://exxita.com/">
                <img style="max-height: 10vh; padding: 9px;" src="../../../assets/img/powered-cyg-logo.png">
            </a>
        </div>
    </div>

    <div class="flex-row between vcenter submenu" *ngIf="screenWidth != undefined && screenWidth <= 992 && screenWidth > 770 ">
        <div class="primary-color decides1">
            <label>tú decides:</label>
        </div>
        <div class="flex-row around start">
                <a href="/repara" style="text-decoration:none">
                    <div class="flex-column pointer" [ngClass]="background == 'repara' ? 'fondoverdeRepara' : 'fondoRepara'">
                        <label class="pointer" style="margin-top: 50px;">repara_</label>
                    </div>
                </a>
                <a href="https://exxitashop.com/" style="text-decoration:none">
                    <div class="flex-column pointer" [ngClass]="background == 'products' ? 'fondoverdeAlquiler' : 'fondoAlquiler'">
                        <label class="pointer"  style="margin-top: 50px;">alquila_</label>
                    </div>
                </a>

                <a href="https://exxitashop.com/" style="text-decoration:none">
                    <div class="flex-column pointer" [ngClass]="background == 'products/buy' ? 'fondoverdeCompra' : 'fondoCompra'">
                        <label class="pointer" style="margin-top: 50px;">compra_</label>
                    </div>
                </a>
        </div>
        <div class="powered">
            <a href="https://exxita.com/">
                <img style="max-height: 5vh;" src="../../../assets/img/powered-cyg-logo.png">
            </a>
        </div>
    </div>

    <div class="flex-row vcenter submenu" *ngIf="screenWidth != undefined && screenWidth <= 770 && screenWidth > 500 ">
        <div class="primary-color decides1">
            <label>tú decides:</label>
        </div>
        <div class="col-1"></div>
        <a href="/repara" style="text-decoration:none">
            <div class="flex-column pointer" [ngClass]="background == 'repara' ? 'fondoverdeRepara' : 'fondoRepara'">
                <label class="pointer" style="margin-top: 50px;">repara_</label>
            </div>
        </a>
        <a href="https://exxitashop.com/" style="text-decoration:none">
            <div class="flex-column pointer" [ngClass]="background == 'products' ? 'fondoverdeAlquiler' : 'fondoAlquiler'">
                <label class="pointer"  style="margin-top: 50px;">alquila_</label>
            </div>
        </a>
        <a href="https://exxitashop.com/" style="text-decoration:none">
            <div class="flex-column pointer" [ngClass]="background == 'products/buy' ? 'fondoverdeCompra' : 'fondoCompra'">
                <label class="pointer" style="margin-top: 50px;">compra_</label>
            </div>
        </a> 
        
        <div class="col powered">
            <a href="https://exxita.com/">
                <img style="max-height: 5vh;" src="../../../assets/img/powered-cyg-logo.png">
            </a>
        </div>
    </div>
    

    <div class="flex-row vcenter submenu" *ngIf="screenWidth != undefined && screenWidth <= 500">
        <div class="primary-color decides1">
            <label>tú decides:</label>
        </div>
        <div class="col-1"></div>
        <a href="/repara" style="text-decoration:none">
            <div class="flex-column pointer" [ngClass]="background == 'repara' ? 'fondoverdeRepara' : 'fondoRepara'">
                <label class="pointer" style="margin-top: 40px;">repara_</label>
            </div>
        </a>
        <a href="https://exxitashop.com/" style="text-decoration:none">
            <div class="flex-column pointer" [ngClass]="background == 'products' ? 'fondoverdeAlquiler' : 'fondoAlquiler'">
                <label class="pointer"  style="margin-top: 40px;">alquila_</label>
            </div>
        </a>

        <a href="https://exxitashop.com/" style="text-decoration:none">
            <div class="flex-column pointer" [ngClass]="background == 'products/buy' ? 'fondoverdeCompra' : 'fondoCompra'">
                <label class="pointer" style="margin-top: 40px;">compra_</label>
            </div>
        </a> 

        <div class="col powered">
            <a href="https://exxita.com/">
                <img style="max-height: 5vh;" src="../../../assets/img/powered-cyg-logo.png">
            </a>
        </div>
    </div>

    
</header>