<img class="img-fluid mt-4 mb-4" src="../../../assets/img/productosPrueba/asusVivoWatch.png" alt="Responsive image">

<div class="container-lg mt-4 mb-4">
    <h6>
        HOME
        <i class="fa fa-angle-right" style="margin-left: 10px; margin-right: 10px;" aria-hidden="true"></i>
        TIENDA
        <hr>
    </h6>

</div>

<form #mainForm="ngForm" [formGroup]="formFiltros">
    <div class="row" *ngIf="products && screenWidth != undefined && screenWidth > 990">

        <div class="col-3 mt-4 mb-4">
            <div #categorias>
                <h6 style="font-weight: bold;">CATEGORÍAS</h6>
                <hr>

                <div class="col-12 mb-4">
                    <div class="flex-column">
                        <label><input type="radio" name="category" formControlName="category" value="1">&nbsp;Smartphones</label>
                        <label><input type="radio" name="category" formControlName="category" value="72">&nbsp;Ordenadores</label>
                        <label><input type="radio" name="category" formControlName="category" value="8">&nbsp;Tablets</label>
                        <label><input type="radio" name="category" formControlName="category" value="47">&nbsp;Monitores</label>
                        <label><input type="radio" name="category" formControlName="category" value="78">&nbsp;Smart Watches</label>
                    </div>
                </div>
            </div>

            <div #precioMensual class="mt-4 mb-4">
                <h6 style="font-weight: bold;">PRECIO MENSUAL</h6>
                <hr>

                <div class="col-12">
                    <div class="row">

                        <ng5-slider [(ngModel)]="sliderValue" [ngModelOptions]="{standalone: true}" [(value)]="minValue" [(highValue)]="maxValue"
                            [options]="optionsPrice"></ng5-slider>

                    </div>
                </div>

            </div>

            <div #periodoAlquiler class="mt-2" *ngIf="mesesTotales.length > 0">
                <h6 style="font-weight: bold;">PERIODO MÍNIMO DE ALQUILER</h6>
                <hr>
                <div class="row">

                    <div class="col-3 mt-2" *ngFor="let mes of mesesTotales">

                        <button  [ngClass]="{'border-secondary': monthSelected != mes, 'btn-success': monthSelected == mes}" class="btn border-secondary"
                            (click)="selectMonth(mes)">
                            <p>{{mes}}+</p>mes
                        </button>


                    </div>

                </div>

                <div class="row mt-2">
                    <div class="col-3"></div>
                    <div class="col-3">
                        <button *ngIf="monthSelected != undefined" class="btn btn-danger btn-sm"
                            (click)="deleteSelectedMonth()">
                            <p>Deseleccionar</p>
                        </button>
                    </div>
                </div>

            </div>

            <div #marcas class="mt-4">
                <h6 style="font-weight: bold;">MARCAS</h6>
                <hr>

                <div class="col-12">
                    <div class="form-group flex-column">

                        <label *ngFor="let marca of marcasMostrar">
                            <input class="form-check-input" type="checkbox" name="marcas" value="{{marca.id}}" (change)="changeMarca($event)">
                            &nbsp;{{marca.name}}
                        </label>
                    </div>

                    <!--<a class="text-muted" href="/#">Ver todas las marcas</a>-->
                </div>
                
                <div class="row">
                    <button class="btn btn-outline-success" type="submit" (click)="filtrar()">Filtrar</button>
                    <button class="btn btn-outline-danger mt-2" type="submit" (click)="deleteFilter()">Borrar
                        filtros</button>
                </div>

            </div>

        </div>

        <div class="col-9 mt-4 mb-4">
            <div class="menu-legend2 flex-row between">

                <!--<div class="col-7">
                    <p class="text-muted"> Ordenar por:
                        <button class="btn border-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Ordenar por defecto
                        </button>
                    </p>
                </div>-->
                <div class="flex-row vcenter">
                    <label class="text-muted"> Mostrar: &nbsp;</label>
                    <div ngbDropdown>
                        <button class="btn border-secondary" 
                            id="dropdownBasic1" ngbDropdownToggle>{{pageSize}}</button>
                        <div ngbDropdownMenu>
                            <button ngbDropdownItem (click)="cambiarSizePag(12)">12</button>
                            <button ngbDropdownItem (click)="cambiarSizePag(24)">24</button>
                            <button ngbDropdownItem (click)="cambiarSizePag(48)">48</button>
                        </div>
                    </div>
                </div>
                <!--<div>
                    <button class="btn"><i class="fas fa-border-all"></i></button>
                    <button class="btn"><i class="fas fa-th-list"></i></button>
                </div>-->

            </div>

            <div class="container">
                <div class="row mt-4">
                    <div class="flex-row start" style="flex-wrap: wrap;">
                        <div class="flex-column stretch grow1 div-dispositivo-alquilado" *ngFor="let movimiento of products;">
                            <a href="/products/{{movimiento.elemento.ID}}" *ngIf="movimiento.elemento">
                                <div class="green-border-div">
                                    <!--<button type="button" class="btn btn-primary">
                                        <img src="../../../assets/img/shopping-bag.png">
                                    </button>-->
                                    <div class="div-img-content">
                                        <div class="flex-column center vcenter">
                                            <img [src]="(movimiento.elemento.urlImagenes && movimiento.elemento.urlImagenes.length>0 && movimiento.elemento.urlImagenes[0].url)?rutaImagen+movimiento.elemento.urlImagenes[0].url:''">
                                        </div>
                                    </div>
                                </div>
                            </a>
                
                            <div class="flex-column" *ngIf="movimiento.elemento">
                                <span class="secondary-text">{{movimiento.elemento.tipo_dispositivo?movimiento.elemento.tipo_dispositivo.name:'-'}}</span>
                                <label>{{movimiento.elemento.name?movimiento.elemento.name:'-'}}</label>
                                <!--<div class="flex-row">
                                    <star-rating [value]="movimiento.elemento.rating" [totalstars]="5" checkedcolor="#5bc500" uncheckedcolor="gray"
                                    size="24px" [readonly]="true"></star-rating>
                                </div>-->
                                <div>
                                    <span class="secondary-text">desde</span>&nbsp;<label>{{movimiento.elemento.precioMinimo}}€</label>&nbsp;<span class="secondary-text">al mes</span>
                                </div>
                                
                                <div>
                                    <app-dialog-select-options-update [product]="movimiento.elemento"></app-dialog-select-options-update>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr class="mt-4">

                    <div class="flex-row between">
                        <div class="flex-row vcenter">
                            <label class="text-muted"> Mostrar: &nbsp;</label>
                            <div ngbDropdown>
                                <button class="btn border-secondary" 
                                    id="dropdownBasic1" ngbDropdownToggle>{{pageSize}}</button>
                                <div ngbDropdownMenu>
                                    <button ngbDropdownItem (click)="cambiarSizePag(12)">12</button>
                                    <button ngbDropdownItem (click)="cambiarSizePag(24)">24</button>
                                    <button ngbDropdownItem (click)="cambiarSizePag(48)">48</button>
                                </div>
                            </div>
                        </div>
                        <div>
                            <ngb-pagination [collectionSize]="totalProductos" [pageSize]="pageSize" [(page)]="numPage" aria-label="Paginación" [maxSize]="5" [ellipses]="false" (pageChange)="filtrar(false)"></ngb-pagination>
                        </div>

                    </div>

                </div>
            </div>

        </div>
    </div>



    <div class="row" *ngIf="products && screenWidth != undefined && screenWidth <= 990">

        <div class="row">
            <div class="col-9"></div>
            <div class="col-3">
                <button class="btn btn-sm btn-outline-dark mb-2 mr-2" (click)="openVerticallyCentered(content)">FILTROS
                    <i class="fas fa-filter"></i></button>
            </div>
        </div>

        <div class="col mb-4">
            <div class="flex-row between vcenter" style="margin-bottom: 10px;">

                <!--<div class="col-7">
                    <p class="text-muted"> Ordenar por:
                        <button class="btn border-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Ordenar por defecto
                        </button>
                    </p>
                </div>-->
                <div class="flex-row vcenter">
                    <label class="text-muted"> Mostrar: &nbsp;</label>
                    <div ngbDropdown>
                        <button class="btn border-secondary" 
                            id="dropdownBasic1" ngbDropdownToggle>{{pageSize}}</button>
                        <div ngbDropdownMenu>
                            <button ngbDropdownItem (click)="cambiarSizePag(12)">12</button>
                            <button ngbDropdownItem (click)="cambiarSizePag(24)">24</button>
                            <button ngbDropdownItem (click)="cambiarSizePag(48)">48</button>
                        </div>
                    </div>
                </div>
                <!--<div class="col-2">
                    <button class="btn"><i class="fas fa-border-all"></i></button>
                    <button class="btn"><i class="fas fa-th-list"></i></button>
                </div>-->

            </div>

            <div class="container">
                <div class="row">
                    <div class="flex-row start" style="flex-wrap: wrap;">
                        <div class="flex-column stretch grow1 div-dispositivo-alquilado xs" *ngFor="let movimiento of products;">
                            <a href="/products/{{movimiento.elemento.ID}}" *ngIf="movimiento.elemento">
                                <div class="green-border-div">
                                    <!--<button type="button" class="btn btn-primary">
                                        <img src="../../../assets/img/shopping-bag.png">
                                    </button>-->
                                    <div class="div-img-content">
                                        <div class="flex-column center vcenter">
                                            <img [src]="(movimiento.elemento.urlImagenes && movimiento.elemento.urlImagenes.length>0 && movimiento.elemento.urlImagenes[0].url) ?rutaImagen+movimiento.elemento.urlImagenes[0].url:''">
                                        </div>
                                    </div>
                                </div>
                            </a>
                
                            <div class="flex-column" *ngIf="movimiento.elemento">
                                <span class="secondary-text">{{movimiento.elemento.tipo_dispositivo?movimiento.elemento.tipo_dispositivo.name:'-'}}</span>
                                <label style="font-size: 3vw;">{{movimiento.elemento.name?movimiento.elemento.name:'-'}}</label>
                                <!--<div class="flex-row">
                                    <star-rating [value]="movimiento.elemento.rating" [totalstars]="5" checkedcolor="#5bc500" uncheckedcolor="gray"
                                    size="24px" [readonly]="true"></star-rating>
                                </div>-->
                                <div>
                                    <span class="secondary-text">desde</span>&nbsp;<label>{{movimiento.elemento.precioMinimo}}€</label>&nbsp;<span class="secondary-text">al mes</span>
                                </div>
                                
                                <div>
                                    <app-dialog-select-options-update [product]="movimiento.elemento"></app-dialog-select-options-update>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr class="mt-4">

                    <div class="flex-row between vcenter">

                        <div class="flex-row vcenter">
                            <label class="text-muted"> Mostrar: &nbsp;</label>
                            <div ngbDropdown>
                                <button class="btn border-secondary" 
                                    id="dropdownBasic1" ngbDropdownToggle>{{pageSize}}</button>
                                <div ngbDropdownMenu>
                                    <button ngbDropdownItem (click)="cambiarSizePag(12)">12</button>
                                    <button ngbDropdownItem (click)="cambiarSizePag(24)">24</button>
                                    <button ngbDropdownItem (click)="cambiarSizePag(48)">48</button>
                                </div>
                            </div>
                        </div>
                        <div>
                            <ngb-pagination [collectionSize]="totalProductos" [pageSize]="pageSize" [(page)]="numPage" aria-label="Paginación" [maxSize]="5" [ellipses]="false" (pageChange)="filtrar(false)"></ngb-pagination>
                        </div>

                    </div>

                </div>
            </div>

        </div>
    </div>
    </form>


    <ng-template #content let-modal>
        <div class="modal-header">
            <h4 class="modal-title m-0" style="font-size: 24px;">Filtros</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body" style="max-height: 70vh; overflow: hidden; overflow-y: auto;">
            <form #mainForm="ngForm" [formGroup]="formFiltros">
            <div #categorias>
                <h6 style="font-weight: bold;">CATEGORÍAS</h6>
                <hr>

                <div class="col mb-4">
                    <div class="flex-column">
                        <label><input type="radio" name="category" formControlName="category" value="1">&nbsp;Smartphones</label>
                        <label><input type="radio" name="category" formControlName="category" value="72">&nbsp;Ordenadores</label>
                        <label><input type="radio" name="category" formControlName="category" value="8">&nbsp;Tablets</label>
                        <label><input type="radio" name="category" formControlName="category" value="47">&nbsp;Monitores</label>
                        <label><input type="radio" name="category" formControlName="category" value="78">&nbsp;Smart Watches</label>
                    </div>
                </div>
            </div>

            <div #precioMensual class="mt-4 mb-4">
                <h6 style="font-weight: bold;">PRECIO MENSUAL</h6>
                <hr>

                <div class="col-12">
                    <div class="row">

                        <ng5-slider [(ngModel)]="sliderValue" [ngModelOptions]="{standalone: true}" [(value)]="minValue" [(highValue)]="maxValue"
                            [options]="optionsPrice"></ng5-slider>

                    </div>
                </div>

            </div>

            <div #periodoAlquiler class="mt-2" *ngIf="mesesTotales.length > 0">
                <h6 style="font-weight: bold;">PERIODO MÍNIMO DE ALQUILER</h6>
                <hr>
                <div class="row">

                    <div class="col-3 mt-2" *ngFor="let mes of mesesTotales">

                        <button  [ngClass]="{'border-secondary': monthSelected != mes, 'btn-success': monthSelected == mes}" class="btn border-secondary"
                            (click)="selectMonth(mes)">
                            <p>{{mes}}+</p>mes
                        </button>


                    </div>

                </div>

                <div class="row mt-2">
                    <div class="col-3"></div>
                    <div class="col-3">
                        <button *ngIf="monthSelected != undefined" class="btn btn-danger btn-sm"
                            (click)="deleteSelectedMonth()">
                            <p>Deseleccionar</p>
                        </button>
                    </div>
                </div>

            </div>

            <div #marcas class="mt-4">
                <h6 style="font-weight: bold;">MARCAS</h6>
                <hr>

                <div class="col-12">
                    <label *ngFor="let marca of marcasMostrar">
                        <input class="form-check-input" type="checkbox" name="marcas" value="{{marca.id}}" (change)="changeMarca($event)">
                        &nbsp;{{marca.name}}
                    </label>
                </div>
            </div>
            </form>

            <div class="row justify-content-center">
                <div class="col-10">
                    <button class="btn btn-outline-success" style="width: 100%;" (click)="filtrar()">Filtrar</button>
                </div>
                <div class="col-10">
                    <button class="btn btn-outline-danger mt-2" style="width: 100%;" (click)="deleteFilter()">Borrar filtros</button>
                </div>
            </div>

        </div>
    </ng-template>