import { Component, ElementRef, HostListener, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CartItem } from 'src/app/models/cart-item';
import { Product } from 'src/app/models/product';
import { MessageService } from 'src/app/services/message.service';
import { ProductService } from 'src/app/services/product.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-dialog-select-options',
  templateUrl: './dialog-select-options.component.html',
  styleUrls: ['./dialog-select-options.component.scss']
})
export class DialogSelectOptionsComponent implements OnInit {

  @Input() product: Product | undefined;
  closeResult: string | undefined;
  monthSelected: number | undefined;
  cantidad: number | undefined;
  imgPreview: string = '';
  imagesToshow: Array<any> = [];
  emailString: string;

  meses: number[] = []
  mesesPrecio = new Array();

  screenHeight: number | undefined;
  screenWidth: number | undefined;

  @ViewChild("modalAddSuccess") modalAddSuccess: ElementRef | undefined
  
  constructor(
    private modalService: NgbModal,
    private messageSvc: MessageService,
    private storageSvc: StorageService,
    private productService: ProductService,
  ) {
    this.onResize()
    this.emailString = "mailto:flex@cygit.com";

  }

  ngOnInit(): void {
    if(this.product && this.product.imgUrl){
      this.product.imgUrl.forEach(element => {
        if(this.imagesToshow.length < 4){
          this.imagesToshow.push(element);
        }
      });
    }
  }

  openVerticallyCentered(content: any) {
    this.modalService.open(content, { centered: true, size: 'xl' });
    if(this.product != undefined){
      this.getAlquiler(this.product.id)
    }
  }

  addToCart(cantidad: string): void {
    if (this.product != undefined) {
      if(localStorage.getItem('cart') == null){
        localStorage.setItem('cart', '[]');
      }
      console.log(this.product.name + ' añadido')
      let res = new CartItem(this.product);
      res.quantity = Number(cantidad);
      if (this.monthSelected != undefined) {
        res.month = this.monthSelected;
      }

      let cartItems = this.storageSvc.getCart();
      let productoMetido = false;;
      if (cartItems.length == 0) {
        cartItems.push(res)
        productoMetido = true;
      } else {
        if(res.month > 0){
          productoMetido = true;
        }

        for (let i = 0; i < cartItems.length && productoMetido == false; i++) {
          if(cartItems[i].month > 0){
            productoMetido = true;
          }
          
          if (cartItems[i].productId == res.productId && cartItems[i].month == res.month) {
            cartItems[i].quantity += res.quantity;
            productoMetido = true;
          }
        }

        if (productoMetido == false) {

          cartItems.push(res)

        }
      }

      this.storageSvc.setCart(cartItems);
    }

    this.modalService.open(this.modalAddSuccess, { centered: true }); //AQUI ME HE QUEDAO
    this.modalService.dismissAll();
  }

  selectMonth(month: number): void {
    console.log('Seleccionado ' + month + ' meses')
    this.monthSelected = month;
    for (let i = 0; i < this.mesesPrecio.length; i++) {
      if (this.mesesPrecio[i].meses == month) {
        if (this.product != undefined) {
          this.product.price = this.mesesPrecio[i].precio;
        }
      }
    }
    console.log(this.monthSelected)
  }

  getAlquiler(productId: number) {
    this.productService.getAlquiler(productId).subscribe(
      (data) => {
        this.meses = []
        this.mesesPrecio = []
        if (this.product != undefined) {
          this.mesesPrecio = data;
          this.monthSelected = data[0].meses
          this.product.price = data[0].precio
          for (let i = 0; i < data.length; i++) {
            this.meses.push(data[i].meses);
          }
          var sortedArray: number[] = this.meses.sort((n1, n2) => n1 - n2);
          this.meses = sortedArray;
          this.product.monthsPossible = this.meses
          console.log(this.meses)
          console.log(data)
        }
      },
      (err) => {
        console.log('Error -> ' + err)
      }
    );
  }


  @HostListener('window:resize', ['$event'])
  onResize(event?: undefined) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  showImage(urlImagen: string){
    this.imgPreview = urlImagen;
  }

}
