<div class="mt-4">
    <div *ngIf="subdomain">
        <div class="row">
            <div class="col-4"></div>
            <div class="col-4">
                <img class="img-fluid mb-4" [src]="subdomainImage">
            </div>
        </div>
    </div>

    <h3 class="text-center mt-4">RECUPERAR CONTRASEÑA</h3>

    <div class="mt-4" *ngIf="showRemember" id="remember-form">

        <form name="rememberForm" [formGroup]="rememberForm" (ngSubmit)="onRemember()" novalidate>

            <h5 class="text-center"> Indique su correo electrónico </h5>

            <div class="row">

                <div class="col-3"></div>
                <div class="col text-center">
                    <input matInput formControlName="usuarioRemember" class="form-control" #email maxlength="80" name="Email"
                    required>
                </div>
                <div class="col-3"></div>
                
            </div>

            <div class="row">

                <div class="col-5"></div>
                <div class="col-2">
                    <div class="row">
                        <button type="submit" class="btn btn-dark mt-4">Enviar</button>
                    </div>
                    <div class="row">
                        <button type="cancel" class="btn btn-dark mt-4 mb-4" routerLink="/">Cancelar</button>
                    </div>
                </div>
                <div class="col-5"></div>

            </div>

        </form>
    </div>

    <div class="mt-4" *ngIf="showRemember !== true && showCambiado !== true">

        <div class="mt-4" *ngIf="msgSuccessRemember && msgSuccessRemember !== ''">
            <div class="alert alert-success" role="alert">
                {{msgSuccessRemember}}
            </div>

        </div>


        <form name="rememberCodeForm" [formGroup]="rememberCodeForm" (ngSubmit)="onChangePass()" novalidate>

            <h5 class="text-center mt-2"> Indique su código de seguridad</h5>
            <input matInput formControlName="codeRemember" class="form-control" #codeRemember maxlength="80"
                name="CodeRemember" required>


            <h5 class="text-center mt-2"> Indique su nueva contraseña</h5>

            <input matInput formControlName="password" class="form-control" #password maxlength="80" name="Password"
                type="password" required>


            <div class="row">

                <div class="col-4"></div>
                <div class="col-4">
                    <div class="row">
                        <button type="submit" class="btn btn-dark mt-4">Enviar</button>
                    </div>
                    <div class="row">
                        <button type="cancel" class="btn btn-dark mt-4 mb-4"
                            (click)="showRemember=true; msgErrorChangePass=''">Cancelar</button>
                    </div>
                </div>
            </div>

        </form>


    </div>

    <div class="mt-4" *ngIf="showCambiado === true">
        <div class="mt-4" *ngIf="msgSuccessChangePass && msgSuccessChangePass !== ''">
            <div class="alert alert-success" role="alert">
                {{msgSuccessChangePass}}
            </div>

        </div>
    </div>

    <div class="mt-4" *ngIf="msgErrorRemember && msgErrorRemember !== ''">
        <div class="alert alert-danger" role="alert">
            {{msgErrorRemember}}
        </div>

    </div>

    <div class="mt-4" *ngIf="msgErrorChangePass && msgErrorChangePass !== ''">
        <div class="alert alert-danger" role="alert">
            {{msgErrorChangePass}}
        </div>

    </div>



</div>