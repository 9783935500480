<div class="container mt-4 mb-4">
    <h1 class="text-green">A. General</h1>
    <p>
        Los tratamientos de datos personales que se llevan a cabo en la presente web son titularidad de Éxxita Be Circular, S.A., (Exxita SA en adelante) 
        a través de su marca Flex, con domicilio en A-474, Km 7, Bollullos de la Mitación, Sevilla (España), CIF número A41631904 y correo electrónico de contacto 
        info@exxita.com; sociedad inscrita en el Registro Mercantil de Sevilla en fecha 19 de enero de 1994 al Tomo 1884 de Sociedades, Folio 37, Hoja nº SE 16918. 
        Inscripción 1.
    </p>
    
    <hr>

    <h1 class="text-green mt-2">B. ¿Qué hacemos con tus datos?</h1>
    <p>Los tratamientos, y por tanto, el uso que de los datos personales
        que se llevan a cabo son los siguientes:</p>
    <br>

    <p class="text-muted">● </p>
    <p class="text-green">Atención al cliente</p>
    <p>, para la gestión de dudas, consultas y
        reclamaciones de los usuarios de la web y sus servicios.
    </p>
    <br>

    <p class="text-muted">● </p>
    <p class="text-green">Comunicaciones comerciales y publicidad</p>
    <p>, para la
        realización de comunicaciones comerciales y campañas
        publicitarias por distintas vías como redes sociales o
        televisión, así como la propia gestión de las redes sociales.</p>
    <br>

    <p class="text-muted">● </p>
    <p class="text-green">Analítica de la web</p>
    <p>, para revisar el correcto uso de la web, su
        utilización y corrección de posibles errores, como es usada
        por los usuarios.
    </p>
    <br>

    <p class="text-muted">● </p>
    <p class="text-green">Gestión de clientes</p>
    <p>, para gestionar los clientes de la web
        físicos, cobros y servicios prestados.
    </p>
    <br>

    <p class="text-muted">● </p>
    <p class="text-green">Gestión de Proveedores</p>
    <p>, para gestionar los servicios dados
        a través de terceros.</p>
    <br>

    <p class="text-muted">● </p>
    <p class="text-green">Reparación equipos:</p>
    <p> 
        Se accede a los datos personales almacenados en el terminal para su formateo previo a la reparación, sin visualización de los mismos, 
        en el caso de que el cliente lo solicite expresamente y si es posible.
    </p>
    <br>

    <p class="text-muted">● </p>
    <p class="text-green">Web:</p>
    <p> IP, huella digital (Navegador, Sistema Operativo,
        cookies).
    </p>
    <br>

    <hr>

    <h1 class="text-green">C. ¿Qué datos personales tratamos de nuestros usuarios?</h1>
    <p>Los datos que se tratan, en función de los tratamientos que se
        llevan a cabo con los mismos, son los siguientes:</p>
    <br>
    <br>

    <table>
        <thead>
            <tr>
                <th>Tratamiento</th>
                <th>Datos asociados</th>
                <th>Plazo de Conservación</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>
                    Comuncaciones, Publicidad y redes sociales
                </td>
                <td>
                    E-mail, Nombre y apellidos. Usuario RRSS
                </td>
                <td>
                    Necesario para la gestión
                </td>
            </tr>
            <tr>
                <td>
                    Reparación equipos
                </td>
                <td>
                    Mail Imágenes navegador
                </td>
                <td>
                    Necesario para la reparación y sólo en el caso de petición expresa del cliente
                </td>
            </tr>
            <tr>
                <td>
                    Atención al cliente
                </td>
                <td>
                    Nombre Apellidos Dirección<br />Teléfono Mail Nº Cuenta corriente
                </td>
                <td>
                    Dos años después del fin de la relación
                </td>
            </tr>
            <tr>
                <td>
                    Recursos humanos
                </td>
                <td>
                    Nombre Apellidos Email<br />Dirección Teléfono IP Fecha nacimiento<br />Cookies Usuario RRSS<br />
                    Nº Cuenta corriente Formación Puesto
                </td>
                <td>
                    Tiempo necesario
                </td>
            </tr>
            <tr>
                <td>
                    Analítica de la web
                </td>
                <td>
                    id de usuario cookies
                </td>
                <td>
                    Tiempo necesario
                </td>
            </tr>
            <tr>
                <td>
                    Gestión de clientes
                </td>
                <td>
                    Nombre y apellidos E-mail<br />Dirección Teléfono Fecha nacimiento DNI
                </td>
                <td>
                    Dos años después del fin de la relación
                </td>
            </tr>
            <tr>
                <td>
                    Proveedores
                </td>
                <td>
                    Nombre y apellidos E-mail<br />Nº CC Dirección
                </td>
                <td>
                    Dos años después del fin de la relación
                </td>
            </tr>
            <tr>
                <td>
                    Clientes potenciales
                </td>
                <td>
                    Nombre y apellidos E-mail<br />Teléfono
                </td>
                <td>
                    Tiempo necesario
                </td>
            </tr>
        </tbody>
    </table>

    <hr>

    <h1 class="text-green">D. ¿Por cuánto tiempo se almacenan?</h1>
    <p>Con carácter general, respecto a los tratamientos de la web,
        almacenamos la información por el tiempo necesario para poder
        llevar a cabo los distintos tratamientos, y como máximo, un plazo
        de dos años, salvo petición de cancelación por parte del usuario,
        sin perjuicio a lo establecido en el punto C anterior.
    </p>

    <hr>

    <h1 class="text-green">E. ¿A quién cedemos datos?</h1>
    <p>Cualquier dato que sea recogido por Éxxita Be Circular, 
        S.A., bajo ningún concepto, será cedido a terceras empresas, salvo
        los casos que a continuación se detallan:
    </p>
    <br>

    <p class="text-muted">● </p>
    <p>Safe Computing, donde se almacena la información, relativo
        al tratamiento de consultas.
    </p>
    <br>
    <p class="text-muted">● </p>
    <p>Google Analitycs, para poder hacer uso de las cookies.
    </p>
    <br>
    <p class="text-muted">● </p>
    <p>A las distintas redes sociales al utilizar sus servicios, para lo
        que se estará a lo establecido en las misma, cuando se
        acceda desde la web a las mismas.        
    </p>
    <br>

    <br>
    <p>Las actuales Redes donde se encuentra Éxxita Be Circular, S.A. 
        son las siguientes y se ceden datos conforme a las
        propias políticas de privacidad de cada una de ellas.</p>
        <div style="display:flex;justify-content: center; align-items: center;gap: 10px;">
            <div class="social-icon-container">
                <a href="https://www.facebook.com/CyGitsolutions" target="_blank" h-use-smooth-scroll="true" rel="noopener">
                <div class="icon-container h-social-icon h-global-transition">
                    <div class="h-icon-svg" style="width: 32px">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="facebook-square" viewBox="0 0 1536 1896.0833">
                        <path d="M1248 128q119 0 203.5 84.5T1536 416v960q0 119-84.5 203.5T1248 1664h-188v-595h199l30-232h-229V689q0-56 23.5-84t91.5-28l122-1V369q-63-9-178-9-136 0-217.5 80T820 666v171H620v232h200v595H288q-119 0-203.5-84.5T0 1376V416q0-119 84.5-203.5T288 128h960z"></path>
                    </svg>
                    </div>
                </div>
                </a>
            </div>
            <div class="social-icon-container">
                <a href="//www.linkedin.com/company/exxitabecircular" target="_blank" h-use-smooth-scroll="true" rel="noopener">
                <div class="icon-container h-social-icon h-global-transition">
                    <div class="h-icon-svg" style="width: 32px">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="linkedin-square" viewBox="0 0 1536 1896.0833">
                        <path d="M237 1414h231V720H237v694zm246-908q-1-52-36-86t-93-34-94.5 34-36.5 86q0 51 35.5 85.5T351 626h1q59 0 95-34.5t36-85.5zm585 908h231v-398q0-154-73-233t-193-79q-136 0-209 117h2V720H595q3 66 0 694h231v-388q0-38 7-56 15-35 45-59.5t74-24.5q116 0 116 157v371zm468-998v960q0 119-84.5 203.5T1248 1664H288q-119 0-203.5-84.5T0 1376V416q0-119 84.5-203.5T288 128h960q119 0 203.5 84.5T1536 416z"></path>
                    </svg>
                    </div>
                </div>
                </a>
            </div>
            <div class="social-icon-container">
                <a href="https://twitter.com/ExxitaBCircular" target="_blank" h-use-smooth-scroll="true" rel="noopener">
                <div class="icon-container h-social-icon h-global-transition">
                    <div class="h-icon-svg" style="width: 32px">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="twitter-square" viewBox="0 0 1536 1896.0833">
                        <path d="M1280 610q-56 25-121 34 68-40 93-117-65 38-134 51-61-66-153-66-87 0-148.5 61.5T755 722q0 29 5 48-129-7-242-65T326 550q-29 50-29 106 0 114 91 175-47-1-100-26v2q0 75 50 133.5t123 72.5q-29 8-51 8-13 0-39-4 21 63 74.5 104t121.5 42q-116 90-261 90-26 0-50-3 148 94 322 94 112 0 210-35.5t168-95 120.5-137 75-162T1176 746q0-18-1-27 63-45 105-109zm256-194v960q0 119-84.5 203.5T1248 1664H288q-119 0-203.5-84.5T0 1376V416q0-119 84.5-203.5T288 128h960q119 0 203.5 84.5T1536 416z"></path>
                    </svg>
                    </div>
                </div>
                </a>
            </div>
            <div class="social-icon-container">
                <a href="https://www.youtube.com/user/CyGITSolutions" target="_blank" h-use-smooth-scroll="true" rel="noopener">
                <div class="icon-container h-social-icon h-global-transition">
                    <div class="h-icon-svg" style="width: 32px">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="youtube-square" viewBox="0 0 1536 1896.0833">
                        <path d="M919 1303v-157q0-50-29-50-17 0-33 16v224q16 16 33 16 29 0 29-49zm184-122h66v-34q0-51-33-51t-33 51v34zM532 915v70h-80v423h-74V985h-78v-70h232zm201 126v367h-67v-40q-39 45-76 45-33 0-42-28-6-17-6-54v-290h66v270q0 24 1 26 1 15 15 15 20 0 42-31v-280h67zm252 111v146q0 52-7 73-12 42-53 42-35 0-68-41v36h-67V915h67v161q32-40 68-40 41 0 53 42 7 21 7 74zm251 129v9q0 29-2 43-3 22-15 40-27 40-80 40-52 0-81-38-21-27-21-86v-129q0-59 20-86 29-38 80-38t78 38q21 29 21 86v76h-133v65q0 51 34 51 24 0 30-26 0-1 .5-7t.5-16.5V1281h68zM785 457v156q0 51-32 51t-32-51V457q0-52 32-52t32 52zm533 713q0-177-19-260-10-44-43-73.5t-76-34.5q-136-15-412-15-275 0-411 15-44 5-76.5 34.5T238 910q-20 87-20 260 0 176 20 260 10 43 42.5 73t75.5 35q137 15 412 15t412-15q43-5 75.5-35t42.5-73q20-84 20-260zM563 519l90-296h-75l-51 195-53-195h-78q7 23 23 69l24 69q35 103 46 158v201h74V519zm289 81V470q0-58-21-87-29-38-78-38-51 0-78 38-21 29-21 87v130q0 58 21 87 27 38 78 38 49 0 78-38 21-27 21-87zm181 120h67V350h-67v283q-22 31-42 31-15 0-16-16-1-2-1-26V350h-67v293q0 37 6 55 11 27 43 27 36 0 77-45v40zm503-304v960q0 119-84.5 203.5T1248 1664H288q-119 0-203.5-84.5T0 1376V416q0-119 84.5-203.5T288 128h960q119 0 203.5 84.5T1536 416z"></path>
                    </svg>
                    </div>
                </div>
                </a>
            </div>
        </div>
    <p>En cualquier caso, cualquiera de las mencionadas cesiones está debidamente documentada con su respectivo contrato de encargado del tratamiento.</p>
    <hr>


    <h1 class="text-green">F. ¿Hacemos decisiones automatizadas?</h1>
    <p>No, para nosotros cada cliente es único y con respecto a los
        tratamientos asociados a la web, no se llevan a cabo.        
    </p>
    <br>
    <p>Para más información sobre cookies, visite nuestro apartado
        relativo a cookies.
    </p>

    <hr>

    <h1 class="text-green">G. Derechos de acceso, rectificación, supresión,
        limitación del tratamiento, portabilidad de los datos y
        oposición</h1>
    <p>Conforme al Reglamento General de Protección de datos, junto con
        su derecho a oponerse a la automatización de decisiones, todo
        usuario que haya facilitado datos a Éxxita Be Circular, S.A.
        tiene los siguientes derechos que puede hacer ejercer:              
    </p>
    <br>
    <br>
    <p class="text-muted">● </p>
    <p>Acceso</p>
    <br>
    <p class="text-muted">● </p>
    <p>Rectificación</p>
    <br>
    <p class="text-muted">● </p>
    <p>Supresión</p>
    <br>
    <p class="text-muted">● </p>
    <p>Limitación del tratamiento</p>
    <br>
    <p class="text-muted">● </p>
    <p>Portabilidad de los datos</p>
    <br>
    <p class="text-muted">● </p>
    <p>Oposición</p>
    <br>
    <br>

    <p>El ejercicio de estos derechos deberá llevarse a cabo mediante
        comunicación dirigida a Éxxita Be Circular, S.A., ya sea a su
        dirección postal, indicada más arriba, o a la dirección electrónica
        dispuesta a estos efectos </p>
    <p class="text-green"> protecciondedatos@exxita.com</p>
    <p>, sin
        perjuicio del uso de cualquier otro medio válido en derecho que
        permita cumplimentar de forma legalmente válida los requisitos
        para ello. Para acreditar su identidad necesitaremos que nos
        facilite:</p>
    <br>
    <br>

    <p class="text-muted">● </p>
    <p>Nombre y apellidos del interesado; copia de DNI, pasaporte
        o similar y, en su caso, de la persona que lo represente.</p>
    <br>
    <p class="text-muted">● </p>
    <p>Objeto de la petición.
    </p>
    <br>
    <p class="text-muted">● </p>
    <p>Dirección a efectos de notificaciones, fecha y firma del
        solicitante.
        </p>
    <br>
    <p class="text-muted">● </p>
    <p>Documentos acreditativos de la petición de la petición
        formulada en el caso de que se estimen convenientes o
        necesarios.
        </p>
    <br>
    <br>
    <p>A su vez tiene usted derecho a plantear las reclamaciones que
        estime convenientes ante la AEPD.
    </p>

    <hr>


    <h1 class="text-green">H. Gestión de la información</h1>
    <p>Si queréis saber más acerca de Internet y vuestra privacidad desde
        Éxxita Be Circular, S.A., os recomendamos que visitéis los
        siguientes enlaces:               
    </p>
    <br>
    <p class="text-muted">● </p>
    <a href="http://www.osi.es" style="text-decoration: none; color: #63b421;" target="_blank"> Oficina Seguridad Internet: http://www.osi.es</a>
    <br>
    <p class="text-muted">● </p>
    <a href="http://www.agpd.es" style="text-decoration: none; color: #63b421;" target="_blank">Agencia Española de Protección de Datos: http://www.agpd.es</a>
    <br>

</div>