import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-remember',
  templateUrl: './remember.component.html',
  styleUrls: ['./remember.component.css']
})
export class RememberComponent implements OnInit {

  rememberForm: FormGroup;
  rememberCodeForm: FormGroup;

  msgErrorRemember: string | undefined;
  msgSuccessRemember: string | undefined;

  msgErrorChangePass: string | undefined;
  msgSuccessChangePass: string | undefined;

  showRemember: boolean;
  showCambiado: boolean;

  email: string | undefined;

  subdomain: boolean | undefined;
  subdomainImage: string | undefined;

  constructor(
    private authSvc: AuthService,
    private _formBuilder: FormBuilder,
    private router: Router
  ) {

    this.showRemember = true;
    this.showCambiado = false;
    this.rememberForm = this._formBuilder.group({
      usuarioRemember: ['', [Validators.required]],
    });
    this.rememberCodeForm = this._formBuilder.group({
      codeRemember: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.msgErrorRemember = "";
    this.msgSuccessRemember = "";
    this.msgErrorChangePass = "";
    this.msgSuccessChangePass = ""


    let ubicacion = window.location.href
    if (ubicacion.includes('flex.exxita') || ubicacion.includes('localhost')) {
      this.subdomain = false
    } else {
      this.subdomain = true
      this.subdomainImage = '../../../assets/img/marcas/' + ubicacion.slice(ubicacion.indexOf('//') + 2, ubicacion.indexOf('.')) + '.png'
    }
  }

  onRemember(): void {
    this.msgErrorRemember = '';
    const formValue = this.rememberForm.value;

    if (this.rememberForm.valid) {
      this.authSvc.rememberPassword(formValue.usuarioRemember).subscribe(
        data => {
          this.showRemember = false;
          this.email = formValue.usuarioRemember;
          this.msgSuccessRemember = "Se ha enviado un email a tu cuenta para restablecer la contraseña.";
        },
        err => {
          this.msgErrorRemember = err;
        }
      );
    }
  }

  onChangePass(): void {
    this.msgErrorChangePass = '';
    const formValue = this.rememberCodeForm.value;
    console.log(this.rememberCodeForm.valid)
    if (this.rememberCodeForm.valid && this.email != undefined) {
      this.authSvc.changePasswordSecurityCode(this.email, formValue.codeRemember, formValue.password).subscribe(
        data => {
          this.showCambiado = true;
          this.msgSuccessChangePass = "Se ha cambiado la contraseña de su cuenta";

          setTimeout(() => {

            if (this.email != undefined) {
              this.authSvc.login(this.email, formValue.password).subscribe(
                data => {
                  if (data) {
                    console.log(data)
                    this.router.navigate(['/']);
                  }
                },
                err => {

                  this.msgErrorChangePass = err;
                  console.log(this.msgErrorRemember)
                }

              );
            }
          }, 2000);

        },
        err => {
          this.msgErrorChangePass = err;
        }
      );
    }
  }




}
