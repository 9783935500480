<div class="container-lg mt-4 mb-4">
    <h6>
        HOME
        <i class="fa fa-angle-right" style="margin-left: 10px; margin-right: 10px;" aria-hidden="true"></i>
        TIENDA
        <i class="fa fa-angle-right" style="margin-left: 10px; margin-right: 10px;" aria-hidden="true"></i>
        MI CUENTA
    </h6>
</div>

<hr>

<div class="row" *ngIf="screenWidth != undefined && screenWidth >=770">

    <div class="col-3 mt-4 mb-4">
        <app-profile-nav-bar></app-profile-nav-bar>
    </div>

    <div class="col-9 mt-4 mb-4">
        <p class="text-muted d-inline">Hola</p>
        <p class="d-inline" style="font-weight: bold;"> {{nombreVisible}} </p>
        <p class="text-muted d-inline">(¿no eres tú?</p>
        <a class="d-inline" style="color: green; cursor: pointer;" (click)="cerrarSesion()"> Cerrar sesión</a>
        <p class="text-muted d-inline">)</p>

        <div class="mt-4 mb-4">
            <p class="text-muted d-inline">Desde el escritorio de tu cuenta puedes ver tus </p>
            <a class="d-inline" style="color: green;" href="/">pedidos recientes</a>
            <p class="text-muted d-inline">, gestionar tus </p>
            <a class="d-inline" style="color: green;" href="/">direcciones de envío y facturación</a>
            <p class="text-muted d-inline"> y </p>
            <a class="d-inline" style="color: green;" href="/">editar tu contraseña y los detalles de tu cuenta</a>
        </div>

        <div class="row">
            <div class="col-4">
                <div class="container border border-secondary">
                    <a href="/orders"><img src="../../../assets/img/pedidos.png" class="img-fluid"
                            alt="Responsive image"></a>
                </div>
            </div>
            <div class="col-4">
                <div class="container border border-secondary">
                    <a href="/address"><img src="../../../assets/img/direccion.png" class="img-fluid"
                            alt="Responsive image"></a>
                </div>
            </div>
            <div class="col-4">
                <div class="container border border-secondary">
                    <a href="/accountDetails"><img src="../../../assets/img/detallesCuenta.png" class="img-fluid"
                            alt="Responsive image"></a>
                </div>
            </div>
        </div>
        <div class="row mt-4 mb-4 d-flex justify-content-center">
            <div class="col-4">
                <div class="container border border-secondary">
                    <a href="/wishes"><img src="../../../assets/img/listaDeseos.png"
                            class="img-fluid" alt="Responsive image"></a>
                </div>
            </div>
            <div class="col-4">
                <div class="container border border-secondary">
                    <a style="cursor: pointer;" (click)="cerrarSesion()"><img src="../../../assets/img/salir.png"
                            class="img-fluid" alt="Responsive image"></a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row" *ngIf="screenWidth != undefined && screenWidth >= 620 && screenWidth < 770">

    <div class="col mt-4 mb-4 text-center">
        <p class="text-muted d-inline">Hola</p>
        <p class="d-inline" style="font-weight: bold;"> {{nombreVisible}} </p>
        <p class="text-muted d-inline">(¿no eres </p>
        <p class="d-inline" style="font-weight: bold;"> {{nombreVisible}}</p>
        <p class="text-muted d-inline">?</p>
        <a class="d-inline" style="color: green; cursor: pointer;" (click)="cerrarSesion()"> Cerrar sesión</a>
        <p class="text-muted d-inline">)</p>

        <div class="mt-4 mb-4">
            <p class="text-muted d-inline">Desde el escritorio de tu cuenta puedes ver tus </p>
            <a class="d-inline" style="color: green;" href="/">pedidos recientes</a>
            <p class="text-muted d-inline">, gestionar tus </p>
            <a class="d-inline" style="color: green;" href="/">direcciones de envío y facturación</a>
            <p class="text-muted d-inline"> y </p>
            <a class="d-inline" style="color: green;" href="/">editar tu contraseña y los detalles de tu cuenta</a>
        </div>

        <div class="row">
            <div class="col-4">
                <div class="container border border-secondary">
                    <a href="/orders"><img src="../../../assets/img/pedidos.png" class="img-fluid"
                            alt="Responsive image"></a>
                </div>
            </div>
            <div class="col-4">
                <div class="container border border-secondary">
                    <a href="/address"><img src="../../../assets/img/direccion.png" class="img-fluid"
                            alt="Responsive image"></a>
                </div>
            </div>
            <div class="col-4">
                <div class="container border border-secondary">
                    <a href="/accountDetails"><img src="../../../assets/img/detallesCuenta.png" class="img-fluid"
                            alt="Responsive image"></a>
                </div>
            </div>
        </div>
        <div class="row mt-4 mb-4">
            <div class="col-1"></div>
            <div class="col-4">
                <div class="container border border-secondary">
                    <a href="/wishes"><img src="../../../assets/img/listaDeseos.png"
                            class="img-fluid" alt="Responsive image"></a>
                </div>
            </div>
            <div class="col-1"></div>
            <div class="col-4">
                <div class="container border border-secondary">
                    <a style="cursor: pointer;" (click)="cerrarSesion()"><img src="../../../assets/img/salir.png"
                            class="img-fluid" alt="Responsive image"></a>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="row" *ngIf="screenWidth != undefined && screenWidth < 620">

    <div class="col mt-4 mb-4 text-center">
        <p class="text-muted d-inline">Hola</p>
        <p class="d-inline" style="font-weight: bold;"> {{nombreVisible}} </p>
        <p class="text-muted d-inline">(¿no eres </p>
        <p class="d-inline" style="font-weight: bold;"> {{nombreVisible}}</p>
        <p class="text-muted d-inline">?</p>
        <a class="d-inline" style="color: green; cursor: pointer;" (click)="cerrarSesion()"> Cerrar sesión</a>
        <p class="text-muted d-inline">)</p>

        <div class="mt-4 mb-4">
            <p class="text-muted d-inline">Desde el escritorio de tu cuenta puedes ver tus </p>
            <a class="d-inline" style="color: green;" href="/">pedidos recientes</a>
            <p class="text-muted d-inline">, gestionar tus </p>
            <a class="d-inline" style="color: green;" href="/">direcciones de envío y facturación</a>
            <p class="text-muted d-inline"> y </p>
            <a class="d-inline" style="color: green;" href="/">editar tu contraseña y los detalles de tu cuenta</a>
        </div>

        <div class="row mb-4 text-center">
            <div class="col-2"></div>
            <div class="col-8">
                <div class="container border border-secondary">
                    <a href="/orders"><img src="../../../assets/img/pedidos.png" class="img-fluid"
                        alt="Responsive image"></a>
                </div>
            </div>
            <div class="col-2"></div>
        </div>

        <div class="row mb-4">
            <div class="col-2"></div>
            <div class="col-8">
                <div class="container border border-secondary">
                    <a href="/address"><img src="../../../assets/img/direccion.png" class="img-fluid"
                            alt="Responsive image"></a>
                </div>
            </div>
            <div class="col-2"></div>
        </div>
        
        
        <div class="row mb-4">
            <div class="col-2"></div>
            <div class="col-8">
                <div class="container border border-secondary">
                    <a href="/accountDetails"><img src="../../../assets/img/detallesCuenta.png" class="img-fluid"
                            alt="Responsive image"></a>
                </div>
            </div>
            <div class="col-2"></div>
        </div>


        <div class="row mb-4">
            <div class="col-2"></div>
            <div class="col-8">
                <div class="container border border-secondary">
                    <a href="/wishes"><img src="../../../assets/img/listaDeseos.png"
                            class="img-fluid" alt="Responsive image"></a>
                </div>
            </div>
            <div class="col-2"></div>
        </div>

        <div class="row mb-4">
            <div class="col-2"></div>
            <div class="col-8">
                <div class="container border border-secondary">
                    <a style="cursor: pointer;" (click)="cerrarSesion()"><img src="../../../assets/img/salir.png"
                            class="img-fluid" alt="Responsive image"></a>
                </div>
            </div>
            <div class="col-2"></div>
        </div>
    </div>
</div>