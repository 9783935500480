<div *ngIf="screenWidth != undefined">

    <div *ngIf="product && screenWidth > 760">
        <div class="modal-body">
            <div class="row mb-4">

                <div class="col-5">
                    <div class="flex-column stretch" style="width: 100%;">
                        <div class="div-dispositivo-alquilado-primary">
                            <div class="green-border-div">
                                <div class="div-img-content">
                                    <div class="flex-column center vcenter">
                                        <img [src]="(imgPreview && imgPreview!='')?rutaImagen+imgPreview:((product.urlImagenes && product.urlImagenes[0] && rutaImagen+product.urlImagenes[0].url)?rutaImagen+product.urlImagenes[0].url:'')">
                                    </div>
                                </div>
                            </div>
                        </div>
            
                        <div class="flex-row start div-dispositivo-alquilado-primary">
                            <div class="green-border-div min pointer" *ngFor="let imagen of imagesToshow; index as i" [class.mr10] = "i<3 && i+1<imagesToshow.length" (click)="imgPreview = imagen.url">
                                <div class="div-img-content">
                                    <div class="flex-column center vcenter">
                                        <img src="{{rutaImagen+imagen.url}}">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


                <div class="col-6 mt-4">
                    <h2 style="font-weight: bold;">{{product.name}}</h2>
                    <hr>

                    <!--<div class="ratings">

                        <div class="row">
                            <div class="col">

                                <star-rating [value]="product.rating" [totalstars]="5" checkedcolor="#5bc500"
                                    uncheckedcolor="gray" size="24px" [readonly]="false"></star-rating>

                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                            </div>

                            <hr>
                        </div>


                    </div>-->

                    <div #precio class="mb-2">
                        <h3 style="display:inline; font-weight: bold;">{{priceSelected}} €</h3>
                        <div *ngIf="monthSelected == 1 && buy==false">
                            <p style="font-size: 80%;"> al mes durante {{monthSelected}} mes. Puede cancelar en
                                cualquier
                                momento
                            </p>
                        </div>
                        <div *ngIf="monthSelected != 1 && buy==false">
                            <p style="font-size: 80%;"> al mes durante {{monthSelected}} meses. Puede cancelar en
                                cualquier
                                momento
                            </p>
                        </div>
                    </div>


                    <p>{{product.descripcion}}</p>
                    <p><a class="text-muted">SKU: </a>6541119951-2</p>
                    <p><a class="text-muted">CATEGORÍAS: </a>{{product.tipo_dispositivo?product.tipo_dispositivo.name:'-'}}</p>
                    <!--
                        <img class="img-fluid" style="height: 50px; width: 180px;"
                        src="../../../assets/img/productosPrueba/garantia10ç.png">
                    -->
                        <p class="mt-2" style="margin-left: 10px;"><i class="fas fa-truck"></i> Entrega gratuita al día
                        siguiente
                    </p>
                    <div *ngIf="product.articulo_alquileres.length != 0 && buy==false && product.stock > 0">
                        <p class="mt-2" style="margin-left: 10px;"> Selecciona un <a href="#" style="color: black;">periodo
                            mínimo
                            de
                            alquiler</a></p>
                            
                            
                        <ng-template ngFor let-element [ngForOf]="product.articulo_alquileres" let-i="index">
                            <button *ngIf="element.alquiler_mes && monthSelected == element.alquiler_mes.month && element.alquiler_mes.month != 0" class="btn btn-success"
                            (click)="selectMonth(element.alquiler_mes.month, element.alquiler_mes.price)" style="margin-right: 10px;">
                            <p>{{element.alquiler_mes.month}}+</p>mes
                            </button>
                            <button *ngIf="element.alquiler_mes && monthSelected != element.alquiler_mes.month && element.alquiler_mes.month != 0" class="btn border-secondary"
                            (click)="selectMonth(element.alquiler_mes.month, element.alquiler_mes.price)" style="margin-right: 10px;">
                            <p>{{element.alquiler_mes.month}}+</p>mes
                            </button>
                        </ng-template>
                    </div>
            
            
            <div *ngIf="noDisponible == true">
                <p>Lo sentimos, este producto no está disponible</p>
            </div>

                    <hr>

                    <div class="mt-4 mb-4" *ngIf="product.articulo_alquileres.length != 0 && noDisponible == false">

                        <input #cantidad type="number" value="1" min="1" max="{{product.stock}}" step="1" onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                            style="margin-right: 20px;" />

                        <button class="btn btn-dark" (click)="addToCart(cantidad.value)"><i
                                class="fas fa-shopping-bag"></i> Añadir a la cesta</button>
                    </div>



                    <hr>

                    <div #redes class="row">
                        <div class="col-1" style="margin-left: 10px;">
                            <a href="https://www.facebook.com/CyGitsolutions" target="_blank"><i class="fab fa-facebook fa-2x" style="color: rgb(0, 198, 0);"></i></a>
                        </div>
                        <div class="col-1" style="margin-left: 10px;">
                            <a href="https://twitter.com/CyGitsolutions" target="_blank"><i class="fab fa-twitter fa-2x" style="color: rgb(0, 198, 0);"></i></a>
                        </div>
                        <div class="col-1" style="margin-left: 10px;">
                            <a href="https://www.instagram.com/cygitsolutions/" target="_blank"><i class="fab fa-instagram fa-2x" style="color: rgb(0, 198, 0);"></i></a>
                        </div>
                        <div class="col-1" style="margin-left: 10px;">
                            <a href="https://www.linkedin.com/company/cygsustainabletech" target="_blank"><i class="fab fa-linkedin fa-2x" style="color: rgb(0, 198, 0);"></i></a>
                        </div>
                        <div class="col-1" style="margin-left: 10px;">
                            <a href="https://www.youtube.com/user/CyGITSolutions" target="_blank"><i class="fab fa-youtube fa-2x" style="color: rgb(0, 198, 0);"></i></a>
                        </div>
                        <div class="col-1" style="margin-left: 10px;">
                            <a [href]="emailString" target="_top"><i class="fas fa-envelope fa-2x" style="color: rgb(0, 198, 0);"></i></a>
                        </div>


                    
                        <div class="col" *ngIf="deseado == true && logueado == true">
                            <button (click)="eliminarDeseo(idDeseado)" class="btn"><i style="color: red;" class="fas fa-heart"></i> Eliminar de lista de deseos</button>
                        </div>
                        <div class="col" *ngIf="deseado != true && logueado == true">
                            <button (click)="addDeseo(productId)" class="btn"><i class="far fa-heart"></i> Añadir a lista de deseos</button>
                        </div>
                    
                    </div>
                </div>



            </div>
        </div>

    </div>

    <div *ngIf="product != undefined && screenWidth <= 760">


        <div class="container-lg mt-4 mb-4">
            <h6 style="text-transform: uppercase;">
                HOME
                <i class="fa fa-angle-right" style="margin-left: 10px; margin-right: 10px;" aria-hidden="true"></i>
                TIENDA
                <i class="fa fa-angle-right" style="margin-left: 10px; margin-right: 10px;" aria-hidden="true"></i>
                {{product.tipo_dispositivo?product.tipo_dispositivo.name:'-'}}
                <i class="fa fa-angle-right" style="margin-left: 10px; margin-right: 10px;" aria-hidden="true"></i>
                {{product.name}}
                <hr>
            </h6>

        </div>

        <div class="modal-body">
            
            <div class="row mb-4" style="margin-left: 10px; margin-right: 10px;">
                <div class="flex-column">

                    <div class="flex-column stretch" style="width: 100%;">
                        <div class="div-dispositivo-alquilado-primary">
                            <div class="green-border-div">
                                <div class="div-img-content">
                                    <div class="flex-column center vcenter">
                                        <img [src]="(imgPreview && imgPreview!='')?rutaImagen+imgPreview:((product.urlImagenes && product.urlImagenes[0] && rutaImagen+product.urlImagenes[0].url)?rutaImagen+product.urlImagenes[0].url:'')">
                                    </div>
                                </div>
                            </div>
                        </div>
            
                        <div class="flex-row start div-dispositivo-alquilado-primary">
                            <div class="green-border-div min pointer" *ngFor="let imagen of imagesToshow; index as i" [class.mr10] = "i<3 && i+1<imagesToshow.length" (click)="imgPreview = imagen.url">
                                <div class="div-img-content">
                                    <div class="flex-column center vcenter">
                                        <img src="{{rutaImagen+imagen.url}}">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="row text-center">
                <div class="row">
                    <h2 style="font-weight: bold;">{{product.name}}</h2>
                </div>
                <div class="row">

                    <div class="ratings">
                        <!--<div class="row">

                            <star-rating [value]="product.rating" [totalstars]="5" checkedcolor="#5bc500"
                                uncheckedcolor="gray" size="24px" [readonly]="false"></star-rating>

                        </div>-->
                        <div class="row">
                            <!--

    <p class="text-muted">1 valoración de cliente | <a href="#" style="color: gray;">Añadir
        una
        valoración</a></p>
    -->
                        </div>

                        <hr>
                    </div>


                </div>

            </div>
            <div #precio class="mb-2">
                <h3 style="display:inline; font-weight: bold;">{{priceSelected}} €</h3>
                <div *ngIf="monthSelected == 1 && buy==false">
                    <p style="font-size: 80%;"> al mes durante {{monthSelected}} mes. Puede cancelar en
                        cualquier
                        momento
                    </p>
                </div>
                <div *ngIf="monthSelected != 1 && buy==false">
                    <p style="font-size: 80%;"> al mes durante {{monthSelected}} meses. Puede cancelar en
                        cualquier
                        momento
                    </p>
                </div>
            </div>


            <p>{{product.descripcion}}</p>
            <p><a class="text-muted">SKU: </a>6541119951-2</p>
            <p><a class="text-muted">CATEGORÍAS: </a>{{product.descripcion}}</p>
            <!--
                <img class="img-fluid" style="height: 50px; width: 180px;"
                src="../../../assets/img/productosPrueba/garantia10ç.png">
            -->
            <p class="mt-2" style="margin-left: 10px;"><i class="fas fa-truck"></i> Entrega gratuita al día
                siguiente
            </p>
            <div *ngIf="product.articulo_alquileres.length != 0 && buy==false && product.stock > 0">
                <p class="mt-2" style="margin-left: 10px;"> Selecciona un <a href="#" style="color: black;">periodo
                    mínimo
                    de
                    alquiler</a></p>
            

                <ng-template ngFor let-element [ngForOf]="product.articulo_alquileres" let-i="index">
                    <button *ngIf="element.alquiler_mes && monthSelected == element.alquiler_mes.month && element.alquiler_mes.month != 0" class="btn btn-success"
                        (click)="selectMonth(element.alquiler_mes.month, element.alquiler_mes.price)" style="margin-right: 10px;">
                        <p>{{element.alquiler_mes.month}}+</p>mes
                    </button>
                    <button *ngIf="element.alquiler_mes && monthSelected != element.alquiler_mes.month && element.alquiler_mes.month != 0" class="btn border-secondary"
                        (click)="selectMonth(element.alquiler_mes.month, element.alquiler_mes.price)" style="margin-right: 10px;">
                        <p>{{element.alquiler_mes.month}}+</p>mes
                    </button>
                </ng-template>
            </div>


            <div *ngIf="noDisponible == true">
                <p>Lo sentimos, este producto no está disponible</p>
            </div>


            <hr>

            <div class="mt-4 mb-4" *ngIf="product.articulo_alquileres.length != 0 && noDisponible == false">

                <input #cantidad type="number" value="1" min="1" max="{{product.stock}}" step="1" onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                    style="margin-right: 20px;" />

                <button class="btn btn-dark" (click)="addToCart(cantidad.value)"><i
                        class="fas fa-shopping-bag"></i> Añadir a la cesta</button>
            </div>

            


            <hr>

            <div #redes class="row">
                <div class="col-1">
                    <a href="https://www.facebook.com/CyGitsolutions" target="_blank"><i class="fab fa-facebook fa-2x" style="color: rgb(0, 198, 0);"></i></a>
                </div>
                <div class="col-1">
                    <a href="https://twitter.com/CyGitsolutions" target="_blank"><i class="fab fa-twitter fa-2x" style="color: rgb(0, 198, 0);"></i></a>
                </div>
                <div class="col-1">
                    <a href="https://www.instagram.com/cygitsolutions/" target="_blank"><i class="fab fa-instagram fa-2x" style="color: rgb(0, 198, 0);"></i></a>
                </div>
                <div class="col-1">
                    <a href="https://www.linkedin.com/company/cygsustainabletech" target="_blank"><i class="fab fa-linkedin fa-2x" style="color: rgb(0, 198, 0);"></i></a>
                </div>
                <div class="col-1">
                    <a href="https://www.youtube.com/user/CyGITSolutions" target="_blank"><i class="fab fa-youtube fa-2x" style="color: rgb(0, 198, 0);"></i></a>
                </div>
                <div class="col-1">
                    <a [href]="emailString" target="_top"><i class="fas fa-envelope fa-2x" style="color: rgb(0, 198, 0);"></i></a>
                </div>


                <div class="col" *ngIf="deseado == true && logueado == true">
                    <button (click)="eliminarDeseo(idDeseado)" class="btn"><i style="color: red;" class="fas fa-heart"></i> Eliminar de lista de deseos</button>
                </div>
                <div class="col" *ngIf="deseado != true && logueado == true">
                    <button (click)="addDeseo(productId)" class="btn"><i class="far fa-heart"></i> Añadir a lista de deseos</button>
                </div>
            </div>



        </div>

    </div>

    <!--

    <div class="container mt-4 mb-4">

        <button class="btn">Descripción</button>
        <button class="btn">Valoraciones (1)</button>

        <div #texto class="mt-4 mb-4">
            <p>
                Considera el Lenovo V15 IIL de 39,62 cm (15,62") como tu jefe de oficina, capa de llevar a cabo
                tus
                tareas
                del día a día con eficiencia. Mantendrá tu negocio productivo y tus
                datos, seguros. Además, su diseño más fino y más pequeño con marcos laterales más estrechos le
                darán un
                aspecto limpio y profesional a tu escritorio o donde elijas
                usarlo.
            </p>

            <h5 class="mt-4" style="font-weight: bold;">Características</h5>
            <p>• Haz que tu trabajo fluya y que tus datos estén seguros: Por un lado, las opciones de
                almacenamiento
                dual
                del
                portátil V15 facilitan la productividad; por otro, su módulo
                de plataforma segura (TPM) 2.0 (firmware) mantiene el trabajo resultante seguro mediante el
                cifrado de
                datos
                y contraseñas.</p>
            <p>• Imágenes elegantes: El diseño contemporáneo de este portátil le da un aspecto y sensación de
                primera
                calidad,
                con un cuerpo fino que se adapta a tus viajes cuando
                estás fuera de la oficina. La pantalla de 39,62 cm (15,6") con resolución 1920x1080 te ofrece
                colores
                vivos
                y claridad, con marcos laterales estrechos para mejorar la vista</p>

            <p>• Probado para la fiabilidad: Lenovo ha sometido este portátil a pruebas de fiabilidad y
                durabilidad en
                ocho
                condiciones extremas: prueba de choque, prueba de vibración,
                prueba de teclado dentro del sistema, prueba de vida de las bisagras, prueba de fiabilidad del
                ventilador,
                prueba de desgaste del panel, prueba de temperatura de funcionamiento y prueba de presión. Está
                preparado
                para cualquier circunstancia a la que pueda verse sometido en tu negocio.</p>


            <h5 class="mt-4" style="font-weight: bold;">Especificaciones Lenovo V15 IIL</h5>

            <p>• Procesador Intel Core i5-1035G1 (4 núcleos / 8 hilos, frecuencia desde 1.0 GHz hasta 3.6 GHz,
                6MB
                caché)
                <br>
                • Memoria RAM 8 GB DDR4 2666 MHz (4GB soldada a placa + 4GB SO-DIMM) <br>
                • Almacenamiento 256 GB SSD M.2 2242 PCle NVMe 3.0x2 <br>
                • Unidad óptica No dispone<br>
                • Display 15.6" Ful HD (1920x1080) TN 220 nits Antirreflectante<br>
                • Controlador gráfico Integrado: Intel UHD Graphics<br>
                • Conectividad <br>
                &nbsp;&nbsp;&nbsp;&nbsp;- WiFi 802.11ac (1x1)<br>
                &nbsp;&nbsp;&nbsp;&nbsp;- Bluetooth 4.2<br>
                • Cámara de portátil Sí, 0.3 MP<br>
                • Micrófono Sí, Mono<br>
                • Batería Integrada 35Wh
            </p>

        </div>

    </div>
-->

<div #productosRelacionados class="flex-column" style="margin-top: 15px;" *ngIf="relacionados && relacionados.length > 0 && screenWidth != undefined && screenWidth > 990">
    <label>PRODUCTOS RELACIONADOS</label>
    <hr>

    <div class="flex-row start stretch">
        <div class="flex-column stretch grow1 div-dispositivo-alquilado" *ngFor="let movimiento of relacionados;">
            <div class="green-border-div pointer" (click)="navigate('/products/'+movimiento.elemento.ID)" *ngIf="movimiento.elemento && buy == false">
                <!--<button type="button" class="btn btn-primary">
                    <img src="../../../assets/img/shopping-bag.png">
                </button>-->
                <div class="div-img-content">
                    <div class="flex-column center vcenter">
                        <img [src]="(movimiento.elemento.urlImagenes && movimiento.elemento.urlImagenes.length>0 && movimiento.elemento.urlImagenes[0].url)?rutaImagen+movimiento.elemento.urlImagenes[0].url:''">
                    </div>
                </div>
            </div>

            <div class="green-border-div pointer" (click)="navigate('/products/buy/'+movimiento.elemento.ID)" *ngIf="movimiento.elemento && buy != false">
                <!--<button type="button" class="btn btn-primary">
                    <img src="../../../assets/img/shopping-bag.png">
                </button>-->
                <div class="div-img-content">
                    <div class="flex-column center vcenter">
                        <img [src]="(movimiento.elemento.urlImagenes && movimiento.elemento.urlImagenes.length>0 && movimiento.elemento.urlImagenes[0].url)?rutaImagen+movimiento.elemento.urlImagenes[0].url:''">
                    </div>
                </div>
            </div>

            <div class="flex-column" *ngIf="movimiento.elemento">
                <span class="secondary-text">{{movimiento.elemento.tipo_dispositivo?movimiento.elemento.tipo_dispositivo.name:'-'}}</span>
                <label>{{movimiento.elemento.name?movimiento.elemento.name:'-'}}</label>
                <!--<div class="flex-row">
                    <star-rating [value]="movimiento.elemento.rating" [totalstars]="5" checkedcolor="#5bc500" uncheckedcolor="gray"
                    size="24px" [readonly]="true"></star-rating>
                </div>-->
                <div *ngIf="buy == false">
                    <span class="secondary-text">desde</span>&nbsp;<label>{{movimiento.elemento.precioMinimo}}€</label>&nbsp;<span class="secondary-text">al mes</span>
                </div>

                <div *ngIf="buy != false">
                    <label>{{movimiento.elemento.precioCompra}}€</label>
                </div>
                
                <div>
                    <app-dialog-select-options-update [product]="movimiento.elemento"></app-dialog-select-options-update>
                </div>
            </div>
        </div>
    </div>
</div>

<div #productosRelacionados class="flex-column" style="margin: 10px;" *ngIf="relacionados && relacionados.length > 0 && screenWidth != undefined && screenWidth <= 990">
    <label style="font-size: 4vw;">PRODUCTOS RELACIONADOS</label>
    <hr>

    <div class="flex-row start stretch" style="flex-wrap: wrap;">
        <div class="flex-column stretch grow1 div-dispositivo-alquilado xs" *ngFor="let movimiento of relacionados;">
            <div class="green-border-div pointer" (click)="navigate('/products/'+movimiento.elemento.ID)" *ngIf="movimiento.elemento && buy == false">
                <!--<button type="button" class="btn btn-primary">
                    <img src="../../../assets/img/shopping-bag.png">
                </button>-->
                <div class="div-img-content">
                    <div class="flex-column center vcenter">
                        <img [src]="(movimiento.elemento.urlImagenes && movimiento.elemento.urlImagenes.length>0 && movimiento.elemento.urlImagenes[0].url) ?rutaImagen+movimiento.elemento.urlImagenes[0].url:''">
                    </div>
                </div>
            </div>

            <div class="green-border-div pointer" (click)="navigate('/products/buy/'+movimiento.elemento.ID)" *ngIf="movimiento.elemento && buy != false">
                <!--<button type="button" class="btn btn-primary">
                    <img src="../../../assets/img/shopping-bag.png">
                </button>-->
                <div class="div-img-content">
                    <div class="flex-column center vcenter">
                        <img [src]="(movimiento.elemento.urlImagenes && movimiento.elemento.urlImagenes.length>0 && movimiento.elemento.urlImagenes[0].url) ?rutaImagen+movimiento.elemento.urlImagenes[0].url:''">
                    </div>
                </div>
            </div>

            <div class="flex-column" *ngIf="movimiento.elemento">
                <span class="secondary-text">{{movimiento.elemento.tipo_dispositivo?movimiento.elemento.tipo_dispositivo.name:'-'}}</span>
                <label style="font-size: 3vw;">{{movimiento.elemento.name?movimiento.elemento.name:'-'}}</label>
                <!--<div class="flex-row">
                    <star-rating [value]="movimiento.elemento.rating" [totalstars]="5" checkedcolor="#5bc500" uncheckedcolor="gray"
                    size="24px" [readonly]="true"></star-rating>
                </div>-->
                <div *ngIf="buy == false">
                    <span class="secondary-text">desde</span>&nbsp;<label>{{movimiento.elemento.precioMinimo}}€</label>&nbsp;<span class="secondary-text">al mes</span>
                </div>

                <div *ngIf="buy != false">
                    <label>{{movimiento.elemento.precioCompra}}€</label>
                </div>
                
                <div>
                    <app-dialog-select-options-update [product]="movimiento.elemento"></app-dialog-select-options-update>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #modalAddSuccess let-d="dismiss">
    <div class="modal-body text-center">
        <div *ngIf="product != undefined">
            <div *ngIf="cantidad != undefined && cantidad > product.stock">
                <h4>No tenemos disponible {{cantidad}} artículos de este producto. Añadiremos nuestro máximo</h4>
                <h3 class="text-center">¡Producto añadido!</h3>
                <h4 class="text-center">Se ha añadido {{product.name}} x {{product.stock}} a la cesta</h4>
            </div>
            <div *ngIf="cantidad != undefined && cantidad <= product.stock">
                <h3 class="text-center">¡Producto añadido!</h3>
                <h4 class="text-center">Se ha añadido {{product.name}} x {{cantidad}} a la cesta</h4>
            </div>
            <div *ngIf="cantidad == undefined">
                <h3 class="text-center">¡Producto añadido!</h3>
                <h4 class="text-center">Se ha añadido {{product.name}} x 1 a la cesta</h4>
            </div>
        </div>
    </div>
</ng-template>

</div>