import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Product } from 'src/app/models/product';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ObservablesService } from 'src/app/services/observables.service';
import { HostListener } from "@angular/core";
import { Observable, Subscription } from 'rxjs';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  searchForm: FormGroup;

  logueado = false;
  totalCarrito: Observable<number>;
  cliente: any;

  screenHeight: number | undefined;
  screenWidth: number | undefined;

  background: string | undefined;
  
  private popupOpenSubscription!: Subscription;
  private popupCloseSubscription!: Subscription;
  private initializeSubscription!: Subscription;
  private statusChangeSubscription!: Subscription;
  private revokeChoiceSubscription!: Subscription;
  private noCookieLawSubscription!: Subscription;

  constructor(
    private authSvc: AuthService,
    private router: Router,
    private _formBuilder: FormBuilder,
    private observables: ObservablesService,
  ) {
    this.searchForm = this._formBuilder.group({
      busqueda: ['', [Validators.required]],
    });
    this.onResize();

    this.totalCarrito = observables.totalCarrito;
  }

  ngOnInit(): void {
    this.logueado = this.authSvc.isUserLoggedIn();
    console.log("logueado ngOnInit", this.logueado);
    if (this.logueado) {
      this.getCliente(Number(localStorage.getItem('idCliente')));
    }

    if(localStorage && localStorage.getItem('cart') && localStorage.getItem('cart')  != null && localStorage.getItem('cart')  != ""){
      var carritoJson = JSON.parse(localStorage.getItem('cart')! ? localStorage.getItem('cart')!: '');
      this.observables.updateTotal(carritoJson.length);
    }else{
      this.observables.updateTotal(0);
    }
    this.background = document.location.pathname.replace("/", "");
  }

  ngOnDestroy(): void {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializeSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
  }

  getCliente(id: number) {
    this.authSvc.getMe().subscribe(
      (data: any) => {
        this.cliente = data;

			},
			(err) => {
				console.log('Error -> ' + err)
			}
		);
	}

  @HostListener('window:resize', ['$event'])
  onResize(event?: undefined) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  navigate(url: string, params?: any) {
    if (params) {
      this.router.navigate([url], {queryParams: params });
    }
    else {
      this.router.navigate([url]);
    }
	}

  goSearching(){
    this.router.navigate(['/products/search'], 
    { queryParams: { filter: this.searchForm.get('busqueda')?.value } 
  }
  );

  }

  getUrlLogo() {
    if(this.logueado && this.cliente && this.cliente.distribuidor) 
      return "../../../assets/img/distribuidor/" + this.cliente.distribuidor.nombre + ".jpg";
    return "../../../assets/img/flex-logo.png";
  }

  errorLogoHandler(event : any) {
    event.target.src = "../../../assets/img/flex-logo.png";
  }



}
