import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Product } from '../models/product';
import { AuthService } from './auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  productoActual: Product | undefined;
  movimientos = []
  productosBusqueda = []
  stock = {}
  idArticulos: number[] = []


  products: Product[] = [] /*= [
    new Product(1, 'Skyrim', 'Lore Ipsum', 24.69, 'https://images-na.ssl-images-amazon.com/images/I/81VvoP5zRjL.__AC_SX300_SY300_QL70_ML2_.jpg', 'Videojuegos', 4.5, [1, 3, 12], 5),
    new Product(2, 'Fallout IV', 'Lore Ipsum', 19.95, 'https://images-na.ssl-images-amazon.com/images/I/51WNZw7xTPL.__AC_SX300_SY300_QL70_ML2_.jpg', 'Videojuegos', 2, [1, 3, 12], 100),
    new Product(3, 'GTA V', 'Lore Ipsum', 17.99, 'https://images-na.ssl-images-amazon.com/images/I/81HjSSj8KpL.__AC_SX300_SY300_QL70_ML2_.jpg', 'Videojuegos', 5, [1, 3, 12], 80),
    new Product(4, 'FIFA 21', 'Lore Ipsum', 18.99, 'https://images-na.ssl-images-amazon.com/images/I/81X9dUZOA%2BL.__AC_SX300_SY300_QL70_ML2_.jpg', 'Videojuegos', 3, [1, 3, 12], 35),
    new Product(5, 'Day Z', 'Lore Ipsum', 29.99, 'https://images-na.ssl-images-amazon.com/images/I/61IKs2t7UDL._AC_SX522_.jpg', 'Videojuegos', 4, [1, 3, 12], 22),
    new Product(6, 'RDR II', 'Lore Ipsum', 24.90, 'https://images-na.ssl-images-amazon.com/images/I/91C8piUiI0L.__AC_SX300_SY300_QL70_ML2_.jpg', 'Videojuegos', 1, [1, 3, 12], 2),
    new Product(7, 'Lenovo V15 IIL Intel Core i5-1035G1', 'Ordenador portátil LENOVO V15 con pantalla de 15.6" LCD LED HD, 1366x768 píxeles, sin Sistema Operativo (FreeDos), procesador Intel Core i5-1035G1 de 1.0GHz a 3.6GHz, disco sólido SSD de 256GB, 8GB de RAM, gráficos Intel UHD Graphics, WiFi, 1x USB 2.0, 2x USB 3.1, 1x HDMI, batería integrada de 35Wh.', 99.90, '../../../assets/img/productosPrueba/LenovoV15.png', 'Ordenadores', 4.5, [1, 3, 12], 500),
    new Product(8, 'Acer - 25" Nitro XV25WF UM.KX2EE.F01', 'Lore Ipsum', 39.90, '../../../assets/img/productosPrueba/AcerNitro.png', 'Pantallas', 4, [1, 3, 12], 40),
    new Product(9, 'Apple 11" iPad Pro Wi-Fi + LTE 128GB', 'Lore Ipsum', 34.90, '../../../assets/img/productosPrueba/Ipad11.png', 'Ordenadores', 4.8, [1, 3, 12], 20),
    new Product(10, 'Microsoft Xbox Series X', 'Lore Ipsum', 69.90, '../../../assets/img/productosPrueba/Xobx.png', 'Consolas', 4.9, [1, 3, 12], 1),
  ]
  */

  constructor(
    private authSvc: AuthService,
    private http: HttpClient,
  ) {
    //this.almacenarMovimientos();
  }

  getProducts(): Product[] {
    return this.products;
  }

  getById(id: number): Product {
    let res = this.products.filter(x => x.id === id);
    return res[0];
  }

  getMovimientoAlmacenes() {
    return new Observable<any>(observer => {
     
      const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; charset=utf-8'
        })
      }

      const url = environment.API_URL + '/almacenesmovimientos/' + environment.almacen; 
      this.http.get(url, options).subscribe(
        (response: any) => {
          console.log("ws getMovimientosAlmacenes", response);
          observer.next(response);
        },
        (err) => {
          console.log("error ws getMovimientosAlmacenes", err);
          let msgError = "Se ha producido un error cargando los datos";

          observer.error(msgError);
        }
      );

      return { unsubscribe() { } };
    });
  }

  almacenarMovimientos() {
    this.getMovimientoAlmacenes().subscribe(
      (data: any) => {
        this.movimientos = data
      },
      (err) => {

        console.log('Error -> ' + err)
      }
    );
  }

  almacenarMovimientosNombre(busqueda: string) {
    this.getMovimientoAlmacenesNombre(busqueda).subscribe(
      (data: any) => {
        this.productosBusqueda =  data
      },
      (err) => {

        console.log('Error -> ' + err)
      }
    );
  }

  bucleStocks() {
    let res = new Map<number, number[]>();

    for (let i = 0; i < this.movimientos.length; i++) {
      if (res.get(this.movimientos[i]['id_inventario']) == undefined) {
        let array = [this.movimientos[i]['acumulado'], this.movimientos[i]['precio']]
        res.set(this.movimientos[i]['id_inventario'], array)
      } else {
        res.set(this.movimientos[i]['id_inventario'], [Number(res.get(this.movimientos[i]['id_inventario']) + this.movimientos[i]['acumulado']), this.movimientos[i]['precio']])
      }
    }

    return res;
  }

  getProductoId(id: number) {
    return new Observable<any>(observer => {
      
      const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; charset=utf-8'
        })
      }

      const url = environment.API_URL + '/almacenes/maestro-articulos/' + id;
      this.http.get(url, options).subscribe(
        (response: any) => {
          console.log("ws getArticulo", response);
          observer.next(response);
        },
        (err) => {
          console.log("error ws getArticulo", err);
          let msgError = "Se ha producido un error cargando los datos";

          observer.error(msgError);
        }
      );

      return { unsubscribe() { } };
    });
  }

  getProductoById(id:number, compraAlquiler: number){
    return new Observable<any>(observer => {
      
      const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; charset=utf-8'
        })
      }

      const url = environment.API_URL + '/almacenes/inventario/' + id + '/' + compraAlquiler;
      this.http.get(url, options).subscribe(
        (response: any) => {
          console.log("ws getProductoById", response);
          observer.next(response);
        },
        (err) => {
          console.log("error ws getProductoById", err);
          let msgError = "Se ha producido un error cargando los datos";

          observer.error(msgError);
        }
      );

      return { unsubscribe() { } };
    });
  }

  getRelacionados(id:number, compraAlquiler: number){
    return new Observable<any>(observer => {
      
      const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; charset=utf-8'
        })
      }

      const url = environment.API_URL + '/almacenes/inventario/relacionados/' + id+ '/' + compraAlquiler+'?id_almacen='+environment.almacen ;
      this.http.get(url, options).subscribe(
        (response: any) => {
          console.log("ws getRelacionados", response);
          observer.next(response);
        },
        (err) => {
          console.log("error ws getRelacionados", err);
          let msgError = "Se ha producido un error cargando los datos";

          observer.error(msgError);
        }
      );

      return { unsubscribe() { } };
    });
  }

  getMovimientoAlmacenesNombre(nombre: string) {
    return new Observable<any>(observer => {
     
      const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; charset=utf-8'
        })
      }

      const url = environment.API_URL + '/almacenesmovimientos/' + environment.almacen + '/' + nombre;
      this.http.get(url, options).subscribe(
        (response: any) => {
          console.log("ws getMovimientosAlmacenesNombre", response);
          observer.next(response);
        },
        (err) => {
          console.log("error ws getMovimientosAlmacenesNombre", err);
          let msgError = "Se ha producido un error cargando los datos";

          observer.error(msgError);
        }
      );

      return { unsubscribe() { } };
    });
  }

  getAlquiler(productId: number) {
    return new Observable<any>(observer => {
     
      const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; charset=utf-8'
        })
      }

      const url = environment.API_URL + '/almacenes/alquiler/inventario/' + productId; 
      this.http.get(url, options).subscribe(
        (response: any) => {
          console.log("ws getAlquilerProduct", response);
          observer.next(response);
        },
        (err) => {
          console.log("error ws getAlquilerProduct", err);
          let msgError = "Se ha producido un error cargando los datos";

          observer.error(msgError);
        }
      );

      return { unsubscribe() { } };
    });
  }

  getMasAlquilados(compraAlquiler: number){
    return new Observable<any>(observer => {
     
      const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; charset=utf-8'
        })
      }

      /*const url = environment.API_URL + '/almacenes/alquiler/masalquilados/'+environment.almacen+'/'+compraAlquiler; 
      this.http.get(url, options).subscribe(
        (response: any) => {
          console.log("ws getMasAlquilados", response);
          observer.next(response);
        },
        (err) => {
          console.log("error ws getMasAlquilados", err);
          let msgError = "Se ha producido un error cargando los datos";

          observer.error(msgError);
        }
      );*/

      return { unsubscribe() { } };
    });
  }

  getMarcas(){
    return new Observable<any>(observer => {
     
      const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; charset=utf-8'
        })
      }

      const url = environment.API_URL + '/almacenes/manufacturers-almacen/'+environment.almacen; 
      this.http.get(url, options).subscribe(
        (response: any) => {
          console.log("ws getMarcas", response);
          observer.next(response);
        },
        (err) => {
          console.log("error ws getMarcas", err);
          let msgError = "Se ha producido un error cargando los datos";

          observer.error(msgError);
        }
      );

      return { unsubscribe() { } };
    });
  }

  getMarcasGarantia10(){
    return new Observable<any>(observer => {
     
      const accessToken = this.authSvc.getAccessTokenGarantia10();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/almacenes/manufacturers';
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getMarcas", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getMarcas", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
    });
  }

  getTiposDispositivoGarantia10(){
    return new Observable<any>(observer => {
     
      const accessToken = this.authSvc.getAccessTokenGarantia10();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/almacenes/tipos-dispositivo';
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getTiposDispositivo", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getTiposDispositivo", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
    });
  }

  getProductosAlmacen(numPage: number, pageSize: number, order: string, direction: string, formValue: any, compraAlquiler: number): Observable<any> {
    return new Observable<any>(observer => {
     
      const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; charset=utf-8'
        })
      }

      const params = {
        id_almacen: environment.almacen,
        page: numPage,
        pageSize: pageSize,
        sortColum: 'id',
        sortDirection: 'DESC',
        category: 0,
        price_min: 0,
        price_max: 0,
        periodo_alquiler: null,
        marcas: [],
        nombre: ''
      }

      if (direction !== "") {
				params['sortColum'] = order;
				params['sortDirection'] = direction;
			}

      if(formValue.category && formValue.category !== 0){
        params['category'] = formValue.category;
      }

      if(formValue.price_min && formValue.price_min != ""){
        params['price_min'] = formValue.price_min;
      }

      if(formValue.price_max && formValue.price_max != ""){
        params['price_max'] = formValue.price_max;
      }

      if(formValue.periodo_alquiler && formValue.periodo_alquiler !== 0){
        params['periodo_alquiler'] = formValue.periodo_alquiler;
      }

      if(formValue.marcas && formValue.marcas.length>0){
        params['marcas'] = formValue.marcas;
      }

      if(formValue.nombre && formValue.nombre != ""){
        params['nombre'] = formValue.nombre;
      }

      const url = environment.API_URL + '/almacenes/inventario/' + compraAlquiler + '?' + this.serializeData(params); 
      this.http.get(url, options).subscribe(
        (response: any) => {
          console.log("ws getProductosAlmacen", response);
          observer.next(response);
        },
        (err) => {
          console.log("error ws getProductosAlmacen", err);
          let msgError = "Se ha producido un error cargando los datos";

          observer.error(msgError);
        }
      );

      return { unsubscribe() { } };
    });
  }




  serializeData(data:any) {
		if (typeof data !== 'object') {
			return ((data == null) ? "" : data.toString());
		}

		let buffer = [];

		for (let name in data) {
			if (!data.hasOwnProperty(name)) {
				continue;
			}
			let value = data[name];
			buffer.push(
				encodeURIComponent(name) +
				"=" +
				encodeURIComponent((value == null) ? "" : value)
			);
		}

		let source = buffer
			.join("&")
			.replace(/%20/g, "+")
			;
		return (source);
	}


}