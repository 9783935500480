<div class="mb-4" *ngIf="screenWidth != undefined && screenWidth > 990">

    <div class="container-lg mt-4 mb-4">
        <h6>
            HOME
            <i class="fa fa-angle-right" style="margin-left: 10px; margin-right: 10px;" aria-hidden="true"></i>
            TIENDA
            <i class="fa fa-angle-right" style="margin-left: 10px; margin-right: 10px;" aria-hidden="true"></i>
            MI CUENTA
            <i class="fa fa-angle-right" style="margin-left: 10px; margin-right: 10px;" aria-hidden="true"></i>
            PEDIDOS
        </h6>
    </div>
    
    <hr>
    
    <div class="row">
    
    
        <div class="col-3 mt-4 mb-4">
            <app-profile-nav-bar></app-profile-nav-bar>
        </div>
    
        <div class="col-9 mt-4 mb-4">
    
            <h1 class="presupuestos text-center" *ngIf="tipo != 'done'">HISTORIAL DE PEDIDOS</h1>
            <h1 class="presupuestos text-center" *ngIf="tipo == 'done'">HISTORIAL DE PEDIDOS FINALIZADOS</h1>
    
            <button type="button" class="btn btn-dark" (click)="openSearch(content)"><i class="fas fa-search icon" style="color: var(--primary-color) !important;"></i> Buscar</button>

            <table class="table mt-4">
                <thead class="thead-dark">
                    <tr>
                        <th scope="col">TIPO</th>
                        <th scope="col">ID PEDIDO</th>
                        <th scope="col">FECHA MODIFICACIÓN</th>
                        <th scope="col">ESTADO</th>
                        <th scope="col">TOTAL</th>
                        <th scope="col">TOTAL PRESUPUESTOS</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="text-muted" *ngFor="let ticket of pedidos">
                        <td>{{ticket.tracking_category?.name?ticket.tracking_category?.name:'Reparación'}}</td>
                        <td>#{{ticket.ID}}</td>
                        <td>{{ticket.date}}</td>
                        <td>{{ticket.estado_reparacion}}</td>
                        <td>{{ticket.Importe}} €</td>
                        <td>{{ticket.total_presupuestos}} €</td>
                        <td *ngIf="ticket.tracking_category?.name != 'Reparación' && ticket.category != 0 && ticket.category != 218"><i class="fas fa-check-circle fa-2x"></i></td>
                        <td *ngIf="ticket.tracking_category?.name == 'Reparación' || ticket.category == 0 || ticket.category == 218"><button type="submit" class="btn btn-dark" (click)="navigate('repairs/'+ticket.ID)">Seguimiento</button></td>
                    </tr>
                </tbody>
            </table>

            <div class="container-lg mt-4 mb-4 d-flex justify-content-end">
                <ngb-pagination [collectionSize]="total" [pageSize]="pageSize" [(page)]="numPage" aria-label="Paginación" [maxSize]="5" [ellipses]="false" (pageChange)="filtrarPedidos(false)"></ngb-pagination>
            </div>
            
            <div class="container-lg mt-4 mb-4 d-flex justify-content-center">
                
                <button class="btn btn-dark rounded" style="margin-right: 20px;" (click)="navigate('profile')">VOLVER</button>
            </div>
        </div>
    
    </div>
</div>

<div class="mb-4" *ngIf="screenWidth != undefined && screenWidth <= 990">

    <div class="container-lg mt-4 mb-4">
        <div class="row">
            <div class="col">
                  <div class="dropdown">
                    <button class="btn btn-outline-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-list" aria-hidden="true"></i>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <h6 class="dropdown-header">MI CUENTA</h6>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="/profile">Perfil</a>
                        <a class="dropdown-item" href="/orders">Pedidos</a>
                        <a class="dropdown-item" href="/address">Direcciones</a>
                        <a class="dropdown-item" href="/wishes">Lista de deseos</a>
                        <a class="dropdown-item" href="/accountDetails">Detalles de la cuenta</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" style="cursor: pointer;" (click)="cerrarSesion()"><i class="fas fa-sign-out-alt"></i> Salir</a>
    
                    </div>
                  </div>
            </div>
        </div>
    </div>

    <hr>

    <div class="row">
    
        <div class="col mt-4 mb-4">
    
            <h1 class="presupuestos text-center" *ngIf="tipo != 'done'">HISTORIAL DE PEDIDOS</h1>
            <h1 class="presupuestos text-center" *ngIf="tipo == 'done'">HISTORIAL DE PEDIDOS FINALIZADOS</h1>
    
            <button type="button" class="btn btn-dark" (click)="openSearch(content)"><i class="fas fa-search icon" style="color: var(--primary-color) !important;"></i> Buscar</button>

            <div style="max-width: 100%; overflow: auto;">
                
            <table class="table mt-4">
                <thead class="thead-dark">
                    <tr>
                        <th scope="col">TIPO</th>
                        <th scope="col">ID PEDIDO</th>
                        <th scope="col">FECHA MODIFICACIÓN</th>
                        <th scope="col">ESTADO</th>
                        <th scope="col">TOTAL</th>
                        <th scope="col">TOTAL PRESUPUESTOS</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="text-muted" *ngFor="let ticket of pedidos">
                        <td>{{ticket.tracking_category?.name?ticket.tracking_category?.name:'Reparación'}}</td>
                        <td>#{{ticket.ID}}</td>
                        <td>{{ticket.date}}</td>
                        <td>{{ticket.estado_reparacion}}</td>
                        <td>{{ticket.Importe}} €</td>
                        <td>{{ticket.total_presupuestos}} €</td>
                        <td *ngIf="ticket.tracking_category?.name != 'Reparación' && ticket.category != 0 && ticket.category != 218"><i class="fas fa-check-circle fa-2x"></i></td>
                        <td *ngIf="ticket.tracking_category?.name == 'Reparación' || ticket.category == 0 || ticket.category == 218"><button type="submit" class="btn btn-dark" (click)="navigate('repairs/'+ticket.ID)">Seguimiento</button></td>
                    </tr>
                </tbody>
            </table>
            
            </div>

            <div class="container-lg mt-4 mb-4">
                <ngb-pagination [collectionSize]="total" [pageSize]="pageSize" [(page)]="numPage" aria-label="Paginación" [maxSize]="5" [ellipses]="false" (pageChange)="filtrarPedidos(false)"></ngb-pagination>
            </div>

            <div class="container-lg mt-4 mb-4 d-flex justify-content-center">
                
                <button class="btn btn-dark rounded" style="margin-right: 20px;" (click)="navigate('profile')">VOLVER</button>
            </div>
        </div>
    
    </div>
</div>

<ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Buscar pedido</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <form #mainForm="ngForm" [formGroup]="formBusqueda">
            <div class="flex-column">
                <div class="form-group">
                    <label for="tipo">Tipo</label>

                    <ngx-select id="tipo"
                    name="tipo" 
                    formControlName="tipo"
                    [formControl]="selectTipo"
                    [allowClear]="true" 
                    [items]="tipos" 
                    optionValueField="ID"
                    optionTextField="name" 
                    placeholder="Tipo"
                    [(ngModel)]="tipoSelected">
                    </ngx-select>
                </div>

                <div class="form-group gro1">
                    <label for="ID">ID Pedido</label>
                    <input id="ID" name="ID" formControlName="id" placeholder="ID Pedido" class="form-control" [maxlength]="250">
                </div>

                <div class="form-group gro1">
                    <label for="fecha">Fecha</label>
                    <input type="date" id="fecha" name="fecha" formControlName="fecha" class="form-control">
                </div>

                <div class="form-group">
                    <label for="estado">Estado</label>

                    <ngx-select id="estado"
                    name="estado" 
                    formControlName="estado"
                    [formControl]="selectEstado"
                    [allowClear]="true" 
                    [items]="estados" 
                    optionValueField="val"
                    optionTextField="name" 
                    placeholder="Estado"
                    [(ngModel)]="estadosSelected">
                    </ngx-select>
                </div>

                <div class="form-group">
                    <label>Precio</label>
                    <ng5-slider [(ngModel)]="sliderValue" [ngModelOptions]="{standalone: true}" [(value)]="minValue" [(highValue)]="maxValue"
                        [options]="optionsPrice"></ng5-slider>
                </div>
            </div>
            
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="filtrarPedidos()">Buscar</button>
    </div>
  </ng-template>