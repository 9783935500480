import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject } from 'rxjs';
import { Block } from 'notiflix';
import { takeUntil } from 'rxjs/operators';
import { ReparaService } from 'src/app/services/repara/repara.service';

export interface Mensaje {
    type: 'message' | 'action' | 'action-res' | 'input' | 'list-data' | 'message-error',
    disabled?: boolean,
    message?: string,
    error?: boolean,
    success?: boolean,
    actions?: Array<ActionMensaje>,
    input?: string,
    listName?: string,
    data?: any
}

export interface ActionMensaje {
    action: 'iniciar' | 'reiniciar' | 'guardar' | 'sintomaSi' | 'sintomaNo' | 'sintomaNS',
    text?: string,
    icon?: string,
    title?: string,
    id?: string,
    nombre?: string,
    selected?: boolean,
}

@Component({
	selector: 'app-bot-autodiagnosis',
	templateUrl: './bot-autodiagnosis.component.html',
	styleUrls: ['./bot-autodiagnosis.component.scss']
})
export class BotAutodiagnosisComponent implements OnInit, OnDestroy {

	@Input() tipoElemento!: string;
	@Input() marca!: string;
	@Input() modelo!: string;

	@Output() autodiagnosisComplete: EventEmitter<any> = new EventEmitter();

    @ViewChild('messages', {static: true}) scrollMessages: any | undefined;

	private _unsubscribeAll: Subject<any>;

	mensajes: Array<any> = [];
    sintomas: any;
    posiblesSintomas: Array<any> = [];
    indexPosibleSintoma: number = 0;
    sintomasAceptados: Array<any> = [];

	constructor(
		public activeModal: NgbActiveModal,
        private reparaSvc: ReparaService,
	) {
		this._unsubscribeAll = new Subject();
	}

	ngOnInit(): void {
		// console.log("tipoElemento", this.tipoElemento);
		// console.log("marca", this.marca);
		// console.log("modelo", this.modelo);

		this.initProcess();
	}

	ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    initLoading(textoLoading?: string) {
		// this.loading = true;
		Block.standard('.messages', textoLoading ? textoLoading : '', {
			svgColor: '#666',
			messageColor: '#666',
			messageFontSize: '18px'
		});
	}

	stopLoading() {
		// this.loading = false;
		Block.remove('.messages');
	}

    getLangUser() {
        let langUser = "es";
        // if (this.usuario) {
        //     langUser = this.usuario.language ? this.usuario.language : "es";
        // }

        return langUser;
    }

    private scrollDown(): void
    {
        try {
            setTimeout(() => {
                this.scrollMessages.nativeElement.scroll({
                    top: this.scrollMessages.nativeElement.scrollHeight,
                    left: 0,
                    behavior: 'smooth'  
                });
            }, 100);
        } catch (error) {
            console.log("ERROR Scroll", error);
        }
    }

    addMessageError(texto: string) {
        const message: Mensaje = {
            type: 'message',
            message: texto,
            error: true
        }
        this.mensajes.push(message);

        const langUser = this.getLangUser();
        this.sayIt(texto, langUser);
    }
    addMessageSuccess(texto: string) {
        const message: Mensaje = {
            type: 'message',
            message: texto,
            success: true
        }
        this.mensajes.push(message);

        const langUser = this.getLangUser();
        this.sayIt(texto, langUser);
    }
	addMessage(texto: string, read?: boolean) {
        const message: Mensaje = {
            type: 'message',
            message: texto,
        }
        this.mensajes.push(message);

        if (read === undefined) {
            read = true;
        }

        if (read) {
            const langUser = this.getLangUser();
            this.sayIt(texto, langUser);
        }
    }
	addActionMessage(actions: Array<ActionMensaje>, type?: 'action' | 'action-res') {
        const message: Mensaje = {
            type: type ? type : 'action',
            disabled: false,
            actions: actions,
        }
        this.mensajes.push(message);
    }
    addListDataMessage(data: any, listName: string) {
        const message: Mensaje = {
            type: 'list-data',
            listName: listName,
            data: data
        }
        this.mensajes.push(message);
    }

	initProcess() {
		this.mensajes = [];
		this.addMessage("El sistema de autodiagnosis te ayudará a indicar la avería o incidencia de tu dispositivo");
		this.addActionMessage([
            {
                text: "INICIAR AUTODIAGNOSIS",
                action: "iniciar"
            }
        ]);
        this.sintomas = {
            SI: [],
            NO: [],
            NSNC: []
        }
        this.indexPosibleSintoma = 0;
        this.posiblesSintomas = [];
	}

    sayIt(text: string, lang: string) {
		if (window.speechSynthesis) {
			let language;
			switch (lang) {
				case 'es':
					language = 'es-ES';
					break;
				case 'en':
					language = 'en-US';
					break;
			
				default:
					language = 'es-ES';
					break;
			}
	
			let speech = new SpeechSynthesisUtterance();
			speech.text = text;
			speech.volume = 1;
			speech.rate = 1;
			speech.pitch = 1;  
			speech.lang = language;
	
			window.speechSynthesis.speak(speech);
		}
		else {
			console.log("speechSynthesis no soportado");
		}
	}

    actionMensaje(accion: string, index: number, idItem?: number) {
        // console.log("Accion mensaje", accion);
        
        switch (accion) {
            case "iniciar":
                this.mensajes[index].disabled = true;
                this.iniciarAutoDiagnosis();
                break;
            case "reiniciar":
                this.initProcess();
                break;
            case "sintomaSi":
                this.mensajes[index].disabled = true;
                const actionSi = this.mensajes[index].actions.find((item:any) => { return item.action == 'sintomaSi'; });
                if (actionSi) {
                    actionSi['selected'] = true;
                }
                this.sintomas.SI.push(idItem);
                this.sintomasAceptados.push({ id: idItem, nombre: actionSi.nombre });

                this.indexPosibleSintoma = 0;
                this.posiblesSintomas = [];
                this.solicitarPosibleSintoma();
                break;
            case "sintomaNo":
                this.mensajes[index].disabled = true;
                const actionNo = this.mensajes[index].actions.find((item:any) => { return item.action == 'sintomaNo'; });
                if (actionNo) {
                    actionNo['selected'] = true;
                }
                this.sintomas.NO.push(idItem);
                this.solicitarPosibleSintoma();
                break;
            case "sintomaNS":
                this.mensajes[index].disabled = true;
                const actionNS = this.mensajes[index].actions.find((item:any) => { return item.action == 'sintomaNS'; });
                if (actionNS) {
                    actionNS['selected'] = true;
                }
                this.sintomas.NSNC.push(idItem);
                this.solicitarPosibleSintoma();
                break;
            case "guardar":
                this.sendResultAdutodiagnosis();
                break;
        
            default:
                break;
        }
    }

    iniciarAutoDiagnosis() {
        this.solicitarPosibleSintoma();
    }

    addMensajesPosibleSintoma() {
        const posibleSintoma = this.posiblesSintomas[this.indexPosibleSintoma];
        const mensaje = posibleSintoma.sintoma != "" ? posibleSintoma.sintoma + " ?" : 'SIN SINTOMAS';
        this.addMessage(mensaje);
        this.addActionMessage([
            { id: posibleSintoma.idSintoma, nombre: posibleSintoma.sintoma, action: "sintomaSi", icon: "fa-check-circle" },
            { id: posibleSintoma.idSintoma, nombre: posibleSintoma.sintoma, action: "sintomaNo", icon: "fa-times-circle" },
            { id: posibleSintoma.idSintoma, nombre: posibleSintoma.sintoma, action: "sintomaNS", icon: "fa-question-circle", title: posibleSintoma.comentarios },
        ], 'action-res');
        
        this.scrollDown();
    }

    solicitarPosibleSintoma() {
        const langUser = this.getLangUser();

        if (this.posiblesSintomas.length > 0 && this.indexPosibleSintoma < (this.posiblesSintomas.length-1)) {
            this.indexPosibleSintoma++;
            this.addMensajesPosibleSintoma();
        }
        else {
            this.initLoading("Cargando datos ...");
            this.reparaSvc.solcitarSintomaAutoDiagnosis(this.tipoElemento, this.marca, this.modelo, this.sintomas, langUser).pipe(takeUntil(this._unsubscribeAll)).subscribe(
                (data: any) => {
                    this.stopLoading();
    
                    if (data.posiblesSintomas && data.posiblesSintomas.length > 0) {
                        this.indexPosibleSintoma = 0;
                        this.posiblesSintomas = data.posiblesSintomas.slice();
    
                        this.addMensajesPosibleSintoma();
                    }
                    else {
                        if (this.sintomas.SI.length == 0) {
                            this.addMessageError("Del listado de posibles motivos no se ha marcando ninguno");
                            this.addMessage("Reinicia la autodiagnosis para volver a verlos");
                            this.addActionMessage([
                                { text: "REINICIAR DIAGNOSIS",  action: "reiniciar" }
                            ]);
                        }
                        else {
                            this.addMessage("Estos son los motivos de avería que has indicado. Pulsa 'Guardar' para añadirlos al formulario o 'Reiniciar' para volver a ver los posibles mótivos de avería.");
                            this.addListDataMessage(this.sintomasAceptados, 'sintomas-aceptados');
                            this.addActionMessage([
                                { text: "GUARDAR",  action: "guardar" },
                                { text: "REINICIAR",  action: "reiniciar" }
                            ]);
                        }

                        this.scrollDown();
                    }
                },
                (err:any) => {
                    this.stopLoading();
                    this.addMessageError(err);

                    this.scrollDown();
                }
            );
        }

    }

	sendResultAdutodiagnosis() {
		this.autodiagnosisComplete.emit({ sintomas: this.sintomasAceptados });
		this.activeModal.close()
	}

}
