import { Component, ElementRef, HostListener, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CartItem } from 'src/app/models/cart-item';
import { Product } from 'src/app/models/product';
import { MessageService } from 'src/app/services/message.service';
import { ProductService } from 'src/app/services/product.service';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { AuxiliarService } from 'src/app/services/auxiliar.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-dialog-select-options-update',
  templateUrl: './dialog-select-options-update.component.html',
  styleUrls: ['./dialog-select-options-update.component.scss']
})
export class DialogSelectOptionsUpdateComponent implements OnInit {

  @Input() product: any;
  closeResult: string | undefined;
  monthSelected: number | undefined;
  priceSelected: number = 0;
  cantidad: number | undefined;
  imgPreview: string = '';
  imagesToshow: Array<any> = [];
  emailString: string;
  rutaImagen: string = environment.API_URL+'/';

  meses: number[] = []
  mesesPrecio = new Array();

  screenHeight: number | undefined;
  screenWidth: number | undefined;
  
  url = '';
  buy: boolean = false;
  noDisponible: boolean = false;

  listaDeseos = [];
  deseado: Boolean = false;
  idDeseado: Number = 0;

  @ViewChild("modalAddSuccess") modalAddSuccess: ElementRef | undefined

  logueado = false;

  constructor(
    private modalService: NgbModal,
    private messageSvc: MessageService,
    private storageSvc: StorageService,
    private route: ActivatedRoute,
    private productService: ProductService,
    private auxiliar: AuxiliarService,
    private authSvc: AuthService,
  ) {
    this.onResize()
    this.emailString = "mailto:flex@cygit.com";

    try{
      this.url = this.route.snapshot.url[1].path
      if(this.url == 'buy'){
        this.buy = true;
      }
    }
    catch(error){
      console.log('Estás en el apartado de alquiler')
    }
  }

  ngOnInit(): void {
    this.logueado = this.authSvc.isUserLoggedIn()
    if(this.buy == true){
      this.priceSelected = this.product.precioCompra;
    }else{
      if(this.product && this.product.articulo_alquileres && this.product.articulo_alquileres[0] && this.product.articulo_alquileres[0].alquiler_mes){
        this.monthSelected = this.product.articulo_alquileres[0].alquiler_mes.month;
        this.priceSelected = this.product.articulo_alquileres[0].alquiler_mes.price;
      }
    }
    
    if(this.product && this.product.urlImagenes){
      for(let i = 0; i < this.product.urlImagenes.length; i++){
        if(this.imagesToshow.length < 4 && this.product.urlImagenes[i].url){
          this.imagesToshow.push(this.product.urlImagenes[i]);
        }
      }
    }
    for (let i=0; i<this.product.articulo_alquileres.length; i++){
      this.noDisponible = true;
      if(this.product.articulo_alquileres[i].alquiler_mes != undefined){
        this.noDisponible = false;
      }
    }

    // this.loadWishesList();
  }

  openVerticallyCentered(content: any) {
    this.modalService.open(content, { centered: true, size: 'xl' });
  }

  addToCart(cantidad: string): void {
    this.cantidad = Number(cantidad);
    var maximoAlquilados = false;
    let productoMetido = false;

    if(this.buy == true){
      this.monthSelected = 0;
    }

    if (this.product != undefined) {
      var auxProducto = {
        id: this.product.ID,
        name: this.product.name,
        price: this.priceSelected,
        quantity: Number(cantidad),
        imgUrl: (this.product.urlImagenes && this.product.urlImagenes[0] && this.product.urlImagenes[0].url)?[this.rutaImagen+this.product.urlImagenes[0].url]:[''],
        monthsPossible: [this.monthSelected]
      };

      if(localStorage.getItem('cart') == null){
        localStorage.setItem('cart', '[]');
      }
      console.log(this.product.name + ' añadido')
      let res = new CartItem(auxProducto);

      if (this.product != undefined) {
        
        if(cantidad <= this.product.stock){
          res.quantity = Number(cantidad);
        }else{
          res.quantity = this.product.stock;
        }

        let cartItems = this.storageSvc.getCart();
        if (cartItems.length == 0) {
          cartItems.push(res)
          productoMetido = true;
        } else {
          if(this.buy != true){
            productoMetido = true;
            maximoAlquilados = true;
          }

          for (let i = 0; i < cartItems.length && productoMetido == false; i++) {
            if(cartItems[i].month > 0){
              console.log('Carrito', cartItems)
              productoMetido = true;
              maximoAlquilados = true;
            }

            if (cartItems[i].productId == res.productId && cartItems[i].month == res.month) {
              productoMetido = true;
              if(cartItems[i].quantity + res.quantity <= this.product.stock){
                this.cantidad = res.quantity
                cartItems[i].quantity += res.quantity;
              }else{
                this.cantidad = this.product.stock - cartItems[i].quantity
                cartItems[i].quantity = this.product.stock
              }
            }
          }

          if (productoMetido == false) {

            cartItems.push(res)

          }
        }

        this.storageSvc.setCart(cartItems);
      }

      if(maximoAlquilados){
        if(this.monthSelected && this.monthSelected > 0){
          console.log(res.month);
          Notify.warning('Solo puede agregar alquileres en carritos vacíos', {position: 'left-bottom'});
        }else{
          Notify.warning('No se pueden agregar más productos al carrito con alquileres', {position: 'left-bottom'});
        }
      }else{
        this.modalService.dismissAll()
        Notify.success('Producto agregado al carrito', {position: 'left-bottom', success: {background: '#63b421'}});
      }
    }

    /*this.modalService.dismissAll();
      this.modalService.open(this.modalAddSuccess, { centered: true });
      setTimeout(() =>{ 
        this.modalService.dismissAll()
       }, 3000); */
  }

  selectMonth(month: number, price: number): void {
    console.log('Seleccionado ' + month + ' meses')
    this.monthSelected = month;
    this.priceSelected = price;
    /*for (let i = 0; i < this.mesesPrecio.length; i++) {
      if (this.mesesPrecio[i].meses == month) {
        if (this.product != undefined) {
          this.product.price = this.mesesPrecio[i].precio;
        }
      }
    }*/
    console.log(this.monthSelected)
  }


  @HostListener('window:resize', ['$event'])
  onResize(event?: undefined) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  showImage(urlImagen: string){
    this.imgPreview = urlImagen;
  }

  loadWishesList(){
    if(localStorage.getItem('idCliente') != null){

      this.auxiliar.getDeseosCliente(Number(localStorage.getItem('idCliente'))).subscribe(
        (data: any) => {
          this.listaDeseos = data;
          console.log(this.listaDeseos)
          this.compruebaDeseado();
        },
        (err) => {
          console.log('Error: '+err)
        }
      );
    }
  }

  compruebaDeseado(){
    for(let i=0; i<this.listaDeseos.length; i++){
      if(this.product.ID == this.listaDeseos[i]['elemento']['ID']){
        this.deseado = true;
        this.idDeseado = this.listaDeseos[i]['ID'];
      }
    }
  }
  


  addDeseo(elementoId: Number){
    if(localStorage.getItem('idCliente') != null){

      this.auxiliar.crearDeseo(Number(localStorage.getItem('idCliente')), elementoId).subscribe(
        (data: any) => {
          this.deseado = !this.deseado;
          console.log(data)
          this.deseado = true;
          this.idDeseado = data.ID;
        },
        (err) => {
          console.log('Error: '+err)
        }
      );
    }
  }

  eliminarDeseo(idDeseo: Number){
    if(localStorage.getItem('idCliente') != null){

      this.auxiliar.deleteDeseo(idDeseo).subscribe(
        (data: any) => {
          this.idDeseado = 0;
          this.deseado=false;
          console.log(data)
          
        },
        (err) => {
          console.log('Error: '+err)
        }
      );
    }
  }

}
