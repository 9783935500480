import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ObservablesService {
  private totalCarrito_ob: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  totalCarrito: Observable<number> = this.totalCarrito_ob.asObservable();

  constructor() { }

  updateTotal(total: number){
    this.totalCarrito_ob.next(total);
  }
}
