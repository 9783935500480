import { AfterViewInit, Component, ElementRef, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import * as Notiflix from 'notiflix';
import { AuxiliarService } from 'src/app/services/auxiliar.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Report } from 'notiflix/build/notiflix-report-aio';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth/auth.service';
import { create } from 'domain';

@Component({
  selector: 'app-mybudget',
  templateUrl: './mybudget.component.html',
  styleUrls: ['./mybudget.component.css']
})
export class MybudgetComponent implements OnInit, AfterViewInit {

  screenHeight: number | undefined;
  screenWidth: number | undefined;
  total!: number;
  orderId!: number;

  signature!: string;
  merchantFuc!: string;
  merchantParameters!: string;

  ticket: any = null;
  idTicket: any = null;
  idPresupuesto: any = null;
  seguimientos: any = null;
  dir_recogida: any = null;
  dir_envio: any = null;
  presupuesto: any = null;

  metodo_pago: string = '';

  showPispText: boolean = false;

  btn_rechazar_enabled: boolean = true;
  btn_aceptar_enabled: boolean = true;
  motivo_rechazo: string = '';

  pagoAceptado: boolean = false;
  error: boolean = false;

  tpv_url: string = environment.tpv_url;
  tpv_pisp_url: string = environment.tpv_pisp_url;

  @ViewChild('modalSuccess') modalSuccess: TemplateRef<any> | undefined;
  @ViewChild('modalError') modalError: TemplateRef<any> | undefined;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private auxiliarSvc: AuxiliarService,
    private modalService: NgbModal
  ) {
    this.idTicket = this.route.parent?.snapshot.url[1].path;
    this.idPresupuesto = this.route.snapshot.url[1].path;

    this.onResize();

    moment.locale('es');

    this.route.queryParams.subscribe(
      (params) => {
        console.log(params)
        console.log(params.ok)
        if (params.ok && params.ok == 1 /* && basededato.pagao = 0 */) {
          this.pagoAceptado = true;
        } else if (params.ok && params.ok == 0 /* && basededato.pagao = 0 */) {
          this.error = true;
        }
      }
    );
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?: undefined) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  ngOnInit(): void {
    Notiflix.Loading.pulse('Cargando presupuesto...',{
      svgColor: '#63b421',
    });
    this.loadTicket();
  }

  ngAfterViewInit(): void {
    if (this.pagoAceptado == true) {
      if (this.modalSuccess != undefined) {
        this.modalService.open(this.modalSuccess, { centered: true });
      }
    }else if(this.error == true){
      if (this.modalError != undefined) {
        this.modalService.open(this.modalError, { centered: true });
      }
    }
  }

  navigate(url: string, params?: any) {
    if (params) {
      this.router.navigate([url], { queryParams: params });
    }
    else {
      this.router.navigate([url]);
    }
  }

  createPayment(paymentMethod: string) {
    this.auxiliarSvc.obtenerTPV(paymentMethod, String(this.idTicket), String(this.idPresupuesto)).subscribe(
      (data: any) => {
        this.signature = data.signature;
        this.merchantFuc = data.merchantFuc;
        this.merchantParameters = data.merchantParameters;
      },
      (err) => {
        console.log('Error -> ' + err)
      }
    );
  }

  selectPaymentMethod(method: string) {
    this.metodo_pago = method;
    if(this.metodo_pago == 'PISP') {
      this.showPispText = true;
    } else {
      this.showPispText = false;
      this.createPayment(this.metodo_pago);
    }
  }

  modalPresupuesto(modalPayment : any, modalAceptarPresupuesto : any) {
    if(this.ticket.pagar_presupuesto)
      this.openModal(modalPayment);
    else
      this.openModal(modalAceptarPresupuesto)
  }

  aceptarPresupuesto() {
    this.modalService.dismissAll();
    //Aceptar presupuesto
    Notiflix.Loading.pulse('Aceptando presupuesto...', {
      svgColor: '#63b421',
    });
    this.btn_aceptar_enabled = false;
    this.auxiliarSvc.aceptarPresupuesto(this.idPresupuesto, this.idTicket).subscribe(
      (data: any) => {
        Report.success(
          'Aceptado correctamente',
          'El presupuesto se ha aceptado correctamente',
          'Aceptar',
          { success: { svgColor: '#63b421', buttonBackground: '#63b421', backOverlayColor: 'rgba(13, 0, 0, 0.2)', } }
        );
        Notiflix.Loading.remove();
        this.getPresupuesto(this.idPresupuesto);
        this.btn_aceptar_enabled = true;
      },
      (err) => {
        Notiflix.Loading.remove();
        this.btn_aceptar_enabled = true;
        console.log('Error -> ' + err)
      }
    );
  }

  loadTicket() {
    if (this.idTicket && this.idTicket != "") {
      this.getDatosReparacion(this.idTicket);
    }
  }

  getDatosReparacion(id: number) {
    this.auxiliarSvc.getReparacion(id).subscribe(
      (data: any) => {
        this.ticket = data;

        this.getPresupuesto(this.idPresupuesto);
        Notiflix.Loading.remove();
      },
      (err) => {
        Notiflix.Loading.remove();
        console.log('Error -> ' + err)
      }
    );
  }

  formatDate(fecha: string) {
    return (moment(fecha)).format('LL');
  }

  formatNumber(numero: number) {
    return Math.round(numero * 100) / 100;
  }

  getPresupuesto(id: number) {
    this.auxiliarSvc.getPresupuestoId(id).subscribe(
      (data: any) => {
        this.presupuesto = data;
      },
      (err) => {
        Notiflix.Loading.remove();
        console.log('Error -> ' + err)
      }
    );
  }

  descargarPresupuesto() {
    Notiflix.Loading.pulse('Descargando presupuesto...', {
      svgColor: '#63b421',
    });

    this.auxiliarSvc.descargarPresupuesto(this.idPresupuesto, this.idTicket).subscribe(
      (data: any) => {
        var a = document.createElement("a"); //Create <a>
        a.href = "data:" + data.mime + ";base64," + data.data; //Image Base64 Goes here
        a.download = data.filename; //File name Here
        a.click(); //Downloaded file

        Notiflix.Loading.remove();
      },
      (err) => {
        console.log('Error -> ' + err)
      }
    );
  }

  openModal(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {

    }, (reason) => {

    });
  }

  rechazarPresupuesto() {
    this.modalService.dismissAll();
    this.btn_rechazar_enabled = false;
    Notiflix.Loading.pulse('Rechazando presupuesto...', {
      svgColor: '#63b421',
    });
    this.auxiliarSvc.rechazarPresupuesto(this.idPresupuesto, this.motivo_rechazo, this.idTicket).subscribe(
      (data: any) => {
        Report.success(
          'Rechazado correctamente',
          'El presupuesto se ha rechazado correctamente',
          'Aceptar',
          { success: { svgColor: '#63b421', buttonBackground: '#63b421', backOverlayColor: 'rgba(13, 0, 0, 0.2)', } }
        );
        Notiflix.Loading.remove();
        this.getPresupuesto(this.idPresupuesto);
        this.btn_rechazar_enabled = true;
      },
      (err) => {
        Notiflix.Loading.remove();
        this.btn_rechazar_enabled = true;
        console.log('Error -> ' + err)
      }
    );
  }


}
