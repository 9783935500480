import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { Product } from '../models/product';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  message = new Subject();
  checkSession = new BehaviorSubject<boolean>(false);

  constructor() { }

  sendMessage(product: Product): void {
    this.message.next(product);
  }

  getMessage(): Observable<any> {
    return this.message.asObservable();
  }

  sendSessionAproval(check: boolean): void {
    this.checkSession.next(check);
  }

  getSessionAproval(): boolean{
    return this.checkSession.getValue();
  }
}
