<div class="mt-4">

    <h3 class="text-center">REGISTRO</h3>

    <form name="form" (ngSubmit)="send()" #f="ngForm" novalidate>
        <div class="row" *ngIf="screenWidth != undefined && screenWidth > 990">
            <div class="form-group">
                <div class="row mt-4">
                    <div class="col-3"></div>
                    <div class="col-3">
                        <div style="display: inline-flex;">
                            <h6 class="text-muted">Nombre</h6>
                        </div>

                        <input matInput class="form-control" #name maxlength="80" [(ngModel)]="RegisterModel.name"
                            name="Nombre">
                    </div>
                    <div class="col-3">
                        <div style="display: inline-flex;">
                            <h6 class="text-muted">Apellidos</h6>
                        </div>
                        <input matInput class="form-control" #surname maxlength="80" [(ngModel)]="RegisterModel.surname"
                            name="Apellidos">
                    </div>
                    <div class="col-3"></div>
                </div>
                <div class="row mt-4">
                    <div class="col-3"></div>
                    <div class="col-6">
                        <div style="display: inline-flex;">
                            <h6 class="text-muted">Correo electrónico</h6>
                        </div>

                        <input matInput class="form-control" type="email" #email maxlength="80"
                            [(ngModel)]="RegisterModel.email" name="Email" required>
                    </div>
                    <div class="col-3"></div>
                </div>
                <div class="row mt-4">
                    <div class="col-3"></div>
                    <div class="col-3">
                        <div style="display: inline-flex;">
                            <h6 class="text-muted">Contraseña</h6>
                        </div>

                        <input matInput class="form-control" #password type="password" maxlength="80"
                            [(ngModel)]="RegisterModel.password" name="Password" required>
                    </div>
                    <div class="col-3">
                        <div style="display: inline-flex;">
                            <h6 class="text-muted">Repita la contraseña</h6>
                        </div>
                        <input matInput class="form-control" type="password" #password2 maxlength="80"
                            [(ngModel)]="RegisterModel.password2" name="Password2" required>
                    </div>
                    <div class="col-3"></div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="screenWidth != undefined && screenWidth < 990">
            <div class="form-group">
                <div class="row mt-4">
                    <div class="col-3"></div>
                    <div class="col-6">
                        <div style="display: inline-flex;">
                            <h6 class="text-muted">Nombre</h6>
                        </div>

                        <input matInput class="form-control" #name maxlength="80" [(ngModel)]="RegisterModel.name"
                            name="Nombre">
                    </div>

                    <div class="col-3"></div>
                </div>
                <div class="row mt-4">
                    <div class="col-3"></div>
                    <div class="col-6">
                        <div style="display: inline-flex;">
                            <h6 class="text-muted">Apellidos</h6>
                        </div>
                        <input matInput class="form-control" #surname maxlength="80" [(ngModel)]="RegisterModel.surname"
                            name="Apellidos">
                    </div>
                    <div class="col-3"></div>
                </div>
                <div class="row mt-4">
                    <div class="col-3"></div>
                    <div class="col-6">
                        <div style="display: inline-flex;">
                            <h6 class="text-muted">Correo electrónico</h6>
                        </div>

                        <input matInput class="form-control" type="email" #email maxlength="80"
                            [(ngModel)]="RegisterModel.email" name="Email" required>
                    </div>
                    <div class="col-3"></div>
                </div>
                <div class="row mt-4">
                    <div class="col-3"></div>
                    <div class="col-6">
                        <div style="display: inline-flex;">
                            <h6 class="text-muted">Contraseña</h6>
                        </div>

                        <input matInput class="form-control" #password type="password" maxlength="80"
                            [(ngModel)]="RegisterModel.password" name="Password" required>
                    </div>

                    <div class="col-3"></div>
                </div>

                <div class="row mt-4">
                    <div class="col-3"></div>
                    <div class="col-6">
                        <div style="display: inline-flex;">
                            <h6 class="text-muted">Repita la contraseña</h6>
                        </div>
                        <input matInput class="form-control" type="password" #password2 maxlength="80"
                            [(ngModel)]="RegisterModel.password2" name="Password2" required>
                    </div>
                    <div class="col-3"></div>
                </div>
            </div>
        </div>


        <div class="row mt-4 text-center" *ngIf="messageError && messageError !== ''">
            <div class="col-2"></div>
            <div class="col-8">
                <div class="alert alert-danger" role="alert">
                    {{messageError}}
                </div>
            </div>
            <div class="col-2"></div>
        </div>

        <!-- <div class="alert alert-warning" *ngIf="f.submitted && isSignUpFailed">
            Signup failed!<br />{{ errorMessage }}
        </div>

        <div class="alert alert-success" *ngIf="isSuccessful">
            Your registration is successful!
        </div> -->

        <div class="text-center">
            <button type="submit" class="btn btn-dark mb-4">Enviar</button>
        </div>
    </form>

    <div class="text-center mt-4">
        <p>¿Ya tienes cuenta?</p>
        <button type="submit" class="btn btn-dark mb-4" routerLink="/login">Inicia Sesión</button>
    </div>

    <ng-template #modalRegisterSuccess let-d="dismiss">
        <div class="modal-body">
            <h3 class="text-center">¡Bienvenido!</h3>
            <h4 class="text-center">Se ha completado el registro correctamente</h4>
            <div class="text-center mt-4">
                <button class="btn btn-dark mb-4" (click)="caminoInicio()">Inicio</button>
            </div>
        </div>
    </ng-template>
</div>