import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-profile-nav-bar',
  templateUrl: './profile-nav-bar.component.html',
  styleUrls: ['./profile-nav-bar.component.css']
})
export class ProfileNavBarComponent implements OnInit {
  url: string;
  profile = false;
  orders = false;
  orders_done = false;
  address = false;
  accountDetails = false;
  wishes = false;

  screenHeight: number | undefined;
  screenWidth: number | undefined;
  
  constructor(
    private authSvc: AuthService,
    private router: Router,
    private route: ActivatedRoute,
  ) { 
    this.url = this.route.snapshot.url.join('');
    this.compruebaUrl(this.url)
    this.onResize()
  }

  ngOnInit(): void {
    
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?: undefined) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  compruebaUrl(url: string){
    switch(url){
      case 'profile':
        this.profile = true
      break;
      case 'orders':
        this.orders = true
      break;
      case 'ordersdone':
        this.orders_done = true
      break;
      case 'address':
        this.address = true
      break;
      case 'accountDetails':
        this.accountDetails = true
      break;
      case 'wishes':
        this.wishes = true
      break;
    }
  }



  cerrarSesion(): void {
    this.authSvc.logout()
    
  }

}
