<div class="text-center d-none d-sm-block">
    <img class="img-fluid mt-3 mb-3" src="../../../assets/img/repara/banner-repara.png" alt="Responsive image">
    <img class="img-fluid mb-1 px-2" src="../../../assets/img/repara/repara-tu-tecnologia.png" style="max-height: 50px; margin: 0 auto;" alt="Responsive image">
</div>
<div class="text-center d-block d-sm-none">
    <img class="img-fluid mb-2" src="../../../assets/img/repara/banner-repara.png" alt="Responsive image">
    <img class="img-fluid mb-1 px-2" src="../../../assets/img/repara/repara-tu-tecnologia-movil.png" style="max-height: 68px; margin: 0 auto;" alt="Responsive image">
</div>

<div class="container-fluid">

    <div class="flex-column d-none d-md-block" style="margin-top: 15px;">
        <ngb-carousel [showNavigationIndicators]="false" [showNavigationArrows]="true">
            <ng-template ngbSlide >
                <div class="flex-row between">
                    <img src="../../../assets/img/marcas/Logo01-Lenovo.png" alt="Random first slide" style="width: 20% !important;">
          
                    <img src="../../../assets/img/marcas/Logo02-Asus.png" alt="Random second slide" style="width: 20% !important;">
                
                    <img src="../../../assets/img/marcas/Logo03-Toshiba.png" alt="Random third slide" style="width: 20% !important;">
                
                    <img src="../../../assets/img/marcas/Logo04-LG.png" alt="Random third slide" style="width: 20% !important;">
                </div>
            </ng-template>
            <ng-template ngbSlide class="flex-row between">
                <div class="flex-row between">
                    <img src="../../../assets/img/marcas/Logo05-Brother.png" alt="Random first slide" style="width: 20% !important;">
                
                    <img src="../../../assets/img/marcas/Logo06-Qnap.png" alt="Random second slide" style="width: 20% !important;">
                
                    <img src="../../../assets/img/marcas/Logo07-MSI.png" alt="Random third slide" style="width: 20% !important;">
                
                    <img src="../../../assets/img/marcas/Logo08-Apple.png" alt="Random third slide" style="width: 20% !important;">
                </div>
            </ng-template>
            <ng-template ngbSlide class="flex-row between">
                <div class="flex-row between">
                    <img src="../../../assets/img/marcas/Logo09-Dynabook.png" alt="Random first slide" style="width: 20% !important;">
                
                    <img src="../../../assets/img/marcas/Logo10-Alcatel.png" alt="Random second slide" style="width: 20% !important;">
                
                    <img src="../../../assets/img/marcas/Logo11-Samsung.png" alt="Random third slide" style="width: 20% !important;">
                
                    <img src="../../../assets/img/marcas/Logo12-Garmin.png" alt="Random third slide" style="width: 20% !important;">
                </div>
            </ng-template>
        </ngb-carousel>
    </div>
    
    <div class="flex-column d-block d-md-none" style="margin: 10px;">
        <ngb-carousel [showNavigationIndicators]="false" [showNavigationArrows]="true">
            <ng-template ngbSlide >
                <div class="flex-row between">
                    <img src="../../../assets/img/marcas/Logo01-Lenovo.png" alt="Random first slide" style="width: 20% !important;">
          
                    <img src="../../../assets/img/marcas/Logo02-Asus.png" alt="Random second slide" style="width: 20% !important;">
                
                    <img src="../../../assets/img/marcas/Logo03-Toshiba.png" alt="Random third slide" style="width: 20% !important;">
                </div>
            </ng-template>
            <ng-template ngbSlide class="flex-row between">
                <div class="flex-row between">
                    <img src="../../../assets/img/marcas/Logo04-LG.png" alt="Random third slide" style="width: 20% !important;">
                
                    <img src="../../../assets/img/marcas/Logo05-Brother.png" alt="Random first slide" style="width: 20% !important;">
                
                    <img src="../../../assets/img/marcas/Logo06-Qnap.png" alt="Random second slide" style="width: 20% !important;">
                </div>
            </ng-template>
            <ng-template ngbSlide class="flex-row between">
                <div class="flex-row between">
                    <img src="../../../assets/img/marcas/Logo07-MSI.png" alt="Random third slide" style="width: 20% !important;">
                
                    <img src="../../../assets/img/marcas/Logo08-Apple.png" alt="Random third slide" style="width: 20% !important;">
                
                    <img src="../../../assets/img/marcas/Logo09-Dynabook.png" alt="Random first slide" style="width: 20% !important;">
                </div>
            </ng-template>
            <ng-template ngbSlide class="flex-row between">
                <div class="flex-row between">
                    <img src="../../../assets/img/marcas/Logo10-Alcatel.png" alt="Random second slide" style="width: 20% !important;">
                
                    <img src="../../../assets/img/marcas/Logo11-Samsung.png" alt="Random third slide" style="width: 20% !important;">
                
                    <img src="../../../assets/img/marcas/Logo12-Garmin.png" alt="Random third slide" style="width: 20% !important;">
                </div>
            </ng-template>
        </ngb-carousel>
    </div>

    <div class="mt-3 mb-4">
        <h4 class="mb-2">Garantía de éxito_</h4>
        <div class="row">
            <div class="col-12 col-md-6 py-2 d-flex justify-content-center">
                <img class="img-fluid" src="../../../assets/img/repara/reparamos-tu-tecnologia.png">
            </div>
            <div class="col-12 col-md-6 py-3">
                <img class="img-fluid" src="../../../assets/img/repara/pasos-repara.png">
            </div>
        </div>
    </div>
    
    <div class="mt-3 mb-4">
        <h4 class="mb-3">Solicita presupuesto sin compromiso_</h4>
        <div class="row row-col-2 row-col-md-3 d-flex justify-content-center">
            <div class="col-6 col-md-4">
                <div class="btn-solicitar p-2" (click)="navigateForm('portatil')">
                    <img class="img-fluid" src="../../../assets/img/repara/solicitar-presupuesto-portatil.png">
                </div>
            </div>
            <div class="col-6 col-md-4">
                <div class="btn-solicitar p-2" (click)="navigateForm('tablet')">
                    <img class="img-fluid" src="../../../assets/img/repara/solicitar-presupuesto-tablet.png">
                </div>
            </div>
            <div class="col-6 col-md-4">
                <div class="btn-solicitar p-2" (click)="navigateForm('smartphone')">
                    <img class="img-fluid" src="../../../assets/img/repara/solicitar-presupuesto-smartphone.png">
                </div>
            </div>
        </div>
    </div>

    <div class="mt-3 mb-4">
        <div class="row d-flex justify-content-center">
            <div class="col-12 col-md-7">
                <div>
                    <img class="img-fluid" src="../../../assets/img/repara/lider-del-sector.png">
                </div>
            </div>
            <div class="col-8 col-md-5 d-flex align-items-center">
                <div class="py-2">
                    <img class="img-fluid" src="../../../assets/img/repara/inteligencia-artificial.png">
                </div>
            </div>
        </div>
    </div>

    <div class="mt-3 mb-4 wrap-hablan">
        <h4 class="mb-3">Hablan de nosotros_</h4>
        <div class="row mb-5 d-flex justify-content-center">
            <div class="col-12 col-md-6 align-items-center">
                <div class="bloq-hablan">
                    <h5>El hospital europeo de la tecnología</h5>
                    <p class="text-justify font-weight-normal text-muted">
                        Una tienda de informática de un pueblo de Sevilla se
                        convierte en 15 años en una de las mayores redes de
                        servicio técnico de reparaciones.
                    </p>
                </div>
            </div>
            <div class="col-8 col-md-6 d-flex align-items-center justify-content-center">
                <div class="row py-2 d-flex justify-content-center">
                    <div class="col-12 col-md-10 col-lg-8">
                        <img class="img-fluid img-hablan" src="../../../assets/img/repara/elpais.png">
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-12 col-md-6 align-items-center">
                <div class="bloq-hablan">
                    <p class="text-justify font-weight-normal text-muted">
                        "Disponen de un sistema de autodiagnóstico, Aitana,
                        con inteligencia artificial que les permite identificar en
                        el 90% de los casos problemas del dispositivo tan
                        solo con la descripción de unos pocos síntomas",
                    </p>
                </div>
            </div>
            <div class="col-8 col-md-6 d-flex align-items-center justify-content-center">
                <div class="row py-2 d-flex justify-content-center">
                    <div class="col-12 col-md-10 col-lg-8">
                        <img class="img-fluid img-hablan" src="../../../assets/img/repara/emprendedores.png">
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
    
<div class="mt-3">
    <img class="img-fluid" src="../../../assets/img/repara/banner-500k.png">
</div>