<div class="mt-4 mb-4">
    <h3>ÍNDICE</h3>
    <p>1. Uso de cookies por las webs de Éxxita Be Circular, S.A. <br>
        2. Tipos, finalidad y funcionamiento de las cookies utilizadas en las
        webs de Éxxita Be Circular, S.A.<br>
        3. Cómo deshabilitar las cookies en los principales navegadores<br>
        4. Qué ocurre si se deshabilitan las cookies<br>
        5. Cambios en la política de cookies
    </p>
    <h1 class="text-green mt-4">Uso de cookies por las webs de Éxxita Be Circular, S.A.</h1>
    <p>
        La web de Flex, propiedad de Éxxita Be Circular, S.A., utiliza cookies. Las cookies son
        ficheros enviados a un navegador por medio de un servidor web para registrar las actividades del Usuario en una
        web o servicios de las webs de Éxxita Be Circular, S.A. (en adelante, los servicios). Las cookies se
        usan para facilitar un acceso más rápido a los servicios seleccionados, y personalizar los servicios que ofrecen
        las webs de Éxxita Be Circular, S.A., en base a elecciones y preferencias habituales del usuario, al
        hacer uso de los servicios.

        Las cookies se asocian a un usuario anónimo y su ordenador y no recaban datos de carácter personal más allá de
        la IP. El usuario puede configurar su navegador para no permitir la instalación de cookies utilizadas por las
        webs de Éxxita Be Circular, S.A., sin que el rechazo de las mismas impida al usuario acceder los
        servicios, si bien, no permitir la instalación de cookies, puede hacer bajar la rapidez y calidad de
        funcionamiento de la página Web.

        A través de las cookies que se detallan a continuación llevamos a cabo tratamientos de datos personales.
    </p>

    <hr>

    <h1 class="text-green">Tipos, finalidad y funcionamiento de las cookies
        utilizadas en las webs de Éxxita Be Circular, S.A.</h1>
    <p>Clasificación de las cookies en atención a su duración:</p>
    <br>
    <p class="text-muted">● </p>
    <p>“Cookies de sesión” o “Cookies persistentes”: las primeras
        son eliminadas al cerrar el navegador, mientras que las
        segundas permanecen en el equipo informático.
    </p>
    <br>
    <p class="text-muted">● </p>
    <p>“Cookies de primera parte” o “Cookies de tercera parte”: en
        función de si son servidas por el propio dominio web, o a un
        tercero.
    </p>
    <br>
    <br>
    <p>Clasificación de las cookies en atención a su finalidad:</p>
    <br>
    <p class="text-muted">● </p>
    <p>User input cookies o cookies de session: Se emplean para
        realizar inicios de sesión, carros de la compra etc.
    </p>
    <br>
    <p class="text-muted">● </p>
    <p>Cookies de autenticación: Identifican al usuario una vez que
        realiza log-in en el sistema.
    </p>
    <br>
    <p class="text-muted">● </p>
    <p>User centric security cookies: Cookies dirigidas a
        incrementar la seguridad de un servicio solicitado por el
        usuario, como controlar la seguridad al intentar acceder
        repetidamente a un sistema de registro.
    </p>
    <br>
    <p class="text-muted">● </p>
    <p>Multimedia Player session cookies (Flash Cookies): se
        utilizan para almacenar datos técnicos necesarios para
        reproducir vídeo o audio (como podría ser la calidad de la
        imagen, o la velocidad de red).
    </p>
    <br>
    <p class="text-muted">● </p>
    <p>Load balancing session cookies: estas cookies solo
        almacenarán información sobre puntos de conexión (cookie
        de carácter técnico).
    </p>
    <br>
    <p class="text-muted">● </p>
    <p>UI customization cookies: suelen emplearse para
        personalizar la apariencia de la web (lenguaje, etc).
    </p>
    <br>
    <p class="text-muted">● </p>
    <p>Social plug-in content sharing cookies: Las redes sociales
        suelen disponer de “módulos plug-in” que los titulares de
        websites pueden integrar en su plataforma para que los
        usuarios de redes sociales compartan contenidos con sus
        contactos. Estos plug-ins suelen implicar la instalación de
        cookies en los equipos de los usuarios.
    </p>
    <br>
    <p class="text-muted">● </p>
    <p>Third party advertising: Cookies empleados para redes de
        anuncios de terceros.
    </p>
    <br>
    <p class="text-muted">● </p>
    <p>Cookies analíticas: generan estadísticas sobre flujo de
        visitantes, patrones de navegación, etc.
    </p>
    <br>
    <br>
    <p>En este sentido en la presente web, actualmente se utilizan:</p>
    <br>
    <p class="text-muted">● </p>
    <p>Cookies propias:
    </p>
    <table>
        <thead>
            <tr>
                <th>Nombre de la cookie</th>
                <th>Categoría</th>
                <th>Duracción</th>
                <th>Descripción</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>
                    cc_cookie
                </td>
                <td>
                    Preferencias cookies
                </td>
                <td>
                    6 meses
                </td>
                <td>
                    Preferencias sobre el uso de cookies en el sitio web. Si no guardaramos está cookie cada vez que se accede al sitio apareceria el banner sobre las cookies.
                </td>
            </tr>
        </tbody>
    </table>
    <br>
    <p class="text-muted">● </p>
    <p>Cookies de terceros:
    </p>
    <table>
        <thead>
            <tr>
                <th>Nombre de la cookie</th>
                <th>Categoría</th>
                <th>Duracción</th>
                <th>Descripción</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>
                    _ga
                </td>
                <td>
                    Analítica web
                </td>
                <td>
                    2 años
                </td>
                <td>
                    Esta web utiliza Google Analytics para recopilar información anónima tal como el número de visitantes del sitio, 
                    o las páginas más populares.
                </td>
            </tr>
            <tr>
                <td>
                    _ga_5JZMN34LJY
                </td>
                <td>
                    Analítica web
                </td>
                <td>
                    2 años
                </td>
                <td>
                    Esta web utiliza Google Analytics para recopilar información anónima tal como el número de visitantes del sitio, 
                    o las páginas más populares.
                </td>
            </tr>
        </tbody>
    </table>
    <br>

    <hr>

    <h1 class="text-green">Cómo deshabilitar las cookies en los principales
        navegadores</h1>
    <p>Pueden bloquearse o deshabilitarse con las herramientas de
        configuración de su navegador. El navegador le permite rechazar la
        instalación de todas las cookies, o bien elegir rechazar solo alguna
        de ellas.
        <br>
        Le indicamos los enlaces a las herramientas de los principales
        navegadores, en las que poder informarse sobre cómo deshabilitar
        las cookies.
    </p>
    <br>
    <p class="text-muted">● </p>
    <a class="text-green" style="text-decoration: none;" target="_blank"
        href="https://support.google.com/chrome/answer/95647?hl=es&hlrm=en">Google Chrome</a>
    <br>
    <p class="text-muted">● </p>
    <a class="text-green" style="text-decoration: none;" target="_blank"
        href="https://support.mozilla.org/es/kb/cookies-informacion-que-los-sitios-web-guardan-en-?redirectlocale=en-US&redirectslug=Cookies">Mozilla
        Firefox</a>
    <br>
    <p class="text-muted">● </p>
    <a class="text-green" style="text-decoration: none;" target="_blank"
        href="https://support.apple.com/es-es/HT2012650">Apple Safari</a>
    <br>
    <p class="text-muted">● </p>
    <a class="text-green" style="text-decoration: none;" target="_blank"
        href="https://support.microsoft.com/es-es/windows/eliminar-y-administrar-cookies-168dab11-0753-043d-7c16-ede5947fc64d">Internet
        Explorer</a>
    <br>

    <hr>

    <h1 class="text-green">Qué ocurre si se deshabilitan las cookies</h1>
    <p>Si rechaza las cookies podrá seguir usando nuestro sitio web, pero
        es posible que algunos de los servicios y/o enlaces dejen de
        funcionar, y no podremos obtener de usted información muy valiosa
        para ofrecerle contenidos acordes a sus intereses y para que Ud.
        pueda acceder más fácil y rápidamente a la información de su
        interés.
    </p>

    <hr>

    <h1 class="text-green">Cambios en la política de cookies</h1>
    <p>Las webs de Éxxita Be Circular, S.A. pueden modificar esta política de
        cookies, bien por incorporar cookies nuevas por necesidad de
        nuevos contenidos que se puedan ofrecer, o bien en función de
        exigencias legislativas, reglamentarias, que hagan necesario el
        cambio de la misma, por ello se aconseja a los Usuarios que la
        visiten antes de comenzar la navegación por la web, y cada que la
        visiten de nuevo.
    </p>

</div>