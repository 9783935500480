import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class Garantia10Service {

  constructor(private http: HttpClient,
		private authSvc: AuthService,) { }

    crearSolicitudes(solicitudes: Array<any>): Observable<any> {
      return new Observable<any>(observer => {
        const accessToken = this.authSvc.getAccessTokenGarantia10();
  
        const options = {
          headers: new HttpHeaders({
            'Authentication': 'Bearer ' + accessToken,
            'Content-Type': 'application/json; charset=utf-8'
          })
        }
  
        let data = {solicitudes: solicitudes};
        
        const url = environment.API_URL + '/garantia10/solicitudes';
        this.http.post(url, data, options).subscribe(
          (response: any) => {
            console.log("ws crearSolicitudes", response);
            if (response.error) {
              observer.error(response.error);
            }
            else {
              observer.next(response);
            }
          },
          (err) => {
            console.log("error ws crearSolicitudes", err);
            console.log(data)
            let msgError = "Se ha producido un error guardando los datos";
  
            observer.error(msgError);
          }
        );
  
        return { unsubscribe() { } };
      });
    }

    calcularCosteSolicitudes(solicitudes: Array<any>): Observable<any> {
      return new Observable<any>(observer => {
        const accessToken = this.authSvc.getAccessTokenGarantia10();
  
        const options = {
          headers: new HttpHeaders({
            'Authentication': 'Bearer ' + accessToken,
            'Content-Type': 'application/json; charset=utf-8'
          })
        }
  
        let data = {solicitudes: solicitudes};
        
        const url = environment.API_URL + '/garantia10/calcularCoste';
        this.http.post(url, data, options).subscribe(
          (response: any) => {
            console.log("ws calcularCosteSolicitudes", response);
            if (response.error) {
              observer.error(response.error);
            }
            else {
              observer.next(response);
            }
          },
          (err) => {
            console.log("error ws calcularCosteSolicitudes", err);
            console.log(data)
            let msgError = "Se ha producido un error calculando el coste";
  
            observer.error(msgError);
          }
        );
  
        return { unsubscribe() { } };
      });
    }
}
