import { Component, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { Product } from 'src/app/models/product';
import { AuxiliarService } from 'src/app/services/auxiliar.service';
import { ProductService } from 'src/app/services/product.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  products: Product[] = [];
  meses: number[] = []
  precios: number[] = []
  mesesPrecio = new Array()
  monthSelected: number | undefined;
  movimientos: any;
  rutaImagen: string = environment.API_URL+'/';

  emailRegistrado: string = ''

  screenHeight: number | undefined;
  screenWidth: number | undefined;

  email: string = ''

  emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  messageError = "";

  modalSuscripcion: boolean | undefined;


  constructor(
    private productService: ProductService,
    private auxiliarSvc: AuxiliarService,
    private modalService: NgbModal,
  ) {
    this.onResize()
  }
  

  ngOnInit(): void {
    //this.cargarProductos();
    this.loadMasAlquilados();
    /*if(localStorage.getItem('modalSuscripcion') == null || localStorage.getItem('modalSuscripcion') == 'true'){
      this.modalSuscripcion = true
    }else{
      this.modalSuscripcion = false;
    }*/

  }
  

  @HostListener('window:resize', ['$event'])
  onResize(event?: undefined) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  /*async mandar(): Promise<any> {
    await this.verMovimientosAlmacen()
  }

  loadProducts(): void {
    this.products = this.productService.getProducts();
  }


  cargarProductos() {
    return new Promise(response => {
      setTimeout(() => {
        console.log('Promesita')
        this.mandar()
        response(true)
      }, 500)
    });
  }



  async verMovimientosAlmacen(): Promise<void> {

    let idArticulos: number[] = []
    for (let i = 0; i < this.productService.movimientos.length; i++) {
      if (!idArticulos[this.productService.movimientos[i]['id_inventario']]) {
        idArticulos.push(this.productService.movimientos[i]['id_inventario'])
      }
    }
    let map = this.productService.bucleStocks()
    console.log(map)
    for (let i = 0; i < idArticulos.length; i++) {
      let informacionProducto = []
      this.getAlquiler(idArticulos[i])
      this.productService.getProductoId(idArticulos[i]).subscribe(
        (data: any) => {
          informacionProducto = data
          let stock = map.get(idArticulos[i])
          if (stock != undefined) {
            let imagenes = []
            for (let z = 0; z < informacionProducto['imagenes'].length; z++) {
              imagenes.push(environment.API_URL + '/' +  informacionProducto['imagenes'][z]['url'])
              console.log(imagenes)
            }
            console.log(imagenes)
            console.log(this.meses)
            let producto = new Product(idArticulos[i], informacionProducto['nombre'], informacionProducto['descripcion'], this.precios[0], imagenes, informacionProducto['tipo']['nombre'], 5, this.meses, stock[0], informacionProducto['marca']['nombre'])
            let centinela = false
            for (let j = 0; j < this.productService.products.length; j++) {
              if (this.productService.products[j].id == producto.id) {
                centinela = true
              }
            }

            if (centinela != true) {
              this.productService.products.push(producto)
              if(this.products.length < 4){
              this.products = this.productService.products
              }
            }
          }
        },
        (err) => {

          console.log('Error -> ' + err)
        }
      );

    }

    setTimeout(() => {
      console.log('PRODUCTOS DIEGO')
      console.log(this.products);
    }, 1000)

  }*/

  loadMasAlquilados(){
    //Pasamos 1 ya que aquí se muestran solo los más alquilados
    this.productService.getMasAlquilados(1).subscribe(
			(data: any) => {
        this.movimientos = data;
			},
			(err) => {
				console.log('Error: '+err)
			}
		);
  }

  /*getAlquiler(productId: number) {
    this.productService.getAlquiler(productId).subscribe(
      (data) => {
        if (data.length != 0) {

          this.precios = []
          this.meses = []
          for (let i = 0; i < data.length; i++) {
            this.meses.push(data[i].meses);
            this.precios.push(data[i].precio);
          }
          var sortedArray: number[] = this.meses.sort((n1, n2) => n1 - n2);
          var sortedArray2: number[] = this.precios.sort((n1, n2) => n1 - n2);
          this.monthSelected = data[0].meses
          this.meses = sortedArray;
          this.precios = sortedArray2;
          this.mesesPrecio = data;

        } else {
          console.log('No hay info acerca de este dispositivo')
        }
      },
      (err) => {
        console.log('Error -> ' + err)
      }
    );
  }*/

  crearSub() {
    let email = (<HTMLInputElement>document.getElementById('email')).value
    if (this.emailRegex.test(email)) {
      this.messageError = '';
      this.auxiliarSvc.getSuscripcion(email).subscribe(
        (data) => {
          if (data != null) {
            this.emailRegistrado = email + ' ya ha sido registrado con anterioridad'
          } else {
            this.auxiliarSvc.crearSuscripcion(email).subscribe(
              (data) => {
                this.emailRegistrado = email + ' ha sido registrado con éxito en nuestra base de datos'
              },
              (err) => {
                console.log(err)
              }
            );
          }
        },
        (err) => {
          console.log(err)
        }
      );
      (<HTMLInputElement>document.getElementById('email')).value = ''

    } else {
      this.messageError = 'Introduzca un correo electrónico correcto';
    }


  }



}