<div class="container-lg mt-4 mb-4 d-flex justify-content-center">
    <h3 style="color: #808080;">Cesta de la compra
        <i class="fa fa-angle-right" aria-hidden="true"></i>
        Checkout
        <i class="fa fa-angle-right" aria-hidden="true"></i>
    </h3>
    <h3 style="color: green; margin-right: 10px; margin-left: 10px;">
        Completar alquiler
    </h3>
</div>

<div *ngIf="payment_succed=='loading'" class="container container-lg mt-4 mb-4 d-flex justify-content-center">
    <div class="row mt-4 mb-4">
        <div style="display: inline-flex;">
            <h3 style="margin-left: 10px;">Confirmando pedido..</h3>
        </div>

    </div>
</div>

<div *ngIf="payment_succed=='error'" class="container container-lg border border-error mt-4 mb-4 d-flex justify-content-center">
    <div class="row mt-4 mb-4">
        <div style="display: inline-flex;">
            <h3 style="color: red;"><i class="fas fa-error"></i></h3>
            <h3 style="margin-left: 10px;">Hubo algún problema al procesar el pedido. Por favor, inténtelo de nuevo.</h3>
        </div>

    </div>
</div>

<div *ngIf="payment_succed=='success' && screenWidth != undefined && screenWidth > 770">


    <div class="container container-lg border border-success mt-4 mb-4 d-flex justify-content-center">
        <div class="row mt-4 mb-4">
            <div style="display: inline-flex;">
                <h3 style="color: green;"><i class="fas fa-check"></i></h3>
                <h3 style="margin-left: 10px;">Gracias. Tu pedido ha sido recibido</h3>
            </div>

        </div>
    </div>

    <div class="row mt-4 mb-4 d-flex justify-content-center">
        <div class="col-2">
            <h6 class="text-muted text-center">Número del pedido</h6>
            <h6 class="text-center" style="font-weight: bold;">{{idTicket}}</h6>
        </div>

        <div class="col-2">
            <h6 class="text-muted text-center">Estado</h6>
            <h6 class="text-center" style="color: green; font-weight: bold;">PROCESANDO</h6>
        </div>

        <div class="col-3">
            <h6 class="text-muted text-center">Fecha</h6>
            <h6 class="text-center" style="font-weight: bold;">{{fecha.toLocaleDateString()}}</h6>
        </div>

        <div class="col-2">
            <h6 class="text-muted text-center">Total</h6>
            <h6 class="text-center" style="font-weight: bold;">{{total}} €</h6>
        </div>

        <div class="col-2">
            <h6 class="text-muted text-center">Método de pago</h6>
            <h6 class="text-center" style="font-weight: bold;">Tarjeta</h6>
        </div>

    </div>

    <div class="col-12">
        <div class="container container-lg border border-secondary mt-4 mb-4">


            <div class="row mt-4 mb-4">
                <h4 style="margin-left: 15px; font-weight: bolder;"> SU PEDIDO </h4>
            </div>

            <div class="row mt-4">
                <h5 style="margin-left: 30px; font-weight: bolder;">Producto</h5>
            </div>

            <hr>

            <div *ngFor="let item of auxCarrito">

                <div class="row mt-4 mb-4">
                    <div class="col-2" style="margin-left: 30px;">
                        <img src="{{item.imgUrl}}" class="img-fluid" style="border: 1px solid; color: rgb(0, 198, 0);">
                    </div>
                    <div class="col-7">
                        <p>{{item.productName}}</p>
                    </div>
                    <div class="col-2">
                        <p class="text-muted">{{redondear(item.productPrice * item.quantity)}} € / mes</p>
                    </div>

                </div>
            </div>

            <div class="row mt-4 mb-2">
                <div class="col-2 mt-4">
                    <h5 style="margin-left: 30px; font-weight: bolder;">Subtotal</h5>
                </div>
                <div class="col-7">

                </div>

                <div class="col-2 mt-4">
                    <h5 style="color: gray;">{{total}} € / mes</h5>
                </div>
            </div>

            <hr>

            <div class="row mt-4 mb-4">
                <div class="col-3 mt-4">
                    <h5 style="margin-left: 30px;font-weight: bolder;">Método de pago:</h5>
                </div>

                <div class="col-7"></div>

                <div class="col-2 mt-4">
                    <h5 style="color: gray;">Tarjeta </h5>
                </div>
            </div>

            <hr>

            <div class="row mt-4 mb-4">
                <div class="col-2">
                    <h5 style="margin-left: 30px; margin-top: 15px; font-weight: bolder;">Total: </h5>
                </div>

                <div class="col-6"></div>

                <div class="col-3">
                    <h3 style="font-weight: bold; margin-left: 30px;">{{total}} € / mes </h3>
                </div>
            </div>

        </div>
    </div>


    <div class="col-4 mt-4 mb-4">
        <!--
        <h4 style="font-weight: bold; font-weight: 30px;">Dirección de facturación</h4>
            <p class="text-muted">
                Juan Antonio Coronila <br>
                Avd. Sanchez Pizjuan Nº9 <br>
                10-2 <br>
                41009 Sevilla <br>
                Sevilla <br>
                España <br>
                653410444 <br>
                <br>
                themayanfactor1972@gmail.com
            </p>
        -->
        <button class="btn btn-dark" routerLink="/orders"><i class="fas fa-arrow-left"></i> VOLVER A LISTA</button>
    </div>
</div>


<div *ngIf="payment_succed=='success' && screenWidth != undefined && screenWidth <= 770">


    <div class="container container-lg border border-success mt-4 mb-4 d-flex justify-content-center">
        <div class="row mt-4 mb-4">
            <div style="display: inline-flex;">
                <h3 style="color: green;"><i class="fas fa-check"></i></h3>
                <h3 style="margin-left: 10px;">Gracias. Tu pedido ha sido recibido</h3>
            </div>

        </div>
    </div>

    <div class="col mt-4 mb-4">
        <div class="row">
            <h6 class="text-muted text-center">Número del pedido</h6>
            <h6 class="text-center" style="font-weight: bold;">{{idTicket}}</h6>
        </div>

        <div class="row">
            <h6 class="text-muted text-center">Estado</h6>
            <h6 class="text-center" style="color: green; font-weight: bold;">PROCESANDO</h6>
        </div>

        <div class="row">
            <h6 class="text-muted text-center">Fecha</h6>
            <h6 class="text-center" style="font-weight: bold;">{{fecha.toLocaleDateString()}}</h6>
        </div>

        <div class="row">
            <h6 class="text-muted text-center">Total</h6>
            <h6 class="text-center" style="font-weight: bold;">{{total}} €</h6>
        </div>

        <div class="row">
            <h6 class="text-muted text-center">Método de pago</h6>
            <h6 class="text-center" style="font-weight: bold;">Tarjeta</h6>
        </div>

    </div>

    <div class="col-12">
        <div class="container container-lg border border-secondary mt-4 mb-4">


            <div class="row mt-4 mb-4">
                <h3 class="text-muted" style="margin-left: 15px; font-weight: bolder;"> SU PEDIDO </h3>
            </div>

            <div class="row mt-4">
                <h5 style="margin-left: 30px; font-weight: bolder;">Productos</h5>
            </div>

            <hr>

            <div *ngFor="let item of auxCarrito">

                <div class="row mt-4 mb-4">
                    <div class="col-4" style="margin-left: 30px;">
                        <img src="{{item.imgUrl}}" class="img-fluid" style="border: 1px solid; color: rgb(0, 198, 0);">
                    </div>
                    <div class="col">
                        <p>{{item.productName}}</p>
                        <p class="text-muted">{{redondear(item.productPrice * item.quantity)}} € / mes</p>
                    </div>

                </div>
            </div>

            <div class="row mt-4 mb-2">
                <div class="col-5 mt-4">
                    <h5 style="margin-left: 30px; font-weight: bolder;">Subtotal</h5>
                </div>
            </div>
            <div class="row">
                <div class="col-5"></div>
                <div class="col-7 mt-4">
                    <h5 style="color: gray;">{{total}} € / mes</h5>
                </div>
            </div>

            <hr>

            <div class="row mt-4 mb-2">
                <div class="col-5 mt-4">
                    <h5 style="margin-left: 30px;font-weight: bolder;">Método de pago:</h5>
                </div>
            </div>
            <div class="row">
                <div class="col-7"></div>
                <div class="col-5 mt-4">
                    <h5 style="color: gray;">Tarjeta </h5>
                </div>
            </div>

            <hr>

            <div class="row mb-2">
                <div class="col-5 mt-4">
                    <h5 style="margin-left: 30px; margin-top: 15px; font-weight: bolder;">Total: </h5>
                </div>
            </div>
            <div class="row">
                <div class="col-5"></div>
                <div class="col-7 mb-4">
                    <h3 style="font-weight: bold; margin-left: 30px;">{{total}} € / mes </h3>
                </div>
            </div>

        </div>
    </div>

    
    <div class="row">
        <div class="col-4"></div>
        <div class="col-4 mt-4 mb-4 text-center">
            <!--
            <h3 style="font-weight: bold; font-weight: 30px;">Dirección de facturación</h3>
            <p class="text-muted">
                Juan Antonio Coronila <br>
                Avd. Sanchez Pizjuan Nº9 <br>
                10-2 <br>
                41009 Sevilla <br>
                Sevilla <br>
                España <br>
                653410444 <br>
                <br>
                themayanfactor1972@gmail.com
            </p>
        -->
            <button class="btn btn-dark mt-2 mb-4" routerLink="/orders"><i class="fas fa-arrow-left"></i> VOLVER A
                LISTA</button>
        </div>
        <div class="col-4"></div>

    </div>
</div>