import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ayuda-embalar',
  templateUrl: './ayuda-embalar.component.html',
  styleUrls: ['./ayuda-embalar.component.scss']
})
export class AyudaEmbalarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
