import { Injectable } from '@angular/core';
import { CartItem } from '../models/cart-item';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Product } from '../models/product';
import { ObservablesService } from 'src/app/services/observables.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(
		private http: HttpClient,
    private observables: ObservablesService 
    ) { }

  cartExists(): boolean {
    return localStorage.getItem('cart') != null;
  }

  setCart(cart: CartItem[]): void {
    localStorage.setItem('cart', JSON.stringify(cart));

    var carritoJson = JSON.parse(localStorage.getItem('cart')! ? localStorage.getItem('cart')!: '');
    this.observables.updateTotal(carritoJson.length);
  }


  getCart(): CartItem[] {
    let cart = localStorage.getItem('cart');
    return JSON.parse(cart ? cart: '');
  }

  clear(): void {
    localStorage.setItem('cart', "[]");
    this.observables.updateTotal(0);
  }

}
