import { HttpClient } from '@angular/common/http';
import { newArray } from '@angular/compiler/src/util';
import { AfterContentInit, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ICreateOrderRequest, IPayPalConfig, ITransactionItem } from 'ngx-paypal';
import { NgxSpinnerService } from 'ngx-spinner';
import { StripeService } from 'ngx-stripe';
import {
  loadStripe
} from '@stripe/stripe-js';
import { switchMap } from 'rxjs/operators';
import { CartItem } from 'src/app/models/cart-item';
import { Product } from 'src/app/models/product';
import { AuxiliarService } from 'src/app/services/auxiliar.service';
import { MessageService } from 'src/app/services/message.service';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth/auth.service';
import * as Notiflix from 'notiflix';

@Component({
  selector: 'app-completed',
  templateUrl: './completed.component.html',
  styleUrls: ['./completed.component.scss']
})
export class CompletedComponent implements OnInit, OnDestroy {

  fecha = new Date();
  cartItems: Array<CartItem> = [];
  total:number = 0;

  screenHeight: number | undefined;
  screenWidth: number | undefined;

  idTicket: number | undefined;

  idCliente = localStorage.getItem('idCliente');
  cliente: any = null;
  
  ticket_create: any = null;
  payment_succed: string = 'loading';
  auxCarrito: Array<CartItem> = [];

  public payPalConfig?: IPayPalConfig;

  constructor(
    private messageService: MessageService,
    private storageService: StorageService,
    private auxiliarService: AuxiliarService,
    private spinner: NgxSpinnerService,
    private stripeService: StripeService,
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private router: Router,
    private authSvc: AuthService,

  ) {
    this.onResize();
  }

  ngOnInit(): void {
    Notiflix.Loading.pulse('Confirmando pedido...',{
			svgColor: '#63b421',
		});
    this.inicializa();

    if (this.storageService.cartExists()) {
      this.cartItems = this.storageService.getCart();
      this.auxCarrito = this.cartItems;
    }
    
    this.getItem();
    this.getTotal();
    
    let nombresAlquilados = []
    let precio = 0;
    for (let i = 0; i < this.auxCarrito.length; i++) {
      precio += this.auxCarrito[i].productPrice;
      nombresAlquilados.push(this.auxCarrito[i].productName, this.auxCarrito[i].month + ' meses', this.auxCarrito[i].productPrice + '€');
    }

    this.ticket_create = {
      'FK_entities': environment.fk_entity,
      'status': 'new',
      'name': 'exxitaFlexT ' + this.idCliente,
      'clienteSolicitante': this.idCliente,
      'contents': 'El usuario ' + this.idCliente + ' alquiló los siguientes productos: ' + nombresAlquilados,
      'Importe': precio,
      'date': new Date()
    }
  }

  async inicializa() {
    if (this.idCliente && this.idCliente != "") {
      await this.authSvc.getMe().subscribe(
        (data: any) => {
          this.cliente = data;

          this.checkStripePayment();
        },
        (err) => {
          console.log('Error -> ' + err)
        }
      );
    }
  }


  @HostListener('window:resize', ['$event'])
  onResize(event?: undefined) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  ngOnDestroy(): void {
    this.emptyCart()
  }

  crearTicket(ticket: any): void {
    this.auxiliarService.deletePaymentIntent(this.idCliente).subscribe(
      (data: any) => {
        console.log('payment intent removed correctly');
      },
      (err) => {
        console.log('Error -> ' + err)
      }
    );

    this.auxiliarService.crearTicket(ticket).subscribe(
      (data: any) => {
        this.idTicket = data.id;

        this.auxiliarService.getClienteDireccionEnvio(this.idCliente?parseInt(this.idCliente):0).subscribe(
          (data2: any) => {
            let direccionTicketCliente = {
              "id_rel_tracking_cliente": 0,
              "id_tracking": data.id,
              "id_cliente_solicitante": this.idCliente,
              "tipo_direccion": 2,
              "deleted": 0,
              "id_pais": 1,
              "nombre": data2.nombre,
              "apellido": data2.apellido,
              "id_provincia": data2.provincia?.idprovincia,
              "id_localidad": data2.localidad?.idpoblacion,
              "direccion": data2.direccion,
              "direccion_email": data2.direccion_email,
              "num_telefono": data2.num_telefono,
              "otros_datos": data2.otros_datos
            }
    
            this.auxiliarService.crearDireccionTicketCliente(direccionTicketCliente).subscribe(
              (data: any) => {
              },
              (err) => {
                console.log('Error -> ' + err)
              }
            );
          },
          (err) => {
            console.log('Error -> ' + err)
          }
        );
      },
      (err) => {
        console.log('Error -> ' + err)
      }
    );
  }

  async checkStripePayment() {
    if(this.cliente){
      const payment_intent = this.cliente.stripe_session_payment_intent;

      if(payment_intent && payment_intent != ""){
        await this.stripeService.retrievePaymentIntent(payment_intent).toPromise().then(({ paymentIntent }) => {
          const message = document.querySelector('#message')
  
          switch (paymentIntent?.status) {
            case 'succeeded':
                this.payment_succed = 'success';
                Notiflix.Loading.remove();
                var items = this.mapItems();

                if(this.auxCarrito.length > 0){
                  this.crearTicket(this.ticket_create);
                }
                
                this.emptyCart();

                
              break;
  
            /*case 'processing':
              //message.innerText = "Payment processing. We'll update you when payment is received.";
              break;
  
            case 'requires_payment_method':
              //message.innerText = 'Payment failed. Please try another payment method.';
              // Redirect your user back to your payment page to attempt collecting
              // payment again
              break;*/
  
            default:
              Notiflix.Loading.remove();
              this.payment_succed = 'error';
              //message.innerText = 'Something went wrong.';
              break;
          }
        });
      }else{
        Notiflix.Loading.remove();
      }
    }

    /*var check = this.messageService.getSessionAproval();
    if (check) {
      var items = this.mapItems();
      this.emptyCart();
    }*/
  }

  mapItems() {
    var res: { name: string; quantity: number; unit_amount: { value: number; currency: string; }; }[] = [];
    this.auxCarrito.forEach((cartItem: CartItem) => {
      let item = {
        name: cartItem.productName,
        quantity: cartItem.quantity,
        unit_amount: {
          value: cartItem.productPrice,
          currency: 'EUR'
        }
      };
      res.push(item);
    });
    return res;
  }


  getItemListStripe(): any[] {
    const items: any[] = [];
    let item = {};
    this.auxCarrito.forEach((it: CartItem) => {
      let amount = +it.productPrice * 100;
      item = {
        price_data: {
          currency: 'eur',
          product_data: {
            name: it.productName,
          },
          unit_amount: parseInt(amount.toString()),
        },
        quantity: it.quantity,
      };
      items.push(item);
    });
    return items;
  }


  getItem(): void {
    this.messageService.getMessage().subscribe((product: Product) => {
      let exists = false;
      this.auxCarrito.forEach(item => {
        if (item.productId === product.id) {
          exists = true;
          item.quantity++;
        }
      });
      if (!exists) {
        const cartItem = new CartItem(product);
        this.auxCarrito.push(cartItem);
      }
      this.getTotal();
      this.storageService.setCart(this.auxCarrito);
    });
  }

  getItemListPaypal(): any[] {
    const items: any[] = [];
    let item = {};
    this.auxCarrito.forEach((it: CartItem) => {
      item = {
        name: it.productName,
        quantity: it.quantity,
        unit_amount: { value: it.productPrice, currency_code: 'EUR' }
      };
      items.push(item);
    });
    return items;
  }

  getTotal() {
    let total = 0;
    this.auxCarrito.forEach(item => {
      total += item.quantity * item.productPrice;
    });

    this.total += parseFloat(total.toFixed(2));
  }

  emptyCart(): void {
    this.cartItems = [];
    //this.total = 0;
    this.storageService.clear();
  }


  redondear(num: number) {
    let res = Math.round(num * 100) / 100
    return res
  }

}

