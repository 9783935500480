import { Component, HostListener, Input, OnInit } from '@angular/core';
import * as Notiflix from 'notiflix';
import { AuthService } from 'src/app/services/auth/auth.service';
import { AuxiliarService } from 'src/app/services/auxiliar.service';

@Component({
  selector: 'app-sidebar-info-ticket',
  templateUrl: './sidebar-info-ticket.component.html',
  styleUrls: ['./sidebar-info-ticket.component.scss']
})
export class SidebarInfoTicketComponent implements OnInit {

  logueado = false;

  @Input()
  ticket: any;
  @Input()
  dir_recogida: any;
  @Input()
  dir_envio: any;

  screenHeight: number | undefined;
  screenWidth: number | undefined;

  constructor(
    private authSvc: AuthService,
    private auxSvc: AuxiliarService
    ) { 
      this.onResize()
    }

  ngOnInit(): void {
    this.logueado = this.authSvc.isUserLoggedIn()
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?: undefined) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  descargarAdjunto(id: number, idTicket: number){
    Notiflix.Loading.pulse('Descargando adjunto...',{
			svgColor: '#63b421',  
    });

    this.auxSvc.descargarAdjunto(id, idTicket).subscribe(
      (data: any) => {
        var a = document.createElement("a"); //Create <a>
        a.href = "data:"+data.mime+";base64," + data.data; //Image Base64 Goes here
        a.download = data.filename; //File name Here
        a.click(); //Downloaded file

        Notiflix.Loading.remove();
      },
      (err) => {
        console.log('Error -> ' + err)
      }
    );
  }

}
