import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './pages/home/home.component';
import { ProfileComponent } from './pages/adminProfile/profile/profile.component';
import { ShoppingCartComponent } from './pages/buyingProcess/shopping-cart/shopping-cart.component';
import { CheckoutComponent } from './pages/buyingProcess/checkout/checkout.component';
import { CompletedComponent } from './pages/buyingProcess/completed/completed.component';
import { OrdersComponent } from './pages/adminProfile/orders/orders.component';
import { AddressComponent } from './pages/adminProfile/address/address.component';
import { AccountDetailsComponent } from './pages/adminProfile/account-details/account-details.component';
import { HowDoesItWorkComponent } from './pages/terms/how-does-it-work/how-does-it-work.component';
import { ShopComponent } from './pages/shop/shop.component';
import { ProductDetailsComponent } from './pages/product-details/product-details.component';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { HttpClientModule } from '@angular/common/http';
import { NgxPayPalModule } from 'ngx-paypal';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RatingModule } from 'ng-starrating';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogSelectOptionsComponent } from './components/dialog-select-options/dialog-select-options.component';
import { RouterModule, Routes } from '@angular/router';
import { AlertModule } from './components/_alert';
import { LoginComponent } from './pages/users/login/login.component';
import { RegisterComponent } from './pages/users/register/register.component';
import { ProfileNavBarComponent } from './components/profile-nav-bar/profile-nav-bar.component';
import { RememberComponent } from './pages/users/remember/remember.component';
import { PaymentMethodsComponent } from './pages/payment-methods/payment-methods.component';
import { BuyGuideComponent } from './pages/buy-guide/buy-guide.component';
import { PrivacityComponent } from './pages/terms/privacity/privacity.component';
import { WarantyComponent } from './pages/terms/waranty/waranty.component';
import { ConditionsComponent } from './pages/terms/conditions/conditions.component';
import { LegalAdviceComponent } from './pages/terms/legal-advice/legal-advice.component';
import { CookiesComponent } from './pages/terms/cookies/cookies.component';
import { SecurityComponent } from './pages/terms/security/security.component';
import { SearchingComponent } from './pages/searching/searching.component';
import { Ng5SliderModule } from 'ng5-slider';
import { NgSelectModule } from '@ng-select/ng-select';
import { ModalComponent } from './components/modal/modal.component';
import { DialogSelectOptionsUpdateComponent } from './components/dialog-select-options-update/dialog-select-options-update.component';
import { ReparaComponent } from './pages/repara/repara.component';
import { CompraComponent } from './pages/compra/compra.component';
import { AyudaModeloNumSerieComponent } from './pages/repara/ayuda-modelo-num-serie/ayuda-modelo-num-serie.component';
import { AyudaEmbalarComponent } from './pages/repara/ayuda-embalar/ayuda-embalar.component';
import { NgxSelectModule } from 'ngx-select-ex';
import { BudgetsComponent } from './pages/adminProfile/repairs/budgets/budgets.component';
import { WishesComponent } from './pages/wishes/wishes.component';
import { MybudgetComponent } from './pages/adminProfile/repairs/budgets/mybudget/mybudget.component';
import { BudgetPayComponent } from './pages/adminProfile/repairs/budgets/budget-pay/budget-pay.component';
import { ReparaHomeComponent } from './pages/repara-home/repara-home.component';
import { Garantia10Component } from './pages/garantia10/garantia10.component';
import { BotAutodiagnosisComponent } from './components/bot-autodiagnosis/bot-autodiagnosis.component';
import { ModalInfoGarantia10Component } from './components/modal-info-garantia10/modal-info-garantia10.component';
import { RedirectGuard } from './guards/redirect.guard';



const appRoutes: Routes = [
  {
    path: 'products', 
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: 'https://exxitashop.com/'
    }
  },
  {
    path: 'products/search', 
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: 'https://exxitashop.com/'
    }
  },
  {
    path: 'products/category/:categoria', 
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: 'https://exxitashop.com/'
    }
  },
  {
    path: 'products/buy/category/:categoria', 
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: 'https://exxitashop.com/'
    }
  },
  {
    path: 'products/buy', 
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: 'https://exxitashop.com/'
    }
  },
  {
    path: 'products/:id', 
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: 'https://exxitashop.com/'
    }
  },

  {
    path: 'products/buy/:id', 
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: 'https://exxitashop.com/'
    }
  },
]

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    ProfileComponent,
    ShoppingCartComponent,
    CheckoutComponent,
    CompletedComponent,
    OrdersComponent,
    AddressComponent,
    AccountDetailsComponent,
    HowDoesItWorkComponent,
    ShopComponent,
    ProductDetailsComponent,
    DialogSelectOptionsComponent,
    LoginComponent,
    RegisterComponent,
    ProfileNavBarComponent,
    RememberComponent,
    PaymentMethodsComponent,
    BuyGuideComponent,
    PrivacityComponent,
    WarantyComponent,
    ConditionsComponent,
    LegalAdviceComponent,
    CookiesComponent,
    SecurityComponent,
    SearchingComponent,
    ModalComponent,
    DialogSelectOptionsUpdateComponent,
    ReparaComponent,
    CompraComponent,
    AyudaModeloNumSerieComponent,
    AyudaEmbalarComponent,
    BudgetsComponent,
    WishesComponent,
    MybudgetComponent,
    BudgetPayComponent,
    ReparaHomeComponent,
    Garantia10Component,
    BotAutodiagnosisComponent,
    ModalInfoGarantia10Component
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule,
    MatDialogModule,
    HttpClientModule,
    NgxPayPalModule,
    NgxSpinnerModule,
    HttpClientModule,
    MatSnackBarModule,
    RatingModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(appRoutes, {scrollPositionRestoration: 'enabled'}),
    AlertModule,
    Ng5SliderModule,
    NgSelectModule,
    // NgcCookieConsentModule.forRoot(cookieConfig),
    NgxSelectModule
  ],
  providers: [
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } },
    RedirectGuard
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
