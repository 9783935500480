import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Report } from 'notiflix/build/notiflix-report-aio';
import { BotAutodiagnosisComponent } from 'src/app/components/bot-autodiagnosis/bot-autodiagnosis.component';
import { ModalInfoGarantia10Component } from 'src/app/components/modal-info-garantia10/modal-info-garantia10.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { AuxiliarService } from 'src/app/services/auxiliar.service';
import { ReparaService } from 'src/app/services/repara/repara.service';

@Component({
  selector: 'app-repara',
  templateUrl: './repara.component.html',
  styleUrls: ['./repara.component.scss']
})
export class ReparaComponent implements OnInit {

	@ViewChild('tituloSolicitar')
	tituloSolicitar: ElementRef | undefined;

	deshabilitado: boolean = false;
	botAutoDiagnosisEnable: boolean = false;

	loading: boolean = false;

	tiposSolicitud: Array<any>;
	costesDispositivo: Array<any>;
	averiasPortatil: Array<any>;
	averiasTablet: Array<any>;
	averiasSmartphone: Array<any>;
	averias: Array<any>;
	marcaSeleted: string;
	currentStep: number;
	showDirEntrega: boolean = false;
	showDirFacturacion: boolean = false;
	showErrorPage: boolean = false;
	msgError: string;

	modelReparaSmartphone: any;
	modelRepara: any;
	requiredValidation: any;
	phoneValidation: any;
	numeroSolicitud: string;

	logueado: boolean = false;
	cliente: any;

	constructor(
		private authSvc: AuthService,
		private auxSvc: AuxiliarService,
		private router: Router,
		private route: ActivatedRoute,
		private modalService: NgbModal,
		private reparaSvc: ReparaService,
	) {
		this.currentStep = 1;
		this.marcaSeleted = "";
		this.numeroSolicitud = "";
		this.tiposSolicitud = [
			{ id: 'REPARACION PORTATIL', name: 'PORTÁTIL' },
			{ id: 'REPARA TU TABLET', name: 'TABLET' },
			{ id: 'REPARA TU SMARTPHONE', name: 'SMARTPHONE' },
		];
		this.costesDispositivo = [
			{ id: "<150 €", name:"<150 €"},
			{ id: "151 - 300 €", name:"151 - 300 €"},
			{ id: "310 - 500 €", name:"310 - 500 €"},
			{ id: "501 - 750 €", name:"501 - 750 €"},
			{ id: "751 - 1000 €", name:"751 - 1000 €"},
			{ id: "> 1000 €", name:"> 1000 €"},
		];
		this.averiasPortatil = [
			// { id: "Selecciona el motivo de la avería o incidencia", name: "Selecciona el motivo de la avería o incidencia", type: "REPARACION PORTATIL" },
			{ id: "La batería no carga completa o se agota demasiado rápido", name: "La batería no carga completa o se agota demasiado rápido", type: "REPARACION PORTATIL" },
			{ id: "Problemas en el sistema operativo o ruidos en el disco duro", name: "Problemas en el sistema operativo o ruidos en el disco duro", type: "REPARACION PORTATIL" },
			{ id: "Líneas en pantalla, rotura o mala visualización", name: "Líneas en pantalla, rotura o mala visualización", type: "REPARACION PORTATIL" },
			{ id: "Quiero aumentar la velocidad de mi equipo", name: "Quiero aumentar la velocidad de mi equipo", type: "REPARACION PORTATIL" },
			{ id: "Una de las piezas exteriores se encuentra dañada", name: "Una de las piezas exteriores se encuentra dañada", type: "REPARACION PORTATIL" },
			{ id: "Problemas de conectividad, no contecta al WIFI", name: "Problemas de conectividad, no contecta al WIFI", type: "REPARACION PORTATIL" },
			{ id: "Fallo de teclado o teclas inoperativas", name: "Fallo de teclado o teclas inoperativas", type: "REPARACION PORTATIL" },
			{ id: "El equipo no enciende", name: "El equipo no enciende", type: "REPARACION PORTATIL" },
			{ id: "Calentamiento excesivo del equipo, o ruido en el ventilador", name: "Calentamiento excesivo del equipo, o ruido en el ventilador", type: "REPARACION PORTATIL" },
		];
		this.averiasTablet = [
			{ id: "Líneas en pantalla, rotura o mala visualización", name: "Líneas en pantalla, rotura o mala visualización", type: "REPARA TU TABLET" },
			{ id: "La tablet no enciende", name: "La tablet no enciende", type: "REPARA TU TABLET" },
			{ id: "El cargador de la tablet no funciona", name: "El cargador de la tablet no funciona", type: "REPARA TU TABLET" },
			{ id: "Una de las piezas exteriores se encuentra dañada", name: "Una de las piezas exteriores se encuentra dañada", type: "REPARA TU TABLET" },
			{ id: "Problemas de conectividad, no conecta al WIFI", name: "Problemas de conectividad, no conecta al WIFI", type: "REPARA TU TABLET" },
		];
		this.averiasSmartphone = [
			{ id: "Líneas en pantalla, rotura o mala visualización", name: "Líneas en pantalla, rotura o mala visualización", type: "REPARA TU SMARTPHONE" },
			{ id: "El smartphone no enciende", name: "El smartphone no enciende", type: "REPARA TU SMARTPHONE" },
			{ id: "El cargador del smartphone no funciona", name: "El cargador del smartphone no funciona", type: "REPARA TU SMARTPHONE" },
			{ id: "Una de las piezas exteriores se encuentra dañada", name: "Una de las piezas exteriores se encuentra dañada", type: "REPARA TU SMARTPHONE" },
			{ id: "Problemas de conectividad, no conecta al WIFI", name: "Problemas de conectividad, no conecta al WIFI", type: "REPARA TU SMARTPHONE" },
		];
		this.averias = [];
		this.msgError = '';

		this.initData();

		this.route.queryParams.subscribe(
			(params) => {
				if (params.test !== undefined) {
					this.deshabilitado = false;
				}
				if (params.bot_autodiagnosis !== undefined) {
					this.botAutoDiagnosisEnable = true;
				}
			}
		);
	}

	ngOnInit(): void {
		this.route.paramMap.subscribe(
			(params) => {
				const tipoDisp = params.get('tipo');
				switch (tipoDisp) {
					case "portatil":
						this.selectDispositivo("REPARACION PORTATIL");
						// this.selectMarca('lenovo');
						// this.modelRepara.modelo = "TB-7304F";
						break;
					case "tablet":
						this.selectDispositivo("REPARA TU TABLET");
						break;
					case "smartphone":
						this.selectDispositivo("REPARA TU SMARTPHONE");
						break;
				
					default:
						this.selectDispositivo("REPARACION PORTATIL");
						break;
				}
			}
		);
		this.logueado = this.authSvc.isUserLoggedIn();
		console.log("logueado ngOnInit", this.logueado);
		if (this.logueado) {
			this.getCliente();
			this.getDireccionRecogida();
			this.getDireccionEnvio();
			this.getDireccionFacturacion();
		}
	}

	showBotAutodiagnosis() {
		let show = false;
		if (this.botAutoDiagnosisEnable && this.logueado && this.modelRepara.tipo_solicitud === 'REPARACION PORTATIL' && this.modelRepara.marca === 'lenovo' && this.modelRepara.modelo !== '') {
			show = true;
		}
		return show;
	}

	openBotAutodiagnosis() {
		let tipoElemento;
		switch (this.modelRepara.tipo_solicitud) {
			case 'REPARACION PORTATIL':
				tipoElemento = 'NB';
				break;
			case 'REPARA TU TABLET':
				tipoElemento = 'TABLET';
				break;
			case 'REPARA TU SMARTPHONE':
				tipoElemento = 'SMARTPHONE';
				break;
		
			default:
				break;
		}
		const modalRef = this.modalService.open(BotAutodiagnosisComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg', scrollable: true });
		modalRef.componentInstance.tipoElemento = tipoElemento;
		modalRef.componentInstance.marca = this.modelRepara.marca;
		modalRef.componentInstance.modelo = this.modelRepara.modelo;

		modalRef.componentInstance.autodiagnosisComplete.subscribe((resultAutodiagnosis: any) => {
			// console.log("resultado Autodiagnosis", resultAutodiagnosis);
			if (resultAutodiagnosis != undefined) {
				let sintomas = [];

				for (const itemSintoma of resultAutodiagnosis.sintomas) {
					sintomas.push({ id: itemSintoma.nombre, name: itemSintoma.nombre });
				}
				this.averias = sintomas.slice();
				const sintomasIds = this.averias.map((item) => { return item.id; });
				this.modelRepara.averia_sintomas = sintomasIds.slice();
			}
		});
	  }

	getCliente() {
		this.authSvc.getMe().subscribe(
			(data: any) => {
		  		this.cliente = data;
				this.cargaDatosCliente();
			},
			(err) => {
				console.log('Error -> ' + err)
			}
		);
	}

	getDireccionFacturacion() {
		this.auxSvc.getClienteDireccionTipo(Number(localStorage.getItem('idCliente')), 1).subscribe(
			(data: any) => {
				if(data){
					this.modelRepara.facturacion_direccion = data.direccion;
					this.modelRepara.facturacion_municipio = data.provincia.provincia;
					this.modelRepara.facturacion_persona_contacto = data.nombre;
					this.modelRepara.facturacion_telefono_contacto = data.num_telefono;
				}
			},
			(err) => {
				console.log('Error -> ' + err)
			}
		);
	}

	getDireccionEnvio() {
		this.auxSvc.getClienteDireccionTipo(Number(localStorage.getItem('idCliente')), 2).subscribe(
			(data: any) => {
				if(data){
					this.modelRepara.entrega_direccion = data.direccion;
					this.modelRepara.entrega_municipio = data.provincia.provincia;
					this.modelRepara.entrega_persona_contacto = data.nombre;
					this.modelRepara.entrega_telefono_contacto = data.num_telefono;
				}
			},
			(err) => {
				console.log('Error -> ' + err)
			}
		);
	}

	getDireccionRecogida() {
		this.auxSvc.getClienteDireccionTipo(Number(localStorage.getItem('idCliente')), 3).subscribe(
			(data: any) => {
				if(data){
					this.modelRepara.recogida_direccion = data.direccion;
					this.modelRepara.recogida_municipio = data.provincia.provincia;
					this.modelRepara.recogida_persona_contacto = data.nombre;
					this.modelRepara.recogida_telefono_contacto = data.num_telefono;
				}
			},
			(err) => {
				console.log('Error -> ' + err)
			}
		);
	}

	getDireccionContacto() {
		this.auxSvc.getClienteDireccionTipo(Number(localStorage.getItem('idCliente')), 3).subscribe(
			(data: any) => {
				if(data){
					this.modelRepara.contacto_nombre = data.nombre;
					this.modelRepara.contacto_apellidos = this.cliente.apellido;
					this.modelRepara.contacto_telefono = data.num_telefono;
					this.modelRepara.contacto_email = data.direccion_email;
					this.modelRepara.razon_social = this.cliente.nombre;
					this.modelRepara.cif_nif = this.cliente.cif_nif;
				}
			},
			(err) => {
				console.log('Error -> ' + err)
			}
		);
	}

	cargaDatosCliente() {
		this.modelRepara.contacto_nombre = this.cliente.nombre;
		this.modelRepara.contacto_apellidos = this.cliente.apellido;
		this.modelRepara.contacto_telefono = this.cliente.tlf_contacto;
		this.modelRepara.contacto_email = this.cliente.email_contacto;
		this.modelRepara.cif_nif = this.cliente.cif_nif;
	}

	initData() {
		this.modelReparaSmartphone = {
			nombre: '',
			apellidos: '',
			telefono: '',
			email: '',
			checkbox_envio_comunicaciones: false,
		};
		this.modelRepara = {
			tipo_solicitud: 'REPARACION PORTATIL',
			marca: '',
			marcaOtro: '',
			modelo: '',
			numero_serie: '',
			coste_dispositivo: null,
			garantia10: '',
			averia_sintomas: [],
			averia_incidencia_textarea: '',
			contacto_particular_empresa: 'Particular',
			contacto_nombre: '',
			contacto_apellidos: '',
			contacto_email: '',
			contacto_telefono: '',
			razon_social: '',
			cif_nif: '',
			recogida_direccion: '',
			recogida_municipio: '',
			recogida_persona_contacto: '',
			recogida_telefono_contacto: '',
			aceptance_direccion_entrega: true,
			entrega_direccion: '',
			entrega_municipio: '',
			entrega_persona_contacto: '',
			entrega_telefono_contacto: '',
			aceptance_direccion_facturacion: true,
			facturacion_direccion: '',
			facturacion_municipio: '',
			facturacion_persona_contacto: '',
			facturacion_telefono_contacto: '',
			checkbox_tratamiento: 'on',
			checkbox_envio_comunicaciones: false,
			ninccliente: '',
		};

		this.initValidacion();
	}

	initValidacion() {
		this.requiredValidation = {
			tipo_solicitud: false,
			marca: false,
			modelo: false,
			numero_serie: false,
			coste_dispositivo: false,
			contacto_nombre: false,
			contacto_apellidos: false,
			contacto_email: false,
			contacto_telefono: false,
			recogida_direccion: false,
			recogida_municipio: false,
			recogida_persona_contacto: false,
			recogida_telefono_contacto: false,
			entrega_direccion: false,
			entrega_municipio: false,
			entrega_persona_contacto: false,
			entrega_telefono_contacto: false,
			facturacion_direccion: false,
			facturacion_municipio: false,
			facturacion_persona_contacto: false,
			facturacion_telefono_contacto: false,
			nombre: false,
			apellidos: false,
			telefono: false,
			email: false,
			checkbox_envio_comunicaciones: false,
		};
		this.phoneValidation = {
			contacto_telefono: false,
			recogida_telefono_contacto: false,
			entrega_telefono_contacto: false,
			facturacion_telefono_contacto: false,
		}

		this.msgError = 'Debe completar los campos indicados antes de continuar.';
		this.showErrorPage = false;
	}

	initValuesAverias() {
		if (this.modelRepara.tipo_solicitud == 'REPARACION PORTATIL') {
			this.averias = this.averiasPortatil.slice();
		}
		else if (this.modelRepara.tipo_solicitud == 'REPARA TU TABLET') {
			this.averias = this.averiasTablet.slice();
		}
		else if (this.modelRepara.tipo_solicitud == 'REPARA TU SMARTPHONE') {
			this.averias = this.averiasSmartphone.slice();
		}
		else {
			this.averias = this.averiasPortatil.slice();
		}
	}

	selectDispositivo(dispositivo: string) {
		this.modelRepara.tipo_solicitud = dispositivo;
		this.modelRepara.marca = '';
		this.modelRepara.marcaOtro = '';
		this.modelRepara.averia_sintomas = [];

		this.initValuesAverias();
		this.initValidacion();
	}

	clearSelectAverias() {
		this.initValuesAverias();
	}

	selectMarca(marca: string) {
		// this.marcaSeleted = marca;
		this.modelRepara.marca = marca;
		this.checkField('marca');
		if(this.modelRepara.marca != 'otro') {
			this.modelRepara.marcaOtro = '';
			this.requiredValidation.marcaOtro = false;
		}
	}

	checkMarcaOtro() {
		if(this.modelRepara.marca == 'otro' && (this.modelRepara.marcaOtro == '' || this.modelRepara.marcaOtro == null))
			this.requiredValidation.marcaOtro = true;
		else
			this.requiredValidation.marcaOtro = false;
	}

	// getAverias() {
	// 	const tipoSolicitud = this.modelRepara.tipo_solicitud;
	// 	const averias = this.averias.filter(item => { return item.type == tipoSolicitud });
	// 	return averias;
	// }

	toogleDirEntrega() {
		this.requiredValidation.entrega_direccion = false;
		this.requiredValidation.entrega_municipio = false;
		this.requiredValidation.entrega_persona_contacto = false;
		this.requiredValidation.entrega_telefono_contacto = false;
		this.showDirEntrega = !this.showDirEntrega;
	}
	toogleDirFacturacion() {
		this.requiredValidation.facturacion_direccion = false;
		this.requiredValidation.facturacion_municipio = false;
		this.requiredValidation.facturacion_persona_contacto = false;
		this.requiredValidation.facturacion_telefono_contacto = false;
		this.showDirFacturacion = !this.showDirFacturacion;
	}

	checkField(nameField: string) {
		if (this.modelRepara[nameField] == '' || this.modelRepara[nameField] == null) {
			this.requiredValidation[nameField] = true;
			if (this.phoneValidation.hasOwnProperty(nameField)) {
				this.phoneValidation[nameField] = false;
			}
		}
		else {
			this.requiredValidation[nameField] = false;
			if (this.phoneValidation.hasOwnProperty(nameField)) {
				const pattern = /[0-9]{9}/;
				if (!pattern.test(this.modelRepara[nameField])) {
					this.phoneValidation[nameField] = true;
				}
				else {
					this.phoneValidation[nameField] = false;
				}
			}
		}
	}
	checkFieldSmartphone(nameField: string) {
		if (this.modelReparaSmartphone[nameField] == '' || this.modelReparaSmartphone[nameField] == null || this.modelReparaSmartphone[nameField] == false) {
			this.requiredValidation[nameField] = true;
		}
		else {
			this.requiredValidation[nameField] = false;
		}
	}

	validateFields() {
		let error = false;
		if (this.currentStep === 1) {
			this.checkField('tipo_solicitud');

			this.checkField('marca');
			if (this.requiredValidation.marca) {
				error = true;
			}
			this.checkMarcaOtro();
			if (this.requiredValidation.marcaOtro) {
				error = true;
			}
			this.checkField('modelo');
			if (this.requiredValidation.modelo) {
				error = true;
			}
			this.checkField('numero_serie');
			if (this.requiredValidation.numero_serie) {
				error = true;
			}
			this.checkField('coste_dispositivo');
			if (this.requiredValidation.coste_dispositivo) {
				error = true;
			}
		}
		else if (this.currentStep === 2) {
			this.checkField('contacto_nombre');
			if (this.requiredValidation.contacto_nombre) {
				error = true;
			}
			this.checkField('contacto_apellidos');
			if (this.requiredValidation.contacto_apellidos) {
				error = true;
			}
			this.checkField('contacto_email');
			if (this.requiredValidation.contacto_email) {
				error = true;
			}
			this.checkField('contacto_telefono');
			if (this.requiredValidation.contacto_telefono || this.phoneValidation.contacto_telefono) {
				error = true;
			}

			this.checkField('recogida_direccion');
			if (this.requiredValidation.recogida_direccion) {
				error = true;
			}
			this.checkField('recogida_municipio');
			if (this.requiredValidation.recogida_municipio) {
				error = true;
			}
			this.checkField('recogida_persona_contacto');
			if (this.requiredValidation.recogida_persona_contacto) {
				error = true;
			}
			this.checkField('recogida_telefono_contacto');
			if (this.requiredValidation.recogida_telefono_contacto || this.phoneValidation.recogida_telefono_contacto) {
				error = true;
			}

			if (!this.modelRepara.aceptance_direccion_entrega) {
				this.checkField('entrega_direccion');
				if (this.requiredValidation.entrega_direccion) {
					error = true;
				}
				this.checkField('entrega_municipio');
				if (this.requiredValidation.entrega_municipio) {
					error = true;
				}
				this.checkField('entrega_persona_contacto');
				if (this.requiredValidation.entrega_persona_contacto) {
					error = true;
				}
				this.checkField('entrega_telefono_contacto');
				if (this.requiredValidation.entrega_telefono_contacto || this.phoneValidation.entrega_telefono_contacto) {
					error = true;
				}
			}

			if (!this.modelRepara.aceptance_direccion_facturacion) {
				this.checkField('facturacion_direccion');
				if (this.requiredValidation.facturacion_direccion) {
					error = true;
				}
				this.checkField('facturacion_municipio');
				if (this.requiredValidation.facturacion_municipio) {
					error = true;
				}
				this.checkField('facturacion_persona_contacto');
				if (this.requiredValidation.facturacion_persona_contacto) {
					error = true;
				}
				this.checkField('facturacion_telefono_contacto');
				if (this.requiredValidation.facturacion_telefono_contacto || this.phoneValidation.facturacion_telefono_contacto) {
					error = true;
				}
			}
		}

		return error;
	}

	scrollTitulo() {
		if (this.tituloSolicitar !== undefined)
			this.tituloSolicitar.nativeElement.scrollIntoView({behavior: 'smooth'});
	}
	scrollTop() {
		window.scroll({ top: 0, left: 0, behavior: 'smooth' });
	}

	nextStep() {
		const error = this.validateFields();
		
		// console.log("Model Repara", this.modelRepara);
		if (!error) {
			this.showErrorPage = false;
			this.currentStep++;
			this.scrollTitulo();
		}
		else {
			this.msgError = 'Debe completar los campos indicados antes de continuar.';
			this.showErrorPage = true;
		}

	}
	previusStep() {
		this.currentStep--;
	}

	solicitar() {
		const error = this.validateFields();
		if (!error) {
			console.log("solicitar", this.modelRepara);
			this.showErrorPage = false;
			this.loading = true;
			this.reparaSvc.solicitarReparacion(this.modelRepara).subscribe(
				(data: any) => {
					this.loading = false;
					this.currentStep++;
					this.numeroSolicitud = data.id;
					this.scrollTitulo();
				},
				(err) => {
					console.log("ERROR = ", err);
					this.loading = false;
					this.msgError = err;
					this.showErrorPage = true;
				}
			);
			
		}
		else {
			this.msgError = 'Debe completar los campos indicados antes de continuar.';
			this.showErrorPage = true;
		}

	}

	paginaPrincipal() {
		this.initData();
		this.currentStep = 1;
		this.scrollTop();
	}

	enviarDatos() {
		const error = this.validateFields();
		if (!error) {
			console.log("enviarDatos", this.modelReparaSmartphone);
			this.showErrorPage = false;
			this.loading = true;
			this.reparaSvc.enviarDatosSmartphone(this.modelReparaSmartphone).subscribe(
				(data: any) => {
					this.loading = false;

					Report.success("", "Tus datos se han enviado correctamente", "Aceptar", () => {
						this.initData();
						this.scrollTop();
					});
				},
				(err) => {
					this.loading = false;
					this.msgError = err;
					this.showErrorPage = true;
				}
			);
			
		}
		else {
			this.msgError = 'Debe completar los campos indicados.';
			this.showErrorPage = true;
		}
	}

	navigate(url: string) {
		this.router.navigate([url]);
	}

    showGarantia10Info(){
        this.modalService.open(ModalInfoGarantia10Component, { ariaLabelledBy: 'modal-basic-title' });
    }

}
