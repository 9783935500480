<div class="container-lg mt-4 mb-4">
    <h6>
        HOME
        <i class="fa fa-angle-right" style="margin-left: 10px; margin-right: 10px;" aria-hidden="true"></i>
        ¿CÓMO FUNCIONA?
        <hr>
    </h6>

    <h3 class="mt-4 mb-4" style="font-weight: bold;">¿CÓMO FUNCIONA?</h3>

    <div class="row">
        <div class="col-4">

            <h5 style="color: green; font-weight: bold;">1. ELIGE</h5>
            <img src="../../../assets/img/elige.png" class="img-fluid" style="height: 150px; width: 150px;">
            <p class="text-muted">Selecciona tu producto favorito y el periodo de tiempo que quieras disfrutarlo</p>


        </div>
        <div class="col-4">
            <h5 style="color: green; font-weight: bold;">2. UTILIZA</h5>
            <img src="../../../assets/img/utiliza.png" class="img-fluid" style="height: 150px; width: 150px;">
            <p class="text-muted">Tu alquiler comienza en cuanto aceptas tu producto. Te facturaremos al inicio de cada
                mes de alquiler</p>
        </div>
        <div class="col-4">
            <h5 style="color: green; font-weight: bold;">3. DEVUELVE</h5>
            <img src="../../../assets/img/devuelve.png" class="img-fluid" style="height: 150px; width: 150px;">
            <p class="text-muted">Cuando tu periodo mínimo de alquiler llegue a su fin, imprime tu etiqueta de
                devolución, mete tu producto en la caja y llévalo a una oficina de mensajería</p>
        </div>
    </div>

    <div class="container-xl" style="background-color: whitesmoke;">
        <div class="row mt-4 mb-4">
            <h5 style="font-weight: bold; margin-top: 30px; margin-bottom: 30px;">¿POR QUÉ ELEGIRNOS?</h5>
            <div class="col-4 mt-2 mb-4">
                <img src="../../../assets/img/envioGratis.png" class="img-fluid">
            </div>
            <div class="col-4 mt-2 mb-4">
                <img src="../../../assets/img/devolucion.png" class="img-fluid">
            </div>
            <div class="col-4 mt-2 mb-4">
                <img src="../../../assets/img/soporte.png" class="img-fluid">
            </div>
        </div>
    </div>

    <div class="container-xl">
        <div class="row mt-4 mb-4">
            <h5 style="font-weight: bold; margin-top: 30px; margin-bottom: 30px;">CLIENTES FELICES</h5>
            <div class="col-6">
                <div class="row">
                    <div class="col-3">
                        <img src="../../../assets/img/jac.png" class="img-fluid" width="150px" height="150px" alt="Responsive image">
                    </div>
                    <div class="col-9 mt-4">
                        <h5 style="font-weight: bold;">JOSÉ ÁNGEL COSTA</h5>
                        <p class="text-muted">Director de Operaciones e Innovación</p>
                        <img src="../../../assets/img/loremipsum.png" class="img-fluid">
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="row">
                    <div class="col-3">
                        <img src="../../../assets/img/hl.png" class="img-fluid" width="150px" height="150px" alt="Responsive image">
                    </div>
                    <div class="col-9 mt-4">
                        <h5 style="font-weight: bold;">HÉCTOR LÓPEZ</h5>
                        <p class="text-muted">Chief Mark & Com.</p>
                        <img src="../../../assets/img/loremipsum.png" class="img-fluid">
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>


<img src="../../../assets/img/prefooter.png" class="img-fluid" alt="Responsive image">