import { Component, HostListener, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  logueado = false;

  screenHeight: number | undefined;
  screenWidth: number | undefined;

  constructor(
    private authSvc: AuthService,
    ) { 
      this.onResize()
    }

  ngOnInit(): void {
    this.logueado = this.authSvc.isUserLoggedIn()
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?: undefined) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

}
