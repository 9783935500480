import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';
import { Confirm } from 'notiflix/build/notiflix-confirm-aio';

import { environment } from '../../../environments/environment';

import { Token } from '../../shared/models/user.interface';

@Injectable({
	providedIn: 'root'
})
export class AuthService {

	static idAleatorio: any = null;

	constructor(
		private http: HttpClient,
		public router: Router,
	) {
		this.checkToken();
	}

	isUserLoggedIn(): boolean {
		return this.checkToken();
	}

	getAccessToken(): string {
		const stringToken = localStorage.getItem('tokenUser');
		let accessToken = "";

		if (stringToken !== null) {
			const token = JSON.parse(stringToken);
			accessToken = token.access_token;
		}

		return accessToken;
	}



	getMe() {
		return new Observable<any>(observer => {
			const accessToken = this.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/clientesolicitante/me';
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getCliente", response);
					AuthService.idAleatorio = response.redsys_random_id;
					observer.next(response);
				},
				(err) => {
					console.log("error ws getCliente", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return { unsubscribe() { } };
		});
	}


	modificarCliente(idArticulo: number, data: any): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				nombre: data.name,
				apellido: data.surname,
				nombreVisible: data.visibleName,
				email_contacto: data.email,
				password: data.password,
			};

			const url = environment.API_URL + '/clientesolicitante/update/' + idArticulo;
			this.http.put(url, params, options).subscribe(
				(response: any) => {
					console.log("ws editarCliente", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws editarCliente", err);
					let msgError = "Se ha producido un error guardando los datos";
					if(err.error.error == 'email_contacto')
						msgError = "El email introducido ya está registrado. Si desea cambiarlo pongase en contacto con nosotros.";

					observer.error(msgError);
				}
			);

			return { unsubscribe() { } };
		});
	}



	rememberPassword(email: string): Observable<boolean> {
		return new Observable<boolean>(observer => {
			const body = {
				"email": email
			}
			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Basic ' + environment.clientSecret,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			this.http.post(environment.API_URL + '/clientesolicitante/recuperarPassword', body, options).subscribe(
				(response: any) => {
					// console.log("remember password", response);
					observer.next(true);
				},
				err => {
					console.log("error remember password", err);
					let msgError = "No se ha podido enviar el email.";
					if (err.error.error === 'invalid_email') {
						msgError = "Email inváido, compruébelo de nuevo";
					}

					observer.error(msgError);
				}
			);
		});
	}

	changePasswordSecurityCode(email: string, securityCode: string, newPassword: string): Observable<boolean> {
		return new Observable<boolean>(observer => {
			const body = {
				"email": email,
				"security_code": securityCode,
				"password": newPassword
			}
			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Basic ' + environment.clientSecret,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			this.http.post(environment.API_URL + '/clientesolicitante/changePassword', body, options).subscribe(
				(response: any) => {
					// console.log("remember password", response);
					observer.next(true);
				},
				err => {
					console.log("error cambio contraseña", err);
					let msgError = "No se ha podido cambiar la contraseña.";
					if (err.error.message === 'Código no válido') {
						msgError = "Código de seguridad incorrecto, compruébelo de nuevo";
					}

					observer.error(msgError);
				}
			);
		});
	}


	login(email: string, password: string): Observable<boolean> {
		return new Observable<boolean>(observer => {
			const body = {
				"email": email,
				"password": password,
				"grant_type": "password"
			}
			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Basic ' + environment.clientSecret,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			this.http.post(environment.API_URL + '/clientesolicitante/token', body, options).subscribe(
				(response: any) => {
					console.log(response);
					this.saveToken(response);
					observer.next(true);
				},
				err => {
					console.log("error login", err);
					let msgError = "Se ha producido un error";
					if (err.error && err.error.code) {
						const codeError = err.error.code;
						if (codeError === 'L001' || codeError === 'L002') {
							msgError = "Usuario o contraseña incorrectos";
						}
						else if (codeError === 'L003') {
							msgError = "Usuario bloqueado";
						}
					}

					if (err.error.message == 'Cliente no encontrado') {
						msgError = "Usuario no encontrado. Compruebe el email"
					}

					if (err.error.message == 'Email o Password no válidos') {
						msgError = "Email o contraseña incorrectos, compruébelo de nuevo"
					}

					if (err.error.message == 'Usuario registrado en otros proyectos') {
						msgError = "El usuario tiene varios correos dados de alta en otros proyectos. Pruebe con otro correo"
					}


					observer.error(msgError);
				}
			)
		});
	}

	register(email: string, password: string, nombre: string, apellidos: string): Observable<boolean> {
		return new Observable<boolean>(observer => {
			const body = {
				"email": email,
				"password": password,
				"nombre": nombre,
				"apellidos": apellidos,
				"grant_type": "password",
				"platform": 2
			}
			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Basic ' + environment.clientSecret,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			this.http.post(environment.API_URL + '/clientesolicitante/registro', body, options).subscribe(
				(response: any) => {
					console.log(response);
					observer.next(true);
				},
				err => {
					console.log("error registro", err);
					let msgError = "Se ha producido un error";
					if (err.error && err.error.code) {
						const codeError = err.error.code;
						if (codeError === 'L001' || codeError === 'L002') {
							msgError = "Usuario o contraseña incorrectos";
						}
						else if (codeError === 'L003') {
							msgError = "Usuario bloqueado";
						}
					}

					if (err.error.message == 'Email ya registrado') {
						msgError = "Usuario ya registrado. Por favor inicie sesión"
					}

					observer.error(msgError);
				}
			)
		});
	}


	logout(): void {
		Confirm.show(
			'Cerrar Sesión',
			'¿Estás seguro de que quieres cerrar sesión?',
			'Si',
			'No',
			() => {
				localStorage.removeItem('tokenUser');
				localStorage.removeItem('idCliente');
				AuthService.idAleatorio = null;
				this.router.navigate(['/']).then(() => {
					window.location.reload();
				  });
			},
			() => {
				
			},
			{
				titleColor: '#63b421',
				okButtonBackground: '#63b421',
			}
		);
	}

	private checkToken(): boolean {
		let validToken = false;
		const stringToken = localStorage.getItem('tokenUser');

		if (stringToken !== null) {
			// console.log("token", JSON.parse(stringToken));
			const token = JSON.parse(stringToken);
			if (token.access_token && token.expires_in) {
				const now = moment();
				const dateExpired = moment(token.dateExpired);

				// console.log("now", now);
				// console.log("expire", dateExpired);

				if (now.isBefore(dateExpired)) {
					validToken = true;
				}
				else {
					validToken = false;
				}
			}
			else {
				validToken = false;
			}
		}
		else {
			validToken = false;
		}

		return validToken;
	}

	private saveToken(token: Token): void {
		let now = moment();
		let dateExpired = now.toDate().getTime() + token.expires_in;
		token.dateExpired = dateExpired;
		let idCliente = token.IDCliente;
		// console.log("ahora", now);
		// console.log("dateExpired", moment(dateExpired));

		const tokenString = JSON.stringify(token);
		localStorage.setItem('tokenUser', tokenString);
		localStorage.setItem('idCliente', String(idCliente));
	}

	///////////////////
	/// GARANTIA 10 ///
	///////////////////

	loginGarantia10(email: string, password: string): Observable<boolean> {
		return new Observable<boolean>(observer => {
			const body = {
				"username": email,
				"password": password,
				"grant_type": "password"
			}
			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Basic ' + environment.clientSecret,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			console.log(environment.API_URL + '/auth/token');

			this.http.post(environment.API_URL + '/auth/token', body, options).subscribe(
				(response: any) => {
					console.log(response);
					this.saveTokenGarantia10(response);
					observer.next(true);
				},
				err => {
					console.log("error login", err);
					let msgError = "Se ha producido un error";
					if(err.error && err.error.message != ""){
						msgError = err.error.message;
					}

					observer.error(msgError);
				}
			)
		});
	}

	private saveTokenGarantia10(token: Token): void {
		let now = moment();
		let dateExpired = now.toDate().getTime() + token.expires_in;
		token.expires_in = dateExpired;

		const tokenString = JSON.stringify(token);
		localStorage.setItem('tokenGarantia10',tokenString);
	}

	getAccessTokenGarantia10(): string {
		const stringToken = localStorage.getItem('tokenGarantia10');
		let accessToken = "";

		if (stringToken !== null) {
			const token = JSON.parse(stringToken);
			accessToken = token.access_token;
		}
		
		return accessToken;
	}

	private checkTokenGarantia10(): boolean{
		let validToken = false;
		const stringToken = localStorage.getItem('tokenGarantia10');

		if (stringToken !== null) {
			// console.log("token", JSON.parse(stringToken));
			const token = JSON.parse(stringToken);
			if (token.access_token && token.expires_in) {
				const now = moment();
				const dateExpired = moment(token.expires_in);

				// console.log("now", now);
				// console.log("expire", dateExpired);

				if (now.isBefore(dateExpired)) {
					validToken = true;
				}
			}
		}

		return validToken;
	}

	isUserLoggedInGarantia10(): boolean {
		return this.checkTokenGarantia10();
	}

	logoutGarantia10() {
		localStorage.removeItem('tokenGarantia10');

		this.router.navigate(['garantia10']);
	}
}
