import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginForm } from 'src/app/models/loginForm';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  LoginModel = new LoginForm();
  msgErrorLogin: string | undefined;

  entryPointUrl: string | undefined;
  entryPointResources: string | undefined;

  returnUrl: string | undefined;

  subdomain: boolean | undefined;
  subdomainImage: string | undefined;

  screenHeight: number | undefined;
  screenWidth: number | undefined;



  constructor(
    private authSvc: AuthService,
    private router: Router,
  ) {
    this.onResize()

  }

  ngOnInit(): void {
    this.msgErrorLogin = "";
    let ubicacion = window.location.href
    if (ubicacion.includes('flex.exxita') || ubicacion.includes('localhost')) {
      this.subdomain = false
    } else {
      this.subdomain = true
      this.subdomainImage = '../../../assets/img/marcas/' + ubicacion.slice(ubicacion.indexOf('//') + 2, ubicacion.indexOf('.')) + '.png'
    }


  }

  @HostListener('window:resize', ['$event'])
  onResize(event?: undefined) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  send(): void {
    //if (this.emailRegex.test(this.LoginModel.email)) {
    if (this.LoginModel.email == null || this.LoginModel.password == null) {
      this.msgErrorLogin = "Debe rellenar todos los campos antes de continuar"
    } else {
      this.msgErrorLogin = '';
      let email = String(this.LoginModel.email)
      let password = this.LoginModel.password

      if (this.LoginModel) {
        this.authSvc.login(email, password).subscribe(
          data => {
            if (data) {
              console.log(data)
              this.router.navigate(['/']).then(() => {
                window.location.reload();
              });
            }
          },
          err => {
            this.msgErrorLogin = err;
          }
        );
      }
    }
    //} else {
    //  console.log('Introduzca un correo electrónico correcto')
    //}
  }




}
