export class ShippingForm {
    public name!: string;
    public surname!: string;
    public company!: string;
    public country!: string; 
    public address1!: string; 
    public address2!: string;
    public city!: string;
    public province!: number;
    public zipCode!: string;
    public telephone!: number;
    public email!: string;
    public notes!: string; 

/*
    constructor(name: string,
        surname: string,
        company: string,
        country: string,
        address: string,
        city: string,
        province: string,
        zipCode: string,
        telephone: number,
        email: string,
        notes: string,){

        this.name = name;
        this.surname = surname;
        this.company = company;
        this.country = country;
        this.address = address;
        this.city = city;
        this.province = province;
        this.zipCode = zipCode;
        this.telephone = telephone;
        this.email = email;
        this.notes = notes;
    }
*/

}
