import { Component, HostListener, OnInit } from '@angular/core';
import { AuxiliarService } from 'src/app/services/auxiliar.service';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import * as Notiflix from 'notiflix';
import {Validators, FormGroup, FormControl, FormBuilder } from '@angular/forms';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { LabelType, Options } from 'ng5-slider';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {
  tipo = 'not_done';

  pedidos: any[] = [];
  hasMore: boolean = false;
  total: number = 0;
  pageSize: number = 10;
  numPage: number = 0;
  screenHeight: number | undefined;
  screenWidth: number | undefined;

  //Formulario busqueda
  formBusqueda: FormGroup;
  public selectTipo = new FormControl();
  tipos: any = null;
  tipoSelected: any = null;
  public selectEstado = new FormControl();
  estados: any = [
    {
      val: 'En proceso',
      name: 'En proceso'
    },
    {
      val: 'Recogida',
      name: 'Recogida'
    },
    {
      val: 'Entrada',
      name: 'Entrada'
    },
    {
      val: 'Reparación',
      name: 'Reparación'
    },
    {
      val: 'Enviado',
      name: 'Enviado'
    },
    {
      val: 'Entregado',
      name: 'Entregado'
    },
  ];
  estadosSelected: any = null;
  sliderValue = [0, 3000];
  minValue: number = 0;
  maxValue: number = 3000;
  optionsPrice: Options = {
    floor: 0,
    ceil: 3000,
    step: 5,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return '<b>Desde:</b> ' + value;
        case LabelType.High:
          return '<b></b> ' + value;
        default:
          return value.toString();
      }
    }
  };
  //end formulario

  constructor(
    private auxSvc: AuxiliarService,
    private router: Router,
    private route: ActivatedRoute,
    private authSvc: AuthService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal
  ) { 
    this.onResize();
    moment.locale('es');

    this.getCategories();

    this.formBusqueda = this.formBuilder.group({
			tipo: [''],
      id: ['', [Validators.maxLength(250)]],
			fecha: ['', [Validators.maxLength(250)]],
      estado: [''],
      precioMin: [''],
      precioMax: [''],
		});

    if(this.route.snapshot.params && this.route.snapshot.params.tipo)
    this.tipo = this.route.snapshot.params.tipo;
  }

  ngOnInit(): void {
    Notiflix.Loading.pulse('Cargando pedidos...',{
			svgColor: '#63b421',
		  });
      
    this.loadForm();
  }

  loadForm(){
    if(localStorage.getItem("formOrders")){
      const _filtrosSession = localStorage.getItem("formOrders");
      this.formBusqueda.setValue(JSON.parse(_filtrosSession?_filtrosSession:''));

      this.minValue = this.formBusqueda.get('precioMin')?.value;
      this.maxValue = this.formBusqueda.get('precioMax')?.value;
      this.tipoSelected = this.formBusqueda.get('tipo')?.value;
      this.estadosSelected = this.formBusqueda.get('estado')?.value;

      this.sliderValue = [this.minValue, this.maxValue];
    }
    
    this.getPedidos();
  }

  getPedidos() {
    this.auxSvc.getTicketCliente(this.formBusqueda.value, this.pageSize, this.numPage, this.tipo).subscribe(
      (data: any) => {
        if(data.tickets){
          for(let i=0; i<data.tickets.length; i++){     
            data.tickets[i].date = (moment(data.tickets[i].date_mod)).format('LL')
          }
          this.pedidos = data.tickets;
          this.hasMore = data.hasMore;
          this.total = data.total;
        }
        Notiflix.Loading.remove();
      },
      (err) => {
        Notiflix.Loading.remove();
        console.log('Error -> ' + err)
      }
    );

  }

  filtrarPedidos(reset: boolean = true){
    Notiflix.Loading.pulse('Cargando pedidos...',{
			svgColor: '#63b421',
		  });

    if(reset)
      this.numPage = 0;

    this.modalService.dismissAll();

    this.formBusqueda.get('precioMin')?.setValue(this.minValue.toString());
    if(this.maxValue < 3000)
    this.formBusqueda.get('precioMax')?.setValue(this.maxValue.toString());
    this.formBusqueda.get('tipo')?.setValue(this.tipoSelected);
    this.formBusqueda.get('estado')?.setValue(this.estadosSelected);

    localStorage.setItem("formOrders",JSON.stringify(this.formBusqueda.value));

    this.getPedidos();
  }

  getCategories(){
    this.auxSvc.getTrackingCategories().subscribe(
      (data: any) => {
        this.tipos = data;
      },
      (err) => {
        console.log('Error -> ' + err)
      }
    );
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?: undefined) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }


  navigate(url: string, id?: any) {
    if (id) {
      this.router.navigate([url], {queryParams: {id: id} });
    }
    else {
      this.router.navigate([url]);
    }
	}

  cerrarSesion(): void {
    this.authSvc.logout()
  }

  openSearch(content: any) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      //this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }



}
