import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

declare var gtag: (arg0: string, arg1: string, arg2: { page_path: string; }) => void;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  subdomain: boolean | undefined;
  garantia10: boolean = false;
  
  ngOnInit(): void {
    let ubicacion = window.location.href

    if(ubicacion.includes('garantia10')){
      this.garantia10 = true;
    }

    //CAMBIAR O AÑADIR UN OR A ESTE LOCALHOST POR LA URL QUE SE USARÁ EN PRODUCCION
    if(ubicacion.includes('flex.exxita') || ubicacion.includes('localhost')){
      this.subdomain = false
    }else{
      this.subdomain = true
    }
  }

  title = 'maq-web-exxita';

  constructor(
    private router: Router,
  ){
    const routeChange = router.events
    .pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    )
    .subscribe(e => {
      if(typeof gtag !== 'undefined') gtag('config', 'MY_ID', {'page_path':e.urlAfterRedirects});
    });
  }

}
