<div class="row" *ngIf="screenWidth != undefined && screenWidth > 990">
    <div class="col-12 mt-4">
        <!--<div class="picsum-img-wrapper">
            <img src="../../../assets/img/imagen-carrusel-01-300p.png" alt="Random first slide" style="width: 100% !important;">
        </div>-->
        
        <ngb-carousel [showNavigationIndicators]="false">
            <ng-template ngbSlide>
                <div class="picsum-img-wrapper">
                    <img src="../../../assets/img/imagen-carrusel-01-300p.png" alt="Random first slide" style="width: 100% !important;">
                </div>
            </ng-template>
            <ng-template ngbSlide>
                <div class="picsum-img-wrapper">
                    <img src="../../../assets/img/imagen-carrusel-02-300p.png" alt="Random second slide" style="width: 100% !important;">
                </div>
            </ng-template>
            <ng-template ngbSlide>
                <div class="picsum-img-wrapper">
                    <img src="../../../assets/img/imagen-carrusel-03-300p.png" alt="Random third slide" style="width: 100% !important;">
                </div>
            </ng-template>
        </ngb-carousel>
    </div>
    <!--<div class="col-4 mt-4" style="padding-left: 8px !important;">
        <img src="../../../assets/img/imagen-cybermonday-300p.png" style="width: 100%;">
    </div>-->
</div>

<div *ngIf="screenWidth != undefined && screenWidth <= 990">
    <div>
        <!--<div class="picsum-img-wrapper">
            <img src="../../../assets/img/imagen-carrusel-01-300p.png" alt="Random first slide" style="width: 100% !important;">
        </div>-->
            

                <ngb-carousel [showNavigationIndicators]="false">
                    <ng-template ngbSlide>
                <div class="picsum-img-wrapper">
                    <img src="../../../assets/img/imagen-carrusel-01-300p.png" alt="Random first slide" style="width: 100% !important;">
                </div>
            </ng-template>
            <ng-template ngbSlide>
                <div class="picsum-img-wrapper">
                    <img src="../../../assets/img/imagen-carrusel-02-300p.png" alt="Random second slide" style="width: 100% !important;">
                </div>
            </ng-template>
            <ng-template ngbSlide>
                <div class="picsum-img-wrapper">
                    <img src="../../../assets/img/imagen-carrusel-03-300p.png" alt="Random third slide" style="width: 100% !important;">
                </div>
            </ng-template>
        </ngb-carousel>
    
    </div>
    <!--<div>
        <img src="../../../assets/img/imagen-cybermonday-movil-300p.png" style="width: 100%;">
    </div>-->
</div>

<div class="flex-row vcenter green-border-div" style="margin-top: 15px; padding: 10px;" *ngIf="screenWidth != undefined && screenWidth > 990">
    <div class="flex-row between vcenter">
        <img class="grow1" src="../../../assets/img/ObtengaOfertasEspeciales.png" style="max-width: 50%; margin-right: 10px;">

        <div class="flex-column center grow2">
            <div class="input-container mt-2">
                <input class="input-field" id="email" type="email" class="form-control form-control-lg input-btn"
                    placeholder="Ingrese su dirección de correo electrónico..." style="font-size: medium;">
                <button type="button" class="btn btn-default btn-circle" (click)="crearSub()"
                    style="border-radius: 0px 20px 20px 0px; background: #424141; color: whitesmoke; font-size: 10px;">
                    INSCRIBIRSE
                </button>
            </div>

            <h3 class="text-center" style="color: tomato;" *ngIf="messageError != ''">
                {{messageError}}</h3>

            <div class="text-center" *ngIf="emailRegistrado != ''">
                <h3 class="text-center" style="color: tomato;" *ngIf="emailRegistrado.includes('anterioridad')">
                    {{emailRegistrado}}</h3>
                <h3 class="text-center" style="color: darkcyan;" *ngIf="emailRegistrado.includes('éxito')">
                    {{emailRegistrado}}</h3>
            </div>

            <label class="secondary-text" style="font-size: 10px;"><input type="checkbox"> Acepto el envío de comunicaciones comerciales de la compañía</label>
        </div>
    </div>
</div>

<div class="flex-row vcenter green-border-div" style="margin-top: 15px !important; padding: 10px; margin: 10px;" *ngIf="screenWidth != undefined && screenWidth <= 990">
    <div class="flex-column between vcenter">
        <img class="grow1" src="../../../assets/img/ObtengaOfertasEspeciales.png" style="max-width: 100%; margin-right: 10px;">

        <div class="flex-column center grow2">
            <div class="input-container mt-2">
                <input class="input-field" id="email" type="email" class="form-control form-control-lg input-btn"
                    placeholder="Ingrese su dirección de correo electrónico..." style="font-size: medium;">
                <button type="button" class="btn btn-default btn-circle" (click)="crearSub()"
                    style="border-radius: 0px 20px 20px 0px; background: #424141; color: whitesmoke; font-size: 10px;">
                    INSCRIBIRSE
                </button>
            </div>

            <h3 class="text-center" style="color: tomato;" *ngIf="messageError != ''">
                {{messageError}}</h3>

            <div class="text-center" *ngIf="emailRegistrado != ''">
                <h3 class="text-center" style="color: tomato;" *ngIf="emailRegistrado.includes('anterioridad')">
                    {{emailRegistrado}}</h3>
                <h3 class="text-center" style="color: darkcyan;" *ngIf="emailRegistrado.includes('éxito')">
                    {{emailRegistrado}}</h3>
            </div>

            <label class="secondary-text" style="font-size: 10px;"><input type="checkbox"> Acepto el envío de comunicaciones comerciales de la compañía</label>
        </div>
    </div>
</div>

<div class="flex-column" style="margin-top: 20px;" *ngIf="screenWidth != undefined && screenWidth > 990">
    <label style="font-size: 30px;">Una nueva forma de entender la tecnología</label>
    <img src="../../../assets/img/tecnologia.png">
</div>

<div class="flex-column" style="margin: 10px;" *ngIf="screenWidth != undefined && screenWidth <= 990">
    <label style="font-size: 4vw;">Una nueva forma de entender la tecnología</label>
    <img src="../../../assets/img/tecnologia-movil.png">
</div>

<div class="flex-column" style="margin-top: 15px;" *ngIf="screenWidth != undefined && screenWidth > 990">
    <label>NUESTRAS MARCAS</label>
    <hr>

    <ngb-carousel [showNavigationIndicators]="false" [showNavigationArrows]="true">
        <ng-template ngbSlide >
            <div class="flex-row between">
                <img src="../../../assets/img/marcas/Logo01-Lenovo.png" alt="Random first slide" style="width: 20% !important;">
      
                <img src="../../../assets/img/marcas/Logo02-Asus.png" alt="Random second slide" style="width: 20% !important;">
            
                <img src="../../../assets/img/marcas/Logo03-Toshiba.png" alt="Random third slide" style="width: 20% !important;">
            
                <img src="../../../assets/img/marcas/Logo04-LG.png" alt="Random third slide" style="width: 20% !important;">
            </div>
        </ng-template>
        <ng-template ngbSlide class="flex-row between">
            <div class="flex-row between">
                <img src="../../../assets/img/marcas/Logo05-Brother.png" alt="Random first slide" style="width: 20% !important;">
            
                <img src="../../../assets/img/marcas/Logo06-Qnap.png" alt="Random second slide" style="width: 20% !important;">
            
                <img src="../../../assets/img/marcas/Logo07-MSI.png" alt="Random third slide" style="width: 20% !important;">
            
                <img src="../../../assets/img/marcas/Logo08-Apple.png" alt="Random third slide" style="width: 20% !important;">
            </div>
        </ng-template>
        <ng-template ngbSlide class="flex-row between">
            <div class="flex-row between">
                <img src="../../../assets/img/marcas/Logo09-Dynabook.png" alt="Random first slide" style="width: 20% !important;">
            
                <img src="../../../assets/img/marcas/Logo10-Alcatel.png" alt="Random second slide" style="width: 20% !important;">
            
                <img src="../../../assets/img/marcas/Logo11-Samsung.png" alt="Random third slide" style="width: 20% !important;">
            
                <img src="../../../assets/img/marcas/Logo12-Garmin.png" alt="Random third slide" style="width: 20% !important;">
            </div>
        </ng-template>
    </ngb-carousel>
</div>

<div class="flex-column" style="margin: 10px;" *ngIf="screenWidth != undefined && screenWidth <= 990">
    <label style="font-size: 4vw;">NUESTRAS MARCAS</label>
    <hr>

    <ngb-carousel [showNavigationIndicators]="false" [showNavigationArrows]="true">
        <ng-template ngbSlide >
            <div class="flex-row between">
                <img src="../../../assets/img/marcas/Logo01-Lenovo.png" alt="Random first slide" style="width: 20% !important;">
      
                <img src="../../../assets/img/marcas/Logo02-Asus.png" alt="Random second slide" style="width: 20% !important;">
            
                <img src="../../../assets/img/marcas/Logo03-Toshiba.png" alt="Random third slide" style="width: 20% !important;">
            </div>
        </ng-template>
        <ng-template ngbSlide class="flex-row between">
            <div class="flex-row between">
                <img src="../../../assets/img/marcas/Logo04-LG.png" alt="Random third slide" style="width: 20% !important;">
            
                <img src="../../../assets/img/marcas/Logo05-Brother.png" alt="Random first slide" style="width: 20% !important;">
            
                <img src="../../../assets/img/marcas/Logo06-Qnap.png" alt="Random second slide" style="width: 20% !important;">
            </div>
        </ng-template>
        <ng-template ngbSlide class="flex-row between">
            <div class="flex-row between">
                <img src="../../../assets/img/marcas/Logo07-MSI.png" alt="Random third slide" style="width: 20% !important;">
            
                <img src="../../../assets/img/marcas/Logo08-Apple.png" alt="Random third slide" style="width: 20% !important;">
            
                <img src="../../../assets/img/marcas/Logo09-Dynabook.png" alt="Random first slide" style="width: 20% !important;">
            </div>
        </ng-template>
        <ng-template ngbSlide class="flex-row between">
            <div class="flex-row between">
                <img src="../../../assets/img/marcas/Logo10-Alcatel.png" alt="Random second slide" style="width: 20% !important;">
            
                <img src="../../../assets/img/marcas/Logo11-Samsung.png" alt="Random third slide" style="width: 20% !important;">
            
                <img src="../../../assets/img/marcas/Logo12-Garmin.png" alt="Random third slide" style="width: 20% !important;">
            </div>
        </ng-template>
    </ngb-carousel>
</div>

<div class="flex-column" style="margin-top: 15px;" *ngIf="movimientos && screenWidth != undefined && screenWidth > 990">
    <label>DISPOSITIVOS MÁS ALQUILADOS</label>
    <hr>

    <div class="flex-row start stretch">
        <div class="flex-column stretch grow1 div-dispositivo-alquilado" *ngFor="let movimiento of movimientos;">
            <a href="/products/{{movimiento.elemento.ID}}" *ngIf="movimiento.elemento">
                <div class="green-border-div">
                    <!--<button type="button" class="btn btn-primary">
                        <img src="../../../assets/img/shopping-bag.png">
                    </button>-->
                    <div class="div-img-content">
                        <div class="flex-column center vcenter">
                            <img [src]="(movimiento.elemento.urlImagenes && movimiento.elemento.urlImagenes.length>0 && movimiento.elemento.urlImagenes[0].url)?rutaImagen+movimiento.elemento.urlImagenes[0].url:''">
                        </div>
                    </div>
                </div>
            </a>

            <div class="flex-column" *ngIf="movimiento.elemento">
                <span class="secondary-text">{{movimiento.elemento.tipo_dispositivo?movimiento.elemento.tipo_dispositivo.name:'-'}}</span>
                <label>{{movimiento.elemento.name?movimiento.elemento.name:'-'}}</label>
                <!--<div class="flex-row">
                    <star-rating [value]="movimiento.elemento.rating" [totalstars]="5" checkedcolor="#5bc500" uncheckedcolor="gray"
                    size="24px" [readonly]="true"></star-rating>
                </div>-->
                <div>
                    <span class="secondary-text">desde</span>&nbsp;<label>{{movimiento.elemento.precioMinimo}}€</label>&nbsp;<span class="secondary-text">al mes</span>
                </div>
                
                <div>
                    <app-dialog-select-options-update [product]="movimiento.elemento"></app-dialog-select-options-update>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="flex-column" style="margin: 10px;" *ngIf="movimientos && screenWidth != undefined && screenWidth <= 990">
    <label style="font-size: 4vw;">DISPOSITIVOS MÁS ALQUILADOS</label>
    <hr>

    <div class="flex-row start stretch" style="flex-wrap: wrap;">
        <div class="flex-column stretch grow1 div-dispositivo-alquilado xs" *ngFor="let movimiento of movimientos;">
            <a href="/products/{{movimiento.elemento.ID}}" *ngIf="movimiento.elemento">
                <div class="green-border-div">
                    <!--<button type="button" class="btn btn-primary">
                        <img src="../../../assets/img/shopping-bag.png">
                    </button>-->
                    <div class="div-img-content">
                        <div class="flex-column center vcenter">
                            <img [src]="(movimiento.elemento.urlImagenes && movimiento.elemento.urlImagenes.length>0 && movimiento.elemento.urlImagenes[0].url) ?rutaImagen+movimiento.elemento.urlImagenes[0].url:''">
                        </div>
                    </div>
                </div>
            </a>

            <div class="flex-column" *ngIf="movimiento.elemento">
                <span class="secondary-text">{{movimiento.elemento.tipo_dispositivo?movimiento.elemento.tipo_dispositivo.name:'-'}}</span>
                <label style="font-size: 3vw;">{{movimiento.elemento.name?movimiento.elemento.name:'-'}}</label>
                <!--<div class="flex-row">
                    <star-rating [value]="movimiento.elemento.rating" [totalstars]="5" checkedcolor="#5bc500" uncheckedcolor="gray"
                    size="24px" [readonly]="true"></star-rating>
                </div>-->
                <div>
                    <span class="secondary-text">desde</span>&nbsp;<label>{{movimiento.elemento.precioMinimo}}€</label>&nbsp;<span class="secondary-text">al mes</span>
                </div>
                
                <div>
                    <app-dialog-select-options-update [product]="movimiento.elemento"></app-dialog-select-options-update>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- 
<div class="container-xl mt-4 mb-4" style="background-color: whitesmoke;">
    <div class="row">

        <div class="col-2 mt-4 mb-4">
            <a href="#">
                <img class="img-fluid" style="border: 1px solid; color: rgb(0, 198, 0);"
                    src="../../../assets/img/productosPrueba/AppleWatch.png">
            </a>
        </div>

        <div class="col-2 mt-4 mb-4">
            <h4>SMARTWATCH</h4>
            <p class="nombre">Apple Watch Series 6 GPS</p>
            <div class="ratings">

                <p class="positivas"> <i class="fa fa-star"></i> <i class="fa fa-star"></i> <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                </p>
                <p class="negativas"> <i class="fa fa-star"></i></p>

            </div>

            <div #precio class="mb-2">
                <p style="display:inline;">desde </p>
                <p style="text-decoration: line-through; display:inline; font-weight: bold;">22,90</p>
                <p style="display:inline; color: green; font-weight: bold;"> 19,90 €</p>
                <p style="display:inline;"> al mes</p>
            </div>

        </div>



        <div class="col-2 mt-4 mb-4">
            <img class="img-fluid" style="border: 1px solid; color: rgb(0, 198, 0);"
                src="../../../assets/img/productosPrueba/speakerBluetooth.png">
        </div>

        <div class="col-2 mt-4 mb-4">
            <h4>AUDIO</h4>
            <p class="nombre">JBL Charge 5 Bluetooth Portable Speaker</p>
            <div class="ratings">

                <p class="positivas">
                </p>
                <p class="negativas"> <i class="fa fa-star"></i><i class="fa fa-star"></i> <i class="fa fa-star"></i> <i
                        class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                </p>

            </div>

            <div #precio class="mb-2">
                <p style="display:inline;">desde </p>
                <h3 style="display:inline;">9.90 €</h3>
                <p style="display:inline;"> al mes</p>
            </div>

        </div>

        <div class="col-2 mt-4 mb-4">
            <img class="img-fluid" style="border: 1px solid; color: rgb(0, 198, 0);"
                src="../../../assets/img/productosPrueba/iphone12.png">
        </div>

        <div class="col-2 mt-4 mb-4">
            <h4>MÓVILES</h4>
            <p class="nombre">Apple iPhone 12 Pro 256GB</p>
            <div class="ratings">

                <p class="positivas"> <i class="fa fa-star"></i> <i class="fa fa-star"></i> <i class="fa fa-star"></i>
                </p>
                <p class="negativas"> <i class="fa fa-star"></i> <i class="fa fa-star"></i></p>

            </div>

            <div #precio class="mb-2">
                <p style="display:inline;">desde </p>
                <p style="text-decoration: line-through; display:inline; font-weight: bold;">89,90</p>
                <p style="display:inline; color: green; font-weight: bold;"> 84,90 €</p>
                <p style="display:inline;"> al mes</p>
            </div>

        </div>
    </div>

    <hr>

    <div class="row">

        <div class="col-2 mt-4 mb-4">
            <img class="img-fluid" style="border: 1px solid; color: rgb(0, 198, 0);"
                src="../../../assets/img/productosPrueba/asusChromebook.png">
        </div>

        <div class="col-2 mt-4 mb-4">
            <h4>ORDENADORES</h4>
            <p class="nombre">Asus Chromebook Z1500CNEJ0400</p>
            <div class="ratings">

                <p class="positivas"> <i class="fa fa-star"></i> <i class="fa fa-star"></i> <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i><i class="fa fa-star"></i>
                </p>

            </div>

            <div #precio class="mt-2 mb-2">
                <p style="display:inline;">desde </p>
                <p style="text-decoration: line-through; display:inline; font-weight: bold;">99,90</p>
                <p style="display:inline; color: green; font-weight: bold;"> 89,90 €</p>
                <p style="display:inline;"> al mes</p>
            </div>

        </div>

        <div class="col-2 mt-4 mb-4">
            <img class="img-fluid" style="border: 1px solid; color: rgb(0, 198, 0);"
                src="../../../assets/img/productosPrueba/goPro.png">
        </div>

        <div class="col-2 mt-4 mb-4">
            <h4>FOTOGRAFÍA</h4>
            <p class="nombre">GoPro Hero9</p>
            <div class="ratings">

                <p class="positivas"> <i class="fa fa-star"></i> <i class="fa fa-star"></i> <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                </p>
                <p class="negativas"> <i class="fa fa-star"></i></p>

            </div>

            <div #precio class="mb-2">
                <p style="display:inline;">desde </p>
                <p style="text-decoration: line-through; display:inline; font-weight: bold;">24,90</p>
                <p style="display:inline; color: green; font-weight: bold;"> 19,90 €</p>
                <p style="display:inline;"> al mes</p>
            </div>

        </div>

        <div class="col-2 mt-4 mb-4">
            <img class="img-fluid" style="border: 1px solid; color: rgb(0, 198, 0);"
                src="../../../assets/img/productosPrueba/hpDesktop.png">
        </div>

        <div class="col-2 mt-4 mb-4">
            <h4>ORDENADORES</h4>
            <p class="nombre">HP Slim Desktop S01PF1001NS</p>
            <div class="ratings">

                <p class="positivas"> <i class="fa fa-star"></i> <i class="fa fa-star"></i> <i class="fa fa-star"></i>
                </p>
                <p class="negativas"> <i class="fa fa-star"></i> <i class="fa fa-star"></i></p>

            </div>

            <div #precio class="mb-2">
                <p style="display:inline;">desde </p>
                <h3 style="display:inline;">44.90 €</h3>
                <p style="display:inline;"> al mes</p>
            </div>

        </div>
    </div>

</div> -->




    <div class="mt-4">
        <img class="img-fluid mb-4" src="../../../assets/img/superiores100.png">
    </div>

<div *ngIf="screenWidth != undefined && screenWidth > 515 && modalSuscripcion">
    <app-modal></app-modal>
</div>