<div *ngIf="screenWidth != undefined && screenWidth > 768">

    <h1 class="presupuestos text-center">MIS PRESUPUESTOS</h1>

    <h2 *ngIf="presupuestos?.length == 0">Aún no hay ningún presupuesto</h2>

    <table *ngIf="presupuestos?.length > 0" class="table mt-4">
        <thead class="thead-dark">
            <tr>
                <th scope="col"></th>
                <th scope="col">ID PRESUPUESTO</th>
                <th scope="col">FECHA</th>
                <th scope="col">IMPORTE</th>
                <th scope="col"></th>
            </tr>
        </thead>
        <tbody>
            <tr class="text-muted" *ngFor="let presupuesto of presupuestos">
                <td *ngIf="presupuesto.pagado != 1 && presupuesto.rechazado != 1"><i class="far fa-circle"></i></td>
                <td *ngIf="presupuesto.pagado == 1"><i class="fas fa-circle" style="color: green;"></i></td>
                <td *ngIf="presupuesto.rechazado == 1"><i class="fas fa-circle" style="color: red;"></i></td>
                <td>{{presupuesto.ID}}</td>
                <td>{{formatDate(presupuesto.fecha_documento)}}</td>
                <td>{{formatNumber(presupuesto.totalConImpuestos)}} €</td>
                <td><button type="submit" class="btn btn-dark" (click)="navigate('/repairs/' + idTicket + '/budget/'+presupuesto.ID)">VER</button></td>
            </tr>
        </tbody>
    </table>

</div>

<div *ngIf="screenWidth != undefined && screenWidth <= 768">

    <h1 class="presupuestos text-center">MIS PRESUPUESTOS</h1>

    <h2 *ngIf="presupuestos?.length == 0">Aún no hay ningún presupuesto</h2>

    <table *ngIf="presupuestos?.length > 0" class="table mt-4">
        <thead class="thead-dark">
            <tr>
                <th scope="col"></th>
                <th scope="col">ID PRESUPUESTO</th>
                <th scope="col">FECHA</th>
                <th scope="col">IMPORTE</th>
                <th scope="col"></th>
            </tr>
        </thead>
        <tbody>
            <tr class="text-muted" *ngFor="let presupuesto of presupuestos">
                <td *ngIf="presupuesto.aceptado != 1 && presupuesto.rechazado != 1"><i class="far fa-circle"></i></td>
                <td *ngIf="presupuesto.aceptado == 1"><i class="fas fa-circle" style="color: green;"></i></td>
                <td *ngIf="presupuesto.rechazado == 1"><i class="fas fa-circle" style="color: red;"></i></td>
                <td>{{presupuesto.ID}}</td>
                <td>{{formatDate(presupuesto.fecha_documento)}}</td>
                <td>{{formatNumber(presupuesto.totalConImpuestos)}} €</td>
                <td><button type="submit" class="btn btn-dark" (click)="navigate('/repairs/' + idTicket + '/budget/'+presupuesto.ID)">VER</button></td>
            </tr>
        </tbody>
    </table>

</div>