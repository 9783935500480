<div *ngIf="screenWidth != undefined">

    <div *ngIf="product != undefined && screenWidth > 768">
        <ng-template #content let-modal>

            <div class="modal-body">
                <div class="flex-column">
                    <div class="col-12" style="text-align: right;">
                        <button type="close" class="close" style="width: 10px;" (click)="modal.dismiss()">
                            <span>&times;</span>
                        </button>
                    </div>

                    <div class="flex-row start">

                        <div class="flex-column stretch" style="width: 40%;">
                            <div class="div-dispositivo-alquilado">
                                <div class="green-border-div">
                                    <div class="div-img-content">
                                        <div class="flex-column center vcenter">
                                            <img [src]="(imgPreview && imgPreview!='')?imgPreview:product.imgUrl[0]">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="flex-row start div-dispositivo-alquilado">
                                <div class="green-border-div min pointer"
                                    *ngFor="let imagen of imagesToshow; index as i"
                                    [class.mr10]="i<3 && i+1<product.imgUrl.length" (click)="imgPreview = imagen">
                                    <div class="div-img-content" *ngIf="i<4">
                                        <div class="flex-column center vcenter">
                                            <img src="{{imagen}}">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="grow1">
                            <h4 style="font-weight: bold;">{{product.name}}</h4>

                            <div class="ratings">

                                <div class="row">
                                    <!--<div class="col-4">

                                        <star-rating [value]="product.rating" [totalstars]="5" checkedcolor="#5bc500"
                                            uncheckedcolor="gray" size="24px" [readonly]="true"></star-rating>

                                    </div>-->
                                    <div class="col-8">
                                        <!--

        <p class="text-muted">1 valoración de cliente | <a href="#"
            style="color: gray;">Añadir
            una
            valoración</a></p>
        -->
                                    </div>
                                </div>


                            </div>

                            <hr>

                            <div #precio class="mb-2">
                                <h3 style="display:inline; font-weight: bold;">{{product.price}} €</h3>
                                <div *ngIf="monthSelected == 1">
                                    <p style="font-size: 80%;"> al mes durante {{monthSelected}} mes. Puede cancelar en
                                        cualquier
                                        momento
                                    </p>
                                </div>
                                <div *ngIf="monthSelected != 1">
                                    <p style="font-size: 80%;"> al mes durante {{monthSelected}} meses. Puede cancelar
                                        en
                                        cualquier
                                        momento
                                    </p>
                                </div>
                            </div>


                            <p>{{product.description}}</p>
                            <p><a class="text-muted">SKU: </a>6541119951-2</p>
                            <p><a class="text-muted">CATEGORÍAS: </a>{{product.type}}</p>
                            <!--<img class="img-fluid" style="height: 50px; width: 180px;"
                                src="../../../assets/img/productosPrueba/garantia10ç.png">-->
                            <p class="mt-2" style="margin-left: 10px;"><i class="fas fa-truck"></i> Entrega gratuita al
                                día
                                siguiente
                            </p>
                            <p class="mt-2" style="margin-left: 10px;"> Selecciona un <a href="#"
                                    style="color: black;">periodo
                                    mínimo
                                    de
                                    alquiler</a></p>


                            <ng-template ngFor let-element [ngForOf]="product.monthsPossible" let-i="index">
                                <button *ngIf="monthSelected == element" class="btn btn-success"
                                    (click)=selectMonth(element) style="margin-right: 10px;">
                                    <p>{{element}}+</p>mes
                                </button>
                                <button *ngIf="monthSelected != element" class="btn border-secondary"
                                    (click)=selectMonth(element) style="margin-right: 10px;">
                                    <p>{{element}}+</p>mes
                                </button>
                            </ng-template>



                            <hr>

                            <div class="mt-4 mb-4">

                                <input #cantidad type="number" value="1" min="0" max="999" step="1"
                                    style="margin-right: 20px;" />

                                <button class="btn btn-dark" (click)="addToCart(cantidad.value)"><i
                                        class="fas fa-shopping-bag"></i> Añadir a la cesta</button>
                            </div>


                            <hr>

                            <div #redes class="row">
                                <div class="col-1">
                                    <a href="https://www.facebook.com/CyGitsolutions" target="_blank"><i
                                            class="fab fa-facebook fa-2x" style="color: rgb(0, 198, 0);"></i></a>
                                </div>
                                <div class="col-1">
                                    <a href="https://twitter.com/CyGitsolutions" target="_blank"><i
                                            class="fab fa-twitter fa-2x" style="color: rgb(0, 198, 0);"></i></a>
                                </div>
                                <div class="col-1">
                                    <a href="https://www.instagram.com/cygitsolutions/" target="_blank"><i
                                            class="fab fa-instagram fa-2x" style="color: rgb(0, 198, 0);"></i></a>
                                </div>
                                <div class="col-1">
                                    <a href="https://www.linkedin.com/company/cygsustainabletech" target="_blank"><i
                                            class="fab fa-linkedin fa-2x" style="color: rgb(0, 198, 0);"></i></a>
                                </div>
                                <div class="col-1">
                                    <a href="https://www.youtube.com/user/CyGITSolutions" target="_blank"><i
                                            class="fab fa-youtube fa-2x" style="color: rgb(0, 198, 0);"></i></a>
                                </div>
                                <div class="col-1">
                                    <a [href]="emailString" target="_top"><i class="fas fa-envelope fa-2x"
                                            style="color: rgb(0, 198, 0);"></i></a>
                                </div>
                                <!--
                                    <div class="col">
                                        <button class="btn"><i class="far fa-heart"></i> Añadir a lista de deseos</button>
                                    </div>
                                -->
                            </div>
                        </div>
                    </div>



                </div>
            </div>
        </ng-template>

        <div *ngIf="screenWidth!=undefined">


            <button type="button" class="btn btn-secondary btn-seleccionar" (click)="openVerticallyCentered(content)">
                SELECCIONAR OPCIONES
            </button>

        </div>
    </div>

    <div *ngIf="product != undefined && screenWidth<=768">
        <ng-template #content let-modal>
            <div class="modal-body">
                <div class="flex-column">
                    <div class="col-12" style="text-align: right;">
                        <button type="close" class="close" style="width: 10px;" (click)="modal.dismiss()">
                            <span>&times;</span>
                        </button>
                    </div>

                    <div class="flex-column">

                        <div class="flex-column stretch" style="width: 100%;">
                            <div class="div-dispositivo-alquilado xs">
                                <div class="green-border-div">
                                    <div class="div-img-content">
                                        <div class="flex-column center vcenter">
                                            <img [src]="(imgPreview && imgPreview!='')?imgPreview:product.imgUrl[0]">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="flex-row start div-dispositivo-alquilado xs">
                                <div class="green-border-div min pointer"
                                    *ngFor="let imagen of imagesToshow; index as i"
                                    [class.mr10]="i<3 && i+1<product.imgUrl.length" (click)="imgPreview = imagen">
                                    <div class="div-img-content" *ngIf="i<4">
                                        <div class="flex-column center vcenter">
                                            <img src="{{imagen}}">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="row text-center">
                        <div class="row">
                            <h4 style="font-weight: bold;">{{product.name}}</h4>
                        </div>
                        <div class="row">

                            <div class="ratings">
                                <!--<div class="row">

                                    <star-rating [value]="product.rating" [totalstars]="5" checkedcolor="#5bc500"
                                        uncheckedcolor="gray" size="24px" [readonly]="true"></star-rating>

                                </div>-->
                                <div class="row">
                                    <!--

        <p class="text-muted">1 valoración de cliente | <a href="#" style="color: gray;">Añadir
            una
            valoración</a></p>
        -->
                                </div>

                                <hr>
                            </div>


                        </div>

                    </div>
                    <div #precio class="mb-2">
                        <h3 style="font-weight: bold;">{{product.price}} €</h3>
                        <div *ngIf="monthSelected == 1">
                            <p style="font-size: 80%;"> al mes durante {{monthSelected}} mes. Puede cancelar en
                                cualquier
                                momento
                            </p>
                        </div>
                        <div *ngIf="monthSelected != 1">
                            <p style="font-size: 80%;"> al mes durante {{monthSelected}} meses. Puede cancelar en
                                cualquier
                                momento
                            </p>
                        </div>
                    </div>


                    <p>{{product.description}}</p>
                    <p><a class="text-muted">SKU: </a>6541119951-2</p>
                    <p><a class="text-muted">CATEGORÍAS: </a>{{product.type}}</p>
                    <!--<img class="img-fluid" style="height: 50px; width: 180px;"
                        src="../../../assets/img/productosPrueba/garantia10ç.png">-->
                    <p class="mt-2" style="margin-left: 10px;"><i class="fas fa-truck"></i> Entrega gratuita al día
                        siguiente
                    </p>
                    <p class="mt-2" style="margin-left: 10px;"> Selecciona un <a href="#" style="color: black;">periodo
                            mínimo
                            de
                            alquiler</a></p>


                    <div class="flex-row start" style="flex-wrap: wrap;">
                        <ng-template ngFor let-element [ngForOf]="product.monthsPossible" let-i="index">
                            <button *ngIf="monthSelected == element" class="btn btn-success"
                                (click)=selectMonth(element) style="margin-right: 10px; margin-bottom: 10px;">
                                <p>{{element}}+</p>mes
                            </button>
                            <button *ngIf="monthSelected != element" class="btn border-secondary"
                                (click)=selectMonth(element) style="margin-right: 10px; margin-bottom: 10px;">
                                <p>{{element}}+</p>mes
                            </button>
                        </ng-template>
                    </div>



                    <hr>

                    <div class="mt-4 mb-4">

                        <input #cantidad type="number" value="1" min="0" max="999" step="1"
                            style="margin-right: 20px;" />

                        <button class="btn btn-dark mt-2" (click)="addToCart(cantidad.value)"><i
                                class="fas fa-shopping-bag"></i>
                            Añadir a la cesta</button>
                    </div>


                    <hr>

                    <div #redes class="row">
                        <div class="col-1">
                            <a href="https://www.facebook.com/CyGitsolutions" target="_blank"><i
                                    class="fab fa-facebook fa-2x" style="color: rgb(0, 198, 0);"></i></a>
                        </div>
                        <div class="col-1">
                            <a href="https://twitter.com/CyGitsolutions" target="_blank"><i class="fab fa-twitter fa-2x"
                                    style="color: rgb(0, 198, 0);"></i></a>
                        </div>
                        <div class="col-1">
                            <a href="https://www.instagram.com/cygitsolutions/" target="_blank"><i
                                    class="fab fa-instagram fa-2x" style="color: rgb(0, 198, 0);"></i></a>
                        </div>
                        <div class="col-1">
                            <a href="https://www.linkedin.com/company/cygsustainabletech" target="_blank"><i
                                    class="fab fa-linkedin fa-2x" style="color: rgb(0, 198, 0);"></i></a>
                        </div>
                        <div class="col-1">
                            <a href="https://www.youtube.com/user/CyGITSolutions" target="_blank"><i
                                    class="fab fa-youtube fa-2x" style="color: rgb(0, 198, 0);"></i></a>
                        </div>
                        <div class="col-1">
                            <a [href]="emailString" target="_top"><i class="fas fa-envelope fa-2x"
                                    style="color: rgb(0, 198, 0);"></i></a>
                        </div>
                        <!--
                        <div class="col">
                            <button class="btn"><i class="far fa-heart"></i> Añadir a lista de deseos</button>
                        </div>
                    -->
                    </div>

                </div>


            </div>
        </ng-template>


        <button type="button" class="btn btn-secondary btn-seleccionar" (click)="openVerticallyCentered(content)">
            SELECCIONAR OPCIONES
        </button>

    </div>


    <ng-template #modalAddSuccess let-d="dismiss">
        <div class="modal-body">
            <h3 class="text-center">¡Producto añadido!</h3>
            <div *ngIf="product != undefined">
                <h4 class="text-center">Se ha añadido {{product.name}} a la cesta</h4>
            </div>
        </div>
    </ng-template>

</div>