import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
 
@Injectable({
    providedIn: 'root'
})
export class RedirectGuard implements CanActivate {
  constructor() {}
 
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
      window.location.href = route.data['externalUrl'];
      return true;
  }
}