<div *ngIf="screenWidth != undefined">

    <div *ngIf="product != undefined && screenWidth > 768">
        <ng-template #content let-modal>
            
            <div class="modal-body">
                <div class="flex-column">
                    <div class="col-12" style="text-align: right;">
                        <button type="close" class="close" style="width: 10px;" (click)="modal.dismiss()">
                            <span>&times;</span>
                        </button>
                    </div>

                    <div class="flex-row start">

                        <div class="flex-column stretch" style="width: 40%;">
                            <div class="div-dispositivo-alquilado">
                                <div class="green-border-div">
                                    <div class="div-img-content">
                                        <div class="flex-column center vcenter">
                                            <img [src]="(imgPreview && imgPreview!='')?rutaImagen+imgPreview:((product.urlImagenes && product.urlImagenes[0] && rutaImagen+product.urlImagenes[0].url)?rutaImagen+product.urlImagenes[0].url:'')">
                                        </div>
                                    </div>
                                </div>
                            </div>
                
                            <div class="flex-row start div-dispositivo-alquilado">
                                <div class="green-border-div min pointer" *ngFor="let imagen of imagesToshow; index as i" [class.mr10] = "i<3 && i+1<imagesToshow.length" (click)="imgPreview = imagen.url">
                                    <div class="div-img-content">
                                        <div class="flex-column center vcenter">
                                            <img src="{{(imagen && imagen.url)?rutaImagen+imagen.url:''}}">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="grow1">
                            <h4 style="font-weight: bold;">{{product.name}}</h4>

                            <div class="ratings">

                                <div class="row">
                                    <!--<div class="col-4">

                                        <star-rating [value]="product.rating" [totalstars]="5" checkedcolor="#5bc500"
                                            uncheckedcolor="gray" size="24px" [readonly]="true"></star-rating>

                                    </div>-->
                                    <div class="col-8">
                                        <!--

        <p class="text-muted">1 valoración de cliente | <a href="#"
            style="color: gray;">Añadir
            una
            valoración</a></p>
        -->
                                    </div>
                                </div>


                            </div>
                            
                            <hr>

                            <div #precio class="mb-2">
                                <h3 style="display:inline; font-weight: bold;">{{priceSelected}} €</h3>
                                <div *ngIf="monthSelected == 1 && buy==false">
                                    <p style="font-size: 80%;"> al mes durante {{monthSelected}} mes. Puede cancelar en
                                        cualquier
                                        momento
                                    </p>
                                </div>
                                <div *ngIf="monthSelected != 1 && buy==false">
                                    <p style="font-size: 80%;"> al mes durante {{monthSelected}} meses. Puede cancelar en
                                        cualquier
                                        momento
                                    </p>
                                </div>
                            </div>


                            <p>{{product.descripcion}}</p>
                            <p><a class="text-muted">SKU: </a>6541119951-2</p>
                            <p><a class="text-muted">CATEGORÍAS: </a>{{product.tipo_dispositivo?product.tipo_dispositivo.name:'-'}}</p>
                            <!--<img class="img-fluid" style="height: 50px; width: 180px;"
                                src="../../../assets/img/productosPrueba/garantia10ç.png">-->
                            <p class="mt-2" style="margin-left: 10px;"><i class="fas fa-truck"></i> Entrega gratuita al día
                                siguiente
                            </p>
                            <div *ngIf="product.articulo_alquileres.length != 0 && buy==false">
                                <p class="mt-2" style="margin-left: 10px;"> Selecciona un <a href="#" style="color: black;">periodo
                                    mínimo
                                    de
                                    alquiler</a></p>
                                    
                                
                                    <ng-template ngFor let-element [ngForOf]="product.articulo_alquileres" let-i="index">
                                        <button *ngIf="element.alquiler_mes && monthSelected == element.alquiler_mes.month && element.alquiler_mes.month != 0" 
                                        class="btn btn-success" (click)="selectMonth(element.alquiler_mes.month, element.alquiler_mes.price)" style="margin-right: 10px;">
                                        <p>{{element.alquiler_mes.month}}+</p>mes
                                        </button>
                                        <button *ngIf="element.alquiler_mes && monthSelected != element.alquiler_mes.month && element.alquiler_mes.month != 0" 
                                        class="btn border-secondary" (click)="selectMonth(element.alquiler_mes.month, element.alquiler_mes.price)" style="margin-right: 10px;">
                                        <p>{{element.alquiler_mes.month}}+</p>mes
                                         </button>
                                    </ng-template>
                            
                            </div>

                            <div *ngIf="product.articulo_alquileres.length == 0 || product.stock <= 0">
                                <p>Lo sentimos, este producto no está disponible para alquilar</p>
                            </div>



                            <hr>

                            <div class="mt-4 mb-4" *ngIf="product.articulo_alquileres.length != 0 && product.stock > 0">

                                <input #cantidad type="number" value="1" min="1" max="{{product.stock}}" step="1" onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                                    style="margin-right: 20px;" />
        
                                <button class="btn btn-dark" (click)="addToCart(cantidad.value)"><i
                                        class="fas fa-shopping-bag"></i> Añadir a la cesta</button>
                            </div>
        

                            <hr>

                            <div #redes class="row">
                                <div class="col-1">
                                    <a href="https://www.facebook.com/CyGitsolutions" target="_blank"><i class="fab fa-facebook fa-2x" style="color: rgb(0, 198, 0);"></i></a>
                                </div>
                                <div class="col-1">
                                    <a href="https://twitter.com/CyGitsolutions" target="_blank"><i class="fab fa-twitter fa-2x" style="color: rgb(0, 198, 0);"></i></a>
                                </div>
                                <div class="col-1">
                                    <a href="https://www.instagram.com/cygitsolutions/" target="_blank"><i class="fab fa-instagram fa-2x" style="color: rgb(0, 198, 0);"></i></a>
                                </div>
                                <div class="col-1">
                                    <a href="https://www.linkedin.com/company/cygsustainabletech" target="_blank"><i class="fab fa-linkedin fa-2x" style="color: rgb(0, 198, 0);"></i></a>
                                </div>
                                <div class="col-1">
                                    <a href="https://www.youtube.com/user/CyGITSolutions" target="_blank"><i class="fab fa-youtube fa-2x" style="color: rgb(0, 198, 0);"></i></a>
                                </div>
                                <div class="col-1">
                                    <a [href]="emailString" target="_top"><i class="fas fa-envelope fa-2x" style="color: rgb(0, 198, 0);"></i></a>
                                </div>

                                <div class="col" *ngIf="deseado == true && logueado == true">
                                    <button (click)="eliminarDeseo(idDeseado)" class="btn"><i style="color: red;" class="fas fa-heart"></i> Eliminar de lista de deseos</button>
                                </div>
                                <div class="col" *ngIf="deseado != true && logueado == true">
                                    <button (click)="addDeseo(product.ID)" class="btn"><i class="far fa-heart"></i> Añadir a lista de deseos</button>
                                </div>

                            </div>
                        </div>
                    </div>



                </div>
            </div>
        </ng-template>

        <div *ngIf="screenWidth!=undefined">


                <button type="button" class="btn btn-secondary btn-seleccionar" (click)="openVerticallyCentered(content)">
                    SELECCIONAR OPCIONES
                </button>

        </div>
    </div>

    <div *ngIf="product != undefined && screenWidth<=768">
        <ng-template #content let-modal>
            <div class="modal-body">
                <div class="flex-column">
                    <div class="col-12" style="text-align: right;">
                        <button type="close" class="close" style="width: 10px;" (click)="modal.dismiss()">
                            <span>&times;</span>
                        </button>
                    </div>

                    <div class="flex-column">

                        <div class="flex-column stretch" style="width: 100%;">
                            <div class="div-dispositivo-alquilado xs">
                                <div class="green-border-div">
                                    <div class="div-img-content">
                                        <div class="flex-column center vcenter">
                                            <img [src]="(imgPreview && imgPreview!='')?rutaImagen+imgPreview:((product.urlImagenes && product.urlImagenes[0] && rutaImagen+product.urlImagenes[0].url)?rutaImagen+product.urlImagenes[0].url:'')">
                                        </div>
                                    </div>
                                </div>
                            </div>
                
                            <div class="flex-row start div-dispositivo-alquilado xs">
                                <div class="green-border-div min pointer" *ngFor="let imagen of imagesToshow; index as i" [class.mr10] = "i<3 && i+1<imagesToshow.length" (click)="imgPreview = imagen.url">
                                    <div class="div-img-content">
                                        <div class="flex-column center vcenter">
                                            <img src="{{rutaImagen+imagen.url}}">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="row text-center">
                        <div class="row">
                            <h4 style="font-weight: bold;">{{product.name}}</h4>
                        </div>
                        <div class="row">

                            <div class="ratings">
                                <!--<div class="row">

                                    <star-rating [value]="product.rating" [totalstars]="5" checkedcolor="#5bc500"
                                        uncheckedcolor="gray" size="24px" [readonly]="true"></star-rating>

                                </div>-->
                                <div class="row">
                                    <!--

        <p class="text-muted">1 valoración de cliente | <a href="#" style="color: gray;">Añadir
            una
            valoración</a></p>
        -->
                                </div>

                                <hr>
                            </div>


                        </div>

                    </div>
                    <div #precio class="mb-2">
                        <h3 style="display:inline; font-weight: bold;">{{priceSelected}} €</h3>
                        <div *ngIf="monthSelected == 1 && buy==false">
                            <p style="font-size: 80%;"> al mes durante {{monthSelected}} mes. Puede cancelar en
                                cualquier
                                momento
                            </p>
                        </div>
                        <div *ngIf="monthSelected != 1 && buy==false">
                            <p style="font-size: 80%;"> al mes durante {{monthSelected}} meses. Puede cancelar en
                                cualquier
                                momento
                            </p>
                        </div>
                    </div>


                    <p>{{product.descripcion}}</p>
                    <p><a class="text-muted">SKU: </a>6541119951-2</p>
                    <p><a class="text-muted">CATEGORÍAS: </a>{{product.tipo_dispositivo?product.tipo_dispositivo.name:'-'}}</p>
                    <!--<img class="img-fluid" style="height: 50px; width: 180px;"
                        src="../../../assets/img/productosPrueba/garantia10ç.png">-->
                    <p class="mt-2" style="margin-left: 10px;"><i class="fas fa-truck"></i> Entrega gratuita al día
                        siguiente
                    </p>
                    <div *ngIf="product.articulo_alquileres.length != 0 && buy==false">
                        <p class="mt-2" style="margin-left: 10px;"> Selecciona un <a href="#" style="color: black;">periodo
                            mínimo
                            de
                            alquiler</a></p>
                            
                            
                            
                            <div class="flex-row start" style="flex-wrap: wrap;">
                                <ng-template ngFor let-element [ngForOf]="product.articulo_alquileres" let-i="index">
                                    <button *ngIf="element.alquiler_mes && monthSelected == element.alquiler_mes.month && element.alquiler_mes.month != 0" class="btn btn-success"
                                    (click)="selectMonth(element.alquiler_mes.month, element.alquiler_mes.price)" style="margin-right: 10px;">
                                    <p>{{element.alquiler_mes.month}}+</p>mes
                                    </button>
                                    <button *ngIf="element.alquiler_mes && monthSelected != element.alquiler_mes.month && element.alquiler_mes.month != 0" class="btn border-secondary"
                                    (click)="selectMonth(element.alquiler_mes.month, element.alquiler_mes.price)" style="margin-right: 10px;">
                                    <p>{{element.alquiler_mes.month}}+</p>mes
                                    </button>
                                </ng-template>
                        </div>
                    </div>
                    
                    <div *ngIf="product.articulo_alquileres.length == 0 || product.stock <= 0">
                        <p>Lo sentimos, este producto no está disponible para alquilar</p>
                    </div>



                    <hr>

                    <div class="mt-4 mb-4" *ngIf="product.articulo_alquileres.length != 0 && product.stock > 0">

                        <input #cantidad type="number" value="1" min="1" max="{{product.stock}}" step="1" onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                            style="margin-right: 20px;" />

                        <button class="btn btn-dark" (click)="addToCart(cantidad.value)"><i
                                class="fas fa-shopping-bag"></i> Añadir a la cesta</button>
                    </div>

                    <hr>

                    <div #redes class="row">
                        <div class="col-1">
                            <a href="https://www.facebook.com/CyGitsolutions" target="_blank"><i class="fab fa-facebook fa-2x" style="color: rgb(0, 198, 0);"></i></a>
                        </div>
                        <div class="col-1">
                            <a href="https://twitter.com/CyGitsolutions" target="_blank"><i class="fab fa-twitter fa-2x" style="color: rgb(0, 198, 0);"></i></a>
                        </div>
                        <div class="col-1">
                            <a href="https://www.instagram.com/cygitsolutions/" target="_blank"><i class="fab fa-instagram fa-2x" style="color: rgb(0, 198, 0);"></i></a>
                        </div>
                        <div class="col-1">
                            <a href="https://www.linkedin.com/company/cygsustainabletech" target="_blank"><i class="fab fa-linkedin fa-2x" style="color: rgb(0, 198, 0);"></i></a>
                        </div>
                        <div class="col-1">
                            <a href="https://www.youtube.com/user/CyGITSolutions" target="_blank"><i class="fab fa-youtube fa-2x" style="color: rgb(0, 198, 0);"></i></a>
                        </div>
                        <div class="col-1">
                            <a [href]="emailString" target="_top"><i class="fas fa-envelope fa-2x" style="color: rgb(0, 198, 0);"></i></a>
                        </div>
                        <div class="col" *ngIf="deseado == true && logueado == true">
                            <button (click)="eliminarDeseo(idDeseado)" class="btn"><i style="color: red;" class="fas fa-heart"></i> Eliminar de lista de deseos</button>
                        </div>
                        <div class="col" *ngIf="deseado != true && logueado == true">
                            <button (click)="addDeseo(product.ID)" class="btn"><i class="far fa-heart"></i> Añadir a lista de deseos</button>
                        </div>
                    </div>

                </div>


            </div>
        </ng-template>


        <button type="button" class="btn btn-secondary btn-seleccionar" (click)="openVerticallyCentered(content)">
            SELECCIONAR OPCIONES
        </button>

    </div>

    <ng-template #modalAddSuccess let-d="dismiss">
        <div class="modal-body text-center">
            <div *ngIf="product != undefined">
                <div *ngIf="cantidad != undefined && cantidad > product.stock">
                    <h4>No tenemos disponible {{cantidad}} artículos de este producto. Añadiremos nuestro máximo</h4>
                    <h3 class="text-center">¡Producto añadido!</h3>
                    <h4 class="text-center">Se ha añadido {{product.name}} x {{product.stock}} a la cesta</h4>
                </div>
                <div *ngIf="cantidad != undefined && cantidad <= product.stock">
                    <h3 class="text-center">¡Producto añadido!</h3>
                    <h4 class="text-center">Se ha añadido {{product.name}} x {{cantidad}} a la cesta</h4>
                </div>
                <div *ngIf="cantidad == undefined">
                    <h3 class="text-center">¡Producto añadido!</h3>
                    <h4 class="text-center">Se ha añadido {{product.name}} x 1 a la cesta</h4>
                </div>
            </div>
        </div>
    </ng-template>
</div>