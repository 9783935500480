import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  	providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

	constructor(public router: Router, public auth: AuthService) {  }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {

		if (!this.auth.isUserLoggedIn()) {
			this.router.navigate(['login'], {replaceUrl: true, queryParams: { returnUrl: this.router.url }});
			return false;
		}
		return true;
	}
}
