import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-how-does-it-work',
  templateUrl: './how-does-it-work.component.html',
  styleUrls: ['./how-does-it-work.component.scss']
})
export class HowDoesItWorkComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
