import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { CartItem } from "../../../models/cart-item";
import { Product } from '../../../models/product';
import { MessageService } from '../../../services/message.service';
import { StorageService } from '../../../services/storage.service';
import { AuxiliarService } from '../../../services/auxiliar.service';
import { IPayPalConfig, ICreateOrderRequest, ITransactionItem } from 'ngx-paypal';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ShippingForm } from 'src/app/models/shippingForm';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/services/user/user.service';
import * as Notiflix from 'notiflix';
 
import { FormGroup, FormBuilder, Validators, FormControl, ValidationErrors } from '@angular/forms';
import { StripeService, StripeCardComponent } from 'ngx-stripe';

@Component({
	selector: 'app-checkout',
	templateUrl: './checkout.component.html',
	styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
	@ViewChild(StripeCardComponent) card: StripeCardComponent | any;

	public selectProvincia = new FormControl();
	public selectPoblacion = new FormControl();
	provinciaSelected: any = null;
	provincias: any = null;
	localidades: any = null;
	dir_envio: any = null;

	cartItems: Array<CartItem>;
	total = 0;
	formTicket: FormGroup;
	formClienteDireccion: FormGroup;
	clienteId: number;
	idProvincia: number | undefined;
	idPoblacion: number | undefined;

	public payPalConfig?: IPayPalConfig;
	ShippingModel = new ShippingForm();

	screenHeight: number | undefined;
	screenWidth: number | undefined;

	msgCentinelaDireccion = '';
	centinelaDireccion = false;

	envio_zipCode = ''
	envio_nombreCompleto = ''
	envio_direccion = ''
	envio_localidad = ''
	envio_provincia = ''
	envio_pais = ''

	selectedProvince: any;
	selectedTown: any;
	msgErrorSearchingProvince = ''
	msgErrorSearchingPoblacion = ''
	poblacionDisabled = true;

	centinelaCheckout = false;

	procesoPago: boolean = false;
	btnGuardar: boolean = true;

	style = {
		base: {
			color: '#000000',
			fontWeight: 400,
			fontFamily: '"Arial Rounded MT Bold", Arial, Helvetica, sans-serif',
			fontSize: '20px',
			'::placeholder': {
				color: '#E3E2EC',
			},
		},
		invalid: {
			color: '#dc3545',
		},
	};

	payForm: FormGroup | any;

	stripeTest: FormGroup | any;

	constructor(
		private messageService: MessageService,
		private storageService: StorageService,
		private auxiliarService: AuxiliarService,
		private spinner: NgxSpinnerService,
		private stripeService: StripeService,
		private formBuilder: FormBuilder,
		private http: HttpClient,
		private snackBar: MatSnackBar,
		private router: Router,
		private userSvc: UserService
	) {
		this.cartItems = [];
		this.formTicket = this.formBuilder.group({
			FK_entities: [environment.fk_entity, Validators.required],
		});
		this.formClienteDireccion = this.formBuilder.group({
			nombre: [this.ShippingModel.name, Validators.required],
			apellido: [this.ShippingModel.surname],
			direccion: [this.ShippingModel.address1, Validators.required],
			direccion_email: [this.ShippingModel.email],
			num_telefono: [this.ShippingModel.telephone],
			otros_datos: [this.ShippingModel.notes],

		});

		this.onResize()
		this.clienteId = Number(localStorage.getItem('idCliente'));
	}

	@HostListener('window:resize', ['$event'])
	onResize(event?: undefined) {
		this.screenHeight = window.innerHeight;
		this.screenWidth = window.innerWidth;
	}

	ngOnInit(): void {
		this.initConfig();
		if (this.storageService.cartExists()) {
			this.cartItems = this.storageService.getCart();
		}
		this.checkStripePayment();
		this.getItem();
		this.total = this.getTotal();

		this.payForm = this.formBuilder.group({
			nombre_envio: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(250)]],
			apellido_envio: ['', [Validators.required, Validators.maxLength(250)]],
			direccion_envio: ['', [Validators.required, Validators.maxLength(250)]],
			localidad_envio: ['', Validators.required],
			provincia_envio: ['', Validators.required],

			company_envio: [''],
			direccion_email_envio: ['', [Validators.required, Validators.email]],
			num_telefono_envio: ['', [Validators.required, Validators.pattern('[- +()0-9]+')]],
			notas_envio: [''],
			direccion2_envio: ['', [Validators.maxLength(250)]],

			//nombre_tarjeta: ['', [Validators.required]],
			amount: [0],
			/*cardNumber: [false, [Validators.required, Validators.requiredTrue]],
			cardCvv: [false, [Validators.required, Validators.requiredTrue]],
			cardExp: [false, [Validators.required, Validators.requiredTrue]],*/
		});

		this.getDireccionEnvio();
		this.getProvincias();

		this.selectProvincia.valueChanges
			.subscribe((value: number) => {
				if (value != null) {
					this.getPoblaciones(value);
				}
			});

		this.stripeTest = this.formBuilder.group({
			name: ['Angular v10', [Validators.required]],
			amount: [1001, [Validators.required, Validators.pattern(/\d+/)]],
		});
	}

	async openPayment(content: any) {
		//Guardamos la dirección
		this.btnGuardar = false;
		Notiflix.Loading.pulse('Preparanto pedido...',{
			svgColor: '#63b421',
		  });
		if (this.dir_envio && this.dir_envio != null) {
			this.userSvc.updateDireccion(this.payForm.value, 2, this.dir_envio.ID).subscribe(
				(data: any) => {
					this.btnGuardar = true;
					this.showPayment();
				},
				(err) => {
					this.btnGuardar = true;
					Notiflix.Loading.remove();
				}
			);
		} else {
			this.userSvc.createDireccion(this.payForm.value, 2, Number(localStorage.getItem('idCliente'))).subscribe(
				(data: any) => {
				  this.btnGuardar = true;
				  this.showPayment();
				},
				(err) => {
				  this.btnGuardar = true;
				  Notiflix.Loading.remove();
				}
			  );
		}
	}

	async showPayment(){
	}

	checkStripePayment(): void {
		var check = this.messageService.getSessionAproval();
		if (check) {
			var items = this.mapItems();
			this.router.navigate(['/completed']);
		}
	}

	mapItems() {
		var res: { name: string; quantity: number; unit_amount: { value: number; currency: string; }; }[] = [];
		this.cartItems.forEach((cartItem: CartItem) => {
			let item = {
				name: cartItem.productName,
				quantity: cartItem.quantity,
				unit_amount: {
					value: cartItem.productPrice,
					currency: 'EUR'
				},
				month: cartItem.month,
			};
			res.push(item);
		});
		return res;
	}

	checkout() {
		var catch_session: any;
		var url = environment.serverURL + '/create-checkout-session';
		var items = this.getItemListStripe();
		this.http.post(url, { items })
			.pipe(
				switchMap(session => {
					catch_session = session;
					let res = this.stripeService.redirectToCheckout({ sessionId: catch_session.sessionId })
					return res
				})
			)
			.subscribe(result => {
				// If `redirectToCheckout` fails due to a browser or network
				// error, you should display the localized error message to your
				// customer using `error.message`.
				if (result.error) {
					alert(result.error.message);
				} else {
					this.router.navigate(['/completed']);
				}
			});
	}

	getItemListStripe(): any[] {
		const items: any[] = [];
		let item = {};
		this.cartItems.forEach((it: CartItem) => {
			let amount = +it.productPrice * 100;
			item = {
				price_data: {
					currency: 'eur',
					product_data: {
						name: it.productName,
					},
					unit_amount: parseInt(amount.toString()),
				},
				quantity: it.quantity,
			};
			items.push(item);
		});
		return items;
	}

	private initConfig(): void {
		this.payPalConfig = {
			currency: 'EUR',
			clientId: environment.clientId,
			createOrderOnClient: (data) => <ICreateOrderRequest>{
				intent: 'CAPTURE',
				purchase_units: [{
					amount: {
						currency_code: 'EUR',
						value: this.getTotal().toString(),
						breakdown: {
							item_total: {
								currency_code: 'EUR',
								value: this.getTotal().toString()
							}
						}
					},
					items: this.getItemListPaypal()
				}]
			},
			advanced: {
				commit: 'true'
			},
			style: {
				label: 'paypal',
				layout: 'vertical'
			},
			onApprove: (data, actions) => {
				this.spinner.show();
				console.log('onApprove - transaction was approved, but not authorized', data, actions);
				actions.order.get().then((details: any) => {
					console.log('onApprove - you can get full order details inside onApprove: ', details);
				});
			},
			onClientAuthorization: (data) => {
				console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point',
					JSON.stringify(data));
				this.router.navigate(['/completed']);
				this.spinner.hide();
			},
			onCancel: (data, actions) => {
				console.log('OnCancel', data, actions);
			},
			onError: err => {
				console.log('OnError', err);
			},
			onClick: (data, actions) => {
				console.log('onClick', data, actions);
			},
		};
	}

	getItem(): void {
		this.messageService.getMessage().subscribe((product: Product) => {
			let exists = false;
			this.cartItems.forEach(item => {
				if (item.productId === product.id) {
					exists = true;
					item.quantity++;
				}
			});
			if (!exists) {
				const cartItem = new CartItem(product);
				this.cartItems.push(cartItem);
			}
			this.total = this.getTotal();
			this.storageService.setCart(this.cartItems);
		});
	}

	getItemListPaypal(): any[] {
		const items: any[] = [];
		let item = {};
		this.cartItems.forEach((it: CartItem) => {
			item = {
				name: it.productName,
				quantity: it.quantity,
				unit_amount: { value: it.productPrice, currency_code: 'EUR' }
			};
			items.push(item);
		});
		return items;
	}

	getTotal(): number {
		let total = 0;
		this.cartItems.forEach(item => {
			total += item.quantity * item.productPrice;
		});
		return +total.toFixed(2)
	}

	emptyCart(): void {
		this.cartItems = [];
		this.total = 0;
		this.storageService.clear();
	}

	deleteItem(i: number): void {
		if (this.cartItems[i].quantity > 1) {
			this.cartItems[i].quantity--;
		} else {
			this.cartItems.splice(i, 1);
		}
		this.total = this.getTotal();
		this.storageService.setCart(this.cartItems);
	}


	openSnackBar() {
		console.log("ABRIR SNACK");
		this.snackBar.open('An error occurred with Stripe', 'Close');
	}

	redondear(num: number) {
		let res = Math.round(num * 100) / 100
		return res
	}

	guardarDatos(): void {
		const formValue = this.formTicket.value;
		this.auxiliarService.crearTicket(formValue).subscribe(
			(data: any) => {
				console.log(data)
			},
			(err) => {
				console.log('Error -> ' + err)
			}
		);
	}

	send(): void {
		this.ShippingModel.province = this.selectedProvince.id
		this.idProvincia = this.selectedProvince.id
		this.ShippingModel.city = this.selectedTown.id
		this.idPoblacion = this.selectedTown.id

		console.log(this.ShippingModel.province)
		if (this.ShippingModel.name != null && this.ShippingModel.surname != null && this.ShippingModel.country != null && this.ShippingModel.address1 != null && this.ShippingModel.city != null && this.ShippingModel.province != null && this.ShippingModel.zipCode != null && this.ShippingModel.telephone != null && this.ShippingModel.email != null) {
			console.log(this.ShippingModel)
			this.clienteDireccion(this.clienteId)

		} else {
			console.log("Faltan cositas")
		}
	}


	clienteDireccion(idCliente: number): void {
		this.auxiliarService.getClienteDireccion(idCliente).subscribe(
			(data: any) => {
				if (data.length == 0) {
					console.log(data)
					if (this.idProvincia != undefined && this.idPoblacion != undefined) {

						this.auxiliarService.crearClienteDireccion(this.ShippingModel, idCliente, this.idProvincia, this.idPoblacion).subscribe(
							(data: any) => {
								console.log(data)
								this.centinelaCheckout = true;
							},
							(err) => {
								console.log('Error -> ' + err)
							}
						);
					}
				} else {
					this.centinelaCheckout = true;
				}
			},
			(err) => {
				console.log(err)
				if (err.status == 404) {
					console.log(this.ShippingModel)
					if (this.idProvincia != undefined && this.idPoblacion != undefined) {
						this.auxiliarService.crearClienteDireccion(this.ShippingModel, idCliente, this.idProvincia, this.idPoblacion).subscribe(
							(data: any) => {
								console.log(data)
							},
							(err) => {
								console.log('Error -> ' + err)
							}
						);
					}
				}
			}
		);
	}

	getDireccionEnvio() {
		this.auxiliarService.getClienteDireccionEnvio(Number(localStorage.getItem('idCliente'))).subscribe(
			(data: any) => {
				if (data) {
					this.dir_envio = data;

					this.payForm.get('nombre_envio')?.setValue(data.nombre);
					this.payForm.get('apellido_envio')?.setValue(data.apellido);
					this.payForm.get('direccion_envio')?.setValue(data.direccion);
					this.payForm.get('localidad_envio')?.setValue(data.localidad?.idpoblacion);
					this.payForm.get('provincia_envio')?.setValue(data.provincia?.idprovincia);
					this.payForm.get('num_telefono_envio')?.setValue(data.num_telefono);
					this.payForm.get('direccion_email_envio')?.setValue(data.direccion_email);
					this.payForm.get('notas_envio')?.setValue(data.otros_datos);

					if (data.id_provincia) {
						this.getPoblaciones(data.id_provincia);
					}
				}
			},
			(err) => {
				console.log('Error -> ' + err)
			}
		);
	}

	getProvincias(idSelect: number = 0) {
		this.auxiliarService.getProvincias().subscribe(
			(data: any) => {
				if (data) {
					this.provincias = data;

					if (idSelect && idSelect != 0) {
						this.selectProvincia.setValue(idSelect);
					}
				}
			},
			(err) => {
				console.log('Error -> ' + err)
			}
		);
	}

	getPoblaciones(idProvincia: number, idSelect: number = 0) {
		this.auxiliarService.getPoblaciones(idProvincia).subscribe(
			(data: any) => {
				if (data) {
					this.localidades = data;

					if (this.localidades && this.localidades.length == 1) {
						this.selectPoblacion.setValue(this.localidades[0].idpoblacion);
					}
				}
			},
			(err) => {
				console.log('Error -> ' + err)
			}
		);
	}





}
