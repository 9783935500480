<div class="container-lg mt-4 mb-4 d-flex justify-content-center">
    <h3 style="color: green; margin-right: 10px;">Cesta de la compra</h3>
    <h3 style="color: #808080;">
        <i class="fa fa-angle-right" aria-hidden="true"></i>
        Checkout
        <i class="fa fa-angle-right" aria-hidden="true"></i>
        Completar alquiler
    </h3>
</div>
<div *ngIf="screenWidth != undefined">

    <div *ngIf="screenWidth > 1200">



        <div class="row">

            <div *ngIf="cartItems.length < 1">
                <div class="alert alert-success text-center">
                    <h4>El carrito está vacío</h4>
                </div>

            </div>

            <div class="col-8" *ngIf="cartItems.length > 0">

                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col"></th>
                            <th scope="col">DISPOSITIVO</th>
                            <th scope="col">PRECIO</th>
                            <th scope="col">MESES</th>
                            <th scope="col">CANTIDAD</th>
                            <th scope="col">SUBTOTAL</th>
                            <th scope="col">ELIMINAR</th>
                        </tr>
                    </thead>
                    <tbody class="text-center">
                        <tr *ngFor="let item of cartItems; index as i">
                            <td><img src="{{item.imgUrl}}" width="100px" height="100px" class="img-fluid"
                                    style="border: 1px solid; color: rgb(0, 198, 0); min-width: 55px;"></td>
                            <td>{{item.productName}}</td>
                            <td>{{item.productPrice}} €</td>
                            <td *ngIf="item.month == 0">-</td>
                            <td *ngIf="item.month != 0">{{item.month}}</td>
                            <td>{{item.quantity}}</td>
                            <td *ngIf="item.month != 0">
                                {{item.productPrice}} € <h6 style="margin-left: 25px; color: #808080; font-size: 10px;">
                                    al
                                    mes
                                </h6>
                            </td>
                            <td *ngIf="item.month == 0">
                                {{item.productPrice}} € 
                                
                            </td>
                            <td>
                                <a style="cursor: pointer;"><i class="far fa-trash-alt fa-2x"
                                        (click)="deleteItem(i)"></i></a>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <!--

    
    <form>
        <div class="form-group">
            <div class="row mt-4">
                <div class="col-3">
                    <input class="form-control" type="text" placeholder="DNI / NIE">
                </div>
                            <div class="col-3">
                                <input class="form-control" type="text" placeholder="Código de cupón">
                            </div>
                            <div class="col-3">
                                <button type="submit" class="btn btn-dark">APLICAR CUPÓN</button>
                            </div>
                            <div class="col-3">
                                <button type="submit" class="btn btn-dark">ACTUALIZAR CESTA</button>
                            </div>
                        </div>
                    </div>
                </form>
                
            -->
            </div>



            <div class="col-4 border border-secondary mb-4" *ngIf="cartItems.length > 0">
                <h4 style="margin-top: 15px; margin-left: 15px; font-weight: bolder;"> TOTAL </h4>

                <div class="row" style="margin-left: 30px; margin-top: 30px;">
                    <div class="col">
                        <h5 style=" font-weight: bolder;">Subtotal</h5>
                    </div>

                    <div class="col">
                        <h5 style="color: gray;">{{total}} €</h5>
                    </div>
                </div>

                <hr>

                <div class="row" style="margin-left: 30px; margin-top: 30px;">
                    <div class="col">
                        <h5 style=" font-weight: bolder;">Total</h5>
                    </div>

                    <div class="col">
                        <h5>{{total}} € <h6 style="margin-left: 40px; color: #808080; font-size: 10px;">al mes </h6>
                        </h5>
                    </div>
                </div>

                <div class="row mt-4 mb-4">
                    <div class="d-flex justify-content-center">
                        <button routerLink="/checkout" type="button" class="btn btn-dark">REALIZAR PEDIDO <i
                                class="fas fa-arrow-right"></i> </button>
                    </div>
                </div>

                <div class="row mt-4 mb-4">
                    <div class="d-flex justify-content-center">
                        <button class="btn btn-outline-danger" (click)="emptyCart()">
                            <i class="fas fa-ban"></i> VACIAR EL CARRITO </button>
                    </div>


                </div>



            </div>


        </div>

    </div>


    <div *ngIf="screenWidth <= 1200">

        <div class="row">

            <div *ngIf="cartItems.length < 1">
                <div class="alert alert-success text-center">
                    <h4>El carrito está vacío</h4>
                </div>

            </div>

            <div class="col" *ngIf="cartItems.length > 0" style="margin-right: 10px; margin-left: 10px;">

                <div class="row" *ngFor="let item of cartItems; index as i">
                    <div class="col-6">

                        <a href="/products/{{item.productId}}"><img class="img-fluid mt-4"
                                style="border: 1px solid; color: rgb(0, 198, 0);" [src]="item.imgUrl"> </a>

                    </div>
                    <div class="col-6 mt-4">
                        <p class="nombre">{{item.productName}}</p>

                        <div #precio class="mb-2">
                            <p *ngIf="item.month != 0">PRECIO: {{item.productPrice}} € al mes</p>
                            <p *ngIf="item.month == 0">PRECIO: {{item.productPrice}} €</p>

                            <h6 style="margin-left: 25px; color: #808080; font-size: 10px;"></h6>

                        </div>
                        <p *ngIf="item.month != 0" class="mes">{{item.month}} MESES</p>
                        <p class="cantidad">CANTIDAD: {{item.quantity}}</p>

                        <button type="submit" class="btn btn-sm btn-danger mt-2" (click)="deleteItem(i)"><i
                                class="far fa-trash-alt fa-2x"></i>ELMIMINAR OBJETO</button>

                    </div>
                </div>

                <hr>
                <!--

                    <form>
                        <div class="form-group text-center">
                            <div class="row mt-4">
                                <div class="col-6">
                                    <input class="form-control" type="text" placeholder="DNI / NIE">
                                    <input class="form-control mt-2" type="text" placeholder="Código de cupón">
                                </div>
                                <div class="col-6">
                                    <button type="submit" class="btn btn-dark">APLICAR CUPÓN</button>
                                </div>
                            </div>
                        </div>
                    </form>
                -->

            </div>
        </div>
        <div class="row">
            <div class="col-1"></div>
            <div class="col-10 text-center border border-secondary mt-4 mb-4" *ngIf="cartItems.length > 0">
                <h3 style="margin-top: 15px; margin-left: 15px; font-weight: bolder;"> TOTAL </h3>

                <div class="row" style="margin-left: 30px; margin-top: 30px;">
                    <div class="col">
                        <h5 style=" font-weight: bolder;">Subtotal</h5>
                    </div>

                    <div class="col">
                        <h5 style="color: gray;">{{total}} €</h5>
                    </div>
                </div>

                <hr>

                <div class="row" style="margin-left: 30px; margin-top: 30px;">
                    <div class="col">
                        <h5 style=" font-weight: bolder;">Total</h5>
                    </div>

                    <div class="col">
                        <h5>{{total}} € <h6 style="margin-left: 40px; color: #808080; font-size: 10px;">al mes </h6>
                        </h5>
                    </div>
                </div>

                <div class="row mt-4 mb-4">
                    <div class="d-flex justify-content-center">
                        <button routerLink="/checkout" type="button" class="btn btn-dark">REALIZAR PEDIDO <i
                                class="fas fa-arrow-right"></i> </button>
                    </div>
                </div>

                <div class="row mt-4 mb-4">
                    <div class="d-flex justify-content-center">
                        <button class="btn btn-outline-danger" (click)="emptyCart()">
                            <i class="fas fa-ban"></i> VACIAR EL CARRITO </button>
                    </div>


                </div>

                <div class="col-1"></div>

            </div>

        </div>


    </div>

</div>