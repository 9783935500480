<div class="mt-4">

    <div *ngIf="subdomain">
        <div class="row">
            <div class="col-4"></div>
            <div class="col-4">
                <img class="img-fluid mb-4" [src]="subdomainImage">
            </div>
        </div>
    </div>

    <h3 class="text-center mt-4">INICIE SESIÓN</h3>

    <form #loginForm="ngForm" (submit)="send()">
        <div class="form-group">
            <div class="row mt-4">
                <div class="col-3"></div>
                <div class="col text-center">
                    <h6 class="text-muted">Correo electrónico</h6>

                    <input matInput class="form-control" #email maxlength="80" [(ngModel)]="LoginModel.email"
                        name="Email" required>
                    <h6 class="text-muted mt-4 text-center">Contraseña</h6>
                    <input matInput class="form-control" type="password" #password maxlength="80"
                        [(ngModel)]="LoginModel.password" name="Password" required>
                    <button type="submit" class="btn btn-dark mb-4 mt-4">Entrar</button>
                </div>
                <div class="col-3"></div>
            </div>

        </div>
    </form>

    <div class="row mt-4" *ngIf="msgErrorLogin && msgErrorLogin !== ''">
        <div class="col-2"></div>
        <div class="col-8">
            <div class="alert alert-danger" role="alert">
                {{msgErrorLogin}}
            </div>
        </div>
        <div class="col-2"></div>

    </div>


    <div class="text-center mt-4">
        <p>¿Aún no estás registrado?</p>
        <button type="submit" class="btn btn-dark mb-4" routerLink="/register">Registrarse</button>
    </div>
    <div class="text-center mt-2">
        <p>¿No recuerdas tu contraseña?</p>
        <button type="submit" class="btn btn-dark mb-4" routerLink="/remember">Recuperar contraseña</button>
    </div>
</div>