import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CartItem } from 'src/app/models/cart-item';
import { Product } from 'src/app/models/product';
import { MessageService } from 'src/app/services/message.service';
import { ProductService } from 'src/app/services/product.service';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as Notiflix from 'notiflix';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { AuxiliarService } from 'src/app/services/auxiliar.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent implements OnInit {

  //product: Product | undefined;
  relacionados: any;
  product: any = null;
  monthSelected: number | undefined;
  priceSelected: number = 0;
  cantidad: number | undefined;
  productId: number;
  products: Product[] = [];
  meses: number[] = []
  mesesPrecio = new Array();
  emailString: string;
  categoria: String | undefined;
  mesesTotales: number[] = []
  precios: number[] = []

  rutaImagen: string = environment.API_URL+'/';
  imgPreview: string = '';
  imagesToshow: Array<any> = [];

  productosMostrar = new Array();

  screenHeight: number | undefined;
  screenWidth: number | undefined;

  url = '';
  buy: boolean = false;
  noDisponible: boolean = false

  listaDeseos = [];
  deseado: Boolean = false;
  idDeseado: Number = 0;


  @ViewChild("modalAddSuccess") modalAddSuccess: ElementRef | undefined

  logueado = false;

  constructor(
    private modalService: NgbModal,
    private productService: ProductService,
    private storageSvc: StorageService,
    private _route: ActivatedRoute,
    private router: Router,
    private auxiliar: AuxiliarService,
    private authSvc: AuthService,
  ) {
    this.emailString = "mailto:flex@cygit.com";
    this.productId = Number(this._route.snapshot.paramMap.get('id'));

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    try{
      this.url = this._route.snapshot.url[1].path
      if(this.url == 'buy'){
        this.buy = true;
      }
    }
    catch(error){
      console.log('Estás en el apartado de alquiler')
    }
  }

  ngOnInit(): void {
    this.logueado = this.authSvc.isUserLoggedIn()
    //this.cargarProductos();
    Notiflix.Loading.pulse('Cargando productos...',{
      svgColor: '#63b421',
    });
    this.loadProducto();
    this.loadRelacionados();
    this.onResize();
    // this.loadWishesList();
  }

  loadProducto(){
    if(this._route.snapshot.paramMap.get('id')){
      this.productId = Number(this._route.snapshot.paramMap.get('id'));

      let compraAlquila = 1;
      if(this.buy != false){
        compraAlquila = 0
      }

      this.productService.getProductoById(this.productId, compraAlquila).subscribe(
        (data: any) => {
          this.product = data[0];

          if(this.buy == true){
            this.priceSelected = this.product.precioCompra;
          }else{
            if(this.product && this.product.articulo_alquileres && this.product.articulo_alquileres[0] && this.product.articulo_alquileres[0].alquiler_mes){
              this.monthSelected = this.product.articulo_alquileres[0].alquiler_mes.month;
              this.priceSelected = this.product.articulo_alquileres[0].alquiler_mes.price;
            }
          }
          if(this.product && this.product.urlImagenes){
            for(let i = 0; i < this.product.urlImagenes.length; i++){
              if(this.imagesToshow.length < 4 && this.product.urlImagenes[i].url){
                this.imagesToshow.push(this.product.urlImagenes[i]);
              }
            }
          }
          if(this.product.stock <= 0){
            this.noDisponible = true;
          }else{
            for (let i=0; i<this.product.articulo_alquileres.length; i++){
              if(this.product.articulo_alquileres[i].alquiler_mes != undefined){
                //this.priceSelected = this.product.articulo_alquileres[i].alquiler_mes.price
                this.noDisponible = false;
              }
            }
          }
          Notiflix.Loading.remove();
        },
        (err) => {
          console.log('Error: '+err)
        }
      );
    }
  }

  loadRelacionados(){
    if(this._route.snapshot.paramMap.get('id')){
      
      let compraAlquila = 1;
      if(this.buy != false){
        compraAlquila = 0
      }

      this.productService.getRelacionados(this.productId, compraAlquila).subscribe(
        (data: any) => {
          this.relacionados = data;
          console.log('Relacionados');
          console.log(this.relacionados);
        },
        (err) => {
          console.log('Error: '+err)
        }
      );
    }
  }

  removeAccents(str: String){
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  } 

  @HostListener('window:resize', ['$event'])
  onResize(event?: undefined) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  addToCart(cantidad: string): void {
    this.cantidad = Number(cantidad);
    if(this.buy != false){
      this.monthSelected = 0;
    }
    var maximoAlquilados = false;
    if (this.product != undefined) {
      var auxProducto = {
        id: this.product.ID,
        name: this.product.name,
        price: this.priceSelected,
        quantity: Number(cantidad),
        imgUrl: (this.product.urlImagenes && this.product.urlImagenes[0] && this.product.urlImagenes[0].url)?[this.rutaImagen+this.product.urlImagenes[0].url]:[''],
        monthsPossible: [this.monthSelected]
      };

      if(localStorage.getItem('cart') == null){
        localStorage.setItem('cart', '[]');
      }
      console.log(this.product.name + ' añadido')
      let res = new CartItem(auxProducto);
      if(cantidad <= this.product.stock){
        res.quantity = Number(cantidad);
      }else{
        res.quantity = this.product.stock;
      }

      let cartItems = this.storageSvc.getCart();
      let productoMetido = false;;
      if (cartItems.length == 0) {
        cartItems.push(res)
        productoMetido = true;
      } else {
        if(this.buy != true){
          productoMetido = true;
          maximoAlquilados = true;
        }

        for (let i = 0; i < cartItems.length && productoMetido == false; i++) {
          if(cartItems[i].month > 0){
            productoMetido = true;
            maximoAlquilados = true;
          }
          
          if (cartItems[i].productId == res.productId && cartItems[i].month == res.month) {
            productoMetido = true;
            if(cartItems[i].quantity + res.quantity <= this.product.stock){
              this.cantidad = res.quantity
              cartItems[i].quantity += res.quantity;
            }else{
              this.cantidad = this.product.stock - cartItems[i].quantity
              cartItems[i].quantity = this.product.stock
            }
          }
        }

        if (productoMetido == false) {

          cartItems.push(res)

        }
      }

      this.storageSvc.setCart(cartItems);

      if(maximoAlquilados){
        if(this.monthSelected && this.monthSelected > 0){
          console.log(res.month);
          Notify.warning('Solo puede agregar alquileres en carritos vacíos', {position: 'left-bottom'});
        }else{
          Notify.warning('No se pueden agregar más productos al carrito con alquileres', {position: 'left-bottom'});
        }
      }else{
        this.modalService.dismissAll()
        Notify.success('Producto agregado al carrito', {position: 'left-bottom', success: {background: '#63b421'}});
      }

      //this.modalService.open(this.modalAddSuccess, { centered: true });
      //setTimeout(() =>{ 
        //this.modalService.dismissAll()
      //}, 3000); 
      
    }
  }

  selectMonth(month: number, price: number): void {
    console.log('Seleccionado ' + month + ' meses')
    this.monthSelected = month;
    this.priceSelected = price;
  }

  /*getAlquiler(productId: number) {
    this.productService.getAlquiler(productId).subscribe(
      (data) => {
        if (this.product != undefined) {
          this.mesesPrecio = data;
          this.monthSelected = data[0].meses
          this.product.price = data[0].precio
          for (let i = 0; i < data.length; i++) {
            this.meses.push(data[i].meses);
          }
          var sortedArray: number[] = this.meses.sort((n1, n2) => n1 - n2);
          this.meses = sortedArray;
          console.log(this.meses)
          console.log(data)
        }
      },
      (err) => {
        console.log('Error -> ' + err)
      }
    );
  }

  cargarProductos() {
    return new Promise(response => {
      setTimeout(() => {
        console.log('Promesita')
        this.mandar()
        setTimeout(() => {
          console.log('Promesita')
          this.productId = Number(this._route.snapshot.paramMap.get('id'));
          this.product = this.productService.getById(this.productId);
          this.onResize()
          this.getAlquiler(this.productId);
          if (this.product != undefined) {
            this.products = []
            for (let i = 0; this.productService.products.length; i++) {
              if (this.productService.products[i].type == this.product.type && this.productService.products[i].id != this.product.id) {
                this.products.push(this.productService.products[i])

                if(this.productosMostrar.length<4){
                  this.productosMostrar.push(this.productService.products[i]);
                }
              }
            }
          }
          response(true)
        }, 300)

        response(true)
      }, 500)
    });
  }

  async mandar(): Promise<any> {
    await this.verMovimientosAlmacen()
  }

  async verMovimientosAlmacen(): Promise<void> {

    let idArticulos: number[] = []
    for (let i = 0; i < this.productService.movimientos.length; i++) {
      if (!idArticulos[this.productService.movimientos[i]['id_inventario']]) {
        idArticulos.push(this.productService.movimientos[i]['id_inventario'])
      }
    }
    let map = this.productService.bucleStocks()
    console.log(map)
    for (let i = 0; i < idArticulos.length; i++) {
      let informacionProducto = []
      this.getAlquiler(idArticulos[i])
      this.productService.getProductoId(idArticulos[i]).subscribe(
        (data: any) => {
          informacionProducto = data
          if (this.categoria == undefined) {

            let stock = map.get(idArticulos[i])
            if (stock != undefined) {
              let imagenes = []
              for (let z = 0; z < informacionProducto['imagenes'].length; z++) {
                imagenes.push(environment.API_URL + '/' + informacionProducto['imagenes'][z]['url'])
              }
              console.log(imagenes)
              let producto = new Product(idArticulos[i], informacionProducto['nombre'], informacionProducto['descripcion'], this.precios[0], imagenes, informacionProducto['tipo']['nombre'], 5, this.meses, stock[0], informacionProducto['marca']['nombre'])
              let centinela = false
              for (let j = 0; j < this.productService.products.length; j++) {
                if (this.productService.products[j].id == producto.id) {
                  centinela = true
                }
              }

              if (centinela != true) {
                this.productService.products.push(producto)
                this.products = this.productService.products
              }
            }
          } else {
            if(informacionProducto['tipo']['nombre'] != null){

              if (this.removeAccents(this.categoria.toUpperCase()) == this.removeAccents(informacionProducto['tipo']['nombre'].toUpperCase())) {
                let stock = map.get(idArticulos[i])
                if (stock != undefined) {
                  let imagenes = []
                  for (let z = 0; z < informacionProducto['imagenes'].length; z++) {
                    imagenes.push(environment.API_URL + '/' + informacionProducto['imagenes'][z]['url'])
                  }
                  console.log(imagenes)
                  let producto = new Product(idArticulos[i], informacionProducto['nombre'], informacionProducto['descripcion'], stock[1], imagenes, informacionProducto['tipo']['nombre'], 5, [1, 3, 12], stock[0], informacionProducto['marca']['nombre'])
                  let centinela = false
                  for (let j = 0; j < this.productService.products.length; j++) {
                    if (this.productService.products[j].id == producto.id) {
                      centinela = true
                    }
                  }

                  if (centinela != true) {
                    this.productService.products.push(producto)
                    this.products = this.productService.products
                  }
                }
              }
            }
          }
        },
        (err) => {

          console.log('Error -> ' + err)
        }
      );

    }

  }*/

  showImage(urlImagen: string){
    this.imgPreview = urlImagen;
  }

  navigate(url: string) {
		this.router.navigate([url]);
	}

  loadWishesList(){
    if(localStorage.getItem('idCliente') != null){

      this.auxiliar.getDeseosCliente(Number(localStorage.getItem('idCliente'))).subscribe(
        (data: any) => {
          this.listaDeseos = data;
          console.log(this.listaDeseos)
          this.compruebaDeseado();
        },
        (err) => {
          console.log('Error: '+err)
        }
      );
    }
  }

  compruebaDeseado(){
    for(let i=0; i<this.listaDeseos.length; i++){
      if(this.productId == this.listaDeseos[i]['elemento']['ID']){
        this.deseado = true;
        this.idDeseado = this.listaDeseos[i]['ID'];
      }
    }
  }


  addDeseo(elementoId: Number){
    if(localStorage.getItem('idCliente') != null){

      this.auxiliar.crearDeseo(Number(localStorage.getItem('idCliente')), elementoId).subscribe(
        (data: any) => {
          this.deseado = !this.deseado;
          console.log(data)
          this.deseado = true;
          this.idDeseado = data.ID;
        },
        (err) => {
          console.log('Error: '+err)
        }
      );
    }
  }

  eliminarDeseo(idDeseo: Number){
    if(localStorage.getItem('idCliente') != null){

      this.auxiliar.deleteDeseo(idDeseo).subscribe(
        (data: any) => {
          this.idDeseado = 0;
          this.deseado=false;
          console.log(data)
          
        },
        (err) => {
          console.log('Error: '+err)
        }
      );
    }
  }



}
