import { AfterViewInit, Component, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuxiliarService } from 'src/app/services/auxiliar.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit, AfterViewInit {

  screenHeight: number | undefined;
  screenWidth: number | undefined;

  emailRegistrado: string = ''
  email: string = ''

  emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  messageError = "";

  @ViewChild('contenido') contenido: TemplateRef<any> | undefined;
  
  constructor(
    public modal: NgbModal,
    private auxiliarSvc: AuxiliarService,
  ) {
    this.onResize()
   }


  ngOnInit(): void {
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?: undefined) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  ngAfterViewInit(): void {
    if (this.contenido != undefined) {
      setTimeout(() => {
        this.modal.open(this.contenido, { centered: true, size: 'xl' })
      }, 1500);
    }
  }

  crearSub() {
    let email = (<HTMLInputElement>document.getElementById('emailModal')).value
    if (this.emailRegex.test(email)) {
      this.messageError = '';
      this.auxiliarSvc.getSuscripcion(email).subscribe(
        (data) => {
          if (data != null) {
            this.emailRegistrado = email + ' ya ha sido registrado con anterioridad'
          } else {
            this.auxiliarSvc.crearSuscripcion(email).subscribe(
              (data) => {
                this.emailRegistrado = email + ' ha sido registrado con éxito en nuestra base de datos'
              },
              (err) => {
                console.log(err)
              }
            );
          }
        },
        (err) => {
          console.log(err)
        }
      );
      (<HTMLInputElement>document.getElementById('emailModal')).value = ''

    }else{
      this.messageError = 'Introduzca un correo electrónico correcto';
    }


  }

  onClick(e: any) {
    let centinela = String(e.originalTarget.checked == false)
    localStorage.setItem('modalSuscripcion', centinela)
  }

}
