import { HttpClient } from '@angular/common/http';
import { Component, Injectable, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuxiliarService } from 'src/app/services/auxiliar.service';

@Component({
  selector: 'app-budget-pay',
  templateUrl: './budget-pay.component.html',
  styleUrls: ['./budget-pay.component.css']
})

@Injectable()
export class BudgetPayComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private auxSvc: AuxiliarService,
  ) { 
    

  }

  ngOnInit(): void {
    this.auxSvc.callbackTPV();
  }

  navigate(url: string, params?: any) {
    if (params) {
      this.router.navigate([url], {queryParams: params });
    }
    else {
      this.router.navigate([url]);
    }
	}

}
