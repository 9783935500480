import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { AuxiliarService } from 'src/app/services/auxiliar.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-wishes',
  templateUrl: './wishes.component.html',
  styleUrls: ['./wishes.component.css']
})
export class WishesComponent implements OnInit {

  screenHeight: number | undefined;
  screenWidth: number | undefined;
  listaDeseos: any;
  rutaImagen: string = environment.API_URL+'/';

  constructor(
    private router: Router,
    private auxiliar: AuxiliarService,
    private authSvc: AuthService,

  ) { 
    this.onResize();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?: undefined) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  ngOnInit(): void {
    // this.loadWishesList()
  }

  navigate(url: string, params?: any) {
    if (params) {
      this.router.navigate([url], {queryParams: params });
    }
    else {
      this.router.navigate([url]);
    }
	}

  loadWishesList(){
    //Pasamos 1 ya que aquí se muestran solo los más alquilados
    if(localStorage.getItem('idCliente') != null){

      this.auxiliar.getDeseosCliente(Number(localStorage.getItem('idCliente'))).subscribe(
        (data: any) => {
          this.listaDeseos = data;
        },
        (err) => {
          console.log('Error: '+err)
        }
      );
    }
  }

  deleteDeseo(id:any){
    this.auxiliar.deleteDeseo(id).subscribe(
      (data: any) => {
        this.loadWishesList();
      },
      (err) => {
        console.log('Error: '+err)
      }
    );
  }


  cerrarSesion(): void {
    this.authSvc.logout()
  }


}
