<img class="img-fluid mt-4 mb-4" src="../../../../assets/img/repara/banner-repara.png" alt="Responsive image">
<img #tituloPagina class="img-fluid mb-4 px-2" src="../../../../assets/img/repara/modelo-serie.png" style="max-height: 92px;" alt="Responsive image">

<div class="container-fluid">
    <div class="row">
        <div class="col">
            <div class="row">
                <div class="col">
                    <p class="text-justify font-weight-normal text-muted">
                        Si ha pasado un tiempo desde que compraste el dispositivo, es posible que hayas olvidado algunos datos sobre el mismo, 
                        como por ejemplo qué modelo es exactamente, o su número de serie.
                    </p>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-11">
                    <div class="border-top border-1 mb-3 separador"></div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <p class="text-justify font-weight-normal text-muted">
                        En las siguientes secciones te ayudamos a encontrar esa referencia en tu pc, portátil, smartphone o tablet:
                    </p>
                </div>
            </div>

            <div class="row mb-5 mt-3">
                <div class="col">
                    <ngb-accordion #acc="ngbAccordion" [closeOthers]="true" activeIds="panel-1">
                        <ngb-panel id="panel-1" cardClass="panel-flex">
                            <ng-template ngbPanelHeader let-opened="opened">
                                <div class="d-flex align-items-center" (click)="acc.toggle('panel-1')">
                                    <i *ngIf="opened" class="fas fa-minus icon"></i>
                                    <i *ngIf="!opened" class="fas fa-plus icon"></i>
                                    <h6 class="m-0 ml-2">En la parte trasera del dispositivo</h6>
                                </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <div>
                                    <p class="text-justify font-weight-normal text-muted text-panel">
                                        El método más rápido para conocer el modelo y otros datos más sobre tu dispositivo es mirar en la
                                        parte trasera del mismo. Si le das la vuelta verás que en la parte inferior de la carcasa aparecen un
                                        conjunto de letras y números que tiene un significado. Normalmente, lo primero que verás es el
                                        número de modelo, seguido del nombre de fabricante y diseñador, y número IMEI, de gran importancia.
                                    </p>
                                    <p class="text-justify font-weight-normal text-muted text-panel">
                                        Sin embargo, no todos los dispositivos suelen contar con esta información clave en la parte inferior de 
                                        la carcasa. Si este es tu caso, no te preocupes, dispones de más herramientas para averiguar el modelo.
                                    </p>
                                    <div class="d-flex justify-content-center my-3">
                                        <img class="img-fluid" src="../../../../assets/img/repara/modelo01.jpg">
                                    </div>
                                </div>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel id="panel-2" cardClass="panel-flex">
                            <ng-template ngbPanelHeader let-opened="opened">
                                <div class="d-flex align-items-center" (click)="acc.toggle('panel-2')">
                                    <i *ngIf="opened" class="fas fa-minus icon"></i>
                                    <i *ngIf="!opened" class="fas fa-plus icon"></i>
                                    <h6 class="m-0 ml-2">A través de los ajustes del dispositivo</h6>
                                </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <div>
                                    <p class="text-justify font-weight-normal text-muted text-panel">
                                        Los ajustes de tu teléfono móvil siempre suelen guardar la información fundamental del mismo, es el
                                        lugar al que debes acudir sin falta cuando necesites un dato concreto.
                                    </p>

                                    <div class="mb-2">
                                        <ngb-accordion #accAndroid="ngbAccordion">
                                            <ngb-panel id="panel-android" cardClass="subpanel-flex">
                                                <ng-template ngbPanelHeader>
                                                    <div class="d-flex align-items-center" (click)="accAndroid.toggle('panel-android')">
                                                        <span>En dispositivos Android</span>
                                                    </div>
                                                </ng-template>
                                                <ng-template ngbPanelContent>
                                                    <div class="bloq-subpanel">
                                                        <p class="m-0 text-justify font-weight-normal text-muted text-panel">
                                                            <b>1.</b> Ve a <b>Ajustes</b> > <b>Acerca del teléfono</b>
                                                        </p>
                                                    </div>
                                                    <div class="border-top border-1" style="width: 100%;"></div>
                                                    <div class="bloq-subpanel">
                                                        <p class="m-0 text-justify font-weight-normal text-muted text-panel">
                                                            <b>2.</b> Este apartado te mostrará el número de modelo, el número de serie y el número IMEI de tu teléfono móvil
                                                        </p>
                                                        <div class="d-flex justify-content-center my-3">
                                                            <img class="img-fluid" src="../../../../assets/img/repara/modelo02.png">
                                                        </div>
                                                    </div>
                                                    <div class="border-top border-1" style="width: 100%;"></div>
                                                    <div class="bloq-subpanel">
                                                        <p class="m-0 text-justify font-weight-normal text-muted text-panel">
                                                            <b>3.</b> Si no es ese el caso, porque algunos fabricantes pueden esconder aún más la información, entra en la sección
                                                            información de software, donde podrás ver ese número de modelo que buscas, además de la versió Android instalada,
                                                            la version del kernel, el número de compilación y más.
                                                        </p>
                                                    </div>
                                                </ng-template>
                                            </ngb-panel>
                                        </ngb-accordion>
                                    </div>
                                    
                                    <div>
                                        <ngb-accordion #accIphone="ngbAccordion">
                                            <ngb-panel id="panel-iphone" cardClass="subpanel-flex">
                                                <ng-template ngbPanelHeader>
                                                    <div class="d-flex align-items-center" (click)="accIphone.toggle('panel-iphone')">
                                                        <span>En tu iPhone, iPad, o iPod touch</span>
                                                    </div>
                                                </ng-template>
                                                <ng-template ngbPanelContent>
                                                    <div class="bloq-subpanel">
                                                        <p class="m-0 text-justify font-weight-normal text-muted text-panel">
                                                            <b>1.</b> Ve a <b>Ajustes</b> > <b>General</b> y pulsa <b>Acerca de</b>
                                                        </p>
                                                    </div>
                                                    <div class="border-top border-1" style="width: 100%;"></div>
                                                    <div class="bloq-subpanel">
                                                        <p class="m-0 text-justify font-weight-normal text-muted text-panel">
                                                            <b>2.</b> Aquí podemos visualizar el modelo y número de serie del dispositivo. Es posible que tengas que desplazarte hacia
                                                            abajo para encontrar el IMEI/MEID y el ICCID.
                                                        </p>
                                                        <div class="d-flex justify-content-center my-3">
                                                            <img class="img-fluid" src="../../../../assets/img/repara/modelo03.jpg">
                                                        </div>
                                                    </div>
                                                    <div class="border-top border-1" style="width: 100%;"></div>
                                                    <div class="bloq-subpanel">
                                                        <p class="m-0 text-justify font-weight-normal text-muted text-panel">
                                                            <b>3.</b> En algunos dispositivos, encontrarás el número de serie en <b>Ajustes</b>, y el IMEI/MEID, en la bandeja SIM. Si necesitas
                                                            soporte técnico, pero no puedes acceder al menú Ajustes, puedes usar el IMEI/MEID en lugar del número de serie.
                                                        </p>
                                                        <div class="d-flex justify-content-center my-3">
                                                            <img class="img-fluid" src="../../../../assets/img/repara/modelo04.png">
                                                        </div>
                                                    </div>
                                                    <div class="border-top border-1" style="width: 100%;"></div>
                                                    <div class="bloq-subpanel">
                                                        <p class="m-0 text-justify font-weight-normal text-muted text-panel">
                                                            <b>4.</b> Encontrarás el número de serie del iPad Pro, iPad o iPod touch en la parte trasera del dispositivo. En un iPad (modelo
                                                            Wi-Fi + Cellular) también encontrarás el IMEI.
                                                        </p>
                                                        <div class="d-flex justify-content-center my-3">
                                                            <img class="img-fluid" src="../../../../assets/img/repara/modelo05.jpg">
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </ngb-panel>
                                        </ngb-accordion>
                                    </div>
                                </div>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel id="panel-3" cardClass="panel-flex">
                            <ng-template ngbPanelHeader let-opened="opened">
                                <div class="d-flex align-items-center" (click)="acc.toggle('panel-3')">
                                    <i *ngIf="opened" class="fas fa-minus icon"></i>
                                    <i *ngIf="!opened" class="fas fa-plus icon"></i>
                                    <h6 class="m-0 ml-2">Si no tienes el dispositivo o no enciende</h6>
                                </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <div>
                                    <p class="text-justify font-weight-normal text-muted text-panel">
                                        Sobre cada dispositivo existe una placa de identificación, donde se encuentra los datos exactos del
                                        aparato. Aquí tenemos ejemplos, donde se encuentran esta placa de identificación y que número elegir.
                                    </p>
                                    <div class="d-flex justify-content-center my-3">
                                        <img class="img-fluid" src="../../../../assets/img/repara/modelo07.jpg">
                                    </div>
                                    <div class="d-flex justify-content-center my-4">
                                        <img class="img-fluid" src="../../../../assets/img/repara/modelo08.jpg">
                                    </div>
                                    <p class="text-justify font-weight-normal text-muted text-panel">
                                        Si su dispositivo no funciona y usted o puede encontrar los números del producto en la etiqueta o en
                                        el propio dispositivo, revise el embalaje o los lados exteriores de la caja y busque la sección del código
                                        de barras o la etiqueta
                                    </p>
                                    <div class="d-flex justify-content-center my-3">
                                        <img class="img-fluid" src="../../../../assets/img/repara/modelo06.png">
                                    </div>
                                </div>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel id="panel-4" cardClass="panel-flex">
                            <ng-template ngbPanelHeader let-opened="opened">
                                <div class="d-flex align-items-center" (click)="acc.toggle('panel-4')">
                                    <i *ngIf="opened" class="fas fa-minus icon"></i>
                                    <i *ngIf="!opened" class="fas fa-plus icon"></i>
                                    <h6 class="m-0 ml-2">En la web de la marca del dispositivo</h6>
                                </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <div>
                                    <p class="text-justify font-weight-normal text-muted text-panel">
                                        Por norma general cada una de las diferentes marcas de los diferentes dispositivos puedes encontrar
                                        información que te ayudará a localizar el modelo y el número de serie del producto que desea reparar.
                                    </p>
                                </div>
                                <div class="border rounded-2">
                                    <div class="bloq-url-marca">
                                        <p class="m-0 text-justify font-weight-normal text-muted text-panel">
                                            Para dispositivos <a href="https://www.asus.com/es/support/article/566/" target="_blank">ASUS</a>
                                        </p>
                                    </div>
                                    <div class="border-top border-1" style="width: 100%;"></div>
                                    <div class="bloq-url-marca">
                                        <p class="m-0 text-justify font-weight-normal text-muted text-panel">
                                            Para dispositivos <a href="https://msi.com/" target="_blank">MSI</a>
                                        </p>
                                    </div>
                                    <div class="border-top border-1" style="width: 100%;"></div>
                                    <div class="bloq-url-marca">
                                        <p class="m-0 text-justify font-weight-normal text-muted text-panel">
                                            Para dispositivos <a href="https://support.apple.com/es-lamr/HT201581" target="_blank">APPLE</a>
                                        </p>
                                    </div>
                                    <div class="border-top border-1" style="width: 100%;"></div>
                                    <div class="bloq-url-marca">
                                        <p class="m-0 text-justify font-weight-normal text-muted text-panel">
                                            Para dispositivos <a href="https://support.lenovo.com/es/es/solutions/ht510152" target="_blank">LENOVO</a>
                                        </p>
                                    </div>
                                    <div class="border-top border-1" style="width: 100%;"></div>
                                    <div class="bloq-url-marca">
                                        <p class="m-0 text-justify font-weight-normal text-muted text-panel">
                                            Para dispositivos <a href="https://www.toshiba.com/" target="_blank">TOSHIBA</a>
                                        </p>
                                    </div>
                                </div>
                            </ng-template>
                        </ngb-panel>
                    </ngb-accordion>
                </div>
            </div>
        </div>
        <div class="col-3 col-md-3 col-lg-3 d-none d-md-block">
            <img class="img-fluid mb-4" src="../../../../assets/img/repara/formula-flex.png" alt="Responsive image">
        </div>
    </div>

</div>