import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CheckoutComponent } from './pages/buyingProcess/checkout/checkout.component';
import { CompletedComponent } from './pages/buyingProcess/completed/completed.component';
import { ProfileComponent } from './pages/adminProfile/profile/profile.component';
import { AccountDetailsComponent } from './pages/adminProfile/account-details/account-details.component';
import { OrdersComponent } from './pages/adminProfile/orders/orders.component';
import { AddressComponent } from './pages/adminProfile/address/address.component';
import { HowDoesItWorkComponent } from './pages/terms/how-does-it-work/how-does-it-work.component';
import { LoginComponent } from './pages/users/login/login.component';
import { RegisterComponent } from './pages/users/register/register.component';
import { AuthGuardService } from './services/auth/auth-guard.service';
import { RememberComponent } from './pages/users/remember/remember.component';
import { PaymentMethodsComponent } from './pages/payment-methods/payment-methods.component';
import { BuyGuideComponent } from './pages/buy-guide/buy-guide.component';
import { PrivacityComponent } from './pages/terms/privacity/privacity.component';
import { WarantyComponent } from './pages/terms/waranty/waranty.component';
import { ConditionsComponent } from './pages/terms/conditions/conditions.component';
import { LegalAdviceComponent } from './pages/terms/legal-advice/legal-advice.component';
import { CookiesComponent } from './pages/terms/cookies/cookies.component';
import { SecurityComponent } from './pages/terms/security/security.component';
import { ReparaComponent } from './pages/repara/repara.component';
import { CompraComponent } from './pages/compra/compra.component';
import { AyudaModeloNumSerieComponent } from './pages/repara/ayuda-modelo-num-serie/ayuda-modelo-num-serie.component';
import { AyudaEmbalarComponent } from './pages/repara/ayuda-embalar/ayuda-embalar.component';
import { MyRepairsComponent } from './pages/adminProfile/repairs/my-repairs.component';
import { WishesComponent } from './pages/wishes/wishes.component';
import { BudgetPayComponent } from './pages/adminProfile/repairs/budgets/budget-pay/budget-pay.component';
import { ReparaHomeComponent } from './pages/repara-home/repara-home.component';
import { Garantia10Component } from './pages/garantia10/garantia10.component';
import { RedirectGuard } from './guards/redirect.guard';

const routes: Routes = [
  {
    path: '', component: ReparaHomeComponent
  },
  {
    path: 'shoppingCart', 
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: 'https://exxitashop.com/'
    }
  },
  {
    path: 'profile', component: ProfileComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'checkout', component: CheckoutComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'completed', component: CompletedComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'accountDetails', component: AccountDetailsComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'orders', component: OrdersComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'orders/:tipo', component: OrdersComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'address', component: AddressComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'howdoesitwork', component: HowDoesItWorkComponent
  },
  {
    path: 'login', component: LoginComponent
  },
  {
    path: 'register', component: RegisterComponent
  },
  {
    path: 'remember', component: RememberComponent
  },
  {
    path: 'paymentmethods', component: PaymentMethodsComponent
  },
  {
    path: 'buyguide', component: BuyGuideComponent
  },
  {
    path: 'privacy', component: PrivacityComponent
  },
  {
    path: 'waranty', component: WarantyComponent
  },
  {
    path: 'legaladvice', component: LegalAdviceComponent
  },
  {
    path: 'cookies', component: CookiesComponent
  },
  {
    path: 'security', component: SecurityComponent
  },
  {
    path: 'conditions', component: ConditionsComponent
  },
  {
    path: 'repara/solicitar-presupuesto/:tipo', component: ReparaComponent
  },
  {
    path: 'repara', component: ReparaHomeComponent
  },
  {
    path: 'repara/ayuda-encontrar-modelo-num-serie', component: AyudaModeloNumSerieComponent
  },
  {
    path: 'repara/ayuda-embalar', component: AyudaEmbalarComponent
  },
  {
    path: 'compra', component: CompraComponent
  },
  {
    path: 'repairs/:idTicket', component: MyRepairsComponent, loadChildren: () => import('./pages/adminProfile/repairs/my-repairs.module').then( m => m.MyRepairsModule)
  },
  {
    path: 'wishes', component: WishesComponent
  },
  {
    path: 'redsysConfirm', component: BudgetPayComponent
  },
  {
    path: 'garantia10', component: Garantia10Component
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
