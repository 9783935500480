import { Component, HostListener, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { AuxiliarService } from 'src/app/services/auxiliar.service';
import * as Notiflix from 'notiflix';

@Component({
  selector: 'app-budgets',
  templateUrl: './budgets.component.html',
  styleUrls: ['./budgets.component.css']
})
export class BudgetsComponent implements OnInit {

  screenHeight: number | undefined;
  screenWidth: number | undefined;

  ticket: any = null;
  idTicket: any = null;
  presupuestos: any = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private auxSvc: AuxiliarService
  ) { 
    this.onResize();

    this.idTicket = this.route.parent?.snapshot.url[1].path;

    moment.locale('es');
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?: undefined) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  ngOnInit(): void {
    Notiflix.Loading.pulse('Cargando presupuestos...',{
			svgColor: '#63b421',
		  });
    this.loadTicket();
  }

  loadTicket(){
    if(this.idTicket && this.idTicket != ""){
      this.getDatosReparacion(this.idTicket);
    }
  }

  getDatosReparacion(id: number){
    this.auxSvc.getReparacion(id).subscribe(
      (data: any) => {
        this.ticket = data;

        this.getPresupuestos(data.clienteSolicitante, data.ID);
      },
      (err) => {
        Notiflix.Loading.remove();
        console.log('Error -> ' + err)
      }
    );
  }

  getPresupuestos(idCLiente: any, idReparacion: any){
    this.auxSvc.getPresupuestosReparacion(idCLiente, idReparacion).subscribe(
      (data: any) => {
        console.log(data);
        this.presupuestos = data.elements;
        Notiflix.Loading.remove();
      },
      (err) => {
        Notiflix.Loading.remove();
        console.log('Error -> ' + err)
      }
    );
  }

  navigate(url: string, params?: any) {
    if (params) {
      this.router.navigate([url], {queryParams: params });
    }
    else {
      this.router.navigate([url]);
    }
	}

  formatDate(fecha: string){
    return moment(fecha).format('L');
  }

  formatNumber(numero: number){
    return Math.round(numero * 100) / 100;
  }

}
