import { Component, HostListener, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user/user.service';
import { AuxiliarService } from 'src/app/services/auxiliar.service';

import {Validators, FormGroup, FormControl, FormBuilder, ValidatorFn, ValidationErrors } from '@angular/forms';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { Report } from 'notiflix/build/notiflix-report-aio';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {

  dir_facturacion: any = null;
  dir_envio: any = null;
  provincias: any = null;
  provinciasEnvio: any = null;
  localidades: any = null;
  localidadesEnvio: any = null;

  closeResult = '';
  formFacturacion: FormGroup;
  formEnvio: FormGroup;
  provinciaSelected: any = null;
  provinciaEnvioSelected: any = null;
  saveBtnEnabled: boolean = true;
  saveBtnEnabledEnvio: boolean = true;

  screenHeight: number | undefined;
  screenWidth: number | undefined;
  
  public selectProvincia = new FormControl();
  public selectPoblacion = new FormControl();
  public selectProvinciaEnvio = new FormControl();
  public selectPoblacionEnvio = new FormControl();

  constructor(
    private auxSvc: AuxiliarService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private userSvc: UserService,
    private authSvc: AuthService,
  ) {

    this.onResize();
    this.formFacturacion = this.formBuilder.group({
			nombre: ['', [Validators.required, Validators.minLength(2),  Validators.maxLength(250)]],
      apellido: ['', [Validators.required, Validators.maxLength(250)]],
			direccion: ['', [Validators.required, Validators.maxLength(250)]],
      direccion_email: ['', [Validators.required, Validators.email,Validators.maxLength(45)]],
      num_telefono: ['', [Validators.required, Validators.maxLength(11)]],
			localidad: ['', Validators.required],
			provincia: ['', Validators.required],
      otros_datos: ['', [Validators.maxLength(255)]],
		});

    this.formEnvio = this.formBuilder.group({
			nombre: ['', [Validators.required, Validators.minLength(2),  Validators.maxLength(250)]],
      apellido: ['', [Validators.required, Validators.maxLength(250)]],
			direccion: ['', [Validators.required, Validators.maxLength(250)]],
      direccion_email: ['', [Validators.required, Validators.email,Validators.maxLength(45)]],
      num_telefono: ['', [Validators.required, Validators.maxLength(11)]],
			localidad: ['', Validators.required],
			provincia: ['', Validators.required],
      otros_datos: ['', [Validators.maxLength(255)]],
		});
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?: undefined) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  ngOnInit(): void {
    this.getDireccionFacturacion();
    this.getDireccionEnvio();
    this.getProvincias();
    this.getProvinciasEnvio();

    this.selectProvincia.valueChanges
    .subscribe((value: number) => {
      if(value != null){
        
          this.getPoblaciones(value);
      }
    });

    this.selectProvinciaEnvio.valueChanges
    .subscribe((value: number) => {
      if(value != null){
          this.getPoblacionesEnvio(value);
      }
    });
  }

  getDireccionFacturacion() {
    this.auxSvc.getClienteDireccionFacturacion(Number(localStorage.getItem('idCliente'))).subscribe(
      (data: any) => {
        if(data){
          this.dir_facturacion = data;

          this.formFacturacion.get('nombre')?.setValue(data.nombre);
          this.formFacturacion.get('apellido')?.setValue(data.apellido);
          this.formFacturacion.get('direccion')?.setValue(data.direccion);
          this.formFacturacion.get('localidad')?.setValue(data.localidad?.idpoblacion);
          this.formFacturacion.get('provincia')?.setValue(data.provincia?.idprovincia);
          this.formFacturacion.get('direccion_email')?.setValue(data.direccion_email);
          this.formFacturacion.get('num_telefono')?.setValue(data.num_telefono);
          this.formFacturacion.get('otros_datos')?.setValue(data.otros_datos);

          if(this.dir_facturacion && this.dir_facturacion.id_provincia){
            this.getPoblaciones(this.dir_facturacion.id_provincia);
          }
        }
      },
      (err) => {
        console.log('Error -> ' + err)
      }
    );
  }

  getDireccionEnvio() {
    this.auxSvc.getClienteDireccionEnvio(Number(localStorage.getItem('idCliente'))).subscribe(
      (data: any) => {
        if(data){
          this.dir_envio = data;

          this.formEnvio.get('nombre')?.setValue(data.nombre);
          this.formEnvio.get('apellido')?.setValue(data.apellido);
          this.formEnvio.get('direccion')?.setValue(data.direccion);
          this.formEnvio.get('localidad')?.setValue(data.localidad?.idpoblacion);
          this.formEnvio.get('provincia')?.setValue(data.provincia?.idprovincia);
          this.formEnvio.get('direccion_email')?.setValue(data.direccion_email);
          this.formEnvio.get('num_telefono')?.setValue(data.num_telefono);
          this.formEnvio.get('otros_datos')?.setValue(data.otros_datos);

          if(this.dir_envio && this.dir_envio.id_provincia){
            this.getPoblacionesEnvio(this.dir_envio.id_provincia);
          }
        }
      },
      (err) => {
        console.log('Error -> ' + err)
      }
    );
  }

  getProvincias(idSelect:number = 0){
    this.auxSvc.getProvincias().subscribe(
      (data: any) => {
        if(data){
          this.provincias = data;

          if(idSelect && idSelect != 0){
            this.selectProvincia.setValue(idSelect);
          }
        }
      },
      (err) => {
        console.log('Error -> ' + err)
      }
    );
  }

  getProvinciasEnvio(idSelect:number = 0){
    this.auxSvc.getProvincias().subscribe(
      (data: any) => {
        if(data){
          this.provinciasEnvio = data;

          if(idSelect && idSelect != 0){
            this.selectProvinciaEnvio.setValue(idSelect);
          }
        }
      },
      (err) => {
        console.log('Error -> ' + err)
      }
    );
  }

  getPoblaciones(idProvincia:number, idSelect:number = 0){
    this.auxSvc.getPoblaciones(idProvincia).subscribe(
      (data: any) => {
        if(data){
          this.localidades = data;

          if(this.localidades && this.localidades.length == 1){
            this.selectPoblacion.setValue(this.localidades[0].idpoblacion);
          }
        }
      },
      (err) => {
        console.log('Error -> ' + err)
      }
    );
  }

  getPoblacionesEnvio(idProvincia:number, idSelect:number = 0){
    this.auxSvc.getPoblaciones(idProvincia).subscribe(
      (data: any) => {
        if(data){
          this.localidadesEnvio = data;

          if(this.localidadesEnvio && this.localidadesEnvio.length == 1){
            this.selectPoblacionEnvio.setValue(this.localidadesEnvio[0].idpoblacion);
          }
        }
      },
      (err) => {
        console.log('Error -> ' + err)
      }
    );
  }

  openFacturacion(content: any) {
    //cargamos las localidades si tiene provincia informada
    if(this.dir_facturacion && this.dir_facturacion.id_provincia && this.dir_facturacion.id_provincia != null && this.dir_facturacion.id_provincia != ""){
      this.getProvincias(parseInt(this.dir_facturacion.id_provincia));
      this.selectProvincia.setValue(this.dir_facturacion.id_provincia);

      if(this.dir_facturacion.id_localidad && this.dir_facturacion.id_localidad != null && this.dir_facturacion.id_localidad != ""){
        this.selectPoblacion.setValue(this.dir_facturacion.id_localidad);
      }
    }

    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openEnvio(contentEnvio: any) {
    //cargamos las localidades si tiene provincia informada
    if(this.dir_envio && this.dir_envio.id_provincia && this.dir_envio.id_provincia != null && this.dir_envio.id_provincia != ""){
      this.getProvinciasEnvio(parseInt(this.dir_envio.id_provincia));
      this.selectProvinciaEnvio.setValue(this.dir_envio.id_provincia);

      if(this.dir_envio.id_localidad && this.dir_envio.id_localidad != null && this.dir_envio.id_localidad != ""){
        this.selectPoblacionEnvio.setValue(this.dir_envio.id_localidad);
      }
    }

    this.modalService.open(contentEnvio, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  /*private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }*/

  guardarFacturacion(){
    if(this.formFacturacion.valid){
      this.saveBtnEnabled = false;

      if(this.dir_facturacion && this.dir_facturacion != null){
        this.userSvc.updateDireccion(this.formFacturacion.value, 1, this.dir_facturacion.ID).subscribe(
          (data: any) => {
            this.saveBtnEnabled = true;
            this.modalService.dismissAll();

            this.getDireccionFacturacion();
  
            Report.success(
              'Guardado correctamente',
              'Su dirección se ha guardado correctamente',
              'Aceptar',
              {success: {svgColor: '#63b421',  buttonBackground: '#63b421', backOverlayColor: 'rgba(13, 0, 0, 0.2)',}}
            );
          },
          (err) => {
            this.saveBtnEnabled = true;
  
            Notify.failure('No se pudieron guardar los datos', {position: 'left-bottom'});
          }
        );
      }else{
        this.userSvc.createDireccion(this.formFacturacion.value, 1, Number(localStorage.getItem('idCliente'))).subscribe(
          (data: any) => {
            this.saveBtnEnabled = true;
            this.modalService.dismissAll();
            
            this.getDireccionFacturacion();
  
            Report.success(
              'Guardado correctamente',
              'Su dirección se ha guardado correctamente',
              'Aceptar',
              {success: {svgColor: '#63b421',  buttonBackground: '#63b421', backOverlayColor: 'rgba(13, 0, 0, 0.2)',}}
            );
          },
          (err) => {
            this.saveBtnEnabled = true;
  
            Notify.failure('No se pudieron guardar los datos', {position: 'left-bottom'});
          }
        );
      }
    }else{
      Notify.warning('Debe completar todos los campos', {position: 'left-bottom'});
    }
  }

  guardarEnvio(){
    if(this.formEnvio.valid){
      this.saveBtnEnabledEnvio = false;

      if(this.dir_envio && this.dir_envio != null){
        this.userSvc.updateDireccion(this.formEnvio.value, 2, this.dir_envio.ID).subscribe(
          (data: any) => {
            this.saveBtnEnabledEnvio = true;
            this.modalService.dismissAll();

            this.getDireccionEnvio();
  
            Report.success(
              'Guardado correctamente',
              'Su dirección se ha guardado correctamente',
              'Aceptar',
              {success: {svgColor: '#63b421',  buttonBackground: '#63b421', backOverlayColor: 'rgba(13, 0, 0, 0.2)',}}
            );
          },
          (err) => {
            this.saveBtnEnabledEnvio = true;
  
            Notify.failure('No se pudieron guardar los datos', {position: 'left-bottom'});
          }
        );
      }else{
        this.userSvc.createDireccion(this.formEnvio.value, 2, Number(localStorage.getItem('idCliente'))).subscribe(
          (data: any) => {
            this.saveBtnEnabledEnvio = true;
            this.modalService.dismissAll();
            
            this.getDireccionEnvio();
  
            Report.success(
              'Guardado correctamente',
              'Su dirección se ha guardado correctamente',
              'Aceptar',
              {success: {svgColor: '#63b421',  buttonBackground: '#63b421', backOverlayColor: 'rgba(13, 0, 0, 0.2)',}},
            );
          },
          (err) => {
            this.saveBtnEnabledEnvio = true;
  
            Notify.failure('No se pudieron guardar los datos', {position: 'left-bottom'});
          }
        );
      }
    }else{
      Notify.warning('Debe completar todos los campos', {position: 'left-bottom'});
    }
  }

  cerrarSesion(){
    this.authSvc.logout();
      
  }

}
