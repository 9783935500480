import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { AuxiliarService } from 'src/app/services/auxiliar.service';
import * as Notiflix from 'notiflix';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { Report } from 'notiflix/build/notiflix-report-aio';
import { ReparaService } from 'src/app/services/repara/repara.service';

@Component({
  selector: 'app-my-repairs',
  templateUrl: './my-repairs.component.html',
  styleUrls: ['./my-repairs.component.scss']
})
export class MyRepairsComponent implements OnInit {

  screenHeight: number | undefined;
  screenWidth: number | undefined;

  ticket: any = null;
  idTicket: any = null;
  dir_recogida: any = null;
  dir_envio: any = null;

  comentario: string = '';
  
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private auxSvc: AuxiliarService,
    private reparaSvc: ReparaService
  ) {
    this.onResize();

    this.idTicket = this.route.snapshot.url[1].path;

    moment.locale('es');
  }

  descargarAdjunto(id: number, idTicket: number){
    Notiflix.Loading.pulse('Descargando adjunto...',{
			svgColor: '#63b421',  
    });

    this.auxSvc.descargarAdjunto(id, idTicket).subscribe(
      (data: any) => {
        var a = document.createElement("a"); //Create <a>
        a.href = "data:"+data.mime+";base64," + data.data; //Image Base64 Goes here
        a.download = data.filename; //File name Here
        a.click(); //Downloaded file

        Notiflix.Loading.remove();
      },
      (err) => {
        console.log('Error -> ' + err)
      }
    );
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?: undefined) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  ngOnInit(): void {
    Notiflix.Loading.pulse('Cargando datos reparación...',{
			svgColor: '#63b421',
		  });
    this.loadTicket();
  }

  loadTicket(){
    if(this.idTicket && this.idTicket != ""){
      this.getDatosReparacion(this.idTicket);
    }
  }

  getDatosReparacion(id: number){
    this.auxSvc.getReparacion(id).subscribe(
      (data: any) => {
        this.ticket = data;

        this.getDireccionesTicket(data.ID);
      },
      (err) => {
        Notiflix.Loading.remove();
        console.log('Error -> ' + err)
      }
    );
  }

  getDireccionesTicket(ticketId: number){
    this.reparaSvc.getDireccionTicket(ticketId, 3).subscribe(
      (data: any) => {
        if(data){
          this.dir_recogida = data;
        }
      },
      (err) => {
        console.log('Error -> ' + err)
      }
    );

    this.reparaSvc.getDireccionTicket(ticketId, 2).subscribe(
      (data: any) => {
        if(data){
          this.dir_envio = data;
        }
      },
      (err) => {
        console.log('Error -> ' + err)
      }
    );
  }

  navigate(url: string, params?: any) {
    if (params) {
      this.router.navigate([url], {queryParams: params });
    }
    else {
      this.router.navigate([url]);
    }
	}

  refresh(): void {
    window.location.reload();
  }

  formatDate(fecha: string){
    return (moment(fecha)).format('LL');
  }

  ultimaActualizacion(){
    return moment(this.ticket?.date_mod).calendar();
  }

}
