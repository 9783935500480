<img class="img-fluid mt-4 mb-4" src="../../../../assets/img/repara/banner-repara.png" alt="Responsive image">
<img #tituloPagina class="img-fluid mb-4 px-2" src="../../../../assets/img/repara/embalaje-correcto.png" style="max-height: 50px;" alt="Responsive image">

<div class="container-fluid">
    <div class="row">
        <div class="col">
            <div class="row">
                <div class="col">
                    <p class="text-justify font-weight-normal text-muted">
                        El repartidor no puede embalar el envío, ni dispone de embalajes para ello. Es
                        responsabilidad del remitente realizar un embalaje adecuado en función de la tipología
                        de mercancia, así como de etiquetar el envío con datos completos de remitente y 
                        destinatario, incluyendo teléfonos de contacto.
                    </p>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-11">
                    <div class="border-top border-1 mb-3 separador"></div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <p class="text-justify font-weight-normal text-muted">
                        Debes tener en cuenta lo siguiente:
                    </p>
                </div>
            </div>
            <div class="row mb-5 mt-3">
                <div class="col">
                    <ngb-accordion #acc="ngbAccordion" [closeOthers]="true" activeIds="panel-1">
                        <ngb-panel id="panel-1" cardClass="panel-flex">
                            <ng-template ngbPanelHeader let-opened="opened">
                                <div class="d-flex align-items-center" (click)="acc.toggle('panel-1')">
                                    <i *ngIf="opened" class="fas fa-minus icon"></i>
                                    <i *ngIf="!opened" class="fas fa-plus icon"></i>
                                    <h6 class="m-0 ml-2">Normas generales de embalaje</h6>
                                </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <div class="row mb-4">
                                    <div class="col-3">
                                        <div class="d-flex justify-content-center">
                                            <img class="img-fluid image-bloq" src="../../../../assets/img/repara/embalaje01.png">
                                        </div>
                                    </div>
                                    <div class="col-9">
                                        <h6 class="title-bloq">Utiliza las cajas adecuadas</h6>
                                        <p class="text-justify font-weight-normal text-muted text-panel">
                                            Lo ideal es una caja de cartón rígido, que no presente ningún defecto y que sea
                                            lo suficientemente espaciosa para que quepa el objeto, y el material amortiguador.
                                        </p>
                                        <p class="text-justify font-weight-normal text-muted text-panel">
                                            Si es una caja reutilizada, asegúrate de que esté en buenas condiciones: sin
                                            cortes, rajas ni esquinas dañadas y que todas las solapas estén intactas. Retira
                                            las etiquetas y marcas de envíos previos.
                                        </p>
                                    </div>
                                </div>
                                <div class="row mb-4">
                                    <div class="col-3">
                                        <div class="d-flex justify-content-center">
                                            <img class="img-fluid image-bloq" src="../../../../assets/img/repara/embalaje02.png">
                                        </div>
                                    </div>
                                    <div class="col-9">
                                        <h6 class="title-bloq">Coloca protección interior</h6>
                                        <p class="text-justify font-weight-normal text-muted text-panel">
                                            Conviene utilizar el material amortiguador adecuado, procurando no dejar
                                            ningún hueco para evitar que la mercancía pueda moverse dentro de la caja.
                                        </p>
                                        <p class="text-justify font-weight-normal text-muted text-panel">
                                            En caso de que el envío contenga varios objetos, envuelve cada uno de ellos por
                                            separado y, antes de meterlos en la caja, añade material amortiguador.
                                            Introduce los diversos objetos y rellena con el material amortiguador todos los
                                            huecos hasta cubrir completamente la caja, de tal manera que sea imposible 
                                            cualquier movimiento dentro de la misma.
                                        </p>
                                        <p class="text-justify font-weight-normal text-muted text-panel">
                                            Recuerda mantener los artículos frágiles lo más alejados posible de las caras y esquinas de la caja.
                                        </p>
                                    </div>
                                </div>
                                <div class="row mb-4">
                                    <div class="col-3">
                                        <div class="d-flex justify-content-center">
                                            <img class="img-fluid image-bloq" src="../../../../assets/img/repara/embalaje03.png">
                                        </div>
                                    </div>
                                    <div class="col-9">
                                        <h6 class="title-bloq">Precinta correctamente el paquete</h6>
                                        <p class="text-justify font-weight-normal text-muted text-panel">
                                            Comprueba que la caja esté precitada correctamente por todas sus caras. Esto
                                            evitará que pueda abrirse o caerse el contenido.
                                        </p>
                                        <p class="text-justify font-weight-normal text-muted text-panel">
                                            Utiliza una cinta adhesiva con un ancho adecuado. Da más de una pasada e, 
                                            incluso, cubre las aristas para evitar así cualquier entrada de humedad.
                                        </p>
                                    </div>
                                </div>
                                <div class="row mb-4">
                                    <div class="col-3">
                                        <div class="d-flex justify-content-center">
                                            <img class="img-fluid image-bloq" src="../../../../assets/img/repara/embalaje04.png">
                                        </div>
                                    </div>
                                    <div class="col-9">
                                        <h6 class="title-bloq">Pega correctamente la etiqueta de dirección</h6>
                                        <p class="text-justify font-weight-normal text-muted text-panel">
                                            Utiliza una etiqueta donde aparezcan claramente los datos de remitente y
                                            destinatario (nombre, dirección completa y teléfono).
                                        </p>
                                        <p class="text-justify font-weight-normal text-muted text-panel">
                                            Coloca en el sobre o caja una única etiqueta, asegurándote que no existen otras
                                            direcciones escritas, excepto si la caja es grande, caso en elque es conveniente 
                                            colocar dos (por supuesto, con los mismos datos)
                                        </p>
                                        <p class="text-justify font-weight-normal text-muted text-panel">
                                            Pon la etiqueta en el centro de lcara y no la coloques en las esquinas o aristas.
                                            Si se trata de objetos irregulares, asegúrate que está bien sujeta y colocada en
                                            sitio visible.
                                        </p>
                                        <p class="text-justify font-weight-normal text-muted text-panel">
                                            Es recomendable cubrir la etiqueta con cinta adhesiva transparente, para evitar
                                            así que se despegue o que cualquier tipo de humedad borre los datos.
                                        </p>
                                    </div>
                                </div>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel id="panel-2" cardClass="panel-flex">
                            <ng-template ngbPanelHeader let-opened="opened">
                                <div class="d-flex align-items-center" (click)="acc.toggle('panel-2')">
                                    <i *ngIf="opened" class="fas fa-minus icon"></i>
                                    <i *ngIf="!opened" class="fas fa-plus icon"></i>
                                    <h6 class="m-0 ml-2">Te recomendamos el embalaje adecuado para cada mercancía</h6>
                                </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <p class="text-justify font-weight-normal text-muted text-panel">
                                    Queremos que tu envío siempre llegue a su destino en las mejores condiciones, por ello te
                                    aconsejamos sobre cómo embalarlo en función del tipo de mercancía que quieras enviar.
                                </p>

                                <div class="row mb-4">
                                    <div class="col-3">
                                        <div class="d-flex justify-content-center">
                                            <img class="img-fluid image-bloq" src="../../../../assets/img/repara/embalaje05.png">
                                        </div>
                                    </div>
                                    <div class="col-9">
                                        <h6 class="title-bloq">Dispositivos móviles y tablets</h6>
                                        <p class="text-justify font-weight-normal text-muted text-panel">
                                            Protección de cartón y plástico burbuja en cantidad para envolver los
                                            dispositivos. Aconsejable también utilización de poliespán. El embalaje idóneo
                                            sería dentro e la caja del dispositivo original si se conserva.
                                        </p>
                                    </div>
                                </div>
                                <div class="row mb-4">
                                    <div class="col-3">
                                        <div class="d-flex justify-content-center">
                                            <img class="img-fluid image-bloq" src="../../../../assets/img/repara/embalaje06.png">
                                        </div>
                                    </div>
                                    <div class="col-9">
                                        <h6 class="title-bloq">Ordenadores y portatiles</h6>
                                        <p class="text-justify font-weight-normal text-muted text-panel">
                                            Cartón duro con protecciones internas de poliespán y/o plástico burbuja que
                                            dejen inmovilizado el material.
                                        </p>
                                    </div>
                                </div>
                                <div class="row mb-4">
                                    <div class="col-3">
                                        <div class="d-flex justify-content-center">
                                            <img class="img-fluid image-bloq" src="../../../../assets/img/repara/embalaje07.png">
                                        </div>
                                    </div>
                                    <div class="col-9">
                                        <h6 class="title-bloq">Maquinaria en general</h6>
                                        <p class="text-justify font-weight-normal text-muted text-panel">
                                            Varias capas de cartón duro y plástico burbuja. A ser posible, además de
                                            protección de cartón o plástico burbuja, en jaula de madera o palé.
                                        </p>
                                    </div>
                                </div>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel id="panel-3" cardClass="panel-flex">
                            <ng-template ngbPanelHeader let-opened="opened">
                                <div class="d-flex align-items-center" (click)="acc.toggle('panel-3')">
                                    <i *ngIf="opened" class="fas fa-minus icon"></i>
                                    <i *ngIf="!opened" class="fas fa-plus icon"></i>
                                    <h6 class="m-0 ml-2">Características de cada tipo de embalaje</h6>
                                </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <p class="text-justify font-weight-normal text-muted text-panel">
                                    ¿Conoces las características de cada tipo de embalaje? Consúltalas y averigua cuál es el que más se
                                    adapta a tu envío.
                                </p>

                                <div class="row mb-4">
                                    <div class="col-3">
                                        <div class="d-flex justify-content-center">
                                            <img class="img-fluid image-bloq" src="../../../../assets/img/repara/embalaje08.png">
                                        </div>
                                    </div>
                                    <div class="col-9">
                                        <h6 class="title-bloq">Papel burbuja</h6>
                                        <p class="text-justify font-weight-normal text-muted text-panel">
                                            El papel burbuja es protector, flexible ligero e impermeable. Este film de polietileno
                                            de burbujas de aire transparente se utiliza como antichoque, pues constituye un colchón
                                            protector que amortigua los golpes y las vibraciones durante el envío. Además, envuelve
                                            y protege los productos cualquiera que sea su forma, ya que es flexible. También protege
                                            los productos de la humedad y el polvo, ya que es impermeable. Es especialmente recomendable
                                            para embalar artículos ligeros.
                                        </p>
                                        <p class="text-justify font-weight-normal text-muted text-panel">
                                            Envuelve cada artículo por separado y asegúrate de utilizar tantas capas de papel burbuja
                                            como sean necesarias para proteger todo el producto, incluidos bordes y esquinas así como 
                                            de que el contenido no se mueve dentro de la caja.
                                        </p>
                                    </div>
                                </div>
                                <div class="row mb-4">
                                    <div class="col-3">
                                        <div class="d-flex justify-content-center">
                                            <img class="img-fluid image-bloq" src="../../../../assets/img/repara/embalaje09.png">
                                        </div>
                                    </div>
                                    <div class="col-9">
                                        <h6 class="title-bloq">Relleno y protección</h6>
                                        <p class="text-justify font-weight-normal text-muted text-panel">
                                            El poliestireno sirve para proteger los artículos en el interior dle paquete ya que fija
                                            el artículo embalado amortiguando así los impactos y caídas. Este producto no absorbe ni
                                            desprende humedad. Además, su poco peso ahorra costes de transporte. Es fácil y rápido de utilizar.
                                        </p>
                                        <p class="text-justify font-weight-normal text-muted text-panel">
                                            Asegurate de utilizar la cantidad de relleno necesaria par que el contenido no se mueva dentro del paquete.
                                        </p>
                                        <p class="text-justify font-weight-normal text-muted text-panel">
                                            Se utiliza sobre todo como relleno en el envío de artículos ligeros.
                                        </p>
                                    </div>
                                </div>
                                <div class="row mb-4">
                                    <div class="col-3">
                                        <div class="d-flex justify-content-center">
                                            <img class="img-fluid image-bloq" src="../../../../assets/img/repara/embalaje11.png">
                                        </div>
                                    </div>
                                    <div class="col-9">
                                        <h6 class="title-bloq">Papel de envolver (Papel kraft)</h6>
                                        <p class="text-justify font-weight-normal text-muted text-panel">
                                            Se dobla y se arruga para rellenar el espacio vacío dentro de un paquete en artículos con un peso medio.
                                        </p>
                                        <p class="text-justify font-weight-normal text-muted text-panel">
                                            Utilízalo alrededor del contenido y entre los distintos artículos para llenar espacios vacíos.
                                        </p>
                                        <p class="text-justify font-weight-normal text-muted text-panel">
                                            También se utiliza para envolver y proteger artículos pequeños.
                                        </p>
                                    </div>
                                </div>
                                <div class="row mb-4">
                                    <div class="col-3">
                                        <div class="d-flex justify-content-center">
                                            <img class="img-fluid image-bloq" src="../../../../assets/img/repara/embalaje12.png">
                                        </div>
                                    </div>
                                    <div class="col-9">
                                        <h6 class="title-bloq">Cartón ondulado</h6>
                                        <p class="text-justify font-weight-normal text-muted text-panel">
                                            El cartón ondulado protege los productos. Se utiliza como protector entre el producto y la caja
                                            o contenedor en productos pesados.
                                        </p>
                                        <p class="text-justify font-weight-normal text-muted text-panel">
                                            Se le puede dar forma para proteger y aumentar la solidez del producto.
                                        </p>
                                    </div>
                                </div>
                                <div class="row mb-4">
                                    <div class="col-3">
                                        <div class="d-flex justify-content-center">
                                            <img class="img-fluid image-bloq" src="../../../../assets/img/repara/embalaje13.png">
                                        </div>
                                    </div>
                                    <div class="col-9">
                                        <h6 class="title-bloq">Embalajes estancos</h6>
                                        <p class="text-justify font-weight-normal text-muted text-panel">
                                            Para el envío de productos que requieran temperatura controlada entre +2ºC y + 8ºC.
                                            No deben permitir el paso de líquidos ni olores.
                                        </p>
                                    </div>
                                </div>
                            </ng-template>
                        </ngb-panel>
                    </ngb-accordion>
                </div>
            </div>
        </div>
        <div class="col-3 col-md-3 col-lg-3 d-none d-md-block">
            <img class="img-fluid mb-4" src="../../../../assets/img/repara/formula-flex.png" alt="Responsive image">
        </div>
    </div>
</div>