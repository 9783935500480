import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LabelType, Options } from 'ng5-slider';
import { Product } from 'src/app/models/product';
import { ProductService } from 'src/app/services/product.service';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormControl, FormGroup, Validators, FormArray, AbstractControl } from '@angular/forms';
import * as Notiflix from 'notiflix';

@Component({
  selector: 'app-searching',
  templateUrl: './searching.component.html',
  styleUrls: ['./searching.component.scss']
})
export class SearchingComponent implements OnInit {

  busqueda: string;
  productos = []

  screenHeight: number | undefined;
  screenWidth: number | undefined;

  filtroPreciosActivado: boolean = false;
  filtroMesesActivado: boolean = false;

  marcas = ['Acer', 'Apple', 'Assrock', 'Asus', 'Evga', 'Gigabyte', 'LG']


  minValue: number = 0;
  maxValue: number = 300;
  sliderValue = [0, 300];
  optionsPrice: Options = {
    floor: 0,
    ceil: 300,
    step: 5,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return '<b>Rango:</b> ' + value;
        case LabelType.High:
          return '<b></b> ' + value;
        default:
          return 'Rs. ' + value;
      }
    }
  };

  monthSelected: number | undefined;

  meses: number[] = []
  mesesTotales: Array<any> = Array(12).fill(1).map((x,i)=>i+1);
  precios: number[] = []
  mesesPrecio = new Array()

  products: any;
  marcasMostrar: any;

  rutaImagen: string = environment.API_URL+'/';

  numPage: number = 0;
	pageSize: number = 12;
  tableType: number = 1;
  order: string = '';
  direction: string = '';
  hasMore: boolean = false;
  totalProductos: number = 0;

	formFiltros = this.formBuilder.group({
		category: '',
		periodo_alquiler: null,
		marcas: this.formBuilder.array([]),
    price_min: 0,
    price_max: 300,
    nombre: ''
	});

  constructor(
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private productService: ProductService,
    private formBuilder: FormBuilder
  ) {
    // this.busqueda = this.route.snapshot.url[2].path;
    this.busqueda = "";
    
    this.onResize()
  }

  ngOnInit(): void {
    Notiflix.Loading.pulse('Cargando productos...',{
      svgColor: '#63b421',
    });
    /*this.cargarProductos();
    this.mandar();
    this.aux();
    this.cargar();

    this.route.queryParams.subscribe(
			(params) => {
				console.log('route params', params);
				this.busqueda = params.filter;
        console.log("busqueda", this.busqueda);
        this.productService.almacenarMovimientosNombre(this.busqueda);
			}
		);*/

    this.route.queryParams.subscribe(
			(params) => {
				this.formFiltros.get('nombre')?.setValue(params.filter);
        this.filtrar();
			}
		)

    
    this.loadMarcas();

    
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?: undefined) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  loadMarcas(){
    this.productService.getMarcas().subscribe(
			(data: any) => {
        this.marcasMostrar = data;
			},
			(err) => {
				console.log('Error: '+err)
			}
		);
  }

  filtrar(goBack: boolean = true){
    this.formFiltros.get('periodo_alquiler')?.setValue(this.monthSelected?this.monthSelected:null);
    this.formFiltros.get('price_min')?.setValue(this.minValue);
    this.formFiltros.get('price_max')?.setValue(this.maxValue);

    if(goBack == true){
      this.numPage = 0;
    }
    this.modalService.dismissAll();
    this.loadProductos();
  }

  loadProductos(){
    this.productService.getProductosAlmacen(this.numPage, this.pageSize, this.order, this.direction, this.formFiltros.value,1).subscribe(
      (data: any) => {
        this.hasMore = data.hasMore;
        this.totalProductos = data.totalElements;
        this.products = data.elements;
        Notiflix.Loading.remove();
      },
      (err) => {
        console.log('Error: '+err)
      }
    );
  }

  deleteFilter() {
    this.products = this.productService.products
    this.monthSelected = undefined;
    this.minValue = 0;
    this.maxValue = 300;
    this.formFiltros.reset();
    this.filtrar();
  }

  openVerticallyCentered(content: any) {
    this.modalService.open(content, { centered: true, size: 'xl' });
  }

  changeMarca(e: any) {
    const checkArray: FormArray = this.formFiltros.get('marcas') as FormArray;

    if (e.target.checked) {
      checkArray.push(new FormControl(e.target.value));
    } else {
      let i: number = 0;
      checkArray.controls.forEach((item: AbstractControl) => {
        if (item.value == e.target.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }
    
    console.log(this.formFiltros.get('marcas')?.value);
  }

  deletePriceFilter() {
    this.products = this.productService.products
    this.filtroPreciosActivado = false

  }

  selectMonth(month: number) {
    this.monthSelected = month;
  }

  deleteSelectedMonth() {
    this.monthSelected = undefined;
  }

  cambiarSizePag(size: number){
    this.pageSize = size;
    this.filtrar(true);
  }

}
