import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import * as bcrypt from 'bcryptjs';
import { RegisterForm } from 'src/app/models/registerForm';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
  providers: [NgbModalConfig, NgbModal]
})
export class RegisterComponent implements OnInit {

  RegisterModel = new RegisterForm();
  emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  //phoneRegex = /^(\+|\d)[0-9]{7,16}$/;
  passRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]{8,}$/;

  messageError = "";

  isSuccessful = false;
  isSignUpFailed = false;
  errorMessage = '';
  registroSuccess: boolean = false;

  screenHeight: number | undefined;
  screenWidth: number | undefined;

  @ViewChild("modalRegisterSuccess") modalRegisterSuccess: ElementRef | undefined

  constructor(
    configModal: NgbModalConfig,
    private authSvc: AuthService,
    private router: Router,
    private modalService: NgbModal
  ) {
    configModal.backdrop = 'static';
    configModal.keyboard = false;

    this.onResize()

  }

  ngOnInit(): void {
    console.log('Estas aqui')
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?: undefined) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  send(): void {
    console.log(this.RegisterModel)
    if (this.RegisterModel.email == null || this.RegisterModel.name == null || this.RegisterModel.password == null || this.RegisterModel.password2 == null) {
      this.messageError = "Debe rellenar todos los campos antes de continuar"
    } else {

      if (this.RegisterModel.password == this.RegisterModel.password2) {
        if (this.emailRegex.test(this.RegisterModel.email)) {
          //if(this.phoneRegex.test(this.RegisterModel.phone)){
          if (this.passRegex.test(this.RegisterModel.password)) {

            let email = this.RegisterModel.email;
            let nombre = this.RegisterModel.name;
            let apellidos = this.RegisterModel.surname;
            let password = this.RegisterModel.password;

            this.authSvc.register(email, password, nombre, apellidos).subscribe(
              data => {
                console.log(data);
                this.isSuccessful = true;
                this.isSignUpFailed = false;
                this.authSvc.login(email, password).subscribe(
                  data => {
                    if (data) {
                      console.log(data)
                      // this.router.navigate(['/']).then(() => {
                      //   window.location.reload();
                      // });
                      this.modalService.open(this.modalRegisterSuccess, { centered: true });
                    }
                  },
                  err => {
                    console.log(err);
                  }
                );
              },
              err => {
                this.messageError = err;
              }
            );


          } else {
            this.messageError = 'La contraseña debe contener una minúscula, una mayúscula, un dígito, y al menos 8 carácteres';
          }
          //}else{
          //  console.log('Introduzca un teléfono correcto')
          //}
        } else {
          this.messageError = 'Introduzca un correo electrónico correcto';
        }
      } else {
        this.messageError = "Las contraseñas deben ser iguales";
      }
    }



  }

  caminoInicio(){
    this.router.navigate(['/']).then(() => {
        window.location.reload();
      });
    this.modalService.dismissAll()
  }



}