<div class="container-lg mt-4 mb-4 d-flex justify-content-center">
    <h3 style="color: #808080;">Cesta de la compra
        <i class="fa fa-angle-right" aria-hidden="true"></i>
    </h3>
    <h3 style="color: green; margin-right: 10px; margin-left: 10px;">Checkout</h3>
    <h3 style="color: #808080;">
        <i class="fa fa-angle-right" aria-hidden="true"></i>
        Completar alquiler
    </h3>
</div>



    <form #mainForm="ngForm" [formGroup]="payForm">
        <div class="flex-row" style="flex-wrap: wrap;">

            <div class="grow4"  style="padding: 10px;">

                <!--<div style="display: inline-flex;">
                <h6 class="text-muted">¿Tienes un cupón?</h6>
                <h6 style="margin-left: 10px; font-weight: bold ;">INTRODUCE TU CÓDIGO</h6>
            </div>-->


                <h3 style="margin-top: 30px;">Dirección de envío</h3>

                <div class="form-group">
                    <div class="row mt-4">
                        <div class="col-6">
                            <h6 class="text-muted required">Nombre</h6>
                            <input matInput id="nombre_envio" name="nombre_envio" formControlName="nombre_envio"
                                class="form-control" [maxlength]="250">
                        </div>
                        <div class="col-6">
                            <h6 class="text-muted required">Apellidos</h6>
                            <input matInput id="apellido_envio" name="apellido_envio" formControlName="apellido_envio"
                                class="form-control" [maxlength]="250">
                        </div>
                    </div>

                    <!--<div class="row mt-4">
                        <div class="col-12">
                            <h6 class="text-muted">Nombre de la empresa (opcional)</h6>
                            <input matInput id="company_envio" name="company_envio" formControlName="company_envio"
                                class="form-control" [maxlength]="80">
                        </div>
                    </div>-->
                    <div class="row mt-4">
                        <div class="col-12">
                            <h6 class="text-muted required">Dirección de envío</h6>
                            <input matInput id="direccion_envio" name="direccion_envio"
                                formControlName="direccion_envio" class="form-control" [maxlength]="250"
                                placeholder="Dirección 1">
                            <!--<input matInput id="direccion2_envio" name="direccion2_envio"
                                formControlName="direccion2_envio" class="form-control" style="margin-top: 10px;"
                                [maxlength]="250" placeholder="Dirección 2 (opciona)">-->
                        </div>
                    </div>

                    <div class="row mt-4">
                        <div class="col-12">
                            <h6 class="text-muted required">Provincia</h6>

                            <ngx-select id="provincia_envio" name="provincia" formControlName="provincia_envio"
                                [formControl]="selectProvincia" [allowClear]="false" [items]="provincias"
                                optionValueField="idprovincia" optionTextField="provincia" placeholder="Provincia"
                                [(ngModel)]="provinciaSelected">
                            </ngx-select>
                        </div>
                    </div>

                    <div class="row mt-4">
                        <div class="col-12">
                            <h6 class="text-muted required">Localidad</h6>

                            <ngx-select id="localidad_facturacion" [disabled]="!provinciaSelected" name="localidad"
                                formControlName="localidad_envio" [formControl]="selectPoblacion" [allowClear]="false"
                                [items]="localidades" optionValueField="idpoblacion" optionTextField="poblacion"
                                placeholder="Localidad">
                            </ngx-select>
                        </div>
                    </div>

                    <div class="row mt-4">
                        <div class="col-12">
                            <h6 class="text-muted required">Teléfono</h6>

                            <input matInput type="number" id="num_telefono_envio" name="num_telefono_envio"
                                formControlName="num_telefono_envio" class="form-control" [maxlength]="80">
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-12">
                            <h6 class="text-muted required">Correo electrónico</h6>
                            <input type="email" matInput id="direccion_email_envio" name="direccion_email_envio"
                                formControlName="direccion_email_envio" class="form-control" [maxlength]="250">
                        </div>
                    </div>
                </div>

                <div class="row mt-4 mb-4">
                    <div class="col-12">
                        <h3 style="font-weight: bold;">Información adicional</h3>
                        <h6 class="text-muted mt-2">Notas del pedido(opcional)</h6>

                        <textarea matInput class="form-control" id="notas_envio" formControlName="notas_envio"
                            name="notas_envio" rows="3"></textarea>
                    </div>
                </div>
            </div>



            <div class="grow1 border border-secondary" style="padding: 10px; margin-bottom: 10px; width: 30%;">
                <h4 style="margin-top: 15px; margin-left: 15px; font-weight: bolder;"> SU PEDIDO </h4>


                <div class="row" style="margin-left: 30px; margin-top: 30px;">
                    <div class="col">
                        <h5 style=" font-weight: bolder;">Producto</h5>
                    </div>

                </div>

                <hr>


                <div *ngFor="let item of cartItems">
                    <div class=" row" style="margin-top: 30px; margin-left: 15px;">
                        <div class="col-3">
                            <img src="{{item.imgUrl}}" class="img-fluid"
                                style="border: 1px solid; color: rgb(0, 198, 0);">
                        </div>
                        <div class="col-9">
                            <div class="row">
                                <p>{{item.productName}}</p>
                            </div>
                            <div class="row">
                                <p class="text-muted">{{redondear(item.productPrice * item.quantity)}}€ / mes</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row" style="margin-left: 30px; margin-top: 30px;">
                    <div class="col">
                        <h5 style=" font-weight: bolder;">Subtotal</h5>
                    </div>

                    <div class="col">
                        <h5 style="color: gray;">{{total}} €</h5>
                    </div>
                </div>

                <hr>

                <div class="row" style="margin-left: 30px; margin-top: 30px;">
                    <div class="col">
                        <h5 style=" font-weight: bolder;">Total</h5>
                    </div>

                    <div class="col">
                        <h5>{{total}} € <h6 style="margin-left: 40px; color: #808080; font-size: 10px;">al mes </h6>
                        </h5>
                    </div>
                </div>

                <hr>
                <form id="payment-form">
                    <div id="payment-element">
                      <!-- Elements will create form elements here -->
                    </div>
                    <button id="submit" class="btn btn-primary" *ngIf="procesoPago == true">Pagar</button>
                    <div id="error-message">
                      <!-- Display error message to your customers here -->
                    </div>
                  </form>
                <button class="btn btn-primary" (click)="openPayment(content)" *ngIf="procesoPago == false && btnGuardar == true" [disabled]="!payForm.valid"
                    style="margin-top: 10px;">
                    Confirmar y pagar
                </button>

            </div>


        </div>
    </form>

<ng-template #content let-modal>
    <div class="modal-header">
        <h3>Realizar pago</h3>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        
    </div>
    <!--<div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="pay()">Pagar y finalizar</button>
    </div>-->
</ng-template>