import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  cliente = []
  nombreVisible = ''

  screenHeight: number | undefined;
  screenWidth: number | undefined;

  constructor(
    private authSvc: AuthService,
    private router: Router,
  ) {
    this.onResize()
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?: undefined) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }
  
  ngOnInit(): void {
    this.getCliente()
  }
  
  getCliente() {
    this.authSvc.getMe().subscribe(
      (data: any) => {
        this.nombreVisible = data.nombreVisible

			},
			(err) => {
				console.log('Error -> ' + err)
			}
		);
	}



  cerrarSesion(): void {
    this.authSvc.logout()
  }

}
