export class UpdateForm {

    public name!: string;
    public surname!: string;
    public visibleName!: string;
    public email!: string;
    public oldpassword!: string;
    public password!: string;
    public password2!: string;

}
