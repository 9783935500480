<div class="mt-4 mb-4">

  <table class="table table-bordered text-center">
    <tbody>
      <tr>
        <th><img style="width: 100px;" src="../../../assets/img/logoCyG.png"></th>
        <td class="text-green">Términos y condiciones web Flex</td>
        <td>Rev 16/11/2021</td>
      </tr>
    </tbody>
  </table>

  <h1 class="text-green">TÉRMINOS Y CONDICIONES FLEX</h1>
  <br>
  <h4>1. IDENTIFICACIÓN DEL RESPONSABLE DEL SITIO WEB</h4>
    <p>Éxxita Be Circular, S.A.
    CIF: A-41631904
    MYFIXPERT y FLEX son servicios de Éxxita Be Circular, S.A.
    <br>
    Mail de contacto: 
    <a class="text-green" style="text-decoration: none;" href="mailto:flex@cygit.com">flex@cygit.com</a>
    </p>

    <hr>

  <h4>2. ALCANCE DE LA REPARACIÓN</h4>
    <p>El cliente manifiesta dar consentimiento a Éxxita Be Circular, S.A. para efectuar cuantas acciones sean
        necesarias para elaborar un presupuesto o proceder con la reparación en caso de haber sido aceptado el mismo.
        <br>
        El cliente se compromete a declarar todos los fallos y daños del equipo, facilitando así la labor de diagnóstico-reparación.
        Cualquier otro fallo no declarado, detectado o no, y que posteriormente y durante la reparación sea observado, será objeto de
        presupuesto adicional previa notificación al cliente. En caso de rechazo del presupuesto, nuestro Servicio Técnico lo pasará
        por alto, informando al cliente a la entrega del equipo únicamente de los fallos declarados.
        <br>
        Éxxita Be Circular, S.A. se compromete al diagnóstico y/o reparación de la avería indicada por el cliente,
        quedando exenta de toda responsabilidad de cualquier otra avería no indicada en el momento de la entrada en sus
        instalaciones.
        <br>
        La reparación de dispositivos queda limitada a la pieza sustituida, garantizando en todo caso el funcionamiento de dicha pieza
        y no posibles fallos en cualquier otro componente del aparato reparado.
    </p>
    
    <hr>

  <h4>3. GARANTÍA DE LA REPARACIÓN</h4>
  <p>Éxxita Be Circular, S.A. garantiza sus reparaciones por el periodo de 90 días. La garantía de reparación
    comienza a contar desde la retirada del equipo de las instalaciones de Éxxita Be Circular, S.A. La garantía es
    válida sólo para las piezas aplicadas, no incluyendo situaciones tales como uso inadecuado del material a los accesorios
    respectivos, daños originados por un transportista cuando el material no fue enviado debidamente embalado, perturbaciones
    atmosféricas y/o eléctricas (fuego, agua, quiebras de electricidad). Las intervenciones de software no tienen garantía. La
    garantía de las piezas, accesorios y consumibles están sujetas a la garantía del fabricante.
    </p>
        
    <hr>

    <h4>4. BORRADO DE DATOS DEL DISPOSITIVO</h4>
    <p>Éxxita Be Circular, S.A. no se hace responsable en modo alguno de los datos que pueda tener almacenados el
        cliente en los equipos o dispositivos que se depositen en sus instalaciones. Si el cliente posee datos que considere relevantes
        y desea conservarlos, será responsabilidad suya exclusivamente el salvaguardar copia de ellos. El cliente acepta y autoriza
        expresamente a Éxxita Be Circular, S.A. al borrado, destrucción o modificación de los datos y soportes que lo
        contienen.
    </p>
     
    <hr>

    <h4>5. INSTALACIONES DE SOFTWARE</h4>
    <p>Éxxita Be Circular, S.A. no instala ningún programa o información en los equipos depositados.</p>
    
    <hr>

    <h4>6. MODALIDAD DE PAGO</h4>
    <p>Las formas de pago autorizadas son:
        <br>
        - Paypal
        <br>
        - Tarjeta de crédito,
        <br>
        - adeudo directo SEPA
        <br>
        - si se acuerda expresamente, transferencia bancaria
    </p>

    <hr>

    <h4>7. RECLAMACIONES</h4>
    <p>Para cualquier reclamación será necesaria la presentación del justificante de reparación.</p>
    
    <hr>

    <h4>8. PROTECCIÓN DE DATOS</h4>
    <p>En cumplimiento de lo recogido por la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía
        de los derechos digitales, Éxxita Be Circular, S.A.,con CIF A-41631904, como titular de la marca MyFixpert,
        responsable del tratamiento respecto al tratamiento Gestión de clientes, le informa que se procederá al tratamiento de la
        información relativa su nombre, apellidos, correo electrónico, Direcciones postales y/o electrónicas, Teléfonos fijos-móviles,
        así como cualquier información adicional que nos facilite, con la finalidad de la gestión de la reparación de su equipo y la
        gestión de clientes. Sobre dicha información no se llevarán a cabo decisiones automatizadas así como podrá ejercer los
        derechos de acceso, rectificación, supresión, limitación, portabilidad y oposición conforme se detalla en la Política de
        Privacidad, así como cualquier reclamación que estime ante la AEPD. Para más información consulte nuestra política de
        privacidad:
        <a class="text-green" style="text-decoration: none;" href="https://myfixpert.com/politica-de-privacidad/">https://myfixpert.com/politica-de-privacidad/</a>
        A su vez le informamos que los datos personales que puedan estar almacenados en el equipo informático objeto de la
        reparación no serán tratados por el responsable del tratamiento Éxxita Be Circular, S.A.
    </p>

    <hr>

    <h4>9. LEGISLACIÓN APLICABLE Y JURISDICCIÓN COMPETENTE</h4>
    <p>Las partes acuerdan expresamente que el presente contrato se regirá y será interpretado, en todos sus términos y
        condiciones, conforme a la vigente legislación española y se someten para cualesquiera cuestiones o divergencias que
        pudieren suscitarse a la jurisdicción y competencia de los Juzgados y Tribunales correspondientes al domicilio del cliente o a
        los del lugar de cumplimiento de la obligación contractual.
        <br>
        Para presentar reclamaciones en el uso de nuestros servicios, puede dirigirse por correo a la dirección electrónica o física
        indicada en el apartado “Identificación”, comprometiéndonos a buscar en todo momento una solución amistosa del conflicto.
    </p>

    <hr>

    <h4>10. ALCANCE ALQUILER</h4>
    <p>Mediante este servicio Éxxita Be Circular, S.A. ofrece determinados dispositivos electrónicos de valor como
        ordenadores, smartphones, monitores, gaming de alta gama, smartwatchs, dispositivos de limpieza del hogar, etc. en alquiler
        al usuario. En las Condiciones, normalmente nos referimos a estos como “Producto” o a todos ellos colectivamente como
        “Productos”. Nuestros Servicios también incluyen nuestros sitios web (incluyendo este), y cualquier otra información,
        tecnología y servicios proporcionados o puestos a su disposición de otra manera a nuestra discreción.
    </p>

    <hr>

    <h4>11. CONDICIONES ALQUILER</h4>
    <p>11.1 Para alquilar nuestros productos debe ser mayor de edad y estar capacitado para usar nuestros productos con la
        diligencia debida para no dañarlos. Esto también puede significar no utilizar ciertos Productos. También tenga en cuenta que
        no proporcionamos directrices o consejos. No manipule, destroce o trate de obtener acceso no autorizado a nuestros
        Productos. Hacemos todo lo posible para mantener cada producto en buenas condiciones, pero se desgastan debido a las
        necesidades de uso de nuestros clientes. Realice una comprobación de seguridad en el Producto antes de utilizarlo, incluidas
        todas estas comprobaciones: Comprobar su estado general, el estado de las piezas, enchufes y fuentes de alimentación. Algo
        le puede haber pasado al Producto desde la última comprobación de su estado, por ello necesitamos que nos indique si algo
        no está funcionando como debería si se da cuenta de ello antes que nosotros. Por favor, avísenos tan pronto como pueda
        contactándonos en infoflex@cygit.com. Si nota cualquiera de estos u otros problemas en un Producto antes de su uso, no lo
        utilice. Si nota algo durante el uso, detenga su uso tan pronto como pueda de la forma más segura posible. Si no informa de
        estos problemas es posible que se los achaquemos a usted, incluso puede ser responsable de los costes asociados.
    </p>
    <br>
    <br>
    <p class="font-weight-bold">11.2. Recogida del Producto.</p>
    <br>
    <p>Es responsabilidad del remitente realizar un embalaje adecuado en función de la tipología del dispositivo, así como de
        etiquetar el envío con datos completos de remitente y número de localizador (referencia facilitada en la documentación del
        proceso y en el portal del cliente), incluyendo teléfonos de contacto. Queremos que tu envío siempre llegue a su destino en las
        mejores condiciones posibles, por ello te aconsejamos sobre cómo embalarlo en función del tipo de mercancía que quieras
        enviar:
    </p>
    <br>
    <br>
    <p class="text-muted">Dispositivos móviles y tablets:</p>
    <br>
    <p>Protección de cartón y plástico burbuja en cantidad para envolver los dispositivos. Aconsejable también utilización de
        poliespán. El embalaje idóneo sería dentro de la caja del dispositivo original si se conserva.</p>
    <br>
    <p class="text-muted">Ordenadores y portátiles:</p>
    <br>
    <p>Cartón duro con protecciones internas de poliespán y/o plástico burbuja que dejen inmovilizado el material.
    </p>
    <br>
    <br>
    <p>La empresa de transporte recogerá el paquete en la dirección indicada en el proceso de contratación del servicio. La dirección
        podrá ser editada o modificada desde el portal del cliente.
        Si no está de acuerdo con algún cargo, puede ponerse en contacto con nosotros en infoflex@cygit.com para impugnarlo.
        Accidentes o Daños a los Productos en General. Debe informarnos de cualquier daño, rotura, o de un Producto robado o
        perdido tan pronto como sea posible. Si un Producto es robado, debe presentar una denuncia al departamento de policía local
        dentro de las siguientes 24 horas.
    </p>

    <hr>

    <h4>12. TRATE NUESTROS PRODUCTOS COMO SI FUERAN SUYOS</h4>
    <p>Si todos los clientes tratan nuestros Productos con respeto, dándoles un uso adecuado, habrá más disponibles para usar y en
        mejores condiciones. Nuestros Productos desafortunadamente no son indestructibles, así que cuídelos bien cuando estén en
        sus manos. Esperamos que, además del desgaste normal, devuelva un Producto en las mismas condiciones en que lo recibió.
        Si se recibe con algún tipo de daño estético o funcional, ya sea debido al uso, o no se devuelve adecuadamente y se producen
        daños, usted será responsable de los costes asociados. En caso de que esta situación se produjera, esto puede generar un
        cargo desde el 10% hasta el 60% del valor de mercado del dispositivo: este importe se determina en función de los costes de
        piezas, reparación o reemplazo del dispositivo correspondiente. Los mismos cargos se aplicarán a cualquier Producto que no
        sea devuelto dentro de las 48 horas posteriores al servicio de alquiler será considerado perdido o robado y, según las
        circunstancias, es posible que tengamos que presentar una denuncia policial contra usted. También nos reservamos el
        derecho de tomar contra usted aquellas medidas que la ley permita, incluso responsabilizarlo por el valor del Producto.
        Puedes alquilar un producto desde un mes hasta veinticuatro meses. Lleve un registro de su tiempo ya que no lo hacemos por
        usted. Debe devolverlo dentro del periodo de tiempo alquilado. Cada producto tiene una tarifa de alquiler mensual máxima
        fijada en la web. Cuando devuelva un Producto con retraso, se le cobrará el menor importe entre los cargos de alquiler
        acumulados en función de nuestros precios en la Web o la tarifa máxima mensual en proporción diaria al número de días con
        retraso.
    </p>

    <hr>

    <h4>13. CONDICIONES FINANCIERAS ALQUILER</h4>
    <p>13.1 Precios y Pago. Puede alquilar nuestros Servicios por un periodo mínimo de un mes y máximo de 24 meses. Todos los
        precios se pueden encontrar en la Web. El pago se realiza por cuotas mensuales. La primera cuota se paga al alquilar el
        producto en la web y el mes comienza a contar desde que usted recibe el producto. El producto se considerará recibido a
        efectos de comienzo de disfrute del periodo del alquiler, después de tres intentos de entrega de la compañía de transporte. De
        ser necesario el tercer intento de entrega generará coste adicional a facturar en la siguiente mensualidad. Tenga en cuenta
        que podemos cambiar los precios de nuestros Servicios según lo consideremos necesario o apropiado para nuestro negocio.
        Nuestros precios incluyen impuestos (como los impuestos indirectos) y otros cargos gubernamentales aplicables. Todos estos
        se cargarán en el método de pago indicado en su cuenta en función de su uso. Automáticamente cobraremos y retendremos
        los impuestos aplicables si así lo exige la ley. Todos los pagos que se nos realicen se harán a través de un procesador de
        pagos externo en la forma de pago aceptada por ellos. Si no está de acuerdo con los cargos que hemos realizado en su
        cuenta, debe informarnos lo antes posible una vez que tenga conocimiento del problema.
        13.2 Códigos Promocionales. En varias ocasiones, proporcionamos códigos promocionales para alquileres con descuento, que
        pueden estar sujetos a términos adicionales que se le presenten en relación con una promoción en particular que deberá
        revisar cuidadosamente y aceptar si desea participar en ella. Entienda que estas son ofertas únicas, solo se pueden canjear a
        través de la Web y podemos modificarlas o cancelarlas en cualquier momento. Los limitamos a 1 por usuario y cuenta, no
        pueden combinarse con otras ofertas, expirarán de acuerdo con sus términos y no son transferibles ni se pueden revender.
        13..3. Derechos de Cancelación del Consumidor. Tiene derecho a cancelar el alquiler del producto. Se le reembolsará los días
        que quiera cancelar desde el día siguiente al día que entregue el producto o productos a la agencia de transporte autorizada.
        Para cancelar un alquiler, contáctenos en infoflex@cygit.com y proporcione su nombre, apellidos, mail y referencia del
        producto que desea devolver.
    </p>

    <hr>

    <h4>Información Personal</h4>
    <p>Recopilamos y utilizamos su información personal para proporcionarle el servicio de alquiler del producto. Por ejemplo,
        procesamos información tal como su nombre, detalles de contacto y detalles de pago para que pueda registrarse en una
        cuenta con nosotros y utilizar nuestros Servicios. Para obtener más información sobre cómo utilizamos su información
        personal, lea nuestra Política de privacidad, a la que puede acceder en 
        <a class="text-green" style="text-decoration: none;" href="www.XXXX/privacy">www.XXXX/privacy</a>.
    </p>

    <table class="table table-bordered text-center mt-4">
        <tbody>
          <tr>
            <th><img style="width: 100px;" src="../../../assets/img/logoCyG.png"></th>
            <td class="text-green">Términos y condiciones web Flex</td>
            <td>Rev 16/11/2021</td>
          </tr>
        </tbody>
    </table>

    <p>Última actualización: 16 de noviembre de 2021.</p>



</div>
