<div *ngIf="deshabilitado" class="container-fluid">
    <div class="row">
        <div class="col">
            <h4 class="text-center mt-4">Servicio operativo en los próximos días. Permanece atento a nuestros canales en redes sociales</h4>
        </div>
    </div>
</div>

<img *ngIf="deshabilitado" class="img-fluid mt-4 mb-4" src="../../../assets/img/proxima_apertura_alquila.png" alt="Responsive image">

<div *ngIf="!deshabilitado" id="shop-container">
    <div class="text-center" *ngIf="buy == false">
        <img class="img-fluid mt-2 mb-2" src="../../../assets/img/banner-alquila.png" alt="Responsive image">
        <img class="img-fluid mb-4 px-2" style="max-height: 50px;" src="../../../assets/img/mejor-tecnologia.png" alt="Responsive image">
    </div>

    <div class="row justify-content-around" *ngIf="buy == false">
        
        <div class="col-10 col-md-6 col-lg-5 text-center mb-4 mb-md-0">
            <img class="img-fluid mt-4 mb-4" src="../../../assets/img/pasos.png" alt="Responsive image">
            <p class="text-muted text-ayuda-img">* Plazo garantizado desde aprobación/pago del presupuesto para servicios en Península y Baleares</p>
        </div>
        <div class="col-10 col-md-6 col-lg-7 mt-4 mb-4 mb-md-0">
            <div class="bloq-textos">
                <div class="row">
                    <h2 class="font-weight-bold d-inline">
                        Alquila dispositivos reutilizados o nuevos con la máxima flexibilidad
                        <span class="font-weight-normal">, y la garantía de un referente en el sector.</span>
                    </h2>
                </div>
                <div class="row">
                    <h2 class="font-weight-bold d-inline">Elige el tiempo 
                        <span class="font-weight-normal d-inline">que más se ajuste a tus necesidades.</span>
                        Siempre podrás ampliarlo o cambiar de dispositivo.
                    </h2>
                </div>
                <div class="row mb-4">
                    <h2 class="font-weight-bold d-inline">
                        Olvídate de los costes de mantenimiento y/o reparación, 
                        <span class="font-weight-normal d-inline">con Flex siempre tendrás tu tecnología lista para su uso sin coste adicional.</span>
                    </h2>
                </div>       
            </div>
        </div>
                

        <!-- <img class="img-fluid mt-4 mb-4" src="../../../assets/img/pasos-texto.png" alt="Responsive image"> -->
    </div>

    <div class="text-center" *ngIf="buy != false">
        <img class="img-fluid mt-2 mb-2" src="../../../assets/img/compra/imagen-carrusel-02-200p.png" alt="Responsive image">
    </div>

    <div *ngIf="categoria == undefined">
        <div class="container mt-4 mb-4">
            <div class="row d-flex justify-content-center" *ngIf="buy == false">
                <div class="col-lg-4 mt-2 mb-2 col-md-6 mb-md-0" (mouseover)="changeSmartphone=true" (mouseout)="changeSmartphone=false">
                    <!-- <a id="link" href="products/category/smartphone"> -->
                    <a id="link" [attr.href]="paramTest ? 'products/category/smartphone?test' : 'products/category/smartphone'">
                        <div *ngIf="!changeSmartphone" id="fondo1">
                                <img class="img-fluid mt-4 mb-4" src="../../../assets/img/moviles.png" alt="Responsive image">
                                <h1>Smartphones</h1>
                        </div>

                        <div *ngIf="changeSmartphone" id="fondo">
                                <img class="img-fluid mt-4 mb-4" src="../../../assets/img/moviles.png" alt="Responsive image">
                                <h1>Smartphones</h1>
                        </div>
                    </a>
                </div>
                <div class="col-lg-4 mt-2 mb-2 col-md-6 mb-md-0" (mouseover)="changePortatil=true" (mouseout)="changePortatil=false">
                    <!-- <a id="link" href="products/category/ordenadores"> -->
                    <a id="link" [attr.href]="paramTest ? 'products/category/ordenadores?test' : 'products/category/ordenadores'">
                        <div *ngIf="!changePortatil" id="fondo1">
                                <img class="img-fluid mt-4 mb-4" src="../../../assets/img/portatil.png" alt="Responsive image">
                                <h1>Ordenadores</h1>
                        </div>
                        <div *ngIf="changePortatil" id="fondo">
                                <img class="img-fluid mt-4 mb-4" src="../../../assets/img/portatil.png" alt="Responsive image">
                                <h1>Ordenadores</h1>
                        </div>
                    </a>
                </div>
                <div class="col-lg-4 mt-2 mb-2 col-md-6 mb-md-0" (mouseover)="changeTablet=true" (mouseout)="changeTablet=false">
                    <!-- <a id="link" href="products/category/tablet"> -->
                    <a id="link" [attr.href]="paramTest ? 'products/category/tablet?test' : 'products/category/tablet'">
                        <div *ngIf="!changeTablet" id="fondo1">
                                <img class="img-fluid mt-4 mb-4" src="../../../assets/img/tablet.png" alt="Responsive image">
                                <h1>Tablets</h1>
                        </div>
                        <div *ngIf="changeTablet" id="fondo">
                                <img class="img-fluid mt-4 mb-4" src="../../../assets/img/tablet.png" alt="Responsive image">
                                <h1>Tablets</h1>
                        </div>
                    </a>
                </div>
                <div class="col-lg-4 mt-2 mb-2 col-md-6 mb-md-0" (mouseover)="changeMonitor=true" (mouseout)="changeMonitor=false">
                    <!-- <a id="link" href="products/category/monitor"> -->
                    <a id="link" [attr.href]="paramTest ? 'products/category/monitor?test' : 'products/category/monitor'">
                        <div *ngIf="!changeMonitor" id="fondo1">
                                <img class="img-fluid mt-4 mb-4" src="../../../assets/img/monitor.png" alt="Responsive image">
                                <h1>Monitores</h1>
                        </div>
                        <div *ngIf="changeMonitor" id="fondo">
                                <img class="img-fluid mt-4 mb-4" src="../../../assets/img/monitor.png" alt="Responsive image">
                                <h1>Monitores</h1>
                        </div>
                    </a>
                </div>
                <div class="col-lg-4 mt-2 mb-2 col-md-6 mb-md-0" (mouseover)="changeSmartWatch=true" (mouseout)="changeSmartWatch=false">
                    <!-- <a id="link" href="products/category/smartwatch"> -->
                    <a id="link" [attr.href]="paramTest ? 'products/category/smartwatch?test' : 'products/category/smartwatch'">
                        <div *ngIf="!changeSmartWatch" id="fondo1">
                                <img class="img-fluid mt-4 mb-4" src="../../../assets/img/smartwatches.png" alt="Responsive image">
                                <h1>Smart Watches</h1>
                        </div>
                        <div *ngIf="changeSmartWatch" id="fondo">
                                <img class="img-fluid mt-4 mb-4" src="../../../assets/img/smartwatches.png" alt="Responsive image">
                                <h1>Smart Watches</h1>
                        </div>
                    </a>
                </div>
            </div>

            <div class="row d-flex justify-content-center" *ngIf="buy != false">
                <div class="col-lg-4 mt-2 mb-2 col-md-6 mb-md-0" (mouseover)="changeSmartphone=true" (mouseout)="changeSmartphone=false">
                    <a id="link" href="products/buy/category/smartphone">
                        <div *ngIf="!changeSmartphone" id="fondo1">
                                <img class="img-fluid mt-4 mb-4" src="../../../assets/img/moviles.png" alt="Responsive image">
                                <h1>Smartphones</h1>
                        </div>

                        <div *ngIf="changeSmartphone" id="fondo">
                                <img class="img-fluid mt-4 mb-4" src="../../../assets/img/moviles.png" alt="Responsive image">
                                <h1>Smartphones</h1>
                        </div>
                    </a>
                </div>
                <div class="col-lg-4 mt-2 mb-2 col-md-6 mb-md-0" (mouseover)="changePortatil=true" (mouseout)="changePortatil=false">
                    <a id="link" href="products/buy/category/ordenadores">
                        <div *ngIf="!changePortatil" id="fondo1">
                                <img class="img-fluid mt-4 mb-4" src="../../../assets/img/portatil.png" alt="Responsive image">
                                <h1>Ordenadores</h1>
                        </div>
                        <div *ngIf="changePortatil" id="fondo">
                                <img class="img-fluid mt-4 mb-4" src="../../../assets/img/portatil.png" alt="Responsive image">
                                <h1>Ordenadores</h1>
                        </div>
                    </a>
                </div>
                <div class="col-lg-4 mt-2 mb-2 col-md-6 mb-md-0" (mouseover)="changeTablet=true" (mouseout)="changeTablet=false">
                    <a id="link" href="products/buy/category/tablet">
                        <div *ngIf="!changeTablet" id="fondo1">
                                <img class="img-fluid mt-4 mb-4" src="../../../assets/img/tablet.png" alt="Responsive image">
                                <h1>Tablets</h1>
                        </div>
                        <div *ngIf="changeTablet" id="fondo">
                                <img class="img-fluid mt-4 mb-4" src="../../../assets/img/tablet.png" alt="Responsive image">
                                <h1>Tablets</h1>
                        </div>
                    </a>
                </div>
                <div class="col-lg-4 mt-2 mb-2 col-md-6 mb-md-0" (mouseover)="changeMonitor=true" (mouseout)="changeMonitor=false">
                    <a id="link" href="products/buy/category/monitor">
                        <div *ngIf="!changeMonitor" id="fondo1">
                                <img class="img-fluid mt-4 mb-4" src="../../../assets/img/monitor.png" alt="Responsive image">
                                <h1>Monitores</h1>
                        </div>
                        <div *ngIf="changeMonitor" id="fondo">
                                <img class="img-fluid mt-4 mb-4" src="../../../assets/img/monitor.png" alt="Responsive image">
                                <h1>Monitores</h1>
                        </div>
                    </a>
                </div>
                <div class="col-lg-4 mt-2 mb-2 col-md-6 mb-md-0" (mouseover)="changeSmartWatch=true" (mouseout)="changeSmartWatch=false">
                    <a id="link" href="products/buy/category/smartwatch">
                        <div *ngIf="!changeSmartWatch" id="fondo1">
                                <img class="img-fluid mt-4 mb-4" src="../../../assets/img/smartwatches.png" alt="Responsive image">
                                <h1>Smart Watches</h1>
                        </div>
                        <div *ngIf="changeSmartWatch" id="fondo">
                                <img class="img-fluid mt-4 mb-4" src="../../../assets/img/smartwatches.png" alt="Responsive image">
                                <h1>Smart Watches</h1>
                        </div>
                    </a>
                </div>
            </div>
        </div>

        <div class="flex-column" style="margin-top: 15px;">
            <label>NUESTRAS MARCAS</label>
            <hr>

            <ngb-carousel [showNavigationIndicators]="false">
                <ng-template ngbSlide>
                    <div class="flex-row between">
                        <img src="../../../assets/img/marcas/Logo01-Lenovo.png" alt="Random first slide"
                            style="width: 20% !important;">

                        <img src="../../../assets/img/marcas/Logo02-Asus.png" alt="Random second slide"
                            style="width: 20% !important;">

                        <img src="../../../assets/img/marcas/Logo03-Toshiba.png" alt="Random third slide"
                            style="width: 20% !important;">

                        <img src="../../../assets/img/marcas/Logo04-LG.png" alt="Random third slide"
                            style="width: 20% !important;">
                    </div>
                </ng-template>
                <ng-template ngbSlide class="flex-row between">
                    <div class="flex-row between">
                        <img src="../../../assets/img/marcas/Logo05-Brother.png" alt="Random first slide"
                            style="width: 20% !important;">

                        <img src="../../../assets/img/marcas/Logo06-Qnap.png" alt="Random second slide"
                            style="width: 20% !important;">

                        <img src="../../../assets/img/marcas/Logo07-MSI.png" alt="Random third slide"
                            style="width: 20% !important;">

                        <img src="../../../assets/img/marcas/Logo08-Apple.png" alt="Random third slide"
                            style="width: 20% !important;">
                    </div>
                </ng-template>
                <ng-template ngbSlide class="flex-row between">
                    <div class="flex-row between">
                        <img src="../../../assets/img/marcas/Logo09-Dynabook.png" alt="Random first slide"
                            style="width: 20% !important;">

                        <img src="../../../assets/img/marcas/Logo10-Alcatel.png" alt="Random second slide"
                            style="width: 20% !important;">

                        <img src="../../../assets/img/marcas/Logo11-Samsung.png" alt="Random third slide"
                            style="width: 20% !important;">

                        <img src="../../../assets/img/marcas/Logo12-Garmin.png" alt="Random third slide"
                            style="width: 20% !important;">
                    </div>
                </ng-template>
            </ngb-carousel>
        </div>

        
        <div class="flex-column" style="margin-top: 15px;" *ngIf="masAlquilados && screenWidth != undefined && screenWidth > 990">
            <label *ngIf="buy == false">DISPOSITIVOS MÁS ALQUILADOS</label>
            <label *ngIf="buy == true">DISPOSITIVOS MÁS COMPRADOS</label>
            <hr>
        
            <div class="flex-row start stretch">
                <div class="flex-column stretch grow1 div-dispositivo-alquilado" *ngFor="let movimiento of masAlquilados;">
                    <a href="/products/{{movimiento.elemento.ID}}" *ngIf="movimiento.elemento && buy == false">
                        <div class="green-border-div">
                            <!--<button type="button" class="btn btn-primary">
                                <img src="../../../assets/img/shopping-bag.png">
                            </button>-->
                            <div class="div-img-content">
                                <div class="flex-column center vcenter">
                                    <img [src]="(movimiento.elemento.urlImagenes && movimiento.elemento.urlImagenes.length>0 && movimiento.elemento.urlImagenes[0].url)?rutaImagen+movimiento.elemento.urlImagenes[0].url:''">
                                </div>
                            </div>
                        </div>
                    </a>

                    <a href="/products/buy/{{movimiento.elemento.ID}}" *ngIf="movimiento.elemento && buy != false">
                        <div class="green-border-div">
                            <!--<button type="button" class="btn btn-primary">
                                <img src="../../../assets/img/shopping-bag.png">
                            </button>-->
                            <div class="div-img-content">
                                <div class="flex-column center vcenter">
                                    <img [src]="(movimiento.elemento.urlImagenes && movimiento.elemento.urlImagenes.length>0 && movimiento.elemento.urlImagenes[0].url)?rutaImagen+movimiento.elemento.urlImagenes[0].url:''">
                                </div>
                            </div>
                        </div>
                    </a>
        
                    <div class="flex-column" *ngIf="movimiento.elemento">
                        <span class="secondary-text">{{movimiento.elemento.tipo_dispositivo?movimiento.elemento.tipo_dispositivo.name:'-'}}</span>
                        <label>{{movimiento.elemento.name?movimiento.elemento.name:'-'}}</label>
                        <!--<div class="flex-row">
                            <star-rating [value]="movimiento.elemento.rating" [totalstars]="5" checkedcolor="#5bc500" uncheckedcolor="gray"
                            size="24px" [readonly]="true"></star-rating>
                        </div>-->
                        <div *ngIf="buy == false">
                            <span class="secondary-text">desde</span>&nbsp;<label>{{movimiento.elemento.precioMinimo}}€</label>&nbsp;<span class="secondary-text">al mes</span>
                        </div>

                        <div *ngIf="buy != false">
                            <label>{{movimiento.elemento.precioCompra}}€</label>
                        </div>
                        
                        <div>
                            <app-dialog-select-options-update [product]="movimiento.elemento"></app-dialog-select-options-update>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="flex-column" style="margin: 10px;" *ngIf="masAlquilados && screenWidth != undefined && screenWidth <= 990">
            <label *ngIf="buy == false">DISPOSITIVOS MÁS ALQUILADOS</label>
            <label *ngIf="buy == true">DISPOSITIVOS MÁS COMPRADOS</label>
            <hr>
        
            <div class="flex-row start stretch" style="flex-wrap: wrap;">
                <div class="flex-column stretch grow1 div-dispositivo-alquilado xs" *ngFor="let movimiento of masAlquilados;">
                    <a href="/products/{{movimiento.elemento.ID}}" *ngIf="movimiento.elemento && buy == false">
                        <div class="green-border-div">
                            <!--<button type="button" class="btn btn-primary">
                                <img src="../../../assets/img/shopping-bag.png">
                            </button>-->
                            <div class="div-img-content">
                                <div class="flex-column center vcenter">
                                    <img [src]="(movimiento.elemento.urlImagenes && movimiento.elemento.urlImagenes.length>0 && movimiento.elemento.urlImagenes[0].url) ?rutaImagen+movimiento.elemento.urlImagenes[0].url:''">
                                </div>
                            </div>
                        </div>
                    </a>

                    <a href="/products/buy/{{movimiento.elemento.ID}}" *ngIf="movimiento.elemento && buy != false">
                        <div class="green-border-div">
                            <!--<button type="button" class="btn btn-primary">
                                <img src="../../../assets/img/shopping-bag.png">
                            </button>-->
                            <div class="div-img-content">
                                <div class="flex-column center vcenter">
                                    <img [src]="(movimiento.elemento.urlImagenes && movimiento.elemento.urlImagenes.length>0 && movimiento.elemento.urlImagenes[0].url) ?rutaImagen+movimiento.elemento.urlImagenes[0].url:''">
                                </div>
                            </div>
                        </div>
                    </a>
        
                    <div class="flex-column" *ngIf="movimiento.elemento">
                        <span class="secondary-text">{{movimiento.elemento.tipo_dispositivo?movimiento.elemento.tipo_dispositivo.name:'-'}}</span>
                        <label style="font-size: 3vw;">{{movimiento.elemento.name?movimiento.elemento.name:'-'}}</label>
                        <!--<div class="flex-row">
                            <star-rating [value]="movimiento.elemento.rating" [totalstars]="5" checkedcolor="#5bc500" uncheckedcolor="gray"
                            size="24px" [readonly]="true"></star-rating>
                        </div>-->
                        <div *ngIf="buy == false">
                            <span class="secondary-text">desde</span>&nbsp;<label>{{movimiento.elemento.precioMinimo}}€</label>&nbsp;<span class="secondary-text">al mes</span>
                        </div>

                        <div *ngIf="buy != false">
                            <label>{{movimiento.elemento.precioCompra}}€</label>
                        </div>
                        
                        <div>
                            <app-dialog-select-options-update [product]="movimiento.elemento"></app-dialog-select-options-update>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="categoria != undefined">

        <div class="container-lg mt-4 mb-4">
            <h6>
                HOME
                <i class="fa fa-angle-right" style="margin-left: 10px; margin-right: 10px;" aria-hidden="true"></i>
                TIENDA
                <hr>
            </h6>

        </div>

        <form #mainForm="ngForm" [formGroup]="formFiltros">
        <div class="row" *ngIf="products && screenWidth != undefined && screenWidth > 990">

            <div class="col-3 mt-4 mb-4">
                <div #categorias>
                    <h6 style="font-weight: bold;">CATEGORÍAS</h6>
                    <hr>

                    <div class="col-12 mb-4">
                        <div class="flex-column">
                            <label><input type="radio" name="category" formControlName="category" value="1">&nbsp;Smartphones</label>
                            <label><input type="radio" name="category" formControlName="category" value="72">&nbsp;Ordenadores</label>
                            <label><input type="radio" name="category" formControlName="category" value="8">&nbsp;Tablets</label>
                            <label><input type="radio" name="category" formControlName="category" value="47">&nbsp;Monitores</label>
                            <label><input type="radio" name="category" formControlName="category" value="78">&nbsp;Smart Watches</label>
                        </div>
                    </div>
                </div>

                <div #precioMensual class="mt-4 mb-4">
                    <h6 style="font-weight: bold;">PRECIO MENSUAL</h6>
                    <hr>

                    <div class="col-12">
                        <div class="row">

                            <ng5-slider [(ngModel)]="sliderValue" [ngModelOptions]="{standalone: true}" [(value)]="minValue" [(highValue)]="maxValue"
                                [options]="optionsPrice"></ng5-slider>

                        </div>
                    </div>

                </div>

                <div #periodoAlquiler class="mt-2" *ngIf="mesesTotales.length > 0 && buy == false">
                    <h6 style="font-weight: bold;">PERIODO MÍNIMO DE ALQUILER</h6>
                    <hr>
                    <div class="row">

                        <div class="col-3 mt-2" *ngFor="let mes of mesesTotales">

                            <button  [ngClass]="{'border-secondary': monthSelected != mes, 'btn-success': monthSelected == mes}" class="btn border-secondary"
                                (click)="selectMonth(mes)">
                                <p>{{mes}}+</p>mes
                            </button>


                        </div>

                    </div>

                    <div class="row mt-2">
                        <div class="col-3"></div>
                        <div class="col-3">
                            <button *ngIf="monthSelected != undefined" class="btn btn-danger btn-sm"
                                (click)="deleteSelectedMonth()">
                                <p>Deseleccionar</p>
                            </button>
                        </div>
                    </div>

                </div>

                <div #marcas class="mt-4">
                    <h6 style="font-weight: bold;">MARCAS</h6>
                    <hr>

                    <div class="col-12">
                        <div class="form-group flex-column">

                            <label *ngFor="let marca of marcasMostrar">
                                <input class="form-check-input" type="checkbox" name="marcas" value="{{marca.id}}" (change)="changeMarca($event)">
                                &nbsp;{{marca.name}}
                            </label>
                        </div>

                        <!--<a class="text-muted" href="/#">Ver todas las marcas</a>-->
                    </div>
                    
                    <div class="row">
                        <button class="btn btn-outline-success" type="submit" (click)="filtrar()">Filtrar</button>
                        <button class="btn btn-outline-danger mt-2" type="submit" (click)="deleteFilter()">Borrar
                            filtros</button>
                    </div>

                </div>

            </div>

            <div class="col-9 mt-4 mb-4">
                <div class="menu-legend2 flex-row between">

                    <!--<div class="col-7">
                        <p class="text-muted"> Ordenar por:
                            <button class="btn border-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Ordenar por defecto
                            </button>
                        </p>
                    </div>-->
                    <div class="flex-row vcenter">
                        <label class="text-muted"> Mostrar: &nbsp;</label>
                        <div ngbDropdown>
                            <button class="btn border-secondary" 
                                id="dropdownBasic1" ngbDropdownToggle>{{pageSize}}</button>
                            <div ngbDropdownMenu>
                                <button ngbDropdownItem (click)="cambiarSizePag(12)">12</button>
                                <button ngbDropdownItem (click)="cambiarSizePag(24)">24</button>
                                <button ngbDropdownItem (click)="cambiarSizePag(48)">48</button>
                            </div>
                        </div>
                    </div>
                    <!--<div>
                        <button class="btn"><i class="fas fa-border-all"></i></button>
                        <button class="btn"><i class="fas fa-th-list"></i></button>
                    </div>-->

                </div>

                <div class="container">
                    <div class="row mt-4">
                        <div class="flex-row start" style="flex-wrap: wrap;">
                            <div class="flex-column stretch grow1 div-dispositivo-alquilado buscador" *ngFor="let movimiento of products;">
                                <a href="/products/{{movimiento.elemento.ID}}" *ngIf="movimiento.elemento && buy == false">
                                    <div class="green-border-div">
                                        <!--<button type="button" class="btn btn-primary">
                                            <img src="../../../assets/img/shopping-bag.png">
                                        </button>-->
                                        <div class="div-img-content">
                                            <div class="flex-column center vcenter">
                                                <img [src]="(movimiento.elemento.urlImagenes && movimiento.elemento.urlImagenes.length>0 && movimiento.elemento.urlImagenes[0].url)?rutaImagen+movimiento.elemento.urlImagenes[0].url:''">
                                            </div>
                                        </div>
                                    </div>
                                </a>

                                <a href="/products/buy/{{movimiento.elemento.ID}}" *ngIf="movimiento.elemento && buy != false">
                                    <div class="green-border-div">
                                        <!--<button type="button" class="btn btn-primary">
                                            <img src="../../../assets/img/shopping-bag.png">
                                        </button>-->
                                        <div class="div-img-content">
                                            <div class="flex-column center vcenter">
                                                <img [src]="(movimiento.elemento.urlImagenes && movimiento.elemento.urlImagenes.length>0 && movimiento.elemento.urlImagenes[0].url) ?rutaImagen+movimiento.elemento.urlImagenes[0].url:''">
                                            </div>
                                        </div>
                                    </div>
                                </a>
                    
                                <div class="flex-column" *ngIf="movimiento.elemento">
                                    <span class="secondary-text">{{movimiento.elemento.tipo_dispositivo?movimiento.elemento.tipo_dispositivo.name:'-'}}</span>
                                    <label>{{movimiento.elemento.name?movimiento.elemento.name:'-'}}</label>
                                    <!--<div class="flex-row">
                                        <star-rating [value]="movimiento.elemento.rating" [totalstars]="5" checkedcolor="#5bc500" uncheckedcolor="gray"
                                        size="24px" [readonly]="true"></star-rating>
                                    </div>-->
                                    <div *ngIf="buy == false">
                                        <span class="secondary-text">desde</span>&nbsp;<label>{{movimiento.elemento.precioMinimo}}€</label>&nbsp;<span class="secondary-text">al mes</span>
                                    </div>
                
                                    <div *ngIf="buy != false">
                                        <label>{{movimiento.elemento.precioCompra}}€</label>
                                    </div>
                                    
                                    <div>
                                        <app-dialog-select-options-update [product]="movimiento.elemento"></app-dialog-select-options-update>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr class="mt-4">

                        <div class="flex-row between">
                            <div class="flex-row vcenter">
                                <label class="text-muted"> Mostrar: &nbsp;</label>
                                <div ngbDropdown>
                                    <button class="btn border-secondary" 
                                        id="dropdownBasic1" ngbDropdownToggle>{{pageSize}}</button>
                                    <div ngbDropdownMenu>
                                        <button ngbDropdownItem (click)="cambiarSizePag(12)">12</button>
                                        <button ngbDropdownItem (click)="cambiarSizePag(24)">24</button>
                                        <button ngbDropdownItem (click)="cambiarSizePag(48)">48</button>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <ngb-pagination [collectionSize]="totalProductos" [pageSize]="pageSize" [(page)]="numPage" aria-label="Paginación" [maxSize]="5" [ellipses]="false" (pageChange)="filtrar(false)"></ngb-pagination>
                            </div>

                        </div>

                    </div>
                </div>

            </div>
        </div>



        <div class="row" *ngIf="products && screenWidth != undefined && screenWidth <= 990">

            <div class="row">
                <div class="col-9"></div>
                <div class="col-3">
                    <button class="btn btn-sm btn-outline-dark mb-2 mr-2" (click)="openVerticallyCentered(content)">FILTROS
                        <i class="fas fa-filter"></i></button>
                </div>
            </div>

            <div class="col mb-4">
                <div class="flex-row between vcenter" style="margin-bottom: 10px;">

                    <!--<div class="col-7">
                        <p class="text-muted"> Ordenar por:
                            <button class="btn border-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Ordenar por defecto
                            </button>
                        </p>
                    </div>-->
                    <div class="flex-row vcenter">
                        <label class="text-muted"> Mostrar: &nbsp;</label>
                        <div ngbDropdown>
                            <button class="btn border-secondary" 
                                id="dropdownBasic1" ngbDropdownToggle>{{pageSize}}</button>
                            <div ngbDropdownMenu>
                                <button ngbDropdownItem (click)="cambiarSizePag(12)">12</button>
                                <button ngbDropdownItem (click)="cambiarSizePag(24)">24</button>
                                <button ngbDropdownItem (click)="cambiarSizePag(48)">48</button>
                            </div>
                        </div>
                    </div>
                    <!--<div class="col-2">
                        <button class="btn"><i class="fas fa-border-all"></i></button>
                        <button class="btn"><i class="fas fa-th-list"></i></button>
                    </div>-->

                </div>

                <div class="container">
                    <div class="row">
                        <div class="flex-row start" style="flex-wrap: wrap;">
                            <div class="flex-column stretch grow1 div-dispositivo-alquilado xs" *ngFor="let movimiento of products;">
                                <a href="/products/{{movimiento.elemento.ID}}" *ngIf="movimiento.elemento && buy == false">
                                    <div class="green-border-div">
                                        <!--<button type="button" class="btn btn-primary">
                                            <img src="../../../assets/img/shopping-bag.png">
                                        </button>-->
                                        <div class="div-img-content">
                                            <div class="flex-column center vcenter">
                                                <img [src]="(movimiento.elemento.urlImagenes && movimiento.elemento.urlImagenes.length>0 && movimiento.elemento.urlImagenes[0].url) ?rutaImagen+movimiento.elemento.urlImagenes[0].url:''">
                                            </div>
                                        </div>
                                    </div>
                                </a>

                                <a href="/products/buy/{{movimiento.elemento.ID}}" *ngIf="movimiento.elemento && buy != false">
                                    <div class="green-border-div">
                                        <!--<button type="button" class="btn btn-primary">
                                            <img src="../../../assets/img/shopping-bag.png">
                                        </button>-->
                                        <div class="div-img-content">
                                            <div class="flex-column center vcenter">
                                                <img [src]="(movimiento.elemento.urlImagenes && movimiento.elemento.urlImagenes.length>0 && movimiento.elemento.urlImagenes[0].url) ?rutaImagen+movimiento.elemento.urlImagenes[0].url:''">
                                            </div>
                                        </div>
                                    </div>
                                </a>
                    
                                <div class="flex-column" *ngIf="movimiento.elemento">
                                    <span class="secondary-text">{{movimiento.elemento.tipo_dispositivo?movimiento.elemento.tipo_dispositivo.name:'-'}}</span>
                                    <label style="font-size: 3vw;">{{movimiento.elemento.name?movimiento.elemento.name:'-'}}</label>
                                    <!--<div class="flex-row">
                                        <star-rating [value]="movimiento.elemento.rating" [totalstars]="5" checkedcolor="#5bc500" uncheckedcolor="gray"
                                        size="24px" [readonly]="true"></star-rating>
                                    </div>-->
                                    <div *ngIf="buy == false">
                                        <span class="secondary-text">desde</span>&nbsp;<label>{{movimiento.elemento.precioMinimo}}€</label>&nbsp;<span class="secondary-text">al mes</span>
                                    </div>
                
                                    <div *ngIf="buy != false">
                                        <label>{{movimiento.elemento.precioCompra}}€</label>
                                    </div>
                                    
                                    <div>
                                        <app-dialog-select-options-update [product]="movimiento.elemento"></app-dialog-select-options-update>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr class="mt-4">

                        <div class="flex-row between vcenter">

                            <div class="flex-row vcenter">
                                <label class="text-muted"> Mostrar: &nbsp;</label>
                                <div ngbDropdown>
                                    <button class="btn border-secondary" 
                                        id="dropdownBasic1" ngbDropdownToggle>{{pageSize}}</button>
                                    <div ngbDropdownMenu>
                                        <button ngbDropdownItem (click)="cambiarSizePag(12)">12</button>
                                        <button ngbDropdownItem (click)="cambiarSizePag(24)">24</button>
                                        <button ngbDropdownItem (click)="cambiarSizePag(48)">48</button>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <ngb-pagination [collectionSize]="totalProductos" [pageSize]="pageSize" [(page)]="numPage" aria-label="Paginación" [maxSize]="5" [ellipses]="false" (pageChange)="filtrar(false)"></ngb-pagination>
                            </div>

                        </div>

                    </div>
                </div>

            </div>
        </div>
        </form>


        <ng-template #content let-modal>
            <div class="modal-header">
                <h4 class="modal-title m-0" style="font-size: 24px;">Filtros</h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" style="max-height: 70vh; overflow: hidden; overflow-y: auto;">
                <form #mainForm="ngForm" [formGroup]="formFiltros">
                <div #categorias>
                    <h6 style="font-weight: bold;">CATEGORÍAS</h6>
                    <hr>

                    <div class="col mb-4">
                        <div class="flex-column">
                            <label><input type="radio" name="category" formControlName="category" value="1">&nbsp;Smartphones</label>
                            <label><input type="radio" name="category" formControlName="category" value="72">&nbsp;Ordenadores</label>
                            <label><input type="radio" name="category" formControlName="category" value="8">&nbsp;Tablets</label>
                            <label><input type="radio" name="category" formControlName="category" value="47">&nbsp;Monitores</label>
                            <label><input type="radio" name="category" formControlName="category" value="78">&nbsp;Smart Watches</label>
                        </div>
                    </div>
                </div>

                <div #precioMensual class="mt-4 mb-4">
                    <h6 style="font-weight: bold;">PRECIO MENSUAL</h6>
                    <hr>

                    <div class="col-12">
                        <div class="row">

                            <ng5-slider [(ngModel)]="sliderValue" [ngModelOptions]="{standalone: true}" [(value)]="minValue" [(highValue)]="maxValue"
                                [options]="optionsPrice"></ng5-slider>

                        </div>
                    </div>

                </div>

                <div #periodoAlquiler class="mt-2" *ngIf="buy == false">
                    <h6 style="font-weight: bold;">PERIODO MÍNIMO DE ALQUILER</h6>
                    <hr>
                    <div class="row">

                        <div class="col-3 mt-2" >

                            <button *ngFor="let mes of mesesTotales" [ngClass]="{'border-secondary': monthSelected != mes, 'btn-success': monthSelected == mes}" class="btn border-secondary"
                                (click)="selectMonth(mes)">
                                <p>{{mes}}+</p>mes
                            </button>

                        </div>

                    </div>

                    <div class="row mt-2">
                        <div class="col-3"></div>
                        <div class="col-3">
                            <button *ngIf="monthSelected != undefined" class="btn btn-danger btn-sm"
                                (click)="deleteSelectedMonth()">
                                <p>Deseleccionar</p>
                            </button>
                        </div>
                    </div>

                </div>

                <div #marcas class="mt-4">
                    <h6 style="font-weight: bold;">MARCAS</h6>
                    <hr>

                    <div class="col-12">
                        <label *ngFor="let marca of marcasMostrar">
                            <input class="form-check-input" type="checkbox" name="marcas" value="{{marca.id}}" (change)="changeMarca($event)">
                            &nbsp;{{marca.name}}
                        </label>
                    </div>
                </div>
                </form>

                <div class="row justify-content-center">
                    <div class="col-10">
                        <button class="btn btn-outline-success" style="width: 100%;" (click)="filtrar()">Filtrar</button>
                    </div>
                    <div class="col-10">
                        <button class="btn btn-outline-danger mt-2" style="width: 100%;" (click)="deleteFilter()">Borrar filtros</button>
                    </div>
                </div>

            </div>
        </ng-template>
    </div>
</div>