<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">¿Qué es Garantía10?</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()" >
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <p>Garantía 10 es un servicio de cobertura de garantía para equipamiento informático proporcionado por <label style="color: var(--primary-color);">Éxxita Be Circular</label>.</p>
    <p>Si usted dispone de un <b>contrato en vigor</b>, introduzca aquí el código del mismo facilitado por email tras su contratación.</p>
</div>