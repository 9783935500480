<!--
<div *ngIf="screenWidth != undefined && screenWidth > 770">
    -->
<h5 style="font-weight: bold;">MI CUENTA</h5>

<div *ngIf="profile; else other_profile">
    <a href="/profile" style="color: black;">
        <h6 class="mt-4" style="font-weight: bold;"> Escritorio</h6>
    </a>
</div>


<ng-template #other_profile>
    <a href="/profile">
        <h6 class="mt-4 text-muted"> Escritorio</h6>
    </a>
</ng-template>

<hr>

<div *ngIf="orders; else other_orders">
    <a href="/orders" style="color: black;">
        <h6 class="mt-4" style="font-weight: bold;"> Pedidos</h6>
    </a>
</div>


<ng-template #other_orders>
    <a href="/orders">
        <h6 class="mt-4 text-muted"> Pedidos</h6>
    </a>
</ng-template>


<hr>

<div *ngIf="orders_done; else other_orders_done">
    <a href="/orders" style="color: black;">
        <h6 class="mt-4" style="font-weight: bold;"> Pedidos finalizados</h6>
    </a>
</div>

<ng-template #other_orders_done>
    <a href="/orders/done">
        <h6 class="mt-4 text-muted"> Pedidos finalizados</h6>
    </a>
</ng-template>


<hr>


<div *ngIf="address; else other_address">
    <a href="/address" style="color: black;">
        <h6 class="mt-4" style="font-weight: bold;"> Dirección</h6>
    </a>
</div>


<ng-template #other_address>
    <a href="/address">
        <h6 class="mt-4 text-muted"> Dirección</h6>
    </a>
</ng-template>

<hr>

<div *ngIf="wishes; else other_wishes">
    <a href="/wishes" style="color: black;">
        <h6 class="mt-4" style="font-weight: bold;"> Lista de deseos</h6>
    </a>
</div>


<ng-template #other_wishes>
    <a href="/wishes">
        <h6 class="mt-4 text-muted"> Lista de deseos</h6>
    </a>
</ng-template>

<hr>


<div *ngIf="accountDetails; else other_accountDetails">
    <a href="/accountDetails" style="color: black;">
        <h6 class="mt-4" style="font-weight: bold;"> Detalles de la cuenta</h6>
    </a>
</div>


<ng-template #other_accountDetails>
    <a href="/accountDetails">
        <h6 class="mt-4 text-muted"> Detalles de la cuenta</h6>
    </a>
</ng-template>


<hr>
<a style="cursor: pointer;" (click)="cerrarSesion()">
    <h6 class="mt-4 text-muted"> Salir</h6>
</a>
<!--
</div>
-->

<!--
<div *ngIf="screenWidth != undefined && screenWidth <= 770">
    <div class="btn-group">
        <button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          MI CUENTA
        </button>
        <div class="dropdown-menu">
          <a class="dropdown-item" href="/profile">Escritorio</a>
          <a class="dropdown-item" href="/orders">Pedidos</a>
          <a class="dropdown-item" href="/address">Direcciones</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="#">Separated link</a>
        </div>
      </div>
</div>
-->