import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { DialogSelectOptionsComponent } from 'src/app/components/dialog-select-options/dialog-select-options.component';
import { AlertService } from 'src/app/components/_alert';
import { Product } from 'src/app/models/product';
import { MessageService } from 'src/app/services/message.service';
import { ProductService } from 'src/app/services/product.service';
import { Options, LabelType } from 'ng5-slider';
import { newArray } from '@angular/compiler/src/util';
import { FormBuilder, FormControl, FormGroup, Validators, FormArray, AbstractControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import * as Notiflix from 'notiflix';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss']
})
export class ShopComponent implements OnInit {


  deshabilitado: boolean = true;
  paramTest: boolean = false;
  
  marcas: String[] = []

  changeSmartphone: boolean | undefined;
  changePortatil: boolean | undefined;
  changeTablet: boolean | undefined;
  changeMonitor: boolean | undefined;
  changeSmartWatch: boolean | undefined;

  minValue: number = 0;
  maxValue: number = 300;
  sliderValue = [0, 300];
  optionsPrice: Options = {
    floor: 0,
    ceil: 300,
    step: 5,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return '<b>Rango:</b> ' + value;
        case LabelType.High:
          return '<b></b> ' + value;
        default:
          return 'Rs. ' + value;
      }
    }
  };

  monthSelected: number | undefined;

  categoria: String | undefined;
  meses: number[] = []
  mesesTotales: Array<any> = Array(12).fill(1).map((x,i)=>i+1);
  precios: number[] = []
  mesesPrecio = new Array()

  masAlquilados: any;
  marcasMostrar: any;

  products: any;
  options = {
    autoClose: false,
    keepAfterRouteChange: false
  };

  screenHeight: number | undefined;
  screenWidth: number | undefined;
  rutaImagen: string = environment.API_URL+'/';

  filtroPreciosActivado: boolean = false;
  filtroMesesActivado: boolean = false;

  url = '';
  buy: boolean = false;

  //BUSCADOR Y PAGINEO

  numPage: number = 0;
	pageSize: number = 12;
  tableType: number = 1;
  order: string = '';
  direction: string = '';
  hasMore: boolean = false;
  totalProductos: number = 0;

	formFiltros = this.formBuilder.group({
		category: '',
		periodo_alquiler: null,
		marcas: this.formBuilder.array([]),
    price_min: 0,
    price_max: 300
	});

  constructor(
    private productService: ProductService,
    public dialog: MatDialog,
    private router: Router,
    public alertService: AlertService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {

    try{
      this.url = this.route.snapshot.url[1].path
      if(this.url == 'buy'){
        this.buy = true;
        this.maxValue = 5000;
        this.sliderValue = [0, 5000];
        this.optionsPrice = {
          floor: 0,
          ceil: 5000,
          step: 25,
          translate: (value: number, label: LabelType): string => {
            switch (label) {
              case LabelType.Low:
                return '<b>Rango:</b> ' + value;
              case LabelType.High:
                return '<b></b> ' + value;
              default:
                return 'Rs. ' + value;
            }
          }
        };
        this.formFiltros = this.formBuilder.group({
          category: '',
          periodo_alquiler: null,
          marcas: this.formBuilder.array([]),
          price_min: 0,
          price_max: 5000
        });
        
        try {
          this.categoria = this.route.snapshot.url[3].path;
          if(this.categoria && this.categoria!=""){
            let formControl = this.formFiltros.get("category");
    
            if(formControl){
              switch (this.categoria){
                case 'smartphone':
                  formControl.setValue('1');
                break;
                case 'ordenadores':
                  formControl.setValue('72');
                break;
                case 'tablet':
                  formControl.setValue('8');
                break;
                case 'monitor':
                  formControl.setValue('47');
                break;
                case 'smartwatch':
                  formControl.setValue('78');
                break;
              }
            }
          }
        } catch (error) {
          console.log('No tiene ninguna categoría seleccionada')
        }
      }
    }
    catch(error){
      console.log('Estás en el apartado de alquiler')
    }
    try {
      this.categoria = this.route.snapshot.url[2].path;
      if(this.categoria && this.categoria!=""){
        let formControl = this.formFiltros.get("category");

        if(formControl){
          switch (this.categoria){
            case 'smartphone':
              formControl.setValue('1');
            break;
            case 'ordenadores':
              formControl.setValue('72');
            break;
            case 'tablet':
              formControl.setValue('8');
            break;
            case 'monitor':
              formControl.setValue('47');
            break;
            case 'smartwatch':
              formControl.setValue('78');
            break;
          }
        }
      }
    } catch (error) {
      console.log('No tiene ninguna categoría seleccionada')
    }

    

    this.changeSmartWatch = false;
    this.changeMonitor = false;
    this.changePortatil = false;
    this.changeSmartphone = false;
    this.changeTablet = false;

    this.onResize()

    this.route.queryParams.subscribe(
			(params) => {
				if (params.test !== undefined) {
					this.deshabilitado = false;
          this.paramTest = true;
				}
			}
		);
  }

  openDialog(product: Product): void {
    const dialogRef = this.dialog.open(DialogSelectOptionsComponent, {
      width: '250px',
      data: { product }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('El producto no se añadió');
    });
  }


  @HostListener('window:resize', ['$event'])
  onResize(event?: undefined) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  ngOnInit(): void {
    /*Notiflix.Loading.pulse('Cargando productos...',{
      svgColor: '#63b421',
    });
    this.loadMasAlquilados();
    */
    if(this.categoria){
      this.filtrar();
      this.loadMarcas();
    }
    
    console.log(this.categoria)

  }

  openDetails(product: Product): void {
    this.productService.productoActual = product;
    this.router.navigate(['/productDetails']);
  }

  loadMasAlquilados(){
    if(this.buy == true){
      this.productService.getMasAlquilados(0).subscribe(
        (data: any) => {
          this.masAlquilados = data;
          Notiflix.Loading.remove()
        },
        (err) => {
          console.log('Error: '+err)
        }
      );
    }else{
      this.productService.getMasAlquilados(1).subscribe(
        (data: any) => {
          this.masAlquilados = data;
          Notiflix.Loading.remove()
        },
        (err) => {
          console.log('Error: '+err)
        }
      );
    }
    
  }

  loadMarcas(){
    this.productService.getMarcas().subscribe(
			(data: any) => {
        this.marcasMostrar = data;
			},
			(err) => {
				console.log('Error: '+err)
			}
		);
  }

  filtrar(goBack: boolean = true){
    this.formFiltros.get('periodo_alquiler')?.setValue(this.monthSelected?this.monthSelected:null);
    this.formFiltros.get('price_min')?.setValue(this.minValue);
    this.formFiltros.get('price_max')?.setValue(this.maxValue);

    if(goBack == true){
      this.numPage = 0;
    }

    this.modalService.dismissAll();
    this.loadProductos();
  }

  loadProductos(){
    let compraAlquila = 1;
    if(this.buy == true){
      compraAlquila = 0;
    }
    this.productService.getProductosAlmacen(this.numPage, this.pageSize, this.order, this.direction, this.formFiltros.value, compraAlquila).subscribe(
      (data: any) => {
        this.hasMore = data.hasMore;
        this.totalProductos = data.totalElements;
        this.products = data.elements;
        Notiflix.Loading.remove()
      },
      (err) => {
        console.log('Error: '+err)
      }
    );
  }

  deleteFilter() {
    this.products = this.productService.products
    this.monthSelected = undefined;
    this.minValue = 0;
    this.maxValue = 300;
    if(this.buy==true){
      this.maxValue = 5000;
    }
    this.formFiltros.reset();
    this.filtrar();
  }


  changeMarca(e: any) {
    const checkArray: FormArray = this.formFiltros.get('marcas') as FormArray;

    if (e.target.checked) {
      checkArray.push(new FormControl(e.target.value));
    } else {
      let i: number = 0;
      checkArray.controls.forEach((item: AbstractControl) => {
        if (item.value == e.target.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }
    
    console.log(this.formFiltros.get('marcas')?.value);
  }

  openVerticallyCentered(content: any) {
    this.modalService.open(content, { centered: true, size: 'xl' });
  }

  filtroResponsive() {
    this.modalService.dismissAll()
  }

  selectMonth(month: number) {
    this.monthSelected = month;
  }

  deleteSelectedMonth() {
    this.monthSelected = undefined;
  }

  removeAccents(str: String){
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  } 

  cambiarSizePag(size: number){
    this.pageSize = size;
    this.filtrar(true);
  }

}
