import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ayuda-modelo-num-serie',
  templateUrl: './ayuda-modelo-num-serie.component.html',
  styleUrls: ['./ayuda-modelo-num-serie.component.scss']
})
export class AyudaModeloNumSerieComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
