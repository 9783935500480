<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Autodiagnosis</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()" >
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div #messages class="modal-body">
    <div ngbAutofocus class="row wrap-messages">
        <div class="col messages">
            <ng-container *ngIf="mensajes.length > 0">
                <div *ngFor="let itemMensaje of mensajes; let i = index" class="message-row">
                    <div *ngIf="itemMensaje.type == 'message'" class="message-text" [ngClass]="{'error': itemMensaje.error, 'success': itemMensaje.success}">
                        <div class="message text-muted">{{itemMensaje.message}}</div>
                    </div>

                    <div *ngIf="itemMensaje.actions" class="message-action" [ngClass]="{'action': itemMensaje.type == 'action', 'actions': itemMensaje.type == 'action-res'}">
                        <ng-container *ngFor="let action of itemMensaje.actions">
                            <button *ngIf="action.text" class="btn btn-verde" [disabled]="itemMensaje.disabled" (click)="actionMensaje(action.action, i)">{{action.text}}</button>
                            
                            <div *ngIf="action.icon" class="action-icon mr-40" [ngClass]="{'selected': action.selected, 'disabled': itemMensaje.disabled}" 
                                    (click)="actionMensaje(action.action, i, action.id)">
                                <i class="fas" [ngClass]="action.icon"></i>
                            </div>
                        </ng-container>
                    </div>

                    <div *ngIf="itemMensaje.type == 'list-data'" class="message-list">
                        <div *ngFor="let itemData of itemMensaje.data" class="item-list-data">
                            <div class="message text-muted">- {{itemData.nombre}}</div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>