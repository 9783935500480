<div *ngIf="screenWidth != undefined">

    <div class="mb-4" *ngIf="screenWidth > 970">
        <div class="container-lg mt-4 mb-4">
            <h6>
                HOME
                <i class="fa fa-angle-right" style="margin-left: 10px; margin-right: 10px;" aria-hidden="true"></i>
                TIENDA
                <i class="fa fa-angle-right" style="margin-left: 10px; margin-right: 10px;" aria-hidden="true"></i>
                MI CUENTA
                <i class="fa fa-angle-right" style="margin-left: 10px; margin-right: 10px;" aria-hidden="true"></i>
                LISTA DE DESEOS
            </h6>
            <hr>
        </div>

        <div class="row">
            <div class="col-3 mt-4 mb-4">
                <app-profile-nav-bar></app-profile-nav-bar>
            </div>
        
            <div class="col-9 mt-4 mb-4">
                <h3><i class="far fa-heart"></i> Lista de deseos</h3>
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col"></th>
                            <th scope="col">DISPOSITIVO</th>
                            <th scope="col"></th>
                            <th scope="col">PRECIO</th>
                            <th scope="col">DISPONIBILIDAD</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody class="text-center">

                        <tr *ngFor="let deseo of listaDeseos; index as i">
                            <td>
                                <a style="cursor: pointer;"><i class="far fa-times-circle fa-2x"
                                (click)="deleteDeseo(deseo.ID)"></i></a>
                            </td>
                            <td>
                                <img [src]="(deseo.elemento.urlImagenes && deseo.elemento.urlImagenes.length>0 && deseo.elemento.urlImagenes[0].url)?rutaImagen+deseo.elemento.urlImagenes[0].url:''"
                                width="100px" height="100px" class="img-fluid"
                                style="border: 1px solid; color: rgb(0, 198, 0); min-width: 55px;">
                            </td>
                            <td>{{deseo.elemento.name}}</td>
                            <td>
                                {{deseo.elemento.precioMinimo}} €
                                <h6 style="margin-left: 25px; color: #808080; font-size: 10px;">
                                    al
                                    mes
                                </h6>
                            </td>
                            <td>{{deseo.elemento.stock}} unidades</td>
                            <td><button class="btn btn-dark rounded" style="margin-right: 20px;" (click)="navigate('/products/'+deseo.elemento.ID)">ALQUILAR</button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </div>

    <div class="mb-4" *ngIf="screenWidth <= 970">
        <div class="container-lg mt-4 mb-4">
            <div class="row">
                <div class="col">
                      <div class="dropdown">
                        <button class="btn btn-outline-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fa fa-list" aria-hidden="true"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <h6 class="dropdown-header">MI CUENTA</h6>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="/profile">Perfil</a>
                            <a class="dropdown-item" href="/orders">Pedidos</a>
                            <a class="dropdown-item" href="/address">Direcciones</a>
                            <a class="dropdown-item" href="/wishes">Lista de deseos</a>
                            <a class="dropdown-item" href="/accountDetails">Detalles de la cuenta</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" style="cursor: pointer;" (click)="cerrarSesion()"><i class="fas fa-sign-out-alt"></i> Salir</a>
        
                        </div>
                      </div>
                </div>
            </div>
        </div>
    
        <hr>

        <div class="row">        
            <div class="col mt-4 mb-4">
                <h3><i class="far fa-heart"></i> Lista de deseos</h3>
                <div class="mb-4" style="max-width: 100%; overflow: auto;">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col">DISPOSITIVO</th>
                                <th scope="col"></th>
                                <th scope="col">PRECIO</th>
                                <th scope="col">DISPONIBILIDAD</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody class="text-center">
                            <tr *ngFor="let deseo of listaDeseos; index as i">
                                <td>
                                    <a style="cursor: pointer;"><i class="far fa-times-circle fa-2x"
                                    (click)="deleteDeseo(deseo.ID)"></i></a>
                                </td>
                                <td>
                                    <img [src]="(deseo.elemento.urlImagenes && deseo.elemento.urlImagenes.length>0 && deseo.elemento.urlImagenes[0].url)?rutaImagen+deseo.elemento.urlImagenes[0].url:''"
                                    width="100px" height="100px" class="img-fluid"
                                    style="border: 1px solid; color: rgb(0, 198, 0); min-width: 55px;">
                                </td>
                                <td>{{deseo.elemento.name}}</td>
                                <td>
                                    {{deseo.elemento.precioMinimo}} €
                                    <h6 style="margin-left: 25px; color: #808080; font-size: 10px;">
                                        al
                                        mes
                                    </h6>
                                </td>
                                <td>{{deseo.elemento.stock}} unidades</td>
                                <td><button class="btn btn-dark rounded" style="margin-right: 20px;" (click)="navigate('/products/'+deseo.elemento.ID)">ALQUILAR</button></td>
                            </tr>
                        </tbody>
                    </table>
            </div>
            </div>
        </div>

    </div>
</div>
