import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-waranty',
  templateUrl: './waranty.component.html',
  styleUrls: ['./waranty.component.css']
})
export class WarantyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
