<div class="container mt-4 mb-4">
    <p>En cumplimiento del deber de información estipulado en el artículo 10 de la Ley 34/2002 de 11 de julio de Servicios de la Sociedad de la Información y 
        de Comercio Electrónico, <span class="text-green">flex.exxita.com</span>, como marca titularidad de Éxxita Be Circular, S.A. con domicilio en Carretera A-474, km 7, 
        Bollullos de la Mitación – Bormujos, 41110, Bollullos de la Mitación, Sevilla (España), CIF A41631904, Inscrita en el Registro Mercantil de Sevilla,
         Tomo 30.496, folio 108, hoja M-548.809, inscripción 1ª, Puede contactar con Éxxita Be Circular, S.A. en la dirección: 
         <a href="mailto:info@exxita.com">info@exxita.com</a>
    </p>
    <p>La presente información conforma y regula las condiciones de uso, las limitaciones de responsabilidad y las obligaciones que los usuarios de la página Web 
        que se publica bajo el nombre de dominio exxita.com que asumen y se comprometen a respetar.</p>

    <hr>

    <h1 class="text-green">CONDICIONES DE ACCESO Y UTILIZACIÓN_</h1>
    <p>La simple y mera utilización de la Página otorga la condición de usuario de la Página, bien sea persona física o jurídica, y obligatoriamente implica 
        la aceptación completa, plena y sin reservas de todas y cada una de las cláusulas y condiciones generales incluidas en el Aviso Legal. Si el Usuario 
        no estuviera conforme con las cláusulas y condiciones de uso de este Aviso Legal, se abstendrá de utilizar la Página.
    </p>
    <p>Este Aviso Legal está sujeto a cambios y actualizaciones por lo que la versión publicada puede ser diferente en cada momento en que el Usuario acceda al 
        Portal. Por tanto, el Usuario debe leer el Aviso Legal en todas y cada una de las ocasiones en que acceda a la Página. No obstante lo anterior, en caso 
        de cambios en la misma, se facilitará acceso a las versiones antiguas de la misma.</p>
    <p>
        A través de la Página, se facilita a los Usuarios el acceso y la utilización de diversos Contenidos publicados por medio de Internet por o por terceros 
        autorizados.
    </p>
    <p>
        El Usuario está obligado y se compromete a utilizar la Página y los Contenidos de conformidad con la legislación vigente, el Aviso Legal, y cualquier 
        otro aviso o instrucciones puestos en su conocimiento, bien sea por medio de este aviso legal o en cualquier otro lugar dentro de los Contenidos que 
        conforman la Página, así como con las normas de convivencia, la moral y buenas costumbres generalmente aceptadas.
    </p>
    <p>
        A tal efecto, el Usuario se obliga y compromete a NO utilizar cualquiera de los Contenidos con fines o efectos ilícitos, prohibidos en el Aviso Legal 
        o por la legislación vigente, lesivos de los derechos e intereses de terceros, o que de cualquier forma puedan dañar, inutilizar, sobrecargar, deteriorar 
        o impedir la normal utilización de los Contenidos, los equipos informáticos o los documentos, archivos y toda clase de contenidos almacenados en cualquier 
        equipo informático propios o contratados por Éxxita Be Circular, S.A., de otros Usuarios o de cualquier usuario de Internet (hardware y software).
    </p>
    <p>
        Asimismo, de conformidad con todo ello, el Usuario no podrá suprimir, manipular o de cualquier forma alterar el “copyright”, demás datos identificativos 
        y elementos de seguridad vinculados de la reserva de derechos de Éxxita Be Circular, S.A., o de sus titulares.
    </p>
    <p>
        La finalidad de la web indicada es exclusivamente dar información acerca del seguimiento de los pedidos realizados con terceros y que se distribuyen a 
        través de nuestros medios. El Usuario deberá abstenerse de obtener e incluso de intentar obtener los Contenidos empleando para ello medios o procedimientos 
        distintos de los que, según los casos, se hayan puesto a su disposición a este efecto o se hayan indicado a este efecto en las páginas Web donde se encuentren 
        los Contenidos o, en general, de los que se empleen habitualmente en Internet a este efecto siempre que no entrañen un riesgo de daño o inutilización de 
        la Página, y/o de los Contenidos.
    </p>
    <p>
        Éxxita Be Circular, S.A. no es responsable de la transmisión, difusión o puesta a disposición de terceros de cualquier material contenido en la página, 
        tales como informaciones textos, datos, contenidos, mensajes, gráficos, dibujos, archivos de sonido y/o imagen, fotografías, grabaciones, software, logotipos, 
        marcas, iconos, enlaces, diseños gráficos, códigos fuente, o cualquier otro material al que tuviera acceso en su condición de usuario de la página. 
        La utilización de los contenidos por parte de los usuarios se realizará sin menoscabar la imagen y el nombre de Éxxita Be Circular, S.A.
    </p>
    <p>
        Éxxita Be Circular, S.A. no es responsable del uso que se haga de los contenidos por parte de los usuarios. En caso de detectarse un uso de los contenidos 
        con un fin que pudiera, a juicio de Éxxita Be Circular, S.A., menoscabar su imagen, la mera comunicación por correo electrónico al responsable de la mala 
        utilización de los contenidos, será suficiente para que cese en el uso de los contenidos de la Web.
    </p>

    <hr>

    <h1 class="text-green">PROPIEDAD INTELECTUAL_</h1>
    <p>
        Todas las marcas, nombres comerciales o signos distintivos de cualquier clase que aparecen en la Página, y en especial los escritos doctrinales, publicaciones 
        y blogs de la misma son propiedad de Éxxita Be Circular, S.A. o, en su caso, de terceros que han autorizado su uso, sin que pueda entenderse que el uso o acceso 
        al Portal y/o a los Contenidos atribuya al Usuario derecho alguno sobre las citadas marcas, nombres comerciales y/o signos distintivos, y sin que puedan entenderse cedidos al Usuario, ninguno de los derechos de explotación que existen o puedan existir sobre dichos Contenidos.
    </p>
    <p>
        La utilización no autorizada de la información contenida en esta Web, así como la lesión de los derechos de Propiedad Intelectual o Industrial de 
        Éxxita Be Circular, S.A.o de terceros incluidos en la Página que hayan cedido contenidos dará lugar a las responsabilidades legalmente establecidas.
    </p>

    <hr>

    <h1 class="text-green">HIPERENLACES_</h1>
    <p>
        Aquellas personas que se propongan establecer hiperenlaces entre su página Web y la Página deberán observar y cumplir las condiciones siguientes: 
        – No será necesaria autorización previa cuando el Hiperenlace permita únicamente el acceso a la página de inicio, pero no podrá reproducirla de ninguna forma. 
        Cualquier otra forma de Hiperenlace requerirá la autorización expresa e inequívoca por escrito por parte de Éxxita Be Circular, S.A.
    </p>
    <p>
        – No se crearán “marcos” (“frames”) con las páginas Web ni sobre las páginas Web de Éxxita Be Circular, S.A.( www.exxita.com )
    </p>
    <p>
        – No se realizarán manifestaciones o indicaciones falsas, inexactas, u ofensivas sobre Éxxita Be Circular, S.A., sus empleados o colaboradores, o de las 
        personas que se relacionen en la Página por cualquier motivo, o de los Usuarios de las Página, o de los Contenidos suministrados.
    </p>
    <p>
        – No se declarará ni se dará a entender que Éxxita Be Circular, S.A. ha autorizado el Hiperenlace o que ha supervisado o asumido de cualquier forma 
        los Contenidos ofrecidos o puestos a disposición de la página Web en la que se establece el Hiperenlace.
    </p>
    <p>
        – La página Web en la que se establezca el Hiperenlace solo podrá contener lo estrictamente necesario para identificar el destino del Hiperenlace.
    </p>
    <p>
        – La página Web en la que se establezca el Hiperenlace no contendrá informaciones o contenidos ilícitos, contrarios a la moral y a las buenas costumbres 
        generalmente aceptadas y al orden público, así como tampoco contendrá contenidos contrarios a cualesquiera derechos de terceros.
    </p>

    <hr>

    <h1 class="text-green">DISPONIBILIDAD DE LA PÁGINA_</h1>
    <p>
        Éxxita Be Circular, S.A.no garantiza la inexistencia de interrupciones o errores en el acceso a la Página, a sus Contenidos, ni que éste se encuentren 
        actualizados, aunque desarrollará sus mejores esfuerzos para, en su caso, evitarlos, subsanarlos o actualizarlos. Por consiguiente, no se responsabiliza 
        de los daños o perjuicios de cualquier tipo producidos en el Usuario que traigan causa de fallos o desconexiones en las redes de telecomunicaciones que 
        produzcan la suspensión, cancelación o interrupción del servicio del Portal durante la prestación del mismo o con carácter previo.
    </p>
    <p>
        Éxxita Be Circular, S.A.excluye, con las excepciones contempladas en la legislación vigente, cualquier responsabilidad por los daños y perjuicios de 
        toda naturaleza que puedan deberse a la falta de disponibilidad, continuidad o calidad del funcionamiento de la Página y de los Contenidos, al no 
        cumplimiento de la expectativa de utilidad que los usuarios hubieren podido atribuir a la Página y a los Contenidos.
    </p>
    <p>
        La función de los Hiperenlaces que aparecen en esta Web es exclusivamente la de informar al usuario acerca de la existencia de otras Web que contienen 
        información sobre la materia. Dichos Hiperenlaces no constituyen sugerencia ni recomendación alguna.
    </p>
    <p>
        Éxxita Be Circular, S.A.no se hace responsable de los contenidos de dichas páginas enlazadas, del funcionamiento, utilidad o cualquier característica 
        de la misma.
    </p>
    <p style="margin-top: 15px;">        
        El acceso a la Página no implica la obligación por parte de Éxxita Be Circular, S.A. de controlar la ausencia de virus, gusanos o cualquier otro elemento 
        informático dañino. Corresponde al Usuario, en todo caso, la disponibilidad de herramientas adecuadas para la detección y desinfección de programas 
        informáticos dañinos, por lo tanto Éxxita Be Circular, S.A. no se hace responsable de los posibles errores de seguridad que se puedan producir durante 
        la prestación del servicio de la Página, ni de los posibles daños que puedan causarse al sistema informático del usuario o de terceros (hardware y software), 
        los ficheros o documentos almacenados en el mismo, como consecuencia de la presencia de virus en el ordenador del usuario utilizado para la conexión a los 
        servicios y contenidos de la Web, de un mal funcionamiento del navegador o del uso de versiones no actualizadas del mismo.
    </p>

    <hr>

    <h1 class="text-green">CALIDAD DE LA PÁGINA_</h1>
    <p>
        Dado el entorno dinámico y cambiante de la información y servicios que se suministran por medio de la Página, Éxxita Be Circular, S.A.realiza siempre su mejor
         esfuerzo, pero no garantiza la completa veracidad, exactitud, fiabilidad, utilidad y/o actualidad de los Contenidos. La información contenida en las páginas
          que componen este Portal sólo tiene carácter informativo, consultivo, divulgativo y publicitario. En ningún caso ofrecen ni tienen carácter de compromiso
           vinculante o contractual.
    </p>

    <hr>

    <h1 class="text-green">LIMITACIÓN DE LA RESPONSABILIDAD_</h1>
    <p>
        Éxxita Be Circular, S.A.excluye toda responsabilidad por las decisiones que el Usuario pueda tomar basado en esta información, así como por los posibles 
        errores tipográficos que puedan contener los documentos y gráficos de la Página. La información está sometida a posibles cambios periódicos sin previo 
        aviso de su contenido por ampliación, mejora, corrección o actualización de los Contenidos.
    </p>
    <p>
        Será de la completa responsabilidad del usuario de la página comprobar en cada acceso si la información disponible ha cambiado.
    </p>
    <p>
        Así mismo, Éxxita Be Circular, S.A.no es responsable de la información facilitada a través del seguimiento del pedido, ya que dicha información deriva 
        directa y exclusivamente del vendedor. En caso de que la misma no coincida con la originalmente facilitada, deberá contactar de forma directa con el vendedor.
    </p>

    <hr>

    <h1 class="text-green">NOTIFICACIONES_</h1>
    <p>
        Todas las notificaciones y comunicaciones por parte de Éxxita Be Circular, S.A. al Usuario realizados por cualquier medio se considerarán eficaces a todos 
        los efectos.
    </p>

    <hr>

    <h1 class="text-green">DISPONIBILIDADES DE LOS CONTENIDOS_</h1>
    <p>
        La prestación del servicio de la Página y de los Contenidos tiene, en principio, duración indefinida. Éxxita Be Circular, S.A.no obstante, queda autorizado 
        para dar por terminada o suspender la prestación del servicio de la Página y/o de cualquiera de los Contenidos en cualquier momento.
    </p>
    <p>
        Cuando ello sea razonablemente posible, advertirá previamente la terminación o suspensión de la Página.
    </p>
    <p>
        En el caso de los seguimientos de los pedidos, los mismos estarán disponibles en tanto los mismos no hayan sido cerrados por el vendedor que lo ha encargado. 
        En el momento de dicho cierre, cualquier reclamación al respecto deberá realizarse de forma directa ante el vendedor.
    </p>

    <hr>

    <h1 class="text-green">USO DE COOKIES_</h1>
    <p>
        Las webs de Éxxita Be Circular, S.A.utilizan cookies. Las cookies son ficheros enviados a un navegador por medio de un servidor web para registrar las 
        actividades del Usuario en una web o servicios de las webs de Éxxita Be Circular, S.A.
    </p>
    <p>
        Las cookies se usan para facilitar un acceso más rápido a los servicios seleccionados, y personalizar los servicios que ofrecen las webs de Éxxita Be Circular, 
        S.A.,en base a elecciones y preferencias habituales del usuario, al hacer uso de los servicios. Las cookies se asocian a un usuario anónimo y su ordenador y 
        no recaban datos de carácter personal. El usuario puede configurar su navegador para no permitir la instalación de cookies utilizadas por las webs de 
        Éxxita Be Circular, S.A.,sin que el rechazo de las mismas impida al usuario acceder los servicios, si bien, no permitir la instalación de cookies, puede hacer 
        bajar la rapidez y calidad de funcionamiento de la página Web o impedir la utilización de alguno de los servicios del website.
    </p>
    <p>
        Para más información, pueden dirigirse a nuestra Política de Cookies.
    </p>

    <hr>

    <h1 class="text-green">USO EN DISPOSITIVOS MÓVILES_</h1>
    <p>
        La presente web no está diseñada específicamente para dispositivos móviles, no obstante, soporta la posibilidad de uso en los mismos. Éxxita Be Circular, S.A. 
        se exonera expresamente de cualquier tipo de incidencia o responsabilidad por el uso de la web en dichos terminales, así como de los costes asociados al uso 
        de la web a través de estos dispositivos móviles.
    </p>

    <hr>

    <h1 class="text-green">PUBLICIDAD_</h1>
    <p>
        Éxxita Be Circular, S.A.se reserva el derecho a llevar a cabo campañas publicitarias específicas a través de la plataforma más allá de la finalidad propia de 
        la web, la difusión de los productos que pudiera ofrecer.
    </p>

    <hr>

    <h1 class="text-green">PROTECCIÓN DE DATOS DE CARÁCTER PERSONAL_</h1>
    <p>
        Éxxita Be Circular, S.A.es consciente de la importancia de la privacidad de los datos de carácter personal y por ello, ha implementado una política de tratamiento de datos orientada a proveer la máxima seguridad en el uso y recogida de los mismos, garantizando el cumplimiento de la normativa vigente en la materia y configurando dicha política como uno de los pilares básicos en las líneas de actuación de la entidad.
    </p>
    <p>
        Durante la navegación a través de la presente web es posible que se soliciten datos de carácter personal a través de diferentes formularios dispuestos al efecto. De esta forma, la información particular de cada tratamiento de datos se aportará junto a los formularios web por medio de una cláusula incluida en cada uno de ellos, siendo común a todos ellos el responsable del tratamiento Éxxita Be Circular, S.A.,así como el lugar y forma de ejercicio de sus derechos rse mediante una comunicación escrita a la dirección indicada anteriormente incluyendo copia del DNI o documento identificativo equivalente conforme se establece en la Política de Privacidad.
    </p>
    <p>
        La finalidad de los tratamientos es de carácter estadístico, usabilidad de la web y de contestación a las consultas planteadas.
    </p>
    <p>
        Para más información en este sentido, puede acceder a <a href="https://flex.exxita.com/privacy">flex.exxita.com/privacy</a> donde se detalla la misma.
    </p>
    <p>
    </p>
    <p>
        Legislación aplicable
    </p>
    <p>
        El Aviso Legal se rige por la ley española.
    </p>

</div>