<div *ngIf="deshabilitado" class="container-fluid">
    <div class="row">
        <div class="col">
            <h4 class="text-center mt-4">Servicio actualmente en pruebas, próximamente habilitado por los clientes de Flex. Permanece atento a nuestros canales en redes sociales</h4>
        </div>
    </div>
</div>

<img class="img-fluid mt-3 mb-3" src="../../../assets/img/repara/banner-repara.png" alt="Responsive image">
<img *ngIf="!deshabilitado" #tituloSolicitar class="img-fluid mb-4 px-2" src="../../../assets/img/repara/solicitar-presupuesto.png" style="max-height: 50px;" alt="Responsive image">

<div *ngIf="!deshabilitado" class="container-fluid">
    <div class="row">
        <div class="col">
            <div class="row">
                <div *ngIf="currentStep == 1 || currentStep == 2" class="col px-2">
                    <form>
                        <ng-container *ngIf="currentStep == 1">
                            <h4 class="mt-4"><span class="primary-color">1</span> Indica el tipo de dispositivo</h4>
                            <div class="row row-col-2 row-col-md-2 row-col-lg-3 d-flex justify-content-center">
                                <div class="col-6 col-md-6 col-lg-4">
                                    <div class="sel-dispositivo pointer m-2" [class.selected]="modelRepara.tipo_solicitud == 'REPARA TU SMARTPHONE'" 
                                            (click)="selectDispositivo('REPARA TU SMARTPHONE')">
                                        <img class="img-fluid mt-4 mb-4" src="../../../assets/img/moviles.png">
                                        <h3>Smartphone</h3>
                                    </div>
                                </div>
                                <div class="col-6 col-md-6 col-lg-4">
                                    <div class="sel-dispositivo pointer m-2" [class.selected]="modelRepara.tipo_solicitud == 'REPARACION PORTATIL'" 
                                            (click)="selectDispositivo('REPARACION PORTATIL')">
                                        <img class="img-fluid mt-4 mb-4" src="../../../assets/img/portatil.png">
                                        <h3>Portátil</h3>
                                    </div>
                                </div>
                                <div class="col-6 col-md-6 col-lg-4">
                                    <div class="sel-dispositivo pointer m-2" [class.selected]="modelRepara.tipo_solicitud == 'REPARA TU TABLET'" 
                                            (click)="selectDispositivo('REPARA TU TABLET')">
                                        <img class="img-fluid mt-4 mb-4" src="../../../assets/img/tablet.png">
                                        <h3>Tablet</h3>
                                    </div>
                                </div>
                            </div>

                            <h4 *ngIf="modelRepara.tipo_solicitud == 'REPARACION PORTATIL'" class="mt-4"><span class="primary-color">2</span> Indica la marca de tu portátil</h4>
                            <h4 *ngIf="modelRepara.tipo_solicitud == 'REPARA TU TABLET'" class="mt-4"><span class="primary-color">2</span> Indica la marca de tu tablet</h4>
                            <h4 *ngIf="modelRepara.tipo_solicitud == 'REPARA TU SMARTPHONE'" class="mt-4"><span class="primary-color">2</span> Indica la marca de tu smartphone</h4>
                            <div *ngIf="modelRepara.tipo_solicitud == 'REPARA TU TABLET' || modelRepara.tipo_solicitud == 'REPARACION PORTATIL'" class="row row-col-2 row-col-md-3 row-col-lg-3 d-flex justify-content-center">
                                <div class="col-6 col-md-4 col-lg-4 my-2 sel-marca pointer" (click)="selectMarca('asus')">
                                    <img *ngIf="modelRepara.marca != 'asus'" class="img-fluid" src="../../../assets/img/repara/marcas/asus-gris.png" alt="Responsive image">
                                    <img *ngIf="modelRepara.marca == 'asus'" class="img-fluid" src="../../../assets/img/repara/marcas/asus-verde.png" alt="Responsive image">
                                </div>
                                <div class="col-6 col-md-4 col-lg-4 my-2 sel-marca pointer" (click)="selectMarca('msi')">
                                    <img *ngIf="modelRepara.marca != 'msi'" class="img-fluid" src="../../../assets/img/repara/marcas/msi-gris.png" alt="Responsive image">
                                    <img *ngIf="modelRepara.marca == 'msi'" class="img-fluid" src="../../../assets/img/repara/marcas/msi-verde.png" alt="Responsive image">
                                </div>
                                <div class="col-6 col-md-4 col-lg-4 my-2 sel-marca pointer" (click)="selectMarca('lenovo')">
                                    <img *ngIf="modelRepara.marca != 'lenovo'" class="img-fluid" src="../../../assets/img/repara/marcas/lenovo-gris.png" alt="Responsive image">
                                    <img *ngIf="modelRepara.marca == 'lenovo'" class="img-fluid" src="../../../assets/img/repara/marcas/lenovo-verde.png" alt="Responsive image">
                                </div>
                                <div class="col-6 col-md-4 col-lg-4 my-2 sel-marca pointer" (click)="selectMarca('dynabook')">
                                    <img *ngIf="modelRepara.marca != 'dynabook'" class="img-fluid" src="../../../assets/img/repara/marcas/dynabook-gris.png" alt="Responsive image">
                                    <img *ngIf="modelRepara.marca == 'dynabook'" class="img-fluid" src="../../../assets/img/repara/marcas/dynabook-verde.png" alt="Responsive image">
                                </div>
                                <div class="col-6 col-md-4 col-lg-4 my-2 sel-marca pointer" (click)="selectMarca('toshiba')">
                                    <img *ngIf="modelRepara.marca != 'toshiba'" class="img-fluid" src="../../../assets/img/repara/marcas/toshiba-gris.png" alt="Responsive image">
                                    <img *ngIf="modelRepara.marca == 'toshiba'" class="img-fluid" src="../../../assets/img/repara/marcas/toshiba-verde.png" alt="Responsive image">
                                </div>
                                <div class="col-6 col-md-4 col-lg-4 my-2 sel-marca pointer" (click)="selectMarca('hp')">
                                    <img *ngIf="modelRepara.marca != 'hp'" class="img-fluid" src="../../../assets/img/repara/marcas/hp-gris.png" alt="Responsive image">
                                    <img *ngIf="modelRepara.marca == 'hp'" class="img-fluid" src="../../../assets/img/repara/marcas/hp-verde.png" alt="Responsive image">
                                </div>
                                <div *ngIf="modelRepara.tipo_solicitud == 'REPARA TU TABLET'" class="col-6 col-md-4 col-lg-4 my-2 sel-marca pointer" (click)="selectMarca('apple')">
                                    <img *ngIf="modelRepara.marca != 'apple'" class="img-fluid" src="../../../assets/img/repara/marcas/apple-gris.png" alt="Responsive image">
                                    <img *ngIf="modelRepara.marca == 'apple'" class="img-fluid" src="../../../assets/img/repara/marcas/apple-verde.png" alt="Responsive image">
                                </div>
                                <div class="col-6 col-md-4 col-lg-4 my-2 sel-marca pointer" (click)="selectMarca('otro')">
                                    <img *ngIf="modelRepara.marca != 'otro'" class="img-fluid" src="../../../assets/img/repara/marcas/otra-gris.png" alt="Responsive image">
                                    <img *ngIf="modelRepara.marca == 'otro'" class="img-fluid" src="../../../assets/img/repara/marcas/otra-verde.png" alt="Responsive image">
                                    <input matInput class="form-control" name="marcaOtro" placeholder="Introduce otra marca" [(ngModel)]="modelRepara.marcaOtro" (input)="checkMarcaOtro()">
                                </div>
                            </div>
                            <div *ngIf="modelRepara.tipo_solicitud == 'REPARA TU SMARTPHONE'" class="row row-col-2 row-col-md-3 row-col-lg-3 d-flex justify-content-center">
                                <div class="col-6 col-md-4 col-lg-4 my-2 sel-marca pointer" (click)="selectMarca('asus')">
                                    <img *ngIf="modelRepara.marca != 'asus'" class="img-fluid" src="../../../assets/img/repara/marcas/asus-gris.png" alt="Responsive image">
                                    <img *ngIf="modelRepara.marca == 'asus'" class="img-fluid" src="../../../assets/img/repara/marcas/asus-verde.png" alt="Responsive image">
                                </div>
                                <div class="col-6 col-md-4 col-lg-4 my-2 sel-marca pointer" (click)="selectMarca('lenovo')">
                                    <img *ngIf="modelRepara.marca != 'lenovo'" class="img-fluid" src="../../../assets/img/repara/marcas/lenovo-gris.png" alt="Responsive image">
                                    <img *ngIf="modelRepara.marca == 'lenovo'" class="img-fluid" src="../../../assets/img/repara/marcas/lenovo-verde.png" alt="Responsive image">
                                </div>
                                <div class="col-6 col-md-4 col-lg-4 my-2 sel-marca pointer" (click)="selectMarca('xiaomi')">
                                    <img *ngIf="modelRepara.marca != 'xiaomi'" class="img-fluid" src="../../../assets/img/repara/marcas/xiaomi-gris.png" alt="Responsive image">
                                    <img *ngIf="modelRepara.marca == 'xiaomi'" class="img-fluid" src="../../../assets/img/repara/marcas/xiaomi-verde.png" alt="Responsive image">
                                </div>
                                <div class="col-6 col-md-4 col-lg-4 my-2 sel-marca pointer" (click)="selectMarca('samsung')">
                                    <img *ngIf="modelRepara.marca != 'samsung'" class="img-fluid" src="../../../assets/img/repara/marcas/samsung-gris.png" alt="Responsive image">
                                    <img *ngIf="modelRepara.marca == 'samsung'" class="img-fluid" src="../../../assets/img/repara/marcas/samsung-verde.png" alt="Responsive image">
                                </div>
                                <div class="col-6 col-md-4 col-lg-4 my-2 sel-marca pointer" (click)="selectMarca('huawei')">
                                    <img *ngIf="modelRepara.marca != 'huawei'" class="img-fluid" src="../../../assets/img/repara/marcas/huawei-gris.png" alt="Responsive image">
                                    <img *ngIf="modelRepara.marca == 'huawei'" class="img-fluid" src="../../../assets/img/repara/marcas/huawei-verde.png" alt="Responsive image">
                                </div>
                                <div class="col-6 col-md-4 col-lg-4 my-2 sel-marca pointer" (click)="selectMarca('apple')">
                                    <img *ngIf="modelRepara.marca != 'apple'" class="img-fluid" src="../../../assets/img/repara/marcas/apple-gris.png" alt="Responsive image">
                                    <img *ngIf="modelRepara.marca == 'apple'" class="img-fluid" src="../../../assets/img/repara/marcas/apple-verde.png" alt="Responsive image">
                                </div>
                                <div class="col-6 col-md-4 col-lg-4 my-2 sel-marca pointer" (click)="selectMarca('otro')">
                                    <img *ngIf="modelRepara.marca != 'otro'" class="img-fluid" src="../../../assets/img/repara/marcas/otra-gris.png" alt="Responsive image">
                                    <img *ngIf="modelRepara.marca == 'otro'" class="img-fluid" src="../../../assets/img/repara/marcas/otra-verde.png" alt="Responsive image">
                                    <input matInput class="form-control" name="marcaOtro" placeholder="Introduce otra marca" [(ngModel)]="modelRepara.marcaOtro" (input)="checkMarcaOtro()">
                                </div>
                            </div>
                            <div class="row px-4">
                                <small *ngIf="requiredValidation.marca" class="text-danger">Debe seleccionar una marca</small>
                                <small *ngIf="requiredValidation.marcaOtro" class="text-danger">Debe indicar una marca</small>
                            </div>
        
                            <div class="row px-4">
                                <div class="mt-4 col-12 col-lg-10 col-md-12">
                                    <div class="pl-0" style="display: inline-flex;">
                                        <h6 class="text-muted">Modelo<span class="primary-color">_</span></h6>
                                    </div>
            
                                    <input matInput class="form-control" name="modelo" [(ngModel)]="modelRepara.modelo" (blur)="checkField('modelo')">
                                    <small *ngIf="requiredValidation.modelo" class="text-danger">Debe completar este campo</small>
                                    <a [routerLink]="['/repara/ayuda-encontrar-modelo-num-serie']" target="_blank" class="box-help-wrap">
                                        <div class="col-10 col-md-10 col-lg-6 text-center box-help bg-primary-color pointer">
                                            <h6 class="m-0"><span>Ayuda:</span> ¿Dónde puedo encontrarlo?</h6>
                                        </div>
                                    </a>
                                </div>
                                <div class="mt-4 col-12 col-lg-10 col-md-12">
                                    <div class="pl-0" style="display: inline-flex;">
                                        <h6 class="text-muted">Número de serie<span class="primary-color">_</span></h6>
                                    </div>
            
                                    <input matInput class="form-control" name="numero_serie" [(ngModel)]="modelRepara.numero_serie" (blur)="checkField('numero_serie')">
                                    <small *ngIf="requiredValidation.numero_serie" class="text-danger">Debe completar este campo</small>
                                    <a [routerLink]="['/repara/ayuda-encontrar-modelo-num-serie']" target="_blank" class="box-help-wrap">
                                        <div class="col-10 col-md-10 col-lg-6 text-center box-help bg-primary-color pointer">
                                            <h6 class="m-0"><span>Ayuda:</span> ¿Dónde puedo encontrarlo?</h6>
                                        </div>
                                    </a>
                                </div>
                                <div class="mt-4 col-12 col-lg-10 col-md-12">
                                    <div class="pl-0" style="display: inline-flex;">
                                        <h6 class="text-muted">Indica cuánto le costó el dispositivo<span class="primary-color">_</span></h6>
                                    </div>
            
                                    <ng-select [items]="costesDispositivo" name="coste_dispositivo" [(ngModel)]="modelRepara.coste_dispositivo"
                                    bindLabel="name" bindValue="id" placeholder="Selecciona cuánto le costó su dispositivo" (change)="checkField('coste_dispositivo')"
                                    ></ng-select>
                                    <small *ngIf="requiredValidation.coste_dispositivo" class="text-danger">Debe completar este campo</small>
                                </div>
                                <div class="mt-4 col-12 col-lg-10 col-md-12">
                                    <div class="pl-0" style="display: inline-flex;">
                                        <h6 class="text-muted">Nº contrato Garantía10<span class="primary-color">_</span></h6>
                                    </div>
            
                                    <input matInput class="form-control" name="garantia10" [(ngModel)]="modelRepara.garantia10" (blur)="checkField('garantia10')"
                                        placeholder="Si dispones de él, indica el nº de contrato de tu Garantía10">
                                    <a target="_self" class="box-help-wrap" (click)="showGarantia10Info()">
                                        <div class="col-10 col-md-10 col-lg-6 text-center box-help bg-primary-color pointer">
                                            <h6 class="m-0"><span>Ayuda:</span> ¿Qué es Garantía10?</h6>
                                        </div>
                                    </a>
                                </div>

                                <div class="mt-4 col-12 col-lg-10 col-md-12" *ngIf="cliente && cliente.distribuidor" title="Identificador en sistema de gestión de terceros">
                                    <div class="pl-0" style="display: inline-flex;">
                                        <h6 class="text-muted">Número de identificación externo<span class="primary-color">_</span></h6>
                                    </div>
            
                                    <input matInput class="form-control" name="codCliente" [(ngModel)]="modelRepara.ninccliente" (blur)="checkField('codCliente')"
                                        placeholder="Identificador en sistema de gestión de terceros">
                                </div>
                            </div>
        
                            <h4 class="mt-4"><span class="primary-color">3</span> Indica la avería o incidencia</h4>
                            <div class="row px-4">
                                <div class="col-12 col-lg-10 col-md-12">                                
                                    <ng-select [items]="averias" name="averia_sintomas" (clear)="clearSelectAverias()"
                                        [(ngModel)]="modelRepara.averia_sintomas" [multiple]="true"
                                        bindLabel="name" bindValue="id" placeholder="Selecciona el motivo de la avería o incidencia"
                                    ></ng-select>
                                    <div *ngIf="showBotAutodiagnosis()" class="box-help-wrap" (click)="openBotAutodiagnosis()">
                                        <div class="col-10 col-md-10 col-lg-6 text-center box-help bg-primary-color pointer">
                                            <h6 class="m-0"><span>Ayuda:</span> Utilizar sistema de autodiagnosis</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <h4 class="mt-4"><span class="primary-color">4</span> ¿Cuál es la incidencia?</h4>
                            <div class="row px-4">
                                <div class="col-12 col-lg-12 col-md-12">
                                    <textarea matInput class="form-control" name="averia_incidencia_textarea" [(ngModel)]="modelRepara.averia_incidencia_textarea" rows="3"
                                        placeholder="Describe con tus palabras la avería o incidencia, si no se encuentra en el listado anterior"
                                    ></textarea>
                                </div>
                            </div>
                        </ng-container>
                        
                        <ng-container *ngIf="currentStep == 2">
                            <h4 class="mt-4"><span class="primary-color">5</span> Datos de contacto</h4>
                            <div class="row px-4">
                                <div class="col-12">
                                    <div class="form-check form-check-inline" style="margin-right: 4rem;">
                                        <input class="form-check-input" type="radio" name="contacto_particular_empresa" [(ngModel)]="modelRepara.contacto_particular_empresa" id="contPartEmp1" value="Particular">
                                        <label class="form-check-label text-muted" for="contPartEmp1">Particular</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="contacto_particular_empresa" [(ngModel)]="modelRepara.contacto_particular_empresa" id="contPartEmp2" value="Empresa">
                                        <label class="form-check-label text-muted" for="contPartEmp2">Empresa</label>
                                    </div>
                                </div>

                                <div class="mt-4 col-12 col-lg-10">
                                    <input matInput class="form-control" name="contacto_nombre" placeholder="Nombre" 
                                        [(ngModel)]="modelRepara.contacto_nombre" (blur)="checkField('contacto_nombre')">
                                    <small *ngIf="requiredValidation.contacto_nombre" class="text-danger">Debe completar este campo</small>
                                </div>
                                <div class="col-12 col-lg-10">
                                    <input matInput class="form-control input-group" name="contacto_apellidos" placeholder="Apellidos" 
                                        [(ngModel)]="modelRepara.contacto_apellidos" (blur)="checkField('contacto_apellidos')">
                                    <small *ngIf="requiredValidation.contacto_apellidos" class="text-danger">Debe completar este campo</small>
                                </div>
                                <div class="col-12 col-lg-10">
                                    <input matInput class="form-control input-group" name="contacto_email" placeholder="Email" 
                                        [(ngModel)]="modelRepara.contacto_email" (blur)="checkField('contacto_email')">
                                    <small *ngIf="requiredValidation.contacto_email" class="text-danger">Debe completar este campo</small>
                                </div>
                                <div class="col-12 col-lg-10">
                                    <input matInput class="form-control input-group" name="contacto_telefono" placeholder="Teléfono" 
                                        [(ngModel)]="modelRepara.contacto_telefono" (blur)="checkField('contacto_telefono')">
                                    <small *ngIf="requiredValidation.contacto_telefono" class="text-danger">Debe completar este campo</small>
                                    <small *ngIf="phoneValidation.contacto_telefono" class="text-danger">Debe indicar un número de teléfono válido</small>
                                </div>
                                <div *ngIf="modelRepara.contacto_particular_empresa == 'Empresa'" class="col-12 col-lg-10">
                                    <input matInput class="form-control input-group" name="razon_social" placeholder="Razón Social" [(ngModel)]="modelRepara.razon_social">
                                </div>
                                <div *ngIf="modelRepara.contacto_particular_empresa == 'Empresa'" class="col-12 col-lg-10">
                                    <input matInput class="form-control input-group" name="cif_nif" placeholder="CIF/NIF" [(ngModel)]="modelRepara.cif_nif">
                                </div>

                                <div class="col-12 mt-5" style="display: inline-flex;">
                                    <h5 class="text-muted">Recogida<span class="primary-color">_</span></h5>
                                </div>
                                <div class="col-12 col-lg-10">
                                    <input matInput class="form-control input-group" name="recogida_direccion" placeholder="Dirección de recogida" 
                                        [(ngModel)]="modelRepara.recogida_direccion" (blur)="checkField('recogida_direccion')">
                                    <small *ngIf="requiredValidation.recogida_direccion" class="text-danger">Debe completar este campo</small>
                                </div>
                                <div class="col-12 col-lg-10">
                                    <input matInput class="form-control input-group" name="recogida_municipio" placeholder="Municipio, provincia y código postal" 
                                        [(ngModel)]="modelRepara.recogida_municipio" (blur)="checkField('recogida_municipio')">
                                    <small *ngIf="requiredValidation.recogida_municipio" class="text-danger">Debe completar este campo</small>
                                </div>
                                <div class="col-12 col-lg-10">
                                    <input matInput class="form-control input-group" name="recogida_persona_contacto" placeholder="Persona de contacto" 
                                        [(ngModel)]="modelRepara.recogida_persona_contacto" (blur)="checkField('recogida_persona_contacto')">
                                    <small *ngIf="requiredValidation.recogida_persona_contacto" class="text-danger">Debe completar este campo</small>
                                </div>
                                <div class="col-12 col-lg-10">
                                    <input matInput class="form-control input-group" name="recogida_telefono_contacto" placeholder="Teléfono de contacto" 
                                        [(ngModel)]="modelRepara.recogida_telefono_contacto" (blur)="checkField('recogida_telefono_contacto')">
                                    <small *ngIf="requiredValidation.recogida_telefono_contacto" class="text-danger">Debe completar este campo</small>
                                    <small *ngIf="phoneValidation.recogida_telefono_contacto" class="text-danger">Debe indicar un número de teléfono válido</small>

                                    <a [routerLink]="['/repara/ayuda-embalar']" target="_blank" class="box-help-wrap">
                                        <div class="col-10 col-md-10 col-lg-6 text-center box-help bg-primary-color pointer" >
                                            <h6 class="m-0"><span>Ayuda:</span> ¿Cómo embalar de forma segura?</h6>
                                        </div>
                                    </a>
                                </div>
                                
                                <div class="col-12 mt-5" style="display: inline-flex;">
                                    <h5 class="text-muted">Entrega<span class="primary-color">_</span></h5>
                                </div>
                                <div class="col-12">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="mismaEntrega" name="aceptance_direccion_entrega"
                                            [(ngModel)]="modelRepara.aceptance_direccion_entrega" (change)="toogleDirEntrega()">
                                        <label class="form-check-label text-muted" for="mismaEntrega">Misma dirección de recogida</label>
                                    </div>
                                </div>
                                <div class="p-0 panelAccordion" [class.open]="showDirEntrega">
                                    <div class="col-12 col-lg-10">
                                        <input matInput class="form-control input-group" name="entrega_direccion" placeholder="Dirección de entrega" 
                                            [(ngModel)]="modelRepara.entrega_direccion" (blur)="checkField('entrega_direccion')">
                                        <small *ngIf="requiredValidation.entrega_direccion" class="text-danger">Debe completar este campo</small>
                                    </div>
                                    <div class="col-12 col-lg-10">
                                        <input matInput class="form-control input-group" name="entrega_municipio" placeholder="Municipio, provincia y código postal" 
                                            [(ngModel)]="modelRepara.entrega_municipio" (blur)="checkField('entrega_municipio')">
                                        <small *ngIf="requiredValidation.entrega_municipio" class="text-danger">Debe completar este campo</small>
                                    </div>
                                    <div class="col-12 col-lg-10">
                                        <input matInput class="form-control input-group" name="entrega_persona_contacto" placeholder="Persona de contacto" 
                                            [(ngModel)]="modelRepara.entrega_persona_contacto" (blur)="checkField('entrega_persona_contacto')">
                                        <small *ngIf="requiredValidation.entrega_persona_contacto" class="text-danger">Debe completar este campo</small>
                                    </div>
                                    <div class="col-12 col-lg-10">
                                        <input matInput class="form-control input-group" name="entrega_telefono_contacto" placeholder="Teléfono de contacto" 
                                            [(ngModel)]="modelRepara.entrega_telefono_contacto" (blur)="checkField('entrega_telefono_contacto')">
                                        <small *ngIf="requiredValidation.entrega_telefono_contacto" class="text-danger">Debe completar este campo</small>
                                        <small *ngIf="phoneValidation.entrega_telefono_contacto" class="text-danger">Debe indicar un número de teléfono válido</small>
                                    </div>
                                </div>
                                
                                <div class="col-12 mt-5" style="display: inline-flex;">
                                    <h5 class="text-muted">Facturación<span class="primary-color">_</span></h5>
                                </div>
                                <div class="col-12">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="mismaFacturacion" name="aceptance_direccion_facturacion"
                                            [(ngModel)]="modelRepara.aceptance_direccion_facturacion" (change)="toogleDirFacturacion()">
                                        <label class="form-check-label text-muted" for="mismaFacturacion">Misma dirección de recogida</label>
                                    </div>
                                </div>
                                <div class="p-0 panelAccordion" [class.open]="showDirFacturacion">
                                    <div class="col-12 col-lg-10">
                                        <input matInput class="form-control input-group" name="facturacion_direccion" placeholder="Dirección de facturación" 
                                            [(ngModel)]="modelRepara.facturacion_direccion" (blur)="checkField('facturacion_direccion')">
                                        <small *ngIf="requiredValidation.facturacion_direccion" class="text-danger">Debe completar este campo</small>
                                    </div>
                                    <div class="col-12 col-lg-10">
                                        <input matInput class="form-control input-group" name="facturacion_municipio" placeholder="Municipio, provincia y código postal" 
                                            [(ngModel)]="modelRepara.facturacion_municipio" (blur)="checkField('facturacion_municipio')">
                                        <small *ngIf="requiredValidation.facturacion_municipio" class="text-danger">Debe completar este campo</small>
                                    </div>
                                    <div class="col-12 col-lg-10">
                                        <input matInput class="form-control input-group" name="facturacion_persona_contacto" placeholder="Persona de contacto" 
                                            [(ngModel)]="modelRepara.facturacion_persona_contacto" (blur)="checkField('facturacion_persona_contacto')">
                                        <small *ngIf="requiredValidation.facturacion_persona_contacto" class="text-danger">Debe completar este campo</small>
                                    </div>
                                    <div class="col-12 col-lg-10">
                                        <input matInput class="form-control input-group" name="facturacion_telefono_contacto" placeholder="Teléfono de contacto" 
                                            [(ngModel)]="modelRepara.facturacion_telefono_contacto" (blur)="checkField('facturacion_telefono_contacto')">
                                        <small *ngIf="requiredValidation.facturacion_telefono_contacto" class="text-danger">Debe completar este campo</small>
                                        <small *ngIf="phoneValidation.facturacion_telefono_contacto" class="text-danger">Debe indicar un número de teléfono válido</small>
                                    </div>
                                </div>

                                <div class="col-12 mt-4">
                                    <p class="text-justify font-weight-normal text-muted" style="font-size: 12px;">
                                        "Al enviar el presente formulario queda informado de que CyG IT, con CIF A41631904 a través de su marca Flex, responsable del 
                                        tratamiento respecto al tratamiento gestión de Clientes, a que almacene la información de su nombre, apellidos, correo electrónico, 
                                        Direcciones postales y/o electrónicas, Teléfonos fijos-móviles, con la finalidad de llevar a cabo la reparación
                                        de su dispositivo. Sobre dicha información no se llevarán a cabo decisiones automatizadas así como podrá ejercer los derechos acceso, 
                                        rectificación, supresión, limitación, portabilidad y oposición conforme se detalla en la Política de Privacidad, así como cualquier 
                                        reclamación que estime ante la AEPD. Para más información consulte nuestra 
                                        <a [routerLink]="['/privacy']" target="_blank" class="primary-color">política de privacidad</a>
                                    </p>
                                </div>
                                <div *ngIf="!logueado" class="col-12">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="checkEnvioComunicaciones" name="checkbox_envio_comunicaciones"
                                            [(ngModel)]="modelRepara.checkbox_envio_comunicaciones">
                                        <label class="form-check-label text-muted" for="checkEnvioComunicaciones">Acepto el envío de comunicaciones comerciales de la compañia</label>
                                    </div>
                                </div>
                            </div>
                            
                        </ng-container>

                        <div *ngIf="showErrorPage" class="row px-4 mt-3">
                            <div class="col px-2">
                                <div class="alert alert-danger" role="alert">
                                    {{ msgError }}
                                </div>
                            </div>
                        </div>

                        <div class="row justify-content-between mb-5 mt-4">
                            <ng-container>
                                <div class="col-3">
                                    <button *ngIf="currentStep == 2" class="btn btn-verde-outline mt-4 mb-4" (click)="previusStep()">ANTERIOR</button>
                                </div>
                                <div class="col-3">
                                    <button *ngIf="currentStep == 1" class="btn btn-verde mt-4 mb-4" style="float: right; margin-right: 18px;" (click)="nextStep()">SIGUIENTE</button>
                                    <button *ngIf="currentStep == 2" class="btn btn-verde mt-4 mb-4" style="float: right; margin-right: 18px; position: relative;"
                                        [class.spinner]="loading" [disabled]="loading" (click)="solicitar()">
                                        <span>SOLICITAR</span>
                                    </button>
                                </div>
                            </ng-container>
                        </div>
                    </form>
                </div>

                <div *ngIf="currentStep == 3" class="col pl-0">
                    <div class="row mt-3 mb-4">
                        <div class="col-3">
                            <img class="img-fluid" src="../../../assets/img/repara/X.png" alt="Responsive image">
                        </div>
                        <div class="col-7 bloq-text-final">
                            <h4>Hemos recibido correctamente tu solicitud:</h4>
                            <h4 class="primary-color">Número: {{numeroSolicitud}}</h4>
                            <h4 class="mt-4">En breve, nos pondremos en contacto contigo.</h4>
                        </div>
                        <div class="col-2">
                            <div class="box-images-final">
                                <img class="img-fluid" src="../../../assets/img/repara/check.png" alt="Responsive image">
                                <img class="img-fluid" src="../../../assets/img/repara/docu.png" alt="Responsive image">
                            </div>
                        </div>
                    </div>

                    <div class="row justify-content-center">
                        <div class="col-6" style="display: flex; justify-content: center;">
                            <button class="btn btn-verde mx-auto mt-4 mb-4" (click)="paginaPrincipal()">VOLVER A LA PAGINA PRINCIPAL</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-3 col-md-3 col-lg-3 d-none d-md-block">
            <img class="img-fluid mt-4 mb-4" src="../../../assets/img/repara/formula-flex.png" alt="Responsive image">
        </div>
    </div>
</div>