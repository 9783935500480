<div *ngIf="screenWidth != undefined">
    <ng-template #contenido let-modal>
        <div style="position:relative;">
            <button type="close" class="close AClass" (click)="modal.dismiss()">
                <span>&times;</span>
            </button>
            <img class="logo-sello img-fluid" src="../../../assets/img/ofertasEspeciales.png">
            <div *ngIf="screenWidth >= 1200">
                <div class="text-block">
                    <div class="col">
                        <div class="row">
                            <h3 class="font-weight-bold">OBTENGA OFERTAS ESPECIALES</h3>
                            <h5 class="text-muted mt-2">Obtenga toda la información sobre nuestros dispositivos</h5>
                        </div>
                        <form>

                            <div class="row mt-4">
                                <div class="col-6 mt-4">
                                    <div class="input-container">
                                        <input class="input-field" id="emailModal" type="email"
                                            class="form-control form-control-lg"
                                            placeholder="Ingrese su dirección de correo electrónico..."
                                            style="font-size: medium;">
                                        <button type="button" class="btn btn-default btn-circle" (click)="crearSub()"
                                            style="border-radius: 0px 20px 20px 0px; background: #5bc500; color: whitesmoke;">
                                            INSCRIBIRSE
                                        </button>
                                    </div>
                                    <h5 class="text-center" style="color: tomato;" *ngIf="messageError != ''">
                                        {{messageError}}</h5>

                                    <div class="text-center" *ngIf="emailRegistrado != ''">
                                        <h5 class="text-center" style="color: tomato;"
                                            *ngIf="emailRegistrado.includes('anterioridad')">
                                            {{emailRegistrado}}</h5>
                                        <h5 class="text-center" style="color: darkcyan;"
                                            *ngIf="emailRegistrado.includes('éxito')">
                                            {{emailRegistrado}}</h5>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-9 mt-2" style="margin-left: 20px;">
                                        <input class="form-check-input" type="checkbox" id="invalidCheck"
                                            (change)="onClick($event)">
                                        <label class="form-check-label text-muted" for="invalidCheck">
                                            No volver a mostrar esta ventana
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>


            <div *ngIf="screenWidth >= 990 && screenWidth < 1200">
                <div class="text-block2">
                    <div class="col">
                        <div class="row">
                            <h4 class="font-weight-bold">OBTENGA OFERTAS ESPECIALES</h4>
                            <h6 class="text-muted mt-2">Obtenga toda la información sobre nuestros dispositivos</h6>
                        </div>
                        <form>

                            <div class="row mt-4">
                                <div class="col-7 mt-4">
                                    <div class="input-container">
                                        <input class="input-field" id="emailModal" type="email"
                                            class="form-control form-control-lg"
                                            placeholder="Ingrese su dirección de correo electrónico..."
                                            style="font-size: small;">
                                        <button type="button" class="btn btn-default btn-circle" (click)="crearSub()"
                                            style="border-radius: 0px 20px 20px 0px; background: #5bc500; color: whitesmoke;">
                                            INSCRIBIRSE
                                        </button>
                                    </div>
                                    <h6 class="text-center" style="color: tomato;" *ngIf="messageError != ''">
                                        {{messageError}}</h6>

                                    <div class="text-center" *ngIf="emailRegistrado != ''">
                                        <h6 class="text-center" style="color: tomato;"
                                            *ngIf="emailRegistrado.includes('anterioridad')">
                                            {{emailRegistrado}}</h6>
                                        <h6 class="text-center" style="color: darkcyan;"
                                            *ngIf="emailRegistrado.includes('éxito')">
                                            {{emailRegistrado}}</h6>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-9 mt-2" style="margin-left: 20px;">
                                        <input class="form-check-input" type="checkbox" id="invalidCheck"
                                            (change)="onClick($event)">
                                        <label class="form-check-label text-muted" for="invalidCheck">
                                            No volver a mostrar esta ventana
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>


            <div *ngIf="screenWidth < 990">
                <div class="text-block3">
                    <div class="col">
                        <div class="row">
                            <h5 class="font-weight-bold">OBTENGA OFERTAS ESPECIALES</h5>
                            <h6 class="text-muted mt-2">Obtenga toda la información sobre nuestros dispositivos</h6>
                        </div>
                        <form>

                            <div class="row mt-2">
                                <div class="col-7">
                                    <div class="input-container">
                                        <input class="input-field" id="emailModal" type="email"
                                            class="form-control form-control-lg"
                                            placeholder="Ingrese su dirección de correo electrónico..."
                                            style="font-size: small;">
                                        <button type="button" class="btn btn-default btn-circle" (click)="crearSub()"
                                            style="border-radius: 0px 20px 20px 0px; background: #5bc500; color: whitesmoke;">
                                            INSCRIBIRSE
                                        </button>
                                    </div>
                                    <h6 class="text-center" style="color: tomato;" *ngIf="messageError != ''">
                                        {{messageError}}</h6>

                                    <div class="text-center" *ngIf="emailRegistrado != ''">
                                        <h6 class="text-center" style="color: tomato;"
                                            *ngIf="emailRegistrado.includes('anterioridad')">
                                            {{emailRegistrado}}</h6>
                                        <h6 class="text-center" style="color: darkcyan;"
                                            *ngIf="emailRegistrado.includes('éxito')">
                                            {{emailRegistrado}}</h6>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-9 " style="margin-left: 20px;">
                                        <input class="form-check-input" type="checkbox" id="invalidCheck"
                                            (change)="onClick($event)">
                                        <label class="form-check-label text-muted" for="invalidCheck">
                                            No volver a mostrar esta ventana
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>

    </ng-template>
</div>