<div class="mt-4" *ngIf="!logueado">
    <h3 class="text-center mt-4">INICIE SESIÓN</h3>

    <form #loginForm="ngForm" (submit)="send()">
        <div class="form-group">
            <div class="row mt-4">
                <div class="col-3"></div>
                <div class="col text-center">
                    <h6 class="text-muted">Usuario</h6>

                    <input matInput class="form-control" #email maxlength="80" [(ngModel)]="LoginModel.email"
                        name="Email" required>
                    <h6 class="text-muted mt-4 text-center">Contraseña</h6>
                    <input matInput class="form-control" type="password" #password maxlength="80"
                        [(ngModel)]="LoginModel.password" name="Password" required>
                    <button type="submit" class="btn btn-dark mb-4 mt-4">Entrar</button>
                </div>
                <div class="col-3"></div>
            </div>

        </div>
    </form>

    <div class="row mt-4" *ngIf="msgErrorLogin && msgErrorLogin !== ''">
        <div class="col-2"></div>
        <div class="col-8">
            <div class="alert alert-danger" role="alert">
                {{msgErrorLogin}}
            </div>
        </div>
        <div class="col-2"></div>
    </div>
</div>

<div class="mt-4 div-contenido-solicitud" *ngIf="logueado">
    <div class="div-formulario">
        <h1 id="titulo">Solicitudes de cotización</h1>
        <form #mainForm="ngForm" [formGroup]="formSolicitudAux">
            <div class="row mt-4 mb-4">
                <div class="col-6">
                    <div style="display: inline-flex;">
                        <h6 class="text-muted">Marca</h6>
                        <h6 style="color: red; margin-left: 10px;">*</h6>
                    </div>
                    <select class="form-control" name="marca" formControlName="marca" required>
                        <option selected="selected" value="" hidden>Seleccionar marca</option>
                        <option *ngFor="let marca of marcas" value="{{marca.id}}">{{marca.name}}</option>
                    </select>
                </div>

                <div class="col-6">
                    <div style="display: inline-flex;">
                        <h6 class="text-muted">Modelo</h6>
                    </div>
                    <input type="text" class="form-control" placeholder="Modelo" name="modelo" formControlName="modelo" maxlength="255">
                </div>
            </div>

            <div class="row mt-4 mb-4">
                <div class="col-6">
                    <div style="display: inline-flex;">
                        <h6 class="text-muted">Part. Number</h6>
                    </div>
                    <input type="text" class="form-control" placeholder="Part. Number" name="pn" formControlName="pn" maxlength="255">
                </div>

                <div class="col-6">
                    <div style="display: inline-flex;">
                        <h6 class="text-muted">Tipo dispositivo</h6>
                        <h6 style="color: red; margin-left: 10px;">*</h6>
                    </div>
                    <select class="form-control" name="tipo" formControlName="tipo" required>
                        <option selected="selected" value="" hidden>Seleccionar tipo</option>
                        <option *ngFor="let tipo of tipos_dispositivo" value="{{tipo.id}}">{{tipo.name}}</option>
                    </select>
                </div>
            </div>

            <div class="row mt-4 mb-4">
                <div class="col-6">
                    <div style="display: inline-flex;">
                        <h6 class="text-muted">Precio de venta (max. 1199)</h6>
                        <h6 style="color: red; margin-left: 10px;">*</h6>
                    </div>
                    <div class="input-group mb-3">
                        <input type="number" class="form-control" placeholder="Precio de venta" name="precio"
                            formControlName="precio" maxlength="20" required min="0" max="1199">
                        <div class="input-group-prepend">
                            <span class="input-group-text">€</span>
                        </div>
                    </div>
                </div>

                <div class="col-6">
                    <div style="display: inline-flex;">
                        <h6 class="text-muted">Unidades</h6>
                        <h6 style="color: red; margin-left: 10px;">*</h6>
                    </div>
                    <input type="number" class="form-control" placeholder="Unidades" name="unidades"
                        formControlName="unidades" maxlength="11" required min="1">
                </div>
            </div>

            <div class="row mt-4 mb-4">
                <div class="col-6">
                    <div style="display: inline-flex;">
                        <h6 class="text-muted">Tipo servicio</h6>
                        <h6 style="color: red; margin-left: 10px;">*</h6>
                    </div>
                    <select class="form-control" name="servicio" formControlName="servicio" required>
                        <option selected="selected" value="" hidden>Seleccionar servicio</option>
                        <option value="onsite">OnSite</option>
                        <option value="recogida">Recogida y Entrega</option>
                    </select>
                </div>

                <div class="col-6">
                    <div style="display: inline-flex;">
                        <h6 class="text-muted">Años</h6>
                        <h6 style="color: red; margin-left: 10px;">*</h6>
                    </div>
                    <select class="form-control" name="years" formControlName="years" required>
                        <option value="1" selected="selected">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                    </select>
                </div>
            </div>
        </form>

        <hr>

        <div class="row mt-4 mb-4 center-content">
            <button class="btn btn-verde rounded btn-aceptar" [disabled]="!formSolicitudAux.valid"
                (click)="agregarSolicitud()">AGREGAR SOLICITUD</button>
        </div>
    </div>

    <div>
        <h1>Resumen</h1>
        <div class="div-solicitudes">
            <div class="row mt-4 mb-4" *ngIf="solicitudes.length == 0">
                <div class="col-12">
                    <div style="display: inline-flex;">
                        <h6 class="text-muted">Sin solicitudes</h6>
                    </div>
                </div>
            </div>
            <div class="card card-solicitud" *ngFor="let solicitud of solicitudes">
                <div class="card-body">
                    <div>
                        <span>{{getNombreMarca(solicitud.marca)}}</span>
                        <span *ngIf="solicitud.modelo"><br>{{solicitud.modelo}}</span>
                        <span><br>{{getNombreServicio(solicitud.servicio)}}</span>
                    </div>
                    <div class="div-acciones-solicitud">
                        <a (click)="openSolicitud(solicitud)" style="font-size: 2em;"><i class="fas fa-eye icon"></i></a>
                        <a (click)="eliminarSolicitud(solicitud)" style="font-size: 2em; color: red;"><i class="fas fa-trash icon"></i></a>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <h3 *ngIf="precioCalculado && precioCalculado > 0">Precio estimado: {{precioCalculado}} €</h3>
        <hr *ngIf="precioCalculado && precioCalculado > 0">
        <div class="mt-4 mb-4 center-content" style="gap: 1em;">
            <button class="btn rounded" [disabled]="solicitudes.length <= 0"
                (click)="calcularSolicitudes()">CALCULAR PRECIO</button>
            <button class="btn btn-verde rounded btn-aceptar" [disabled]="solicitudes.length <= 0"
                (click)="enviarSolicitudes()">ENVIAR SOLICITUDES</button>
        </div>
        <div class="mt-4 mb-4 center-content">
            <button type="button" class="btn btn-salir btn-verde-outline" (click)="salir()">Salir / Cancelar</button>
        </div>
    </div>
</div>