import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginForm } from 'src/app/models/loginForm';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ProductService } from 'src/app/services/product.service';
import { Garantia10Service } from 'src/app/services/garantia10.service';
import * as Notiflix from 'notiflix';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { Report } from 'notiflix/build/notiflix-report-aio';

import { Validators, FormGroup, FormControl, FormBuilder, ValidatorFn, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'app-garantia10',
  templateUrl: './garantia10.component.html',
  styleUrls: ['./garantia10.component.scss']
})
export class Garantia10Component implements OnInit {
  LoginModel = new LoginForm();
  msgErrorLogin: string | undefined;

  screenHeight: number | undefined;
  screenWidth: number | undefined;

  logueado: boolean = false;

  marcas: Array<any> = [];
  tipos_dispositivo: Array<any> = [];
  formSolicitudAux: FormGroup;
  solicitudes: any = [];

  precioCalculado = 0;

  constructor(
    private authSvc: AuthService,
    private prodSvc: ProductService,
    private formBuilder: FormBuilder,
    private router: Router,
    private garantia10Svc: Garantia10Service
  ) {
    this.onResize();

    this.formSolicitudAux = this.formBuilder.group({
      marca: ['', [Validators.required, Validators.maxLength(11)]],
      modelo: ['', [Validators.maxLength(255)]],
      pn: ['', [Validators.maxLength(255)]],
      tipo: ['', [Validators.required, Validators.maxLength(11)]],
      precio: [null, [Validators.required, Validators.maxLength(20), Validators.max(1199)]],
      unidades: [null, [Validators.required, Validators.maxLength(11), Validators.min(1)]],
      servicio: ['', [Validators.required, Validators.maxLength(45)]],
      years: ['', [Validators.required, Validators.maxLength(11)]],
    });

    this.solicitudes = [];
  }

  ngOnInit(): void {
    this.logueado = this.authSvc.isUserLoggedInGarantia10();

    this.msgErrorLogin = "";

    if (this.logueado) {
      this.cargarFormulario();
    }

  }

  agregarSolicitud() {
    this.solicitudes.push(this.formSolicitudAux.value);
    this.formSolicitudAux.reset();
    this.precioCalculado = 0;
  }

  openSolicitud(solicitud: any){
    this.formSolicitudAux.setValue(solicitud);
    document.getElementById("titulo")?.scrollIntoView({
      behavior: "smooth"
    });
  }

  eliminarSolicitud(solicitud: any){
    var index = this.solicitudes.indexOf(solicitud);
		if (index !== -1) {
			this.solicitudes.splice(index, 1);
		}
    
    this.precioCalculado = 0;
  }

  salir(){
    this.solicitudes = [];
    this.logueado = false;
    this.LoginModel.password = '';
    this.authSvc.logoutGarantia10();
  }

  private cargarFormulario() {
    this.getMarcas();
    this.getTiposDispositivo();
  }

  public getNombreMarca(id: number) {
    const marca = this.marcas.filter(
      function (data) { return data.id == id }
    );

    return marca[0].name.toString();
  }

  public getNombreServicio(id: string) {
    switch (id) {
      case 'onsite':
        return 'OnSite';
        break;
      case 'recogida':
        return 'Recogida y entrega';
        break;
      default:
        return '';
    }
  }

  private getMarcas() {
    this.prodSvc.getMarcasGarantia10().subscribe(
      data => {
        this.marcas = data;
        console.log(this.marcas);
      },
      err => {
        console.log('Hubo un error al recoger las marcas')
      }
    );
  }

  private getTiposDispositivo() {
    this.prodSvc.getTiposDispositivoGarantia10().subscribe(
      data => {
        this.tipos_dispositivo = data;
        console.log(this.tipos_dispositivo);
      },
      err => {
        console.log('Hubo un error al recoger los tipos de dispositivo')
      }
    );
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?: undefined) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  enviarSolicitudes(){
    if(this.solicitudes.length > 0){
      Notiflix.Loading.pulse('Realizando solicitudes...',{
        svgColor: '#63b421',
      });

      this.garantia10Svc.crearSolicitudes(this.solicitudes).subscribe(
        data => {
          Notiflix.Loading.remove();
          Report.success(
            'Guardado correctamente',
            'Las solicitudes de guardaron correctamente',
            'Aceptar',
            {success: {svgColor: '#63b421',  buttonBackground: '#63b421', backOverlayColor: 'rgba(13, 0, 0, 0.2)',}}
          );
          this.formSolicitudAux.reset();
          this.solicitudes = [];
        },
        err => {
          Notiflix.Loading.remove();
          Notify.warning('Hubo algún problema al realizar las solicitudes', {position: 'left-bottom'});
        }
      );
    }
  }

  //Login
  send(): void {
    //if (this.emailRegex.test(this.LoginModel.email)) {
    if (this.LoginModel.email == null || this.LoginModel.password == null) {
      this.msgErrorLogin = "Debe rellenar todos los campos antes de continuar"
    } else {
      this.msgErrorLogin = '';
      let email = String(this.LoginModel.email)
      let password = this.LoginModel.password

      if (this.LoginModel) {
        this.authSvc.loginGarantia10(email, password).subscribe(
          data => {
            this.logueado = this.authSvc.isUserLoggedInGarantia10();
            this.cargarFormulario();
          },
          err => {
            this.msgErrorLogin = err;
          }
        );
      }
    }
  }

  calcularSolicitudes(){
    if(this.solicitudes.length > 0){
      Notiflix.Loading.pulse('Calculando coste...',{
        svgColor: '#63b421',
      });

      this.garantia10Svc.calcularCosteSolicitudes(this.solicitudes).subscribe(
        data => {
          Notiflix.Loading.remove();
          this.precioCalculado = data.coste;
        },
        err => {
          Notiflix.Loading.remove();
          Notify.warning('Hubo algún problema al calcular los costes', {position: 'left-bottom'});
        }
      );
    }
  }
}
