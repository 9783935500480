<div *ngIf="screenWidth != undefined && screenWidth > 768 && ticket && presupuesto">

    <h1 class="presupuestos text-center">PRESUPUESTO</h1>

    <div class="grisIt">
        <div class="row mb-4">
            <div class="col text-left">
                <p><strong>ID Presupuesto: </strong>{{presupuesto.ID}}</p>
            </div>
            <div class="col text-right">
                <p>{{formatDate(presupuesto.fecha_documento)}}</p>
            </div>
        </div>
        <!-- <div class="row mt-4">
            <div class="col text-right">
                <p><i class="fa fa-print" aria-hidden="true"></i> <strong> Descargar presupuesto</strong></p>
            </div>
        </div> -->
    </div>

    <div class="bordecito d-flex justify-content-center">
        <div class="col-11 mt-4 mb-4">
            <div class="bordecito d-flex justify-content-center">
                <div class="col-11 mt-4 mb-4">
                    <p class="text-muted">SUBTOTAL PRESUPUESTO</p>
                    <p class="mb-4">{{formatNumber(presupuesto.totalPresupuesto)}}€</p>

                    <p class="text-muted">SUBTOTAL IMPUESTOS</p>
                    <p class="mb-4">{{formatNumber(presupuesto.totalImpupesto)}}€</p>

                    <p class="text-muted">SUBTOTAL CON IMPUESTOS</p>
                    <p class="mb-4">{{formatNumber(presupuesto.totalConImpuestos)}}€</p>
                </div>
            </div>
            <div class="grisIt mb-4">
                <div class="row mt4">
                    <div class="col-8">
                        <p>IMPORTE DE REPARACIÓN</p>
                        <p><strong>{{formatNumber(presupuesto.totalConImpuestos)}}€</strong></p>
                    </div>
                    <div *ngIf="presupuesto.pagado == 1" class="col-4 estado-presupuesto">
                        <h3 class="pagado">PAGADO</h3>
                    </div> 
                    <div *ngIf="presupuesto.pagado != 1 && presupuesto.aceptado" class="col-4 estado-presupuesto">
                        <h3 class="pagado">ACEPTADO</h3>
                    </div> 
                    <div *ngIf="presupuesto.rechazado == 1" class="col-4 estado-presupuesto">
                        <h3 class="rechazado">RECHAZADO</h3>
                    </div>                            
                </div>
                <div class="row mt-4 d-flex justify-content-center"
                    *ngIf="presupuesto.aceptado != 1 && presupuesto.rechazado != 1">
                    <div class="col-4">
                        <button class="btn btn-success rounded btn-block" [disabled]="!btn_aceptar_enabled"
                            (click)="modalPresupuesto(modalPayment, modalAceptarPresupuesto)">ACEPTAR <span *ngIf="ticket.pagar_presupuesto">Y PAGAR</span></button>
                    </div>
                    <div class="col-4">
                        <button class="btn btn-danger rounded btn-block"
                            (click)="openModal(content)">RECHAZAR</button>
                    </div>
                </div>


                <div class="row mt-4 d-flex justify-content-center" *ngIf="!presupuesto">
                    <div class="col">
                        <h1>PRESUPUESTO NO ENCONTRADO</h1>
                    </div>
                </div>

            </div>

        </div>
    </div>


    <div class="bordecito">
        <div class="col mt-4 mb-4 d-flex justify-content-center">
            <button class="btn btn-dark rounded"
                (click)="navigate('repairs/' + ticket.ID + '/budget')">VOLVER</button>
        </div>
    </div>

</div>

<div *ngIf="screenWidth != undefined && screenWidth <= 768 && ticket && presupuesto">

    <h1 class="presupuestos text-center">PRESUPUESTO</h1>

    <div class="grisIt">
        <div class="row mb-4">
            <div class="col text-left">
                <p><strong>ID Presupuesto: </strong>{{presupuesto.ID}}</p>
            </div>
            <div class="col text-right">
                <p>{{formatDate(presupuesto.fecha_documento)}}</p>
            </div>
        </div>
        <!-- <div class="row mt-4">
            <div class="col text-right">
                <p><i class="fa fa-print" aria-hidden="true"></i> <strong> Descargar presupuesto</strong></p>
            </div>
        </div> -->
    </div>

    <div class="bordecito d-flex justify-content-center" *ngIf="presupuesto">
        <div class="col-11 mt-4 mb-4">
            <div class="bordecito d-flex justify-content-center">
                <div class="col-11 mt-4 mb-4">
                    <p class="text-muted">SUBTOTAL PRESUPUESTO</p>
                    <p class="mb-4">{{formatNumber(presupuesto.totalPresupuesto)}}€</p>

                    <p class="text-muted">SUBTOTAL IMPUESTOS</p>
                    <p class="mb-4">{{formatNumber(presupuesto.totalImpupesto)}}€</p>

                    <p class="text-muted">SUBTOTAL CON IMPUESTOS</p>
                    <p class="mb-4">{{formatNumber(presupuesto.totalConImpuestos)}}€</p>
                </div>
            </div>
            <div class="grisIt mb-4" *ngIf="!pagoAceptado && !error">
                <div class="row mt4">
                    <div class="col-12">
                        <p>IMPORTE DE REPARACIÓN</p>
                        <p><strong>{{formatNumber(presupuesto.totalConImpuestos)}}€</strong></p>
                    </div>
                    <div *ngIf="presupuesto.pagado == 1" class="col-12 estado-presupuesto">
                        <h3 class="pagado">PAGADO</h3>
                    </div> 
                    <div *ngIf="presupuesto.pagado != 1 && presupuesto.aceptado" class="col-12 estado-presupuesto">
                        <h3 class="pagado">ACEPTADO</h3>
                    </div> 
                    <div *ngIf="presupuesto.rechazado == 1" class="col-12 estado-presupuesto">
                        <h3 class="rechazado">RECHAZADO</h3>
                    </div>                            
                </div>
                <div class="row mt-4 d-flex justify-content-center"
                    *ngIf="presupuesto.aceptado != 1 && presupuesto.rechazado != 1">
                    <div class="col-12">
                        <button class="btn btn-success rounded btn-block" [disabled]="!btn_aceptar_enabled"
                            (click)="modalPresupuesto(modalPayment, modalAceptarPresupuesto)">ACEPTAR <span *ngIf="ticket.pagar_presupuesto">Y PAGAR</span></button>
                    </div>
                    <div class="col-12 mt-4">
                        <button class="btn btn-danger rounded btn-block"
                            (click)="openModal(content)">RECHAZAR</button>
                    </div>
                </div>


                <div class="row mt-4 d-flex justify-content-center" *ngIf="!presupuesto">
                    <div class="col">
                        <h1>PRESUPUESTO NO ENCONTRADO</h1>
                    </div>
                </div>

            </div>

            <div *ngIf="pagoAceptado && !error">
                <div class="container mt-4 mb-4">
                    <div class="col">
                        <div class="row text-center mt-2">
                            <i class="far fa-10x fa-check-circle" style="color: green;"></i>
                        </div>
                        <div class="row text-center mt-2">
                            <h2 style="color: green;">El presupuesto ha sido aceptado y pagado. <br> En breve se
                                procederá a la
                                reparaciónde su dispositivo.</h2>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>


    <div class="bordecito">
        <div class="col mt-4 mb-4 d-flex justify-content-center">
            <button class="btn btn-dark rounded"
                (click)="navigate('repairs/' + ticket.ID + '/budget')">VOLVER</button>
        </div>
    </div>

</div>

<ng-template #modalAceptarPresupuesto let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">¿Seguro que quieres aceptar el presupuesto?</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close()">Cancelar</button>
        <button type="button" class="btn btn-success" (click)="aceptarPresupuesto()">Aceptar</button>
    </div>
</ng-template>

<ng-template #modalPayment let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Método de pago</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group flex-column">
            <small class="text-muted">Por favor, indicanos el método de pago</small>
            <ul class="list-group">
                <li class="list-group-item" (click)="selectPaymentMethod('CARD')" [class.active]="metodo_pago == 'CARD'" style="cursor:pointer;">
                    <img src="../../../assets/img/credict_card.png" style="    height: 36px;
                    width: 36px;
                    display: inline-block;
                    border: 1px solid white;
                    border-radius: 50%;
                    background-color: white;
                    margin-right: 3px;"> Pago con tarjeta de crédito/débito</li>
                <li class="list-group-item" (click)="selectPaymentMethod('BIZUM')" [class.active]="metodo_pago == 'BIZUM'" style="cursor:pointer;">
                    <img src="../../../assets/img/bizum.png" style="    height: 36px;
                    width: 36px;
                    display: inline-block;
                    border: 1px solid white;
                    border-radius: 50%;
                    background-color: white;
                    margin-right: 3px;"> Pago con Bizum</li>
                <li class="list-group-item" (click)="selectPaymentMethod('PISP')" [class.active]="metodo_pago == 'PISP'" style="cursor:pointer;">
                    <img src="../../../assets/img/banktransfer.jpg" style="    height: 36px;
                    width: 36px;
                    display: inline-block;
                    border: 1px solid white;
                    border-radius: 50%;
                    background-color: white;
                    margin-right: 3px;"> Pago por transferencia</li>
            </ul>
            <div *ngIf="showPispText" class="box-pago-transferencia">
                <h4>Instrucciones para el pago por transferencia</h4>
                <p>Debes de realizar una transferencia con los siguientes datos:</p>
                <ul>
                    <li>CC: <b>ES70 0061 0338 3200 2152 0405</b></li>
                    <li>Concepto : <b>REF {{idTicket}}</b></li>
                </ul>
                <p>Una vez realizada la transferencia deberás enviarnos el justificante a la siguiente dirección de correo:</p>
                <h5><a href="mailto:oow@exxita.com">oow@exxita.com</a></h5>
                <p>Cuando hayamos confirmado el pago nos pondremos en contacto para la recogida del/los dispositivo/s</p>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close()">Cancelar</button>
        <form name="form" #form target="_self" action="{{metodo_pago != 'PISP' ? tpv_url : tpv_pisp_url}}" method="POST">
            <input *ngIf="metodo_pago != 'PISP'" type="hidden" name="Ds_SignatureVersion" value="HMAC_SHA256_V1" />
            <input *ngIf="metodo_pago != 'PISP'" type="hidden" name="Ds_MerchantParameters" value="{{merchantParameters}}" />
            <input *ngIf="metodo_pago != 'PISP'" type="hidden" name="Ds_Signature" value="{{signature}}" />
            <input *ngIf="metodo_pago == 'PISP'" type="hidden" name="ds_merchant" value="{{merchantFuc}}" />
            <button type="button" class="btn btn-success" [disabled]="metodo_pago == '' || showPispText"
                (click)="form.submit()">Aceptar y pagar</button>
        </form>
    </div>
</ng-template>

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Rechazar presupuesto</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group flex-column">
            <textarea [(ngModel)]="motivo_rechazo" id="motivo_rechazo" rows="8" cols="50"
                placeholder="Motivo (opcional)"></textarea>
            <small class="text-muted">Por favor, indicanos el mótivo por el que rechaza el presupuesto</small>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close()">Cancelar</button>
        <button type="button" class="btn btn-danger" [disabled]="!btn_rechazar_enabled"
            (click)="rechazarPresupuesto()">Rechazar presupuesto</button>
    </div>
</ng-template>


<ng-template #modalSuccess let-modal>
    <div class="container mt-4 mb-4">
        <div class="col">
            <div class="row text-center mt-2">
                <i class="far fa-10x fa-check-circle" style="color: green;"></i>
            </div>
            <div class="row text-center mt-2">
                <h2 style="color: green;">El presupuesto ha sido aceptado y pagado. <br> En breve se procederá a la
                    reparaciónde su dispositivo.</h2>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #modalError let-modal>
    <div class="container mt-4 mb-4">
        <div class="col">
            <div class="row text-center mt-2">
                <i class="fas fa-10x fa-times" style="color: red;"></i>
            </div>
            <div class="row text-center mt-2">
                <h2 style="color: red;">Ha habido un fallo en el proceso de pago. <br> Vuelva a intentarlo de nuevo. Si
                    persiste póngase en contacto con el servicio técnico.</h2>
            </div>
        </div>
    </div>
</ng-template>