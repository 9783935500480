import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';

@Injectable({
  	providedIn: 'root'
})
export class UserService {

	constructor(private http: HttpClient, public router: Router, private authSvc: AuthService) { }

	createDireccion(data: any, tipo: number, id_cliente: number): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				id_cliente: id_cliente,
				name: data.nombre,
				surname: data.apellido,
				address1: data.direccion,
				id_localidad: data.localidad,
				id_provincia: data.provincia,
				tipo: tipo,
				num_telefono: data.num_telefono,
    			direccion_email: data.direccion_email,
    			otros_datos: data.otros_datos,
			};

			const url = environment.API_URL + '/clientedirecciones';
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws createDireccion", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws createDireccion", err);
					let msgError = "Se ha producido un error guardando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	updateDireccion(data: any, tipo: number, id: number): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				ID: id,
				nombre: data.nombre,
				apellido: data.apellido,
				direccion: data.direccion,
				id_localidad: data.localidad,
				id_provincia: data.provincia,
				tipo_direccion: tipo,
				num_telefono: data.num_telefono,
    			direccion_email: data.direccion_email,
    			otros_datos: data.otros_datos,
			};

			const url = environment.API_URL + '/clientedirecciones';
			this.http.put(url, params, options).subscribe(
				(response: any) => {
					console.log("ws updateDireccion", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws updateDireccion", err);
					let msgError = "Se ha producido un error guardando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

}
