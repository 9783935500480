<div *ngIf="screenWidth != undefined && ticket">

    <div class="mb-4" *ngIf="screenWidth > 768">
        
        <div class="container-lg mt-4 mb-4">
            <div class="row">
                <div class="col">
                    <h6>
                        HOME
                        <i class="fa fa-angle-right" style="margin-left: 10px; margin-right: 10px;" aria-hidden="true"></i>
                        TIENDA
                        <i class="fa fa-angle-right" style="margin-left: 10px; margin-right: 10px;" aria-hidden="true"></i>
                        MI CUENTA
                        <i class="fa fa-angle-right" style="margin-left: 10px; margin-right: 10px;" aria-hidden="true"></i>
                        <span (click)="navigate('orders')">PEDIDOS</span>
                        <i class="fa fa-angle-right" style="margin-left: 10px; margin-right: 10px;" aria-hidden="true"></i>
                        <span (click)="navigate('repairs/'+ticket.ID)">SEGUIMIENTO</span>
                    </h6>
                </div>
                <div class="col text-right">
                    <button class="btn btn-verde rounded" style="margin-right: 20px;" (click)="navigate('repairs/'+ticket.ID+'/budget')">PRESUPUESTOS</button>
                    Ref.reparación: {{ticket.ID}}
                </div>
            </div>
        </div>

        <hr>

        <div class="row">
            <div class="col-4">
                <app-sidebar-info-ticket [ticket]="ticket" [dir_recogida]="dir_recogida" [dir_envio]="dir_envio"></app-sidebar-info-ticket>
            </div>
            <div class="col">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>

    <div class="mb-4" *ngIf="screenWidth <= 768">

        <div class="container-lg mt-4">
            <div class="row mb-4">
                <div class="col-4">
                    <app-sidebar-info-ticket [ticket]="ticket" [dir_recogida]="dir_recogida" [dir_envio]="dir_envio"></app-sidebar-info-ticket>
                </div>
                <div class="col text-right">
                    Ref.reparación: {{ticket.ID}}
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <button class="btn btn-verde rounded" (click)="navigate('repairs/'+ticket.ID)">SEGUIMIENTO</button>
                </div>
                <div class="col">
                    <button class="btn btn-verde rounded" (click)="navigate('repairs/'+ticket.ID+'/budget')">PRESUPUESTOS</button>
                </div>
            </div>
        </div>

        <hr>

        <div class="row">
            <div class="col">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>
