import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-modal-info-garantia10',
  templateUrl: './modal-info-garantia10.component.html',
  styleUrls: ['./modal-info-garantia10.component.css']
})
export class ModalInfoGarantia10Component implements OnInit {

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  cerrar(){
    this.activeModal.close();
  }

}
