import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth/auth.service';
import { ProductService } from './product.service';

@Injectable({
	providedIn: 'root'
})
export class AuxiliarService {

	private reparacion: any;

	constructor(
		private http: HttpClient,
		private authSvc: AuthService,
		private productSvc: ProductService
	) { }

	crearTicket(data: any): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = data;


			const url = environment.API_URL + '/tickets';
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws crearTicket", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws crearTicket", err);
					console.log(data)
					let msgError = "Se ha producido un error guardando los datos";

					if (err.error.error === 'invalid_no_fk_entities') {
						msgError = "El fk_entities indicado no existe";
					}

					observer.error(msgError);
				}
			);

			return { unsubscribe() { } };
		});
	}


	getCliente(tlno: number) {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/clientesolicitante/phone/' + tlno;
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getCliente", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getCliente", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return { unsubscribe() { } };
		});
	}

	crearCliente(data: any): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = data;


			const url = environment.API_URL + '/clientesolicitante';
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws crearCliente", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws crearCliente", err);
					console.log(data)
					let msgError = "Se ha producido un error guardando los datos";

					observer.error(msgError);
				}
			);

			return { unsubscribe() { } };
		});
	}

	getClienteDireccion(idCliente: number) {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
			
			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/clientedirecciones/cliente/' + idCliente;
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getClienteDireccion", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getClienteDireccion", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(err);
				}
			);

			return { unsubscribe() { } };
		});
	}

	getClienteDireccionTipo(idCliente: number, tipo: number) {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
			
			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/clientedirecciones/cliente/' + idCliente + "/" + tipo;
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getClienteDireccionFacturacion", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getClienteDireccionFacturacion", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(err);
				}
			);

			return { unsubscribe() { } };
		});
	}

	getClienteDireccionFacturacion(idCliente: number) {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
			
			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/clientedirecciones/cliente/' + idCliente + "/1";
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getClienteDireccionFacturacion", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getClienteDireccionFacturacion", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(err);
				}
			);

			return { unsubscribe() { } };
		});
	}

	getClienteDireccionEnvio(idCliente: number) {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
			
			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/clientedirecciones/cliente/' + idCliente + "/2"; //2 ya que el tipo 2 = direccion envío
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getClienteDireccionEnvio", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getClienteDireccionEnvio", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(err);
				}
			);

			return { unsubscribe() { } };
		});
	}

	getClienteDireccionRecogida(idCliente: number) {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
			
			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/clientedirecciones/cliente/' + idCliente + "/3"; //3 ya que el tipo 3 = direccion recogida
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getClienteDireccionEntrega", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getClienteDireccionEntrega", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(err);
				}
			);

			return { unsubscribe() { } };
		});
	}

	crearClienteDireccion(data: any, idCliente: number, idProvincia: number, idPoblacion: number): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
			data.id_cliente = idCliente;
			data.id_provincia = idProvincia;
			data.id_localidad = idPoblacion;

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = data;


			const url = environment.API_URL + '/clientedirecciones';
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws crearClienteDireccion", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws crearClienteDireccion", err);
					console.log(data)
					let msgError = "Se ha producido un error guardando los datos";

					observer.error(msgError);
				}
			);

			return { unsubscribe() { } };
		});
	}

	getDireccionTicketCliente(idCliente: number) {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/direccionesticketcliente/cliente/' + idCliente;
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getDireccionTicketCliente", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getDireccionTicketCliente", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return { unsubscribe() { } };
		});
	}

	crearDireccionTicketCliente(data: any): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
			
			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = data;


			const url = environment.API_URL + '/direccionesticketcliente';
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws crearDireccionTicketCliente", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws crearDireccionTicketCliente", err);
					console.log(data)
					let msgError = "Se ha producido un error guardando los datos";

					observer.error(msgError);
				}
			);

			return { unsubscribe() { } };
		});
	}

	editDireccionTicketCliente(data: any, idTicket: number): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
			
			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = data;


			const url = environment.API_URL + '/direccionesticketcliente/modify/' + idTicket;
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws modificarDireccionTicketCliente", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws modificarDireccionTicketCliente", err);
					console.log(data)
					let msgError = "Se ha producido un error guardando los datos";

					observer.error(msgError);
				}
			);

			return { unsubscribe() { } };
		});
	}

	crearSuscripcion(email: string): Observable<any> {
		return new Observable<any>(observer => {
			
			const options = {
				headers: new HttpHeaders({
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				"email": email
			}


			const url = environment.API_URL + '/suscripciones';
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws crearSuscripcion", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws crearSuscripcion", err);
					let msgError = "Se ha producido un error guardando los datos";

					observer.error(msgError);
				}
			);

			return { unsubscribe() { } };
		});
	}

	getSuscripcion(email: string) {
		return new Observable<any>(observer => {

			const options = {
				headers: new HttpHeaders({
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/suscripciones/email/' + email;
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getSuscripciones", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getSuscripciones", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return { unsubscribe() { } };
		});
	}

	deletePaymentIntent(idCliente: any): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
			
			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/clientesolicitante/deletepaymentintent/'+idCliente;
			this.http.put(url, {}, options).subscribe(
				(response: any) => {
					console.log("ws deletePaymentIntent", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws updateDireccion", err);
					let msgError = "Se ha producido un error guardando los datos";

					observer.error(msgError);
				}
			);

			return { unsubscribe() { } };
		});
	}

	getIdProvincia(provincia:string){
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
			
			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/provincias/name/' + provincia;
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getProvincias", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getProvincias", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return { unsubscribe() { } };
		});
		
	}

	getIdPoblacion(provincia: number, zip:string, poblacion:string){
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
			
			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/poblaciones/exactly/' + provincia + '/' + zip + '/' + poblacion;
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getPoblaciones", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getPoblaciones", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return { unsubscribe() { } };
		});
		
	}


	getProvincias(){
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
			
			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				pageSize: 100 //Recogemos hasta 100 povincias para así recoger todas las de españa
			}

			const url = environment.API_URL + '/provincias?'+this.productSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getProvincias", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getProvincias", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return { unsubscribe() { } };
		});
		
	}

	getPoblaciones(idProvincia: number){
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
			
			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/poblaciones/provincia/'+idProvincia;
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getPoblaciones", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getPoblaciones", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return { unsubscribe() { } };
		});
	}

	getTicketCliente(formValue: any = null, pageSize: number = 10, numPage: number = 0, tipo: string = 'not_done'){
		
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
			
			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				id: null,
				tipo: null,
				estado: null,
				fecha: null,
				precioMin: null,
				precioMax: null,
				page: numPage,
				pageSize: pageSize,
				mostrar: tipo
			}

			if(formValue.id && formValue.id.trim() != ""){
				params.id = formValue.id;
			}

			if(formValue.tipo && formValue.tipo != null && formValue.tipo != 0 && formValue.tipo != '' ){
				params.tipo = formValue.tipo;
			}

			if(formValue.estado && formValue.estado != null && formValue.estado != 0 && formValue.estado != '' ){
				params.estado = formValue.estado;
			}

			if(formValue.fecha && formValue.fecha.trim() != ""){
				params.fecha = formValue.fecha;
			}

			if(formValue.precioMin && formValue.precioMin.trim() != ""){
				params.precioMin = formValue.precioMin;
			}

			if(formValue.precioMax && formValue.precioMax.trim() != ""){
				params.precioMax = formValue.precioMax;
			}


			const url = environment.API_URL + '/tickets/cliente/portal?'+this.productSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getTicketCliente", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getTicketCliente", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return { unsubscribe() { } };
		});
	}

	descargarAdjunto(id: number, idTicket: number){
		
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
			
			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				idAdjunto: id
			}

			const url = environment.API_URL + '/tickets/'+idTicket+'/descarga-adjunto?'+this.productSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws descargarAdjunto", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws descargarAdjunto", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return { unsubscribe() { } };
		});
	}

	descargarPresupuesto(id: number, idTicket: number){
		
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
			
			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				idPresupuesto: id
			}

			const url = environment.API_URL + '/presupuestos/'+idTicket+'/descarga-presupuesto?'+this.productSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws descargarPresupuesto", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws descargarPresupuesto", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return { unsubscribe() { } };
		});
	}

	rechazarPresupuesto(id: number, motivo: string, idTicket: number){
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
			
			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				"motivo": motivo
			}

			const url = environment.API_URL + '/presupuestos/'+idTicket+'/rechazar-presupuesto/'+id;
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws rechazarPresupuesto", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws rechazarPresupuesto", err);
					let msgError = "Se ha producido un error guardando los datos";

					observer.error(msgError);
				}
			);

			return { unsubscribe() { } };
		});
	}

	aceptarPresupuesto(id: number, idTicket: number){
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
			
			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/presupuestos/'+idTicket+'/aceptar-presupuesto/'+id;
			this.http.post(url, null, options).subscribe(
				(response: any) => {
					console.log("ws aceptarPresupuesto", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws aceptarPresupuesto", err);
					let msgError = "Se ha producido un error guardando los datos";

					observer.error(msgError);
				}
			);

			return { unsubscribe() { } };
		});
	}

	getSeguimientosReparacion(idCliente: number, fk_entity: number){
		
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
			
			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				idCliente: idCliente //Recogemos hasta 100 povincias para así recoger todas las de españa
			}

			const url = environment.API_URL + '/tickets/'+fk_entity+'/seguimientos-portal?'+this.productSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getSeguimientosReparacion", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getSeguimientosReparacion", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return { unsubscribe() { } };
		});
	}

	getPresupuestosReparacion(idCliente: number, fk_entity: number){
		
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
			
			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				idCliente: idCliente //Recogemos hasta 100 povincias para así recoger todas las de españa
			}

			const url = environment.API_URL + '/tickets/'+fk_entity+'/presupuestos?'+this.productSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getPresupuestosReparacion", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getPresupuestosReparacion", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return { unsubscribe() { } };
		});
	}

	getReparacion(idTicket: number){
		return new Observable<any>(observer => {
			if(this.reparacion && this.reparacion.ID && this.reparacion.ID == idTicket) {
				observer.next(this.reparacion);
			} else {
				const accessToken = this.authSvc.getAccessToken();
				
				const options = {
					headers: new HttpHeaders({
						'Authentication': 'Bearer ' + accessToken,
						'Content-Type': 'application/json; charset=utf-8'
					})
				}

				const url = environment.API_URL + '/enviosTransporte/repair/'+idTicket;
				this.http.get(url, options).subscribe(
					(response: any) => {
						console.log("ws getReparacion", response);
						this.reparacion = response;
						observer.next(response);
					},
					(err) => {
						console.log("error ws getReparacion", err);
						let msgError = "Se ha producido un error cargando los datos";

						observer.error(msgError);
					}
				);
			}

			return { unsubscribe() { } };
		});
	}

	getDeseosCliente(idCliente: number){
		
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
			
			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/listaDeseos/cliente/' + idCliente;
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getListaDeseosCliente", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getListaDeseosCliente", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return { unsubscribe() { } };
		});
	}

	deleteDeseo(id: any): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
			
			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/listaDeseos/' + id;
			this.http.delete(url, options).subscribe(
				(response: any) => {
					console.log("ws borrarDeseo", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws borrarDeseo", err);
					let msgError = "Se ha producido un error eliminando el deseo";

					observer.error(msgError);
				}
			);

			return { unsubscribe() { } };
		});
	}

	crearDeseo(clienteId: any, elementoId: any): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				"clienteId": clienteId,
				"elementoId": elementoId
			}


			const url = environment.API_URL + '/listaDeseos';
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws crearDeseo", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws crearDeseo", err);
					let msgError = "Se ha producido un error guardando los datos";

					observer.error(msgError);
				}
			);

			return { unsubscribe() { } };
		});
	}
	
	obtenerTPV(paymentMethod: string, idTicket: any, idPresupuesto: any): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				"paymentMethod": paymentMethod,
				"idTicket": idTicket,
				"idPresupuesto": idPresupuesto
			}

			const url = environment.API_URL + '/tpv';
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws obtenerKeyTPV", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws obtenerKeyTPV", err);
					let msgError = "Se ha producido un error obteniendo los datos";

					observer.error(msgError);
				}
			);

			return { unsubscribe() { } };
		});
	}

	crearSeguimientoCliente(comentario: any, ticketId: any): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				"comentario": comentario
			}
			
			const url = environment.API_URL + '/tickets/'+ticketId+'/create-seguimiento-cliente';
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws crearSeguimientoCliente", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws crearDeseo", err);
					let msgError = "Se ha producido un guardando los datos";

					observer.error(msgError);
				}
			);

			return { unsubscribe() { } };
		});
	}

	getPresupuestoId(id: number){
		
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
			
			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/presupuestos/' + id;
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getPresupuesto", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getPresupuesto", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return { unsubscribe() { } };
		});
	}

	callbackTPV(): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}


			const url = environment.API_URL + '/callback';
			this.http.post(url, options).subscribe(
				(response: any) => {
					console.log("ws callbackTPV", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws callbackTPV", err);
					let msgError = "Se ha producido un error obteniendo los datos";

					observer.error(msgError);
				}
			);

			return { unsubscribe() { } };
		});
	}

	getTrackingCategories(){
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
			
			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/proyectos/tracking-categories/'+environment.fk_entity;
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getTrackingCategories", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getTrackingCategories", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return { unsubscribe() { } };
		});
	}


}
