<div class="mb-4" *ngIf="screenWidth != undefined && screenWidth > 990">

    <div class="container-lg mt-4 mb-4">
        <h6>
            HOME
            <i class="fa fa-angle-right" style="margin-left: 10px; margin-right: 10px;" aria-hidden="true"></i>
            TIENDA
            <i class="fa fa-angle-right" style="margin-left: 10px; margin-right: 10px;" aria-hidden="true"></i>
            MI CUENTA
        </h6>
    </div>

    <hr>

    <div class="row">


        <div class="col-3 mt-4 mb-4">
            <app-profile-nav-bar></app-profile-nav-bar>
        </div>

        <div class="col-9 mt-4 mb-4">

            <h3 style="font-weight: bold;"><img
                    src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbG5zOnN2Z2pzPSJodHRwOi8vc3ZnanMuY29tL3N2Z2pzIiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgeD0iMCIgeT0iMCIgdmlld0JveD0iMCAwIDUxMiA1MTIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMiA1MTIiIHhtbDpzcGFjZT0icHJlc2VydmUiIGNsYXNzPSIiPjxnPjxnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgaWQ9Il8wMS1ob21lIiBkYXRhLW5hbWU9IjAxLWhvbWUiPjxnIGlkPSJnbHlwaCI+PHBhdGggZD0ibTI1NiA0Yy0xMDguMDc1IDAtMTk2IDg3LjkyNS0xOTYgMTk2IDAgNTIuNSAzMS44MDcgMTE5LjkyIDk0LjUzNyAyMDAuMzc4YTEwNjUuODE2IDEwNjUuODE2IDAgMCAwIDkzLjE2OSAxMDQuMjk0IDEyIDEyIDAgMCAwIDE2LjU4OCAwIDEwNjUuODE2IDEwNjUuODE2IDAgMCAwIDkzLjE2OS0xMDQuMjk0YzYyLjczLTgwLjQ1OCA5NC41MzctMTQ3Ljg3OCA5NC41MzctMjAwLjM3OCAwLTEwOC4wNzUtODcuOTI1LTE5Ni0xOTYtMTk2em0wIDMzNmMtNzcuMiAwLTE0MC02Mi44LTE0MC0xNDBzNjIuOC0xNDAgMTQwLTE0MCAxNDAgNjIuOCAxNDAgMTQwLTYyLjggMTQwLTE0MCAxNDB6IiBmaWxsPSIjYzVjNWM1IiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBzdHlsZT0iIiBjbGFzcz0iIj48L3BhdGg+PHBhdGggZD0ibTM1Mi4wNzIgMTgzLjEyMS04OC04MGExMiAxMiAwIDAgMCAtMTYuMTQ0IDBsLTg4IDgwYTEyLjAwNiAxMi4wMDYgMCAwIDAgLTIuMjMgMTUuMDM5IDEyLjMzMSAxMi4zMzEgMCAwIDAgMTAuNjYgNS44NGgxMS42NDJ2NzZhMTIgMTIgMCAwIDAgMTIgMTJoMjhhMTIgMTIgMCAwIDAgMTItMTJ2LTQ0YTEyIDEyIDAgMCAxIDEyLTEyaDI0YTEyIDEyIDAgMCAxIDEyIDEydjQ0YTEyIDEyIDAgMCAwIDEyIDEyaDI4YTEyIDEyIDAgMCAwIDEyLTEydi03NmgxMS42NDJhMTIuMzMxIDEyLjMzMSAwIDAgMCAxMC42Ni01Ljg0IDEyLjAwNiAxMi4wMDYgMCAwIDAgLTIuMjMtMTUuMDM5eiIgZmlsbD0iI2M1YzVjNSIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCIgc3R5bGU9IiIgY2xhc3M9IiI+PC9wYXRoPjwvZz48L2c+PC9nPjwvc3ZnPg=="
                    width="40px" height="40px" /> Dirección</h3>


            <p class="text-muted">Las siguientes direcciones se utilizan por defecto en la página de pago</p>

            <div class="row mt-4 mb-4">
                <div class="col-6">
                    <p style="font-weight: bold;">Dirección de facturación</p>
                    <p class="text-muted" *ngIf="dir_facturacion">
                        {{dir_facturacion.nombre?dir_facturacion.nombre:''}} {{dir_facturacion.apellido?dir_facturacion.apellido:''}}<br>
                        {{dir_facturacion.direccion}} <br>
                        {{dir_facturacion.localidad?dir_facturacion.localidad.poblacion+', '+dir_facturacion.localidad.postal:''}}<br>
                        {{dir_facturacion.provincia?dir_facturacion.provincia.provincia:'-'}} <br>
                    </p>
                    <p class="text-muted" *ngIf="!dir_facturacion">
                        No hay ninguna dirección guardada. Puede añadirla pulsando el siguiente botón
                    </p>

                    <button class="btn btn-secondary" (click)="openFacturacion(content)">EDITAR DIRECCIÓN</button>

                </div>
                <div class="col-6">
                    <p style="font-weight: bold;">Dirección de envío</p>
                    <p class="text-muted" *ngIf="dir_envio">
                        {{dir_envio.nombre?dir_envio.nombre:''}} {{dir_envio.apellido?dir_envio.apellido:''}}<br>
                        {{dir_envio.direccion}} <br>
                        {{dir_envio.localidad?dir_envio.localidad.poblacion+', '+dir_envio.localidad.postal:'-'}}<br>
                        {{dir_envio.provincia?dir_envio.provincia.provincia:'-'}} <br>
                    </p>
                    <p class="text-muted" *ngIf="!dir_envio">
                        No hay ninguna dirección guardada. Puede añadirla pulsando el siguiente botón
                    </p>

                    <button class="btn btn-secondary" (click)="openEnvio(contentEnvio)">EDITAR DIRECCIÓN</button>
                </div>
            </div>


        </div>
    </div>
</div>

<div class="mb-4" *ngIf="screenWidth != undefined && screenWidth <= 990">

    <div class="container-lg mt-4 mb-4">
        <div class="row">
            <div class="col">
                  <div class="dropdown">
                    <button class="btn btn-outline-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-list" aria-hidden="true"></i>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <h6 class="dropdown-header">MI CUENTA</h6>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="/profile">Perfil</a>
                        <a class="dropdown-item" href="/orders">Pedidos</a>
                        <a class="dropdown-item" href="/address">Direcciones</a>
                        <a class="dropdown-item" href="/wishes">Lista de deseos</a>
                        <a class="dropdown-item" href="/accountDetails">Detalles de la cuenta</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" style="cursor: pointer;" (click)="cerrarSesion()"><i class="fas fa-sign-out-alt"></i> Salir</a>
    
                    </div>
                  </div>
            </div>
        </div>
    </div>

    <hr>

    <div class="row">

        <div class="col text-center" style="padding: 40px;">

            <h3 style="font-weight: bold;"><img
                    src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbG5zOnN2Z2pzPSJodHRwOi8vc3ZnanMuY29tL3N2Z2pzIiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgeD0iMCIgeT0iMCIgdmlld0JveD0iMCAwIDUxMiA1MTIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMiA1MTIiIHhtbDpzcGFjZT0icHJlc2VydmUiIGNsYXNzPSIiPjxnPjxnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgaWQ9Il8wMS1ob21lIiBkYXRhLW5hbWU9IjAxLWhvbWUiPjxnIGlkPSJnbHlwaCI+PHBhdGggZD0ibTI1NiA0Yy0xMDguMDc1IDAtMTk2IDg3LjkyNS0xOTYgMTk2IDAgNTIuNSAzMS44MDcgMTE5LjkyIDk0LjUzNyAyMDAuMzc4YTEwNjUuODE2IDEwNjUuODE2IDAgMCAwIDkzLjE2OSAxMDQuMjk0IDEyIDEyIDAgMCAwIDE2LjU4OCAwIDEwNjUuODE2IDEwNjUuODE2IDAgMCAwIDkzLjE2OS0xMDQuMjk0YzYyLjczLTgwLjQ1OCA5NC41MzctMTQ3Ljg3OCA5NC41MzctMjAwLjM3OCAwLTEwOC4wNzUtODcuOTI1LTE5Ni0xOTYtMTk2em0wIDMzNmMtNzcuMiAwLTE0MC02Mi44LTE0MC0xNDBzNjIuOC0xNDAgMTQwLTE0MCAxNDAgNjIuOCAxNDAgMTQwLTYyLjggMTQwLTE0MCAxNDB6IiBmaWxsPSIjYzVjNWM1IiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBzdHlsZT0iIiBjbGFzcz0iIj48L3BhdGg+PHBhdGggZD0ibTM1Mi4wNzIgMTgzLjEyMS04OC04MGExMiAxMiAwIDAgMCAtMTYuMTQ0IDBsLTg4IDgwYTEyLjAwNiAxMi4wMDYgMCAwIDAgLTIuMjMgMTUuMDM5IDEyLjMzMSAxMi4zMzEgMCAwIDAgMTAuNjYgNS44NGgxMS42NDJ2NzZhMTIgMTIgMCAwIDAgMTIgMTJoMjhhMTIgMTIgMCAwIDAgMTItMTJ2LTQ0YTEyIDEyIDAgMCAxIDEyLTEyaDI0YTEyIDEyIDAgMCAxIDEyIDEydjQ0YTEyIDEyIDAgMCAwIDEyIDEyaDI4YTEyIDEyIDAgMCAwIDEyLTEydi03NmgxMS42NDJhMTIuMzMxIDEyLjMzMSAwIDAgMCAxMC42Ni01Ljg0IDEyLjAwNiAxMi4wMDYgMCAwIDAgLTIuMjMtMTUuMDM5eiIgZmlsbD0iI2M1YzVjNSIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCIgc3R5bGU9IiIgY2xhc3M9IiI+PC9wYXRoPjwvZz48L2c+PC9nPjwvc3ZnPg=="
                    width="40px" height="40px" /> Dirección</h3>


            <p class="text-muted">Las siguientes direcciones se utilizan por defecto en la página de pago</p>

            <div class="row mt-4 mb-4">
                <div class="col">
                    <p style="font-weight: bold;">Dirección de facturación</p>
                    <p class="text-muted" *ngIf="dir_facturacion">
                        {{dir_facturacion.nombre?dir_facturacion.nombre:''}} {{dir_facturacion.apellido?dir_facturacion.apellido:''}}<br>
                        {{dir_facturacion.direccion}} <br>
                        {{dir_facturacion.localidad?dir_facturacion.localidad.poblacion+', '+dir_facturacion.localidad.postal:''}}<br>
                        {{dir_facturacion.provincia?dir_facturacion.provincia.provincia:'-'}} <br>
                    </p>
                    <p class="text-muted" *ngIf="!dir_facturacion">
                        No hay ninguna dirección guardada. Puede añadirla pulsando el siguiente botón
                    </p>

                    <button class="btn btn-secondary" (click)="openFacturacion(content)">EDITAR DIRECCIÓN</button>

                </div>
            </div>
            <div class="row mb-4">
                <div class="col">
                    <p style="font-weight: bold;">Dirección de envío</p>
                    <p class="text-muted" *ngIf="dir_envio">
                        {{dir_envio.nombre?dir_envio.nombre:''}} {{dir_envio.apellido?dir_envio.apellido:''}}<br>
                        {{dir_envio.direccion}} <br>
                        {{dir_envio.localidad?dir_envio.localidad.poblacion+', '+dir_envio.localidad.postal:'-'}}<br>
                        {{dir_envio.provincia?dir_envio.provincia.provincia:'-'}} <br>
                    </p>
                    <p class="text-muted" *ngIf="!dir_envio">
                        No hay ninguna dirección guardada. Puede añadirla pulsando el siguiente botón
                    </p>

                    <button class="btn btn-secondary" (click)="openEnvio(contentEnvio)">EDITAR DIRECCIÓN</button>
                </div>
            </div>
        </div>

    </div>
</div>


<ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Dirección de facturación</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <form #mainForm="ngForm" [formGroup]="formFacturacion">
            <div class="flex-column">
                <div class="form-group gro1">
                    <label for="nombre_facturacion" class="required">Nombre</label>
                    <input id="nombre_facturacion" name="nombre" formControlName="nombre" class="form-control" [maxlength]="250">
                </div>
                <div class="form-group">
                    <label for="apellido_facturacion" class="required">Apellidos</label>
                    <input id="apellido_facturacion" name="apellido" formControlName="apellido" class="form-control" [maxlength]="500">
                </div>
                <div class="form-group">
                    <label for="direccion_facturacion" class="required">Dirección</label>
                    <input id="direccion_facturacion" name="direccion" formControlName="direccion" class="form-control" [maxlength]="250">
                </div>
                <div class="form-group">
                    <label for="num_telefono_facturacion" class="required">Teléfono</label>
                    <input type="number" id="num_telefono_facturacion" name="num_telefono" formControlName="num_telefono" class="form-control" [maxlength]="11">
                </div>
                <div class="form-group">
                    <label for="direccion_email_facturacion" class="required">Correo electrónico</label>
                    <input type="email" id="direccion_email_facturacion" name="direccion_email" formControlName="direccion_email" class="form-control" [maxlength]="45">
                </div>

                <div class="form-group">
                    <label for="provincia_facturacion" class="required">Provincia</label>

                    <ngx-select id="provincia_facturacion"
                    name="provincia" 
                    formControlName="provincia"
                    [formControl]="selectProvincia"
                    [allowClear]="false" 
                    [items]="provincias" 
                    optionValueField="idprovincia"
                    optionTextField="provincia" 
                    placeholder="Provincia"
                    [(ngModel)]="provinciaSelected">
                    </ngx-select>
                </div>

                <div class="form-group grow2">
                    <label for="localidad_facturacion" class="required">Localidad</label>

                    <ngx-select id="localidad_facturacion"
                    [disabled]="!provinciaSelected"
                    name="localidad" 
                    formControlName="localidad"
                    [formControl]="selectPoblacion"
                    [allowClear]="false" 
                    [items]="localidades" 
                    optionValueField="idpoblacion"
                    optionTextField="poblacion" 
                    placeholder="Localidad">
                    </ngx-select>
                </div>

                <div class="form-group">
                    <label for="otros_datos_facturacion">Información adicional</label>
                    <textarea matInput class="form-control" id="otros_datos_facturacion" formControlName="otros_datos"
                            name="otros_datos" rows="3"></textarea>
                </div>
            </div>
            
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancelar</button>
        <button type="button" class="btn btn-primary" [disabled]="(!saveBtnEnabled) || (!formFacturacion.valid)" (click)="guardarFacturacion()">Guardar</button>
    </div>
  </ng-template>


  <ng-template #contentEnvio let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Dirección de envio</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <form #mainForm="ngForm" [formGroup]="formEnvio">
            <div class="flex-column">
                <div class="form-group gro1">
                    <label for="nombre_envio" class="required">Nombre</label>
                    <input id="nombre_envio" name="nombre" formControlName="nombre" class="form-control" [maxlength]="250">
                </div>
                <div class="form-group">
                    <label for="apellido_envio" class="required">Apellidos</label>
                    <input id="apellido_envio" name="apellido" formControlName="apellido" class="form-control" [maxlength]="500">
                </div>
                <div class="form-group">
                    <label for="direccion_envio" class="required">Dirección</label>
                    <input id="direccion_envio" name="direccion" formControlName="direccion" class="form-control" [maxlength]="250">
                </div>
                <div class="form-group">
                    <label for="num_telefono_envio" class="required">Teléfono</label>
                    <input type="number" id="num_telefono_envio" name="num_telefono" formControlName="num_telefono" class="form-control" [maxlength]="11">
                </div>
                <div class="form-group">
                    <label for="direccion_email_envio" class="required">Correo electrónico</label>
                    <input type="email" id="direccion_email_envio" name="direccion_email" formControlName="direccion_email" class="form-control" [maxlength]="45">
                </div>

                <div class="form-group">
                    <label for="provincia_envio" class="required">Provincia</label>

                    <ngx-select id="provincia_envio"
                    name="provincia" 
                    formControlName="provincia"
                    [formControl]="selectProvinciaEnvio"
                    [allowClear]="false" 
                    [items]="provinciasEnvio" 
                    optionValueField="idprovincia"
                    optionTextField="provincia" 
                    placeholder="Provincia"
                    [(ngModel)]="provinciaEnvioSelected">
                    </ngx-select>
                </div>

                <div class="form-group grow2">
                    <label for="localidad_envio" class="required">Localidad</label>

                    <ngx-select id="localidad_envio"
                    [disabled]="!provinciaEnvioSelected"
                    name="localidad" 
                    formControlName="localidad"
                    [formControl]="selectPoblacionEnvio"
                    [allowClear]="false" 
                    [items]="localidadesEnvio" 
                    optionValueField="idpoblacion"
                    optionTextField="poblacion" 
                    placeholder="Localidad">
                    </ngx-select>
                </div>

                <div class="form-group">
                    <label for="otros_datos_envio">Información adicional</label>
                    <textarea matInput class="form-control" id="otros_datos_envio" formControlName="otros_datos"
                            name="otros_datos" rows="3"></textarea>
                </div>
            </div>
            
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancelar</button>
        <button type="button" class="btn btn-primary" [disabled]="(!saveBtnEnabledEnvio) || (!formEnvio.valid)" (click)="guardarEnvio()">Guardar</button>
    </div>
  </ng-template>