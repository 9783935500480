import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'app-repara-home',
  templateUrl: './repara-home.component.html',
  styleUrls: ['./repara-home.component.scss']
})
export class ReparaHomeComponent implements OnInit {

	paramTest: boolean = false;

    constructor(
		private router: Router,
		private route: ActivatedRoute,
	) {

		this.route.queryParams.subscribe(
			(params) => {
				if (params.test !== undefined) {
					this.paramTest = false;  // 
				}
			}
		);
	}

    ngOnInit(): void {
    }

    navigateForm(tipoDispositivo: string) {
		if (this.paramTest) {
			let navExtras: NavigationExtras = {
				queryParams: {
					test: ''
				}
			}
			this.router.navigate(['/repara/solicitar-presupuesto', tipoDispositivo], navExtras);
		}
		else {
			this.router.navigate(['/repara/solicitar-presupuesto', tipoDispositivo]);
		}
    }

}
