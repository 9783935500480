import { Component, HostListener, OnInit } from '@angular/core';
import { CartItem } from "../../../models/cart-item";
import { Product } from '../../../models/product';
import { MessageService } from '../../../services/message.service';
import { StorageService } from '../../../services/storage.service';
import { IPayPalConfig, ICreateOrderRequest, ITransactionItem } from 'ngx-paypal';
@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.scss']
})
export class ShoppingCartComponent implements OnInit {
  cartItems: Array<CartItem>;
  total = 0;

  public payPalConfig?: IPayPalConfig;

  screenHeight: number | undefined;
  screenWidth: number | undefined;

  constructor(
    private messageService: MessageService,
    private storageService: StorageService
  ) {
    this.cartItems = [];
    this.onResize()
  }

  ngOnInit(): void {
    if (this.storageService.cartExists()) {
      this.cartItems = this.storageService.getCart();
    }
    this.getItem();
    this.total = this.getTotal();
    this.cartItems.forEach(item => {
      if(!item.imgUrl.includes('/almacenes')){
        item.imgUrl = item.imgUrl.replace('almacenes', '/almacenes')
      }
    });
    console.log(this.cartItems)
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?: undefined) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }


  mapItems() {
    var res: { name: string; quantity: number; unit_amount: { value: number; currency: string; }; }[] = [];
    this.cartItems.forEach((cartItem: CartItem) => {
      let item = {
        name: cartItem.productName,
        quantity: cartItem.quantity,
        unit_amount: {
          value: cartItem.productPrice,
          currency: 'EUR'
        }
      };
      res.push(item);
    });
    return res;
  }


  getItem(): void {
    this.messageService.getMessage().subscribe((product: Product) => {
      let exists = false;
      this.cartItems.forEach(item => {
        if (item.productId === product.id) {
          exists = true;
          item.quantity++;
        }
      });
      if (!exists) {
        const cartItem = new CartItem(product);
        this.cartItems.push(cartItem);
      }
      this.total = this.getTotal();
      this.storageService.setCart(this.cartItems);
    });
  }

  getTotal(): number {
    let total = 0;
    this.cartItems.forEach(item => {
      total += item.quantity * item.productPrice;
    });
    return +total.toFixed(2)
  }

  emptyCart(): void {
    this.cartItems = [];
    this.total = 0;
    this.storageService.clear();
  }

  deleteItem(i: number): void {
    if (this.cartItems[i].quantity > 1) {
      this.cartItems[i].quantity--;
    } else {
      this.cartItems.splice(i, 1);
    }
    this.total = this.getTotal();
    this.storageService.setCart(this.cartItems);
  }

}
